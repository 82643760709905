import React, {useEffect, useState} from 'react'
import ListingHomepage from './ListingHomepage'
import { useSelector } from 'react-redux'
import { showInfoToast } from '../../utils/toastNotif'
import { useLocation, useNavigate } from 'react-router-dom'
import RoutePath from '../../routes/RoutePath'

const ListingHomepageLogic = () => {
  const testimonialData = useSelector(state=>state.dashboard.testimonials)
    const [currentSlide, setCurrentSlide] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    const tokenFromRedux = useSelector(state=>state.auth.tokenId);
    const tokenFromStorage = localStorage.getItem('token');
    const token = tokenFromRedux || tokenFromStorage;

    useEffect(() => {
        const interval = setInterval(() => {
          setCurrentSlide((prevSlide) => (prevSlide + 1) % testimonialData?.length);
        }, 5000);
    
        return () => clearInterval(interval);
      }, [testimonialData]);

      const handleIndicatorClick = (index) => {
        setCurrentSlide(index);
      };
      
      const handleClick = ()=>{
        if(!token || token === "undefined" || token === ""){
          showInfoToast("kindly login to continue");
          navigate(RoutePath.login, {state: {data: location?.pathname + location?.search}});
          return;
        }else{
          navigate(RoutePath.listingForm);
        }
      }

  return (
    <><ListingHomepage
    testimonialData={testimonialData}
    currentSlide={currentSlide}
    handleIndicatorClick={handleIndicatorClick}
    handleClick={handleClick}
    /></>
  )
}

export default ListingHomepageLogic