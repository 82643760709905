import React, { useState, useEffect } from 'react';
import Faq from './Faq';

const FaqLogic = ({ type }) => {
  const [faqData, setFaqData] = useState([]);
  const [faqType, setFaqType] = useState('');
  const [openIndexes, setOpenIndexes] = useState([]);
  const [isFaqTypeChanging, setIsFaqTypeChanging] = useState(false);
  const isType = type || '';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(process.env.PUBLIC_URL + '/data/faqData.json');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        setFaqData(jsonData.faq);
        setFaqType(jsonData.faq[0]?.heading);
      } catch (error) {
        console.error('Error fetching faq data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setOpenIndexes([]);
    setIsFaqTypeChanging(true);
    const timer = setTimeout(() => {
      setIsFaqTypeChanging(false); 
    }, 500);

    return () => clearTimeout(timer);
  }, [faqType]);

  const toggleAnswer = (index) => {
    if (openIndexes.includes(index)) {
      setOpenIndexes(openIndexes.filter((i) => i !== index));
    } else {
      setOpenIndexes([...openIndexes, index]);
    }
  };

  return (
    <Faq
      faqData={faqData}
      faqType={faqType}
      setFaqType={setFaqType}
      toggleAnswer={toggleAnswer}
      openIndexes={openIndexes}
      isType={isType}
      isFaqTypeChanging={isFaqTypeChanging} 
    />
  );
};

export default FaqLogic;
