import React from 'react'
import ServiceDropDown from './ServiceDropDown'

const ServiceDropDownLogic = ({isServiceDropdownOpen, togglingServiceMobileHeaderRef, togglingServiceDesktopHeaderRef, serviceOptions, selectService, setisServiceDropdownOpen, togglingDivRef, togglingDivMobileRef}) => {
  
  
  return (
    <><ServiceDropDown togglingDivRef={togglingDivRef} togglingServiceMobileHeaderRef={togglingServiceMobileHeaderRef} togglingServiceDesktopHeaderRef={togglingServiceDesktopHeaderRef} setisServiceDropdownOpen={setisServiceDropdownOpen} togglingDivMobileRef={togglingDivMobileRef} isServiceDropdownOpen={isServiceDropdownOpen} serviceOptions={serviceOptions} selectService={selectService}/></>
  )
}

export default ServiceDropDownLogic