import React from 'react'
import CollegeDropdown from './CollegeDropdown'
import { useSelector } from 'react-redux'


const CollegeDropdownLogic = ({contactUs, isCollegeDropdownOpen,togglingCollegeMobileNavbarRef, togglingCollegeMobileHeaderRef, togglingCollegeDesktopHeaderRef, setisCollegeDropdownOpen, collegeList, selectCollege, searchQuery, setSearchQuery, filteredColleges, togglingCollegeDesktopNavbarRef}) => {
  
  const topCollegeCodes = ["DTU", "MAIT", "SSCBS"];
  // const topSearchColleges = collegeList?.slice(0, 3);
  const topSearchColleges = collegeList.filter(college => topCollegeCodes.includes(college.collegeCode));
  const screenWidth = useSelector(state=>state.screenWidth.screenWidth);

  
  return (
    <><CollegeDropdown contactUs={contactUs} togglingCollegeMobileNavbarRef={togglingCollegeMobileNavbarRef} togglingCollegeMobileHeaderRef={togglingCollegeMobileHeaderRef} togglingCollegeDesktopHeaderRef={togglingCollegeDesktopHeaderRef} togglingCollegeDesktopNavbarRef={togglingCollegeDesktopNavbarRef}  setisCollegeDropdownOpen={setisCollegeDropdownOpen} screenWidth={screenWidth} isCollegeDropdownOpen={isCollegeDropdownOpen} selectCollege={selectCollege} collegeList={collegeList} searchQuery={searchQuery} setSearchQuery={setSearchQuery} filteredColleges={filteredColleges} topSearchColleges={topSearchColleges}/></>
  )
}

export default CollegeDropdownLogic