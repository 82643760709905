import React from 'react'
import PaymentSuccessful from './PaymentSuccessful'

const PaymentSuccessfulLogic = () => {

 

    
  return (
    <><PaymentSuccessful /></>
  )
}

export default PaymentSuccessfulLogic