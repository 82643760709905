import React from 'react'
import Modal from "react-awesome-modal";
import SpinnerLogic from '../spinner/SpinnerLogic';
import { Input } from 'antd';
import InputOtp from '../otpInputField/OtpInput';


const FirebaseOtpModal = ({checkLoginOrSignup,signup,verifyAndLogin, openModal, setOpenModal, phoneNumber, setOtp, otp, verifying, otperrormsg, setOtpErrormsg, timerotp, confirmationResult, setLoading,setConfirmationResult, settimerotp, handleLoginWithOtp}) => {
  // const onChange = (text) => {
  //   setOtp(text);
  // };
  // const sharedProps = {
  //   onChange,
  // };  
  const handleOtpComplete = (otp) => {
    console.log("OTP is complete: ", otp);
    // Call any function you want here after OTP is fully entered
    
    checkLoginOrSignup(otp);
};
  return(
        <Modal
        visible={openModal}
          width="400"
          height="300"
          effect="fadeInUp"
        >
           <div className='p-5 flex flex-col items-center justify-center'>
            {
              verifying ? <div className='w-full h-full justify-center items-center flex flex-col'>
              <p className='text-secondary text-base mt-11 font-poppins font-medium '>Verifying otp....</p>
              <div className="z-[9999] absolute min-h-full inset-0 bg-black opacity-50"></div><div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'><SpinnerLogic /></div>
              </div> :
              <>
            <h1 className='font-poppins font-semibold mt-5 text-lg text-center text-secondary'>Please check your Mobile.</h1>
            <p className='font-poppins font-semibold mt-1 mb-4 text-sm text-center text-textColor'>We've sent a code to +91{phoneNumber}</p>
            <InputOtp
                        otpLength={4}
                        onComplete={handleOtpComplete}
                        // You can specify any additional props that the InputOTP component accepts:
                        numberOnly={true}
                        autoFocus={true}
                        wrapperClass={'otpWrapper'}
                        inputWrapperClass={'otpInputWrapper'}
                        inputClass={'otpInput '}
                        errorMessageClass={'errorMessage'}
                    // type="number"
                    // pattern={/your-regex-pattern/}
                    // error={false}
                    // errorMessage="Custom error message"
                    // disabled={false}

                    />
            {/* <Input.OTP variant='filled' size='large' length={4} formatter={(str) => str.toUpperCase()} {...sharedProps} /> */}
            {/* <input value={otp} onChange={(e)=>setOtp(e.target.value)} type="number" placeholder='Enter OTP' className='border text-base text-textColor font-poppins font-normal mt-5 border-primary outline-none mx-auto rounded-lg w-[90%] py-2 px-4'/> */}
            <p className='mt-1 text-red-800 font-poppins text-xs font-normal'>{otperrormsg && otperrormsg}</p>
            {
                  confirmationResult && timerotp >= 0  && <p className='text-sm mt-2 font-normal text-textColor font-poppins'>Resend OTP in {timerotp} </p>
                }
                {
                  timerotp < 0 ? <button  id='stud-resend-btn' className="mt-2 cursor-pointer font-poppins text-sm font-bold  text-primary rounded-md " onClick={(e)=>handleLoginWithOtp(phoneNumber, setLoading, setOpenModal, setConfirmationResult,"stud-resend-btn", settimerotp)}>Resend OTP</button> : 
                  <button disabled={true}  className="cursor-pointer mt-1 rounded-md "><p className="text-sm font-poppins text-gray-300 font-medium">Resend OTP</p></button>
                }
             <div className='flex mt-5 w-full items-center justify-center'>
              <button  onClick={()=>{setOpenModal(false); setOtp(""); setOtpErrormsg("")}} className='py-[10px] px-[16px] rounded-[4px] w-[48%] border border-[#E4E4E7] blog-button-shadow text-[#3F3F46] font-medium text-center text-sm font-poppins'>Cancel</button>
              {/* <button  onClick={checkLoginOrSignup} className='blog-button-shadow w-[48%] text-center font-poppins text-sm font-medium background-primary text-white py-[10px] px-[16px] rounded-[4px]'>Verify</button> */}
              
             </div>
              </>
            }
           </div>
        </Modal>
      )
}

export default FirebaseOtpModal