import React, { useState } from 'react';
import { CiHeart } from 'react-icons/ci';
import { LuPlus } from 'react-icons/lu';
import ShareLogic from '../../components/share/ShareLogic';
import ImageCarouselLogic from '../../components/imageCarousel/ImageCarouselLogic';
import CollegeDropdownLogic from '../../components/collegeDropdown/CollegeDropdownLogic';
import { useSelector } from 'react-redux';
import heart from "../../assets/images/heart.svg"
import { handleFav } from '../../utils/handleFav';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import redHeart from "../../assets/images/redHeartIcon.svg";
import BreadCrumbsLogic from '../../components/breadCrumbs/BreadCrumbsLogic';
import BarLoaderLogic from '../../components/barLoader/BarLoaderLogic';
import RoutePath from '../../routes/RoutePath';
import plus from "../../assets/images/plus.svg";
import minus from "../../assets/images/minus.svg";
import { handleUpdateCart } from '../../utils/handleUpdateCart';


const FurnishingById = ({ handleUpdateClick,quantity,data, handleCart, showAllDetails, toggleDetails, isCollegeDropdownOpen,
    toggleCollegeDropdown,
    selectCollege,
    selectedCollege,
    collegeList,
    searchQuery,
    setSearchQuery,
    filteredColleges,
    handleAvailability,
    loading,
    cartLoading,
    isFavorited,
    progress,
    favloading,setFavLoading, apibody
 }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  
  // Calculate the length of productDetails while excluding empty string values
  const screenWidth = useSelector(state=>state.screenWidth.screenWidth)
  const filteredProductDetailsLength = Object.entries(data?.productDetails || {}).filter(([_, value]) => value !== '').length;
  

  return (
    <div className='md:px-20 px-5  '>
      <BreadCrumbsLogic/>
      {!data ? (
        <div></div>
      ) : (
        <>
          <div className='w-full justify-between items-center flex'>
            <div>
              <h1 className='text-secondary font-poppins font-semibold text-xl md:leading-10 md:text-[32px] md:tracking-[-0.2px]'>
                {data?.productId}
              </h1>
              <p className='text-[#3F3F46] mt-2 font-poppins font-medium text-xs md:text-base'>
                Product No. {data?.productId}
              </p>
            </div>
            <div className="flex md:hidden justify-center items-center space-x-4">
           
            {/* <button disabled={cartLoading} onClick={handleCart} className="rounded-[4px] py-[10px] px-5 text-white blog-button-shadow background-primary font-poppins font-medium text-sm transition duration-300">
               {
                cartLoading ? <div className='animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-gray-900'></div> : <p>Add to cart</p>
                }
            </button> */}
            <button onClick={()=>navigate(RoutePath.cart)} className="text-primary font-poppins cursor-pointer text-sm font-medium">
                View Cart
            </button>
        </div>
            <div className='md:flex hidden items-center space-x-3'>
              <ShareLogic/>
              {
          (favloading ? <div className='animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-gray-900'></div> : <div onClick={(e)=>{
            handleFav(apibody, navigate, dispatch, setFavLoading, location);}} className='cursor-pointer'><img src={isFavorited ? redHeart : heart} alt="" /></div>)
         }
            </div>
          </div>
          <div className='flex md:mt-[50px] mt-[20px] flex-col md:flex-row md:items-start md:justify-between items-center'>
            <div className='flex flex-col items-center md:items-start md:w-1/2 w-full'>
              <div className='w-2/3 md:h-[432px] h-[232px] aspect-[16/9] bg-cover rounded-xl'>
                <ImageCarouselLogic images={data?.images} indicator={data?.images?.length > 1 ? true : false} />
              </div>
              <div className='mt-[30px] w-full'>
              <div className='flex justify-between w-full items-start'>
              <p className=' text-textColor font-poppins text-xs font-normal'>Price incl. of all taxes</p>
                <div className='flex md:hidden items-center space-x-3'>
              <ShareLogic/>
              {
          (favloading ? <div className='animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-gray-900'></div> : <div onClick={(e)=>{
            handleFav(apibody, navigate, dispatch, setFavLoading, location);}} className='cursor-pointer'><img src={isFavorited ? redHeart : heart} alt="" /></div>)
         }
            </div>
              </div>
                <p className='font-poppins mt-1 mb-5 font-semibold text-sm text-secondary'>
                  Rs.<span className=''>{data?.rent}</span>
                  <span className='text-textColor  font-poppins font-normal'>/month</span>
                </p>
                <h1 className='font-poppins text-textColor font-normal text-sm md:text-base'>{data?.description}</h1>
              </div>
            </div>
            <div className={`md:w-[60%] w-full md:rounded-[16px] md:p-10 md:border md:border-[#D4D4D8] flex flex-col ${screenWidth >= 768 && 'header-box-shadow'}`}>
              <div className='hidden md:block'>
                <p className='font-poppins  font-semibold text-lg text-secondary'>
                  Rs.<span className='text-xl'>{data?.rent}</span>
                  <span className='text-textColor text-xl font-poppins font-normal'>/month</span>
                </p>
                <p className='mt-1 text-textColor  font-poppins text-xs font-normal'>Price incl. of all taxes</p>
              </div>
              <hr className='text-[#D4D4D8] my-7' />
              <div className='w-full mb-8'>
                <h1 className='font-semibold font-poppins text-secondary md:text-base text-sm'>Product Details</h1>
                {Object.entries(data?.productDetails || {}).map(([key, value], index) => (
                  <div key={index} className={`flex flex-wrap gap-2 items-center mt-1 ${(!showAllDetails && index >= 3) ? 'hidden' : ''}`}>
                    {value !== '' && (
                      <>
                        <p className='text-secondary font-poppins font-medium text-sm'>{key.charAt(0).toUpperCase() + key.slice(1)}:</p>
                        {Array.isArray(value) ? (
                          value.map((item, itemIndex) => (
                            <p key={itemIndex} className='text-[#52525B] font-normal font-poppins text-sm'>
                              {item}
                            </p>
                          ))
                        ) : (
                          <p className='text-[#52525B] font-normal font-poppins text-sm'>{value}</p>
                        )}
                      </>
                    )}
                  </div>
                ))}
                {filteredProductDetailsLength > 3 && (
                  <button onClick={toggleDetails} className='text-primary font-poppins font-medium text-sm mt-4'>
                    {showAllDetails ? 'View less' : 'View more'}
                  </button>
                )}
              </div>
              {/* <div className='flex items-center justify-between w-full'>
              
             
                  <div className='outline-none cursor-pointer relative bg-white font-poppins font-normal text-base text-textColor rounded py-[10px] px-[14px] w-2/3 border border-[#D4D4D8] flex blog-button-shadow'>
                 
               <p onClick={toggleCollegeDropdown} className={`font-poppins font-normal text-base ${selectedCollege ? 'text-secondary' : 'text-textColor'}`}>{selectedCollege ? selectedCollege?.collegeName : 'Enter Your College' }</p>
               <CollegeDropdownLogic isCollegeDropdownOpen={isCollegeDropdownOpen} selectCollege={selectCollege} collegeList={collegeList} searchQuery={searchQuery} setSearchQuery={setSearchQuery} filteredColleges={filteredColleges}/>
            </div>
                {
                    loading ? <div className='animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-gray-900'></div>  : <button onClick={handleAvailability} className='text-primary font-poppins font-medium text-sm'>Check availability</button>
                }
              </div> */}
             {
              (cartLoading && screenWidth >= 768) ? <BarLoaderLogic progress={progress}/> : 
              (quantity !== undefined && !isNaN(quantity) && quantity !== 0) ? <><div className='flex w-full items-center justify-between rounded-md  '>
              <button className='w-1/2 rounded-md bg-[#EF7C22] flex justify-center align-middle p-2' onClick={()=>handleUpdateClick('minus')} ><img src={minus} alt="-" className='h-8'/></button>
              <p className='md:text-xl text-lg w-1/6 flex justify-center align-middle font-poppins font-semibold text-[#3F3F46]'>{quantity}</p>
              <button className='w-1/2 rounded-md bg-[#EF7C22] flex justify-center align-middle p-2' onClick={()=>handleUpdateClick('plus')} ><img src={plus} alt="+" className='h-8'/></button>
              </div></> : <><button onClick={handleCart} className='font-poppins mt-7 w-full justify-center text-white md:flex items-center space-x-2 text-sm font-medium blog-button-shadow background-primary rounded-[4px] py-[12px] px-5'>
              
              <p >Add to Cart</p>
            </button></>
             }
            </div>
          </div>
          <div className='mt-[50px] md:w-1/2 w-full'>
            {data?.specification && (
              <>
                <h1 className='text-secondary font-poppins font-medium text-sm md:text-base'>Specification</h1>
                <p className='font-poppins mt-2 text-textColor font-normal text-sm md:text-base'>{data?.specification}</p>
              </>
            )}
            {data?.measurement && (
              <>
                <h1 className='text-secondary mt-5 font-poppins font-medium text-sm md:text-base'>Measurement</h1>
                <p className='font-poppins mt-2 text-textColor font-normal text-sm md:text-base'>{data?.measurement}</p>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default FurnishingById;
