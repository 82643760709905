import React from 'react'
import ImageCarouselLogic from '../imageCarousel/ImageCarouselLogic'
import phone from "../../assets/images/phoneIconWhite.png"
import { IoIosStar } from 'react-icons/io'
import { handleCheckAvailability } from '../../utils/handleCheckAvailability'
import { useNavigate } from 'react-router-dom'

const LocalAssistCard = ({ele, id, dispatch, tokenId, loading, setLoading}) => {
   const navigate = useNavigate();
    return (
        <div className={`w-full mt-6 flex mb-6  flex-col md:flex-row items-start  md:space-x-8 `}>
             <div className={` w-full md:w-4/12 rounded-xl aspect-[16/9] bg-cover h-[256px]`}>
            <ImageCarouselLogic images={ele?.images} id={ele?._id} apibody={ele} alt="" className={`rounded-xl bg-cover w-full `} />
          </div>
          <div className={` md:w-8/12 w-full mt-5 md:mt-0`}>
          <div className='flex  items-start  justify-between'>
              <div>
              <p  className={`font-poppins cursor-pointer font-semibold text-[#0F172A] text-base  md:text-lg`}>{ele?.name}</p>
              <div className='flex items-center space-x-3'>
              {
                ele?.averageRating &&  <div className='md:hidden flex items-center space-x-2'>
                <IoIosStar className='text-[#475569]' />
                <p className='text-[#222222] font-normal text-xs'>{ele?.averageRating} |</p>
              </div>
               }
              <p className='font-poppins text-xs md:text-sm font-normal text-textColor mt-1'>{ele?.profession}</p>
              </div>
              </div>
               <div className='md:hidden block'>
              <p className='text-textColor text-right font-poppins font-normal text-xs'>Starting from</p>
              <p className='font-poppins text-sm text-secondary font-semibold'>₹{ele?.baseChargePerVisit} <span className='text-textColor font-poppins font-normal text-sm'>/month</span></p>
            </div>
               {
                ele?.averageRating &&  <div className='md:flex hidden items-center space-x-2'>
                <IoIosStar className='text-[#475569]' />
                <p className='text-[#222222] font-normal text-[15px] leading-[20px]'>{ele?.averageRating}</p>
              </div>
               }
            </div>
            {
              ele?.details && <div className={` mt-7 block`}>
              <p className='font-poppins text-textColor mb-3 text-xs font-normal'>Service</p>
              {
                ele?.details?.map((detail, ind)=>(
                    <div className='flex flex-col items-start space-y-3 font-normal font-poppins text-xs text-[#0F172A]'>{detail}</div>
                ))
              }
          </div>
            }
            <div className='flex flex-wrap gap-3 items-center mt-7 justify-between w-full'>
              <div className={` hidden md:block `}>
                <p className='text-textColor  font-poppins mb-2 font-normal text-xs'>Starting from</p>
                <p className='font-poppins text-lg text-secondary font-semibold'>₹{ele?.baseChargePerVisit} <span className='text-textColor font-poppins font-normal text-sm'>/month</span></p>
              </div>
              {
               loading ? <div className='animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-gray-900'></div> :  <div className='flex items-center w-full md:w-auto space-x-3'>
               <button disabled={loading} onClick={()=>{
                   handleCheckAvailability(id, "localAssistance", dispatch,tokenId, navigate, setLoading)
               }}  className='font-poppins w-full  justify-center text-white flex items-center space-x-2 text-sm font-medium blog-button-shadow background-primary rounded-[4px] py-[12px] px-5'><img src={phone} alt="" />
                   <p>Check availability</p></button>
             </div>
              }
            </div>
          </div>
        </div>
      )
}

export default LocalAssistCard