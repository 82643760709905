import React, {useState, useEffect, useContext} from 'react'
import RoutePath from '../../routes/RoutePath';
import FurnishingById from './FurnishingById'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { showErrorToast, showInfoToast, showSuccessToast } from '../../utils/toastNotif';
import { handleAddToCart } from '../../utils/handleAddToCart';
import  {handleCheckAvailability} from "../../utils/handleCheckAvailability"
import useFindCollege from '../../utils/findCollege';
import { handleUpdateCart } from '../../utils/handleUpdateCart';
import LoginLogic from '../login/LoginLogic';
import CartContext from '../../context/CartContext'

const FurnishingByIdLogic = () => {
    const collegeList = useSelector(state=>state.dashboard.colleges)
    const token = localStorage.getItem('token')
    const tokenId = useSelector(state=>state.auth.tokenId)
    const userState = useSelector(state=>state.auth.userState);
    const location = useLocation();
    const {id} = useParams();
    const [isFavorited, setIsFavorited] = useState(false);
    const [favloading, setFavLoading] = useState(false);
    const userDataString =  localStorage.getItem('userData');
    const [key, setKey] = useState(Date.now());
    const dispatch = useDispatch();
    const [showAllDetails, setShowAllDetails] = useState(false);
    const [cartButtonClicked, setCartButtonClicked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [LocalCartLoading,setLocalCartLoading] = useState(false);
    const [data, setData] = useState([]);
    const [isCollegeDropdownOpen, setisCollegeDropdownOpen] = useState(false);
    const [selectedCollege, setselectedCollege] = useState(null);
    const [filteredColleges, setFilteredColleges] = useState([])
    const [searchQuery, setSearchQuery] = useState('');
    const [checked, setChecked] = useState(false);
    const [cartLoading, setCartLoading] = useState(false);
    const status = useSelector(state => state.dashboard.status);
    const error = useSelector(state => state.dashboard.error);
    const [isAvailable, setIsAvailable] = useState(false);
    const navigate = useNavigate();
    const [progress, setProgress] = useState(0);
    const {findCollegeDetailsById} = useFindCollege();
    const [cartItems,setCartItems] = useState([]);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const initialqty = cartItems?.find(item => item.item._id === data?._id)?.item?.quantity;
    const [quantity, setQuantity] = useState(initialqty);
    const { updateCartItems } = useContext(CartContext);

    useEffect(()=>{
      setQuantity(initialqty);
    },[initialqty])
    
    
    useEffect(()=>{
      const fetchData = async()=>{
          try{
              setLoading(true);
              const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/users/getCartItems`, {
                  method: "GET",
                  headers: {
                    'Authorization': `Bearer ${token}`,
                  },
              });
              if(response === null || response === undefined){
                  throw new Error("Response is null")
              }
            
              if(!response.ok){
                  if(response?.status === 401){
                      if(token){
                        showErrorToast("kindly login again to continue");
                        navigate(RoutePath.login, {state: {data: location?.pathname + location?.search}});
                      }
                  }else{
                  throw new Error("Response not ok");}
              }
              const data = await response.json();
              if(data === null || data === undefined){
                  throw new Error("Data is null")
              }
              if (!Array.isArray(data)) {
                // If data is not an array, handle the error appropriately
                throw new Error("Data is not an array");
              }
              setCartItems(data);
              updateCartItems(data);
          }catch(error){
              console.log("Error fetching cart data", error)
          }finally{
              setLoading(false)
          }
      }
      fetchData()
  },[key])

    useEffect(()=>{
      if (location.state && location.state.data) {
        setData(location.state.data)
      }
      const searchParams = new URLSearchParams(location.search);
      const eleString = searchParams.get('data');
      if (eleString) {
        const decodedEleString = decodeURIComponent(atob(eleString));
        const ele = JSON.parse(decodedEleString);
        setData(ele);
        console.log(ele);
      }
    }, [location])

    useEffect(()=>{
      if(!data){
        showInfoToast("There is some problem displaying in data, kindly try again");
         navigate(RoutePath.homepage)
      }
     }, [data]);

     useEffect(() => {
      const handleStorageChange = () => {
          setKey(Date.now());  
      };
  
      window.addEventListener('storage', handleStorageChange);
  
      return () => {
        window.removeEventListener('storage', handleStorageChange);
      };
    }, []);


    useEffect(()=>{
      if(id){
        const college = findCollegeDetailsById(id);
        college && setselectedCollege(college)
      }
    }, [id])

  
    useEffect(() => {
       
      if (userDataString) {
        const userData = JSON.parse(userDataString);
        const favouriteIds = userData.favourites.map(favourite => favourite._id);
        setIsFavorited(favouriteIds.includes(data?._id));
      }
    }, [data?._id, userDataString, key]);

    const toggleCollegeDropdown = () => {
      
        if(!isCollegeDropdownOpen){
          if (status === 'loading'){
            showInfoToast("Loading college list");
            return;
          }
          if (status === 'failed') {
            console.log(error);
            showErrorToast("There was some problem fetching college list, please try again later")
            return;
          }
        }
        isCollegeDropdownOpen  && setSearchQuery('')
       setisCollegeDropdownOpen(!isCollegeDropdownOpen);
      
    };

    const selectCollege = (college) => {
      setselectedCollege(college);
      setCartButtonClicked(false);
      setChecked(false);
      setIsAvailable(false);
      setisCollegeDropdownOpen(false);
    };

    useEffect(()=>{
      const tempCollege = collegeList.filter((college) =>
      college.collegeName.toLowerCase().includes(searchQuery.toLowerCase())
    );
        setFilteredColleges(tempCollege);
    }, [collegeList, searchQuery])


   const toggleDetails = () => {
     setShowAllDetails(!showAllDetails);
   };

   useEffect(()=>{
      const fetchData = async ()=>{
       try{
         const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/users/updateCart`, {
          method: 'PATCH',
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
            itemId: data?._id,
            quantity: quantity,
            collegeId: selectedCollege?._id
          })
         });

         if(response === null || response === undefined){
           throw new Error("Response is null");
         }
         if(response?.status === 401){
             showErrorToast("kindly login again to continue");
             navigate(RoutePath.login, {state: {data: location?.pathname + location?.search}});
         }
         if(!response.ok){
          console.log(response.status);
           throw new Error("Something went wrong");
         }
         const jsonData = await response.json();
            if (jsonData === null || jsonData === undefined) {
                console.log("Data is null or undefined")
                throw new Error("Data is null or undefined");
            }
         showSuccessToast("Item updated in cart");
         setKey(Date.now());
       }catch(error){
          console.log(error);
          showErrorToast("Item cannot be added to cart");

       }finally{
          setCartLoading(false);
       }
      }
     if(cartButtonClicked){
      setCartButtonClicked(false);
      fetchData();
     }
   }, [quantity])
   
   const handleUpdateClick = (action) =>{
      if(action==='plus'){
        handleUpdateCart(data, tokenId, setCartLoading, setLocalCartLoading, navigate, setKey, quantity+1, location,setProgress);   

      }else{
        handleUpdateCart(data, tokenId, setCartLoading, setLocalCartLoading, navigate, setKey, quantity-1, location,setProgress);   

      }    
    }

   const handleCart = ()=>{
     
         handleAddToCart(token,setShowLoginModal,navigate, dispatch, cartButtonClicked, setCartButtonClicked, userState, data?._id, setQuantity, quantity, setCartLoading, location, setProgress);
     
   }

   const handleAvailability = ()=>{
     if(!selectedCollege){
      showInfoToast("select a college");
      return;
     }
     setChecked(true);
    const result = handleCheckAvailability(selectedCollege?._id, "furnishing", dispatch,tokenId, navigate, setLoading);
    if(result){
      setIsAvailable(true);
    }else{
      setIsAvailable(false);
    }
   }

  return (
    <><FurnishingById handleUpdateClick={handleUpdateClick} quantity={quantity} data={data} showAllDetails={showAllDetails} toggleDetails={toggleDetails}
    id={data?._id}
    isFavorited={isFavorited}
    setIsFavorited={setIsFavorited}
    apibody={data}
    favloading={favloading}
    setFavLoading={setFavLoading}
    isCollegeDropdownOpen={isCollegeDropdownOpen}
    toggleCollegeDropdown={toggleCollegeDropdown}
    selectCollege={selectCollege}
    selectedCollege={selectedCollege}
    collegeList={collegeList}
    searchQuery={searchQuery}
    setSearchQuery={setSearchQuery}
    filteredColleges={filteredColleges}
    handleCart={handleCart}
    handleAvailability={handleAvailability}
    loading={loading}
    cartLoading={cartLoading}
    progress={progress}
    />
    {showLoginModal && <LoginLogic setShowLoginModal={setShowLoginModal} isFromLoginBtn={false} />}
    </>
  )
}

export default FurnishingByIdLogic