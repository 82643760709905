import React from 'react'
import {IoIosStar} from "react-icons/io";
import ShareLogic from '../../components/share/ShareLogic';

import useFindLocality from "../../utils/findLocality";
import heart from "../../assets/images/heart.svg"
import { handleFav } from '../../utils/handleFav';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import redHeart from "../../assets/images/redHeartIcon.svg";
import ImageCarouselLogic from '../../components/imageCarousel/ImageCarouselLogic'
import WhatsappContactLogic from '../../components/whatsappContact/WhatsappContactLogic';
import map from "../../assets/images/map.png"
import { CiCalendar } from "react-icons/ci";
import ScheduleVisitLogic from '../../components/scheduleVisit/ScheduleVisitLogic';
import { useSelector } from 'react-redux';
import BreadCrumbsLogic from '../../components/breadCrumbs/BreadCrumbsLogic';

const CollegeListingById = ({data, openVisitForm, setOpenVisitForm, isFavorited,loading,setLoading, apibody}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
    const {findLocalityName} = useFindLocality();
    const screenWidth = useSelector(state=>state.screenWidth.screenWidth)

    console.log("data",data);

    function camelCaseToNormal(camelCaseStr) {
      // Split the camelCase string at each uppercase letter and join with a space
      const words = camelCaseStr?.replace(/([A-Z])/g, ' $1').toLowerCase();
  
      // Capitalize the first letter of each word
      const normalStr = words?.replace(/\b\w/g, char => char.toUpperCase());
  
      return normalStr;
  }
  return (
    <div className='md:px-20  px-5 '>
      <BreadCrumbsLogic/>
         <WhatsappContactLogic/>
        <div className='w-full flex items-center justify-between'>
            <div> 
                <div className='flex items-center space-x-3'>
                    <p className='font-poppins text-secondary font-semibold text-2xl md:text-[32px] md:leading-10 md:tracking-[-0.2px]'>{camelCaseToNormal(data?.furnishing)} {data?.propertySize?.[0]} in {data?.permanentAddress?.addressLine2 ? data.permanentAddress.addressLine2 : findLocalityName(data?.localityId)}</p>
                     <div className='font-poppins text-primary text-center font-medium text-sm rounded-[100px] bg-[#FFF4EB] py-[6px] px-[14px]'>{data?.genderPreference}</div>
                    </div>
                <div>
        <div className='font-poppins font-normal text-xs md:text-base text-textColor mt-2 flex items-center space-x-3'>
        {
            data?.rating && <div className='flex items-center space-x-2'>
            <IoIosStar className='text-[#475569]' />
            <p className='text-[#222222] font-normal text-[15px] leading-[20px]'>{data?.rating}</p>
          </div>
          }
             <p>{findLocalityName(data?.localityId)}</p>
         
          </div>
        </div>
        </div>
        <div className='flex items-center space-x-4'>
         <ShareLogic/> 
         {
          (loading ? <div className='animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-gray-900'></div> : <div onClick={(e)=>{
            handleFav(apibody, navigate, dispatch, setLoading, location);}} className='cursor-pointer'><img src={isFavorited ? redHeart : heart} alt="" /></div>)
         }
        </div>
        </div>
        <div className='flex md:mt-[50px] mt-[20px] items-start justify-between w-full'>
            <div className='w-full md:w-[55%]'>
             <div className='w-full md:h-[432px] h-[232px] aspect-[16/9] rounded-xl bg-cover'> <ImageCarouselLogic images={data?.imgArr} indicator={true}/></div>
             <button onClick={()=>setOpenVisitForm(true)} className='font-poppins mt-5 w-full md:hidden text-white text-sm font-medium blog-button-shadow background-primary flex items-center justify-center space-x-2 rounded-[4px] py-[10px] px-4'><CiCalendar /><p>Schedule visit</p></button>
             <p className='mt-5 font-poppins text-textColor font-normal md:text-base text-xs'>Start from single bed at</p>
            <p className='font-poppins font-semibold mt-3 text-secondary text-sm md:text-[36px] md:leading-[44px] md:tracking-[-0.2px]'>₹{data?.rent} <span className='font-normal text-textColor'>/month</span></p>
             <p className='text-[#475569] text-sm md:text-base font-poppins mt-5 md:mt-7 font-normal'>{data?.description === "NA" ? '' : data?.description}</p>
             <hr className='w-full text-[#E4E4E7] my-10'/>
             {
                data?.features?.length > 0 && <>
                <h1 className='font-poppins text-secondary md:font-medium md:text-2xl font-semibold text-sm'>Room Amenities</h1>
                <div className='flex mt-2 flex-wrap items-center w-full gap-3'>
              {
                data?.features.map((amenity, ind) => (
                  <div key={ind} className='flex items-center border border-[#E2E8F0] space-x-3 rounded-[24px] px-3 py-2'>
                    {/* <img src={amenity?.image} alt="" className='w-[15px] h-[15px]' /> */}
                    <p className='font-poppins text-xs md:text-sm text-[#27272A] font-normal'>{amenity}</p>
                  </div>
                ))
              }
            </div>
                </>
             }
             {
                data?.houseRules?.length > 0 && <>
                <h1 className='font-poppins text-secondary md:font-medium md:text-2xl font-semibold text-sm'>House Rules</h1>
                <div className='flex mt-2 flex-wrap items-center gap-4'>
              {
                data?.houseRules.map((rules, ind) => (
                  <div key={ind} className='flex items-center border border-[#E2E8F0] space-x-3 rounded-[24px] px-3 py-2'>
                    {/* <img src={amenity?.image} alt="" className='w-[15px] h-[15px]' /> */}
                    <p className='font-poppins text-xs md:text-sm text-[#27272A] font-normal'>{rules}</p>
                  </div>
                ))
              }
            </div>
                </>
             }
              {/* <hr className='w-full text-[#E4E4E7] my-10'/>
              <h1 className='font-poppins text-secondary md:font-medium md:text-2xl font-semibold text-sm'>Location on Map</h1>
              <img src={map} alt="" className='w-full mt-4 h-full'/> */}
            </div>
         
            <div style={{boxShadow: `${openVisitForm ? '0px -6px 30px 20px rgba(0, 0, 0,0.4)' : ''}`}} className={`md:w-[40%] ${screenWidth < 768 && (openVisitForm ? ' w-full fixed block z-50 bottom-0 rounded-t-xl bg-white left-0 pb-6' : 'hidden')}`}>
                <ScheduleVisitLogic data={data} openVisitForm={openVisitForm} setOpenVisitForm={setOpenVisitForm}/>
            </div>
        </div>
    </div>
  )
}

export default CollegeListingById
