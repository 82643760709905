import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { authenticateUser } from '../utils/authenticateUser';
import RoutePath from './RoutePath';
import NavbarLogic from '../components/navbar/NavbarLogic';
import FooterLogic from '../components/footer/FooterLogic';
import HomepageLogic from '../pages/homepage/HomepageLogic';
import { useLocation } from 'react-router-dom';
import ScrollToTop from '../utils/ScrollToTop';
import ServicePageLogic from '../pages/servicepage/ServicePageLogic';
import FavouritePageLogic from '../pages/favouritepage/FavouritePageLogic';
import ListingHomepageLogic from '../pages/listinghomepage/ListingHomepageLogic';
import ListingFormLogic from '../pages/listingForm/ListingFormLogic';
import LoginLogic from '../pages/login/LoginLogic';
import PayRentLogic from "../pages/payRent/PayRentLogic"
import SearchByCollegeLogic from '../pages/searchByCollege/SearchByCollegeLogic';
import FlatmateByCollegeLogic from '../pages/flatmateByCollege/FlatmateByCollegeLogic';
import FurnishingByCollegeLogic from '../pages/furnishingByCollege/FurnishingByCollegeLogic';
import HomeByCollegeLogic from '../pages/homeByCollege/HomeByCollegeLogic';
import LocalAssistByCollegeLogic from '../pages/localAssistByCollege/LocalAssistByCollegeLogic';
import ComingSoonLogic from "../components/comingSoon/ComingSoonLogic"
import AccountLogic from '../pages/account/AccountLogic';
import PaymentSuccessfulLogic from '../pages/paymentSuccessful/PaymentSuccessfulLogic';
import FurnishingByIdLogic from '../pages/furnishingById/FurnishingByIdLogic';
import CartLogic from '../pages/cart/CartLogic';
import OrderLogic from '../pages/orders/OrderLogic';
import PaymentFailedLogic from '../pages/paymentFail/PaymentFailedLogic';
import CollegeListingByIdLogic from '../pages/collegeListingById/CollegeListingByIdLogic';

const Routers = () => {
  const location = useLocation();
  const isAuthenticated = authenticateUser();
  const shouldShowNavbar = () => {
    const excludedRoutes = [RoutePath.login, RoutePath.signup, RoutePath.mobileSearch]; 
    const currentRoute = location.pathname;
    return !excludedRoutes.includes(currentRoute);
  };
  const shouldShowFooter = () => {
    const excludedRoutes = [RoutePath.login, RoutePath.signup, RoutePath.mobileSearch, RoutePath.services, RoutePath.listingForm]; 
    const currentRoute = location.pathname;
    return !excludedRoutes.includes(currentRoute);
  };
    const routeComponents = Object.entries(RoutePath).map(([key, value]) => {
      let component = null;
      switch (key) {
        case 'login':
        component =  <LoginLogic />
        break;
        case 'signup':
        component =  <LoginLogic />
        break;
        case 'homepage':
        component =  <HomepageLogic />
        break;
        case 'services':
        component =  <ServicePageLogic/>
        break;
        case 'favourite':
        component =  isAuthenticated ?  <FavouritePageLogic/> : <LoginLogic/>
        break;
        case 'listing':
        component =  <ListingHomepageLogic/>
        break;
        case 'listingForm':
        component =  isAuthenticated ?  <ListingFormLogic/> : <LoginLogic/>
        break;
        case 'searchByCollege':
        component =   <SearchByCollegeLogic/> 
        break;
        case 'searchByCollegeHome':
        component =   <HomeByCollegeLogic/>
        break;
        case 'searchByCollegeFlatmate':
        component =    <FlatmateByCollegeLogic/>
        break;
        case 'searchByCollegeFurnishing':
        component =    <FurnishingByCollegeLogic/> 
        break;
        case 'searchByCollegeLocalAssist':
        component =    <LocalAssistByCollegeLogic/> 
        break;
        case 'exploreProperties':
        component =  <ComingSoonLogic/>
        break;
        case 'earnFromUs':
        component =  <ComingSoonLogic/>
        break;
        case 'referrals':
        component =  <ComingSoonLogic/>
        break;
        case 'account':
        component = isAuthenticated ?  <AccountLogic/> : <LoginLogic/>
        break;
        case 'furnishingById':
        component =  <FurnishingByIdLogic/>
        break;
        case 'furnishingByIdForCollege':
        component =   <FurnishingByIdLogic/> 
        break;
        case 'cart':
        component =  isAuthenticated ?  <CartLogic/> : <LoginLogic/>
        break;
        case 'orders':
        component =  isAuthenticated ?  <OrderLogic/> : <LoginLogic/>
        break;
        case 'collegeListingById':
        component =    <CollegeListingByIdLogic/> 
        break;
        case 'listingById':
        component =   <CollegeListingByIdLogic/>
        break;
        case 'paymentSuccessful':
        component =  isAuthenticated ?  <PaymentSuccessfulLogic/> : <LoginLogic/>
        break;
        case 'paymentFail':
        component =  isAuthenticated ?  <PaymentFailedLogic/> : <LoginLogic/>
        break;
        case 'payRent':
        component =    <PayRentLogic/> 
        break;
    
        default:
          component = null; 
          break;
      }

return <Route key={key} path={value} element={component} />;
});
return (
    <>
    {shouldShowNavbar() && <NavbarLogic />}
    <ScrollToTop />
      <Routes>
        {routeComponents}
      </Routes>
      {shouldShowFooter() && <FooterLogic />}
    </>
  );
};

export default Routers;
