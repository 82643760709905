import { createSlice } from '@reduxjs/toolkit';

const screenWidthSlice = createSlice({
  name: 'screenWidth',
  initialState: {
    screenWidth: window.innerWidth,
  },
  reducers: {
    setScreenWidth: (state, action) => {
      state.screenWidth = action.payload;
    },
  },
});

export const { setScreenWidth } = screenWidthSlice.actions;
export default screenWidthSlice.reducer;
