import React, {useState, useEffect} from 'react'
import Header from './Header'
import { useSelector } from 'react-redux';
import clients from "../../assets/images/clientsIcon.svg";
import residence from "../../assets/images/residenceIcon.svg"
import colleges from "../../assets/images/collegesIcon.svg"
import { useNavigate } from 'react-router-dom';
import RoutePath from '../../routes/RoutePath';
import { showErrorToast, showInfoToast } from '../../utils/toastNotif'


const HeaderLogic = () => {
    const collegeList = useSelector(state=>state.dashboard.colleges)
    const [isServiceDropdownOpen, setisServiceDropdownOpen] = useState(false);
    const [isCollegeDropdownOpen, setisCollegeDropdownOpen] = useState(false);
    const [selectedService, setselectedService] = useState(null);
    const [selectedCollege, setselectedCollege] = useState(null);
    const [filteredColleges, setFilteredColleges] = useState([])
    const [searchQuery, setSearchQuery] = useState('');
    const status = useSelector(state => state.dashboard.status);
    const error = useSelector(state => state.dashboard.error);
  
    const navigate = useNavigate();

   
    const metricsData = [
      {img: colleges, value: "15", name: "Colleges"},
      {img: clients, value: "5000", name: "Happy Bachelors"},
      {img: residence, value: "2500", name: "Residence"},
]
    const toggleServiceDropdown = () => {
        if(!isServiceDropdownOpen){
          setisCollegeDropdownOpen(false);
        }
        setisServiceDropdownOpen(!isServiceDropdownOpen);
      };
    
    const toggleCollegeDropdown = () => {
          if(!isCollegeDropdownOpen){
            if (status === 'loading'){
              showInfoToast("Loading college list");
              return;
            }
            if (status === 'failed') {
              console.log(error);
              showErrorToast("There was some problem fetching college list, please try again later")
              return;
            }
            setisServiceDropdownOpen(false)
          }
          isCollegeDropdownOpen  && setSearchQuery('')
         setisCollegeDropdownOpen(!isCollegeDropdownOpen);
        
       
        
      };
    
      const selectService = (service) => {
        setselectedService(service);
        setisServiceDropdownOpen(false);
        setSearchQuery('');
      };
      const selectCollege = (college) => {
        setselectedCollege(college);
        setisCollegeDropdownOpen(false);
      };
      
      const serviceOptions = ["Flat & PG", "Rental Furnishing", "Local assist", "College & Nearby"];
      const topSearchColleges = [{id: '6', name: "Delhi University"}, {id: '7', name: "Delhi College of Arts and Commerce"}, {id: '8', name: "Delhi Technological University (DTU)"}]
      useEffect(()=>{
        const tempCollege = collegeList.filter((college) =>
        college.collegeName.toLowerCase().includes(searchQuery.toLowerCase()) || college.collegeCode.toLowerCase().includes(searchQuery.toLowerCase())
      );
          setFilteredColleges(tempCollege);
      }, [collegeList, searchQuery])

    const servicesData = [
        { id: "flat", name: "Rental House", img: process.env.PUBLIC_URL + '/images/featureImages/Frame227.png', value: "Flat & PG"},
        { id: "rental", name: "Rental Furniture", img: process.env.PUBLIC_URL + '/images/featureImages/Frame229.png', value: "Rental Furnishing"},
        { id: "localAssistance", name: "Local Assistance", img: process.env.PUBLIC_URL + '/images/featureImages/Frame228.png', value: "Local assist"},
        { id: "flatmate", name: "Flat mate", img: process.env.PUBLIC_URL + '/images/featureImages/frame230.png', value: "College & Nearby"},
    ]

    const handleSearch = ()=>{
      if(selectedCollege?._id){
       switch(selectedService) {
        case 'Flat & PG' :
          navigate(`${RoutePath.searchByCollegeHome?.replace(":id", selectedCollege?._id)}`);
          break;
        case 'Rental Furnishing' :
          navigate(`${RoutePath.searchByCollegeFurnishing?.replace(":id", selectedCollege?._id)}`);
          break;
        case 'Local assist' :
          navigate(`${RoutePath.searchByCollegeLocalAssist?.replace(":id", selectedCollege?._id)}`);
          break;
        case 'College & Nearby' :
          navigate(`${RoutePath.searchByCollegeFlatmate?.replace(":id", selectedCollege?._id)}`);
          break;
        default :
          navigate(`${RoutePath.searchByCollege?.replace(":id", selectedCollege?._id)}`);
          break;
       }
      }else{
        showInfoToast("kindly select a college")
      }
    }
  return (
    <><Header
    isServiceDropdownOpen={isServiceDropdownOpen}
    toggleServiceDropdown={toggleServiceDropdown}
    selectService={selectService}
    selectedService={selectedService}
    serviceOptions={serviceOptions}
    isCollegeDropdownOpen={isCollegeDropdownOpen}
    toggleCollegeDropdown={toggleCollegeDropdown}
    selectCollege={selectCollege}
    selectedCollege={selectedCollege}
    collegeList={collegeList}
    searchQuery={searchQuery}
    setSearchQuery={setSearchQuery}
    filteredColleges={filteredColleges}
    topSearchColleges={topSearchColleges}
    metricsData={metricsData}
    servicesData={servicesData}
    navigate={navigate}
    handleSearch={handleSearch}
    setselectedService={setselectedService}
    setisServiceDropdownOpen={setisServiceDropdownOpen}
    setisCollegeDropdownOpen={setisCollegeDropdownOpen}
    /></>
  )
}

export default HeaderLogic