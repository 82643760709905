import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchCategories = createAsyncThunk(
    'categories/fetchCategories',
    async ()=>{
        try{
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/services/getCategories`, {
                method: 'POST',
                headers: {
                  "Content-Type": "application/json",
                },
            });
            if (response === null) {
                console.log("Response is null");
                throw new Error("Response is null");
            }
            if(!response.ok){
                console.log(`Error fetching categories data: ${response.status} - ${response.statusText}`);
                throw new Error(`Error fetching categories data: ${response.status} - ${response.statusText}`);
            }
            const data = await response.json();
            if (data === null || data === undefined) {
                console.log("Data is null or undefined")
                throw new Error("Data is null or undefined");
            }
            return data;
            console.log(data)
        }catch(error){
            console.log("Error fetching categories data", error);
            throw error; 
        }
    }
);

const getCategoriesSlice = createSlice({
    name: "getCategories",
    initialState: {
        categoriesData: [],
        status: 'idle',
        error: null
    },

    reducers: {},
    extraReducers: (builder) => {
        builder
          .addCase(fetchCategories.pending, (state) => {
            state.status = 'loading';
          })
          .addCase(fetchCategories.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.categoriesData = action.payload;
            
          })
          .addCase(fetchCategories.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
          });
      }
})

export default getCategoriesSlice.reducer;

