import React, { useState, useCallback, useRef, useEffect } from 'react'
import StepperLogic from '../../components/stepper/StepperLogic'
import SpinnerLogic from "../../components/spinner/SpinnerLogic"
import flag from "../../assets/images/indianFlag.png"
import left from "../../assets/images/leftarrow.svg"
import save from "../../assets/images/saveIcon.svg"
import { FiChevronRight } from "react-icons/fi";
import WhatsappContactLogic from '../../components/whatsappContact/WhatsappContactLogic';
import Modal from "react-awesome-modal";
import flat from "../../assets/images/flatIcon.svg"
import pg from "../../assets/images/pgIcon.svg"
import imageUpload from "../../assets/images/uploadImageIcon.svg"
import { FiPlus } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import upload from "../../assets/images/uploadCloud.svg"
import edit from "../../assets/images/editIcon.svg"
import useFindLocality from "../../utils/findLocality";
import {handleDragOver, handleDragEnter, handleDragLeave, handleDrop, handleImageUpload, handleRemoveImage} from "../../utils/dragAndDrop"
import AddressFormLogic from '../../components/addressForm/AddressFormLogic';

const Preview = ({
  previewModal,
  setpreviewModal,
  handleStepChange,
  formData,
  ownerData,
  imageFiles,
  screenWidth, handleSubmit
})=>{
  const {findLocalityName} = useFindLocality();
  const previewData =   [{
    Personal_Details : {
      Name : ownerData?.name,
      Phone_number : ownerData?.phoneNumber,
      // Person_of_contact : ownerData?.personOfContact,
      Email : ownerData?.email,
      Address_Line : ownerData?.permanentAddress?.addressLine,
      State : ownerData?.permanentAddress?.state,
      City : ownerData?.permanentAddress?.city,
      Pin_code : ownerData?.permanentAddress?.pinCode
    },
    Property_Details : {
      Property_type : formData?.typeOfProperty,
      Flat_type : formData?.typeOfProperty === "flat" ? formData?.propertySize[0]?.size : "",
      Floor_number : formData?.floor,
      Total_floors : formData?.totalFloor,
      Buildup_Area : formData?.builtUpArea,
      PG_name : formData?.apartmentName,
      Occupancy : formData?.typeOfProperty === "pg" ? formData?.propertySize : "",
      Available_From : formData?.availableFrom
    },
    Locality_Details : {
      Address_Line_1 : formData?.permanentAddress?.addressLine1,
      Address_Line_2 : formData?.permanentAddress?.addressLine2,
      Locality : findLocalityName(formData?.localityId),
      Landmark : formData?.permanentAddress?.landmark,
      State : formData?.permanentAddress?.state,
      City : formData?.permanentAddress?.city,
      Pin_code : formData?.permanentAddress?.pinCode
    },

    Rental_Details : {
      Rent : formData?.rent,
      Security : formData?.security,
      Rent_Negotiable : formData?.rentNegotiable
    },

    Amenities : {
      Common_Amenities : formData?.commonAmenities
    },

    Uploaded_Images : imageFiles
  }]
  
  const sections = [
    { title: 'Personal Details', data: previewData && previewData[0]?.Personal_Details },
    { title: 'Property Details', data: previewData && previewData[0]?.Property_Details },
    { title: 'Locality Details', data: previewData && previewData[0]?.Locality_Details },
    { title: 'Rental Details', data: previewData && previewData[0]?.Rental_Details },
    { title: 'Amenities', data: previewData && previewData[0]?.Amenities },
    { title: 'Uploaded Images', data: previewData[0]?.Uploaded_Images }
  ];

  const filteredSections = sections.map(section => {
    if (section.data) {
      if (!Array.isArray(section.data)) {
        const filteredData = Object.entries(section.data)
          .filter(([key, value]) => {
            if (Array.isArray(value)) {
              return value.length > 0;
            } else {
              return value !== undefined && value !== null && value !== '' && value !== false;
            }
          })
          .reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
          }, {});
  
        if (Object.keys(filteredData).length > 0) {
          return { title: section.title, data: filteredData };
        }
      } else if (section.data.length > 0) {
        return { title: section.title, data: section.data };
      }
    }
    return null;
  }).filter(section => section !== null);

  const renderContent = () => {
    return (
      <div style={{ maxHeight: '500px' }} className={`overflow-y-scroll no-scrollbar`}>
        {
          filteredSections?.map((section, index) => (
            <div key={index} className='w-[95%] mb-5 h-full mx-auto border rounded-xl border-[#E4E4E7]'>
              <div className=''>
                <div className='flex items-center py-6 px-8 border-b border-[#E4E4E7] justify-between'>
                  <h2 className='text-xl text-secondary font-poppins font-medium'>{section.title}</h2>
                  <img onClick={() => {
                    handleStepChange(section.title === 'Uploaded Images' ? 6 : index + 1);
                    setpreviewModal(false)
                  }} src={edit} alt="" className='border border-[#E4E4E7] py-2 px-2 rounded-full blog-button-shadow' />
                </div>
                <div className='py-5 px-8'>
                  {section.title === 'Uploaded Images' ? (
                    <div className='my-3 grid grid-cols-2 w-full gap-4 place-items-center'>
                      {section.data && section.data.map((image, imageIndex) => (
                        <img key={imageIndex} src={URL.createObjectURL(image.file)} alt={`Uploaded Image ${imageIndex + 1}`} className='my-1 rounded-md col-span-1 h-[264px]' />
                      ))}
                    </div>
                  ) : (
                    section?.data && Object.entries(section?.data)?.map(([key, value]) => (
                      <div key={key} className='my-3'>
                        <p className='font-poppins text-secondary text-sm font-medium'>{key.replace(/_/g, ' ')}</p>
                        {Array.isArray(value) ? (
                          <div>
                            {value.map((item, itemIndex) => (
                              <div key={itemIndex} className='mt-1'>
                                {Object.entries(item).map(([subKey, subValue]) => (
                                  <p key={subKey} className='font-poppins text-secondary text-sm font-normal'>{`${subKey.replace(/_/g, ' ')}: ${subValue}`}</p>
                                ))}
                              </div>
                            ))}
                          </div>
                        ) : (
                          <p className='font-poppins mt-1 text-secondary text-sm font-normal'>{value}</p>
                        )}
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          ))
        }
      </div>
    )
  }
  

 return screenWidth >= 768 ? (
  <Modal
  visible={previewModal}
  width="60%"
  height="550"
  effect="fadeInUp"
  onClickAway={()=>setpreviewModal(false)}
  >
    <div className='w-full relative h-full overflow-y-hidden no-scrollbar'>
     <div className='flex items-center justify-between px-6 py-5'>
      <h1 className='text-secondary font-poppins text-2xl'>Preview</h1>
      <RxCross2 onClick={()=>setpreviewModal(false)} className='w-[40px] cursor-pointer text-2xl text-[#52525B] '/>
     </div>
  {renderContent()}
     <div className='fixed bottom-0 bg-white right-0 header-box-shadow w-full'><div onClick={handleSubmit} className={`flex ml-auto mt-3 mr-4 mb-10 justify-between w-32 items-center background-primary cursor-pointer blog-button-shadow  py-3 text-white font-poppins text-base font-medium px-5 rounded-[4px]`}><p>Confirm</p><FiChevronRight className='text-xl'/></div></div>
    </div>
  </Modal>
 ) : 
 (
   <>
   {
    previewModal &&  <div className='px-5 z-30  fixed top-0 left-0  bg-white mb-32'>
    <div  className=''><h1 className='font-poppins text-secondary mb-7 mt-5 font-semibold text-xl'>Preview</h1>
    {renderContent()}
    </div>
    <div className={` submit-button  bg-white z-10 fixed bottom-0 w-full left-0 py-4 px-[18px] md:border-t md:border-[#E4E4E7] flex md:hidden justify-between items-center`}>
    <div onClick={()=>{handleStepChange(6);
    setpreviewModal(false)
    }} className='flex justify-center space-x-3 w-[48%] items-center cursor-pointer blog-button-shadow border border-[#E4E4E7] hover:bg-[#E4E4E7] py-3 text-[#3F3F46] font-poppins text-base font-medium px-5 rounded-[4px]'><img src={left} alt="<" /><p>Back</p></div>
 
  {
       <div onClick={handleSubmit} className={`flex justify-center space-x-3 w-[48%] items-center background-primary cursor-pointer blog-button-shadow  py-3 text-white font-poppins text-base font-medium px-5 rounded-[4px]`}><p>Confirm</p><FiChevronRight className='text-xl'/></div>
     }
     
</div>
  </div>
   }
   </>
 )
}

const Navigations = ({handleBack, handleNext,handleSave, activeStep, data, imageFiles, handleUpload, requiredFields, formType, })=>{
  return(
    <>
    {/* desktop */}
    <div className='w-full hidden md:flex mt-10 items-center justify-between'>
      <div onClick={handleBack} className='flex justify-between w-28 items-center cursor-pointer blog-button-shadow border border-[#E4E4E7] hover:bg-[#E4E4E7] py-3 text-[#3F3F46] font-poppins text-base font-medium px-5 rounded-[4px]'><img src={left} alt="<" /><p>Back</p></div>
      <div className='flex items-center space-x-3'>
      {
        activeStep < 6 ? <div onClick={()=>handleSave(data, formType)} className='flex justify-between w-28 items-center cursor-pointer blog-button-shadow border border-[#E4E4E7] hover:bg-[#E4E4E7] py-3 text-[#3F3F46] font-poppins text-base font-medium px-5 rounded-[4px]'><img src={save} alt="+" /><p>Save</p></div> :
        <div onClick={()=>handleSave(imageFiles, "images")} className='flex justify-between w-28 items-center cursor-pointer blog-button-shadow border border-[#E4E4E7] hover:bg-[#E4E4E7] py-3 text-[#3F3F46] font-poppins text-base font-medium px-5 rounded-[4px]'><img src={save} alt="+" /><p>Save</p></div> 
      }
     {
      activeStep < 6 &&  <div  onClick={(e)=>handleNext(e, data, requiredFields, formType)} className={`flex justify-between w-28 items-center ${activeStep === 2 ? !data?.typeOfProperty ? 'bg-[#FCB57D]' : 'background-primary' : 'background-primary'} background-primary cursor-pointer blog-button-shadow  py-3 text-white font-poppins text-base font-medium px-5 rounded-[4px]`}><p>Next</p><FiChevronRight className='text-xl'/></div>
     }
      {
        activeStep === 6 && <div onClick={handleUpload} className={`flex justify-between w-28 items-center ${imageFiles?.length < 5 ? 'bg-[#FCB57D]' : 'background-primary'} cursor-pointer blog-button-shadow  py-3 text-white font-poppins text-base font-medium px-5 rounded-[4px]`}><p>Upload</p><img src={upload} alt='upload' className='text-xl'/></div>
      }
      </div>
    </div>

    {/* mobile */}
    <div className={` submit-button  bg-white z-10 fixed bottom-0 w-full overflow-x-hidden left-0 py-4 px-[18px] md:border-t md:border-[#E4E4E7] flex md:hidden justify-between items-center`}>
    <div onClick={handleBack} className='flex justify-center space-x-3 w-[48%] items-center cursor-pointer blog-button-shadow border border-[#E4E4E7] hover:bg-[#E4E4E7] py-3 text-[#3F3F46] font-poppins text-base font-medium px-5 rounded-[4px]'><img src={left} alt="<" /><p>Back</p></div>
 
  {
      activeStep < 6 &&  <div  onClick={(e)=>handleNext(e, data, requiredFields, formType)} className={`flex justify-center space-x-3 w-[48%] items-center ${activeStep === 2 ? !data?.typeOfProperty ? 'bg-[#FCB57D]' : 'background-primary' : 'background-primary'} background-primary cursor-pointer blog-button-shadow  py-3 text-white font-poppins text-base font-medium px-5 rounded-[4px]`}><p>Next</p><FiChevronRight className='text-xl'/></div>
     }
      {
        activeStep === 6 && <div onClick={handleUpload} className={`flex justify-center space-x-3 w-[48%] items-center ${imageFiles?.length < 5 ? 'bg-[#FCB57D]' : 'background-primary'} cursor-pointer blog-button-shadow  py-3 text-white font-poppins text-base font-medium px-5 rounded-[4px]`}><p>Upload</p><img src={upload} alt='upload' className='text-xl'/></div>
      }
</div>
    </>
  )
}

// const ApplyModal = ({modalDimensions,openModal, setOpenModal, phoneNumber, otp, loading, otperrormsg,setotperrormsg,
//   setverified,
//   setOtp, sendotp, verified})=>{
//   return(
//     <Modal
//     visible={openModal}
//       width={modalDimensions.width}
//       height={modalDimensions.height}
//       effect="fadeInUp"
//       onClickAway={() => setOpenModal(false)}
//       className="modal-shadow rounded-xl"
//     >
//        <div className={`p-5  relative w-full h-full flex flex-col  items-center ${verified ? 'justify-start' : 'justify-center'}`}>
//         {
//           verified ? <>
//            <img src={tick} alt="verified" />
//            <p className='text-black font-semibold text-2xl text-center font-poppins mt-2'>Your phone number is verified</p>
//            <p className='text-textColor font-poppins mt-2 text-lg font-normal text-center'>We've booked a visit slot for you</p>
//            <button onClick={()=>setOpenModal(false)} className='py-[10px] mt-6 px-[16px] rounded-[4px] w-[174px] border border-[#E4E4E7] blog-button-shadow text-[#3F3F46] font-medium text-center text-sm font-poppins'>Close</button>
//           </> 
//           :
//           <>
//           {
//           loading ? <>
//           <p className='text-secondary text-base font-poppins font-medium '>Verifying otp....</p>
//           </> :
//           <>
//           <img src={cross} alt="X"  onClick={()=>setOpenModal(false)} className='absolute top-0 right-0 m-6'/>
//           <img src={phone} className=' border-8 rounded-[48px] bg-[#FEE3CD] border-[#FFF4EB] p-3' alt="phone" />
//         <h1 className='font-poppins font-semibold mt-3 text-lg text-center text-secondary'>Please check your Mobile.</h1>
//        <div className='w-full ml-4'>
//        <p className='font-poppins font-semibold mt-1 text-sm text-center text-textColor'>We've sent a code to +91{phoneNumber}</p>
//        <div className="flex mt-5">
//           {Array.from({ length: 4})?.map((_, index) => (
//             <input
//               key={index}
//               id={`listing-otp-input-${index}`}
//               type="text"
//               maxLength="1"
//               value={otp[index] || ''}
//               onChange={(e) => handleOtpChange(index, e.target.value, setOtp, "listing")}
//               className='border min-[450px]:text-[48px] text-2xl leading-[56px] tracking-[-0.2px] text-primary font-poppins font-medium blog-button-shadow border-primary outline-none mx-1 rounded-lg min-[450px]:w-20 min-[450px]:h-20 w-14 h-14 px-2 py-[10px] text-center'
//             />
//           ))}
//         </div>
//         {
//           sendotp &&  <p className=' font-poppins mt-1 font-normal text-textColor text-sm'>Didn't get a code?<span className='underline ml-1'>Click to resend.</span> </p>
//         }
//         {
//           otperrormsg && <p className='mt-1 text-red-800 font-poppins text-xs font-normal'>{otperrormsg}</p> 
//         }
//        </div>
//          <div className='flex mt-10 w-full items-center justify-between'>
//           <button onClick={()=>setOpenModal(false)} className='py-[10px] px-[16px] rounded-[4px] w-[48%] border border-[#E4E4E7] blog-button-shadow text-[#3F3F46] font-medium text-center text-sm font-poppins'>
//             Cancel
//           </button>
//           <button onClick={()=>handleVerifyOtp(setotperrormsg, setverified, otp)} className='blog-button-shadow w-[48%] text-center font-poppins text-sm font-medium background-primary text-white py-[10px] px-[16px] rounded-[4px]'>Verify</button>
//          </div>
//           </>
//         }
//           </>
//         }
//        </div>
//     </Modal>
//   )
// }


const FormPartOne = ({handleBack, handleSave, errors,setownerData, handleNext, addpersonofcontact, setaddpersonofcontact, selectedState, setselectedCity, setselectedState, selectedCity, openModal, setOpenModal, setOtp, setverified, setotperrormsg, otp, otperrormsg, sendotp, loading, verified, handleDataChange, ownerData,modalDimensions, activeStep, requiredFieldErrors, cities, setcities
  })=>{
    return(
        <div className='w-full flex flex-col items-start overflow-x-hidden'>
            <h5 className='font-semibold hidden md:block font-poppins tracking-[-0.2px] text-[32px] leading-10 text-secondary'>Fill your personal details</h5>
            <p className='font-poppins mt-2 text-lg hidden md:block text-textColor font-normal'>Choose your properties type</p>
            <div className='flex md:hidden items-center justify-between w-full'><p className='font-poppins text-secondary font-semibold text-xl'>Step 1: Personal details</p><div onClick={()=>handleSave(ownerData, "owner")} className='flex blog-button-shadow border border-[#E4E4E7] rounded-[4px] px-3 py-2 items-center space-x-3'><img src={save} alt="" /><span className='font-poppins font-medium text-xs text-[#3F3F46]'>Save</span></div></div>
            <div className='mt-[30px] w-full mb-24 md:mb-0'>
              <div className='flex flex-col'>
              <p className='text-[#3F3F46] font-medium font-poppins text-sm'>Name</p>
              <input name='name' value={ownerData?.name} onChange={(e)=>handleDataChange("name", e, ownerData, setownerData, "owner")} placeholder='Enter Your Name' type="text" className='border mt-1 outline-none border-[#D4D4D8] blog-button-shadow text-textColor font-poppins text-base font-normal w-full rounded-[4px] py-[10px] px-[14px]'/>
              <p className='text-red-500 text-sm font-poppins mt-1 font-normal'>{errors["owner"]?.name ||  requiredFieldErrors["owner"]?.name}</p>
              </div>
              <div className='flex mt-6 flex-col'>
              <p className='text-[#3F3F46] font-medium font-poppins text-sm'>Phone number</p>
              <div className='border mt-1 flex justify-between items-center outline-none  w-full rounded-[4px] py-[10px] px-[14px] border-[#D4D4D8] blog-button-shadow'><div className='flex w-[60%] min-[360px]:w-auto items-center space-x-2'><div className='flex items-center space-x-1'><img src={flag} alt="india" className='w-5' /><p className='text-[#27272A] font-poppins w-10 min-[360px]:text-base text-sm font-medium'>+91</p></div><input readOnly={verified} name='phoneNumber' value={ownerData?.phoneNumber} onChange={(e)=>handleDataChange("phoneNumber", e, ownerData, setownerData, "owner")} placeholder='000-000-0000' type="tel" className=' text-textColor outline-none font-poppins min-[360px]:text-base text-sm font-normal'/></div>
               {/* <button disabled={errors["owner"]?.phoneNumber || !ownerData?.phoneNumber || verified} onClick={()=>setOpenModal(true)} className={`text-sm font-poppins font-medium ${verified ? 'text-green-500' : 'text-primary'}`}>{verified ? 'Verified' : 'Verify'}</button> */}
               </div>
              {
                 <p className='text-red-500 text-sm font-poppins mt-1 font-normal'>{errors["owner"]?.phoneNumber || requiredFieldErrors["owner"]?.phoneNumber}</p>
              }
              {/* {<p className='text-red-500 text-sm font-poppins mt-1 font-normal'>{(ownerData?.phoneNumber && !errors["owner"]?.phoneNumber && !verified) && 'kindly verify your phone number'}</p>} */}
              {/* <div className="flex mt-4 items-center">
              
               <Switch
               onChange={()=>setaddpersonofcontact(!addpersonofcontact)}
               checked={addpersonofcontact}
                 onColor="#EF7C22" 
                offColor="#F4F4F5" 
                uncheckedIcon={false}
               checkedIcon={false}
               className="react-switch"/>
                <span className="ml-2 text-[#3F3F46] font-medium font-poppins text-sm">Add phone number of person of contact.</span>
              </div> */}
              {/* {
                addpersonofcontact && <div className='flex mt-6 flex-col'> <p className='text-[#3F3F46] font-medium font-poppins text-sm'>Person of contact</p><div className='border mt-1 outline-none  w-full rounded-[4px] py-[10px] px-[14px] border-[#D4D4D8] blog-button-shadow'><div className='flex items-center space-x-2'><div className='flex items-center space-x-1'><img src={flag} alt="india" className='w-5' /><p className='text-[#27272A] font-poppins text-base font-medium'>+91</p></div><input name='personOfContact' value={ownerData?.personOfContact} onChange={(e)=>handleDataChange("personOfContact", e, ownerData, setownerData, "owner")} placeholder='000-000-0000' type="tel" className=' text-textColor outline-none font-poppins text-base font-normal'/></div></div>
                 {
                <p className='text-red-500 text-sm font-poppins mt-1 font-normal'>{errors["owner"]?.personOfContact}</p>
              }
                </div>
              } */}
              </div>
              <div className='flex mt-6 flex-col'>
              <p className='text-[#3F3F46] font-medium font-poppins text-sm'>Email (Optional)</p>
              <input name='email' value={ownerData?.email} onChange={(e)=>handleDataChange("email", e, ownerData, setownerData, "owner")} placeholder='abc@email.com' type="text" className='border mt-1 border-[#D4D4D8] outline-none blog-button-shadow text-textColor font-poppins text-base font-normal w-full rounded-[4px] py-[10px] px-[14px]'/>
              {
                 <p className='text-red-500 text-sm font-poppins mt-1 font-normal'>{errors["owner"]?.email ||  requiredFieldErrors["owner"]?.email}</p>
              }
              </div>
            
           
           </div>
           <Navigations handleSave={handleSave} handleBack={handleBack} handleNext={handleNext} data={ownerData} activeStep={activeStep} formType={"owner"} requiredFields={["name", "phoneNumber"]} />
           {/* <ApplyModal openModal={openModal} phoneNumber={ownerData?.phoneNumber} setOpenModal={setOpenModal}  otp={otp}  otperrormsg={otperrormsg} sendotp={sendotp}  loading={loading}
                 verified={verified} modalDimensions={modalDimensions} setotperrormsg={setotperrormsg}
                 setverified={setverified}
                 setOtp={setOtp}/> */}
        </div>

    )

}

const FormPartTwo = ({handleBack,handleSave, handleNext, activeStep, formData, setFormData, handleDataChange, errors,requiredFieldErrors, dateOnly, setRequiredFieldErrors})=>{

  const flatOptions = [{name: "1 RK", desc: "1 room, 1 kitchen"}, {name: "1 BHK", desc: "1 room, 1 hall, 1 kitchen"},
  {name: "2 BHK", desc: "2 room, 1 hall, 1 kitchen"},{name: "3 BHK", desc: "2 room, 1 hall, 1 kitchen"}, {name: "4 BHK", desc: "2 room, 1 hall, 1 kitchen"},
]
  const occupancyOptions = ["Single", "Double", "Triple", "Quadruple"];
  const [selectedDesc, setselectedDesc] = useState("")
 
 return(
    <div className='w-full flex flex-col items-start overflow-x-hidden'>
        <h5 className='font-semibold font-poppins hidden md:block tracking-[-0.2px] text-[32px] leading-10 text-secondary'> Property details</h5>
        <p className='font-poppins hidden md:block mt-2 text-lg text-textColor font-normal'>Choose your properties type</p>
        <div className='flex md:hidden flex-wrap items-center justify-between w-full'><p className='font-poppins text-secondary font-semibold text-xl'>Step 2: Property details</p><div onClick={()=>handleSave(formData, "form")} className='flex blog-button-shadow border border-[#E4E4E7] rounded-[4px] px-3 py-2 items-center space-x-3'><img src={save} alt="" /><span className='font-poppins font-medium text-xs text-[#3F3F46]'>Save</span></div></div>
        <div className='mt-[30px] w-full mb-32 md:mb-0'>
       <p className='text-secondary font-semibold text-lg font-poppins'>Choose your properties type</p>
       <div className='flex w-full items-center space-x-5 mt-5'>
        <div onClick={()=>{setFormData({...formData, typeOfProperty: "flat"});
        setFormData((prevFormData) => ({
          ...prevFormData,
          apartmentName: "",
           propertySize: []  
        }));
        setRequiredFieldErrors((prevErrors)=>({
          ...prevErrors, "form": {
            ...prevErrors["form"],
            apartmentName: "",
            propertySize: "",
            availableFrom: "",
            rent: "",
            security: ""
          }
        }))
      }} className={`${formData?.typeOfProperty === 'flat' ? 'border border-secondary bg-[#FAFAFA]' : 'border-[#E4E4E7]'} flex cursor-pointer w-1/2 flex-col items-center justify-center py-4 px-6 border  rounded-lg`}>
          <img src={flat} alt="flat" />
          <p className='text-[#27272A] text-base font-poppins font-medium mt-3'>Flat</p>
        </div>
        <div onClick={()=>{setFormData({...formData, typeOfProperty: "pg"});
         setFormData((prevFormData) => ({
          ...prevFormData,
          propertySize: [],
          floor: "",
          totalFloor: "",
          builtUpArea: "",
        }));

        setRequiredFieldErrors((prevErrors)=>({
          ...prevErrors, "form": {
            ...prevErrors["form"],
            propertySize: "",
            floor: "",
            totalFloor: "",
            builtUpArea: "",
            availableFrom: "",
            rent: "",
            security: ""
          }
        }))
      }} className={`${formData?.typeOfProperty === 'pg' ? 'border border-secondary bg-[#FAFAFA]' : 'border-[#E4E4E7]'} flex cursor-pointer w-1/2 flex-col items-center justify-center py-4 px-6 border  rounded-lg`}>
          <img src={pg} alt="pg" />
          <p className='text-[#27272A] text-base font-poppins font-medium mt-3'>PG</p>
        </div>
       </div>
       {
        formData.typeOfProperty === 'flat' && <div className='w-full mt-5'>
          <div className='flex flex-col'>
           <p className='text-secondary font-poppins font-medium text-sm'>Flat type</p>
           <div className='flex flex-wrap gap-y-3 items-center mt-3 space-x-3'>
            {
              flatOptions && flatOptions.map((ele, ind)=>(
                <div  onClick={(e) => {
                 
                  handleDataChange(
                    "propertySize",
                    {
                      target: {
                        name: "propertySize",
                        value: ele?.name,
                      },
                    },
                    formData,
                    setFormData,
                    "form"
                  );
                  setselectedDesc(ele?.desc);
                }} key={ind} className={`rounded-[4px] py-[10px] text-[#3F3F46] cursor-pointer font-poppins text-sm font-medium px-4 blog-button-shadow ${formData?.propertySize[0]?.size === ele?.name ? 'border border-secondary bg-[#FAFAFA]' : 'border border-[#E4E4E7]'}`}>{ele?.name}</div>
              ))
            }
           </div>
          {
            formData?.propertySize?.length > 0 &&  <p className='mt-1 text-textColor font-normal text-xs font-poppins'>{formData?.propertySize[0]?.size}:{selectedDesc}</p>
          }
           <p className='text-red-500 text-sm font-poppins mt-1 font-normal'>{requiredFieldErrors["form"]?.propertySize}</p>
          </div>
         <div className='flex items-center mt-5 space-x-5'>
         <div className='flex w-1/2  flex-col'>
              <p className='text-[#3F3F46] font-medium font-poppins text-sm'>Floor number</p>
              <input value={formData?.floor} name='floor' onChange={(e)=>handleDataChange("floor", e, formData, setFormData, "form")} min={0} placeholder='Enter floor number' type="number" className='border mt-1 outline-none border-[#D4D4D8] blog-button-shadow text-textColor font-poppins text-base font-normal w-full rounded-[4px] py-[10px] px-[14px]'/>
              <p className='text-red-500 text-sm font-poppins mt-1 font-normal'>{requiredFieldErrors["form"]?.floor}</p>
         </div>
          <div className='flex w-1/2  flex-col'>
              <p className='text-[#3F3F46] font-medium font-poppins text-sm'>Total floors</p>
              <input value={formData?.totalFloor} name='totalFloor' onChange={(e)=>handleDataChange("totalFloor", e, formData, setFormData, "form")} min={0} placeholder='Enter total floors' type="number" className='border mt-1 outline-none border-[#D4D4D8] blog-button-shadow text-textColor font-poppins text-base font-normal w-full rounded-[4px] py-[10px] px-[14px]'/>
              <p className='text-red-500 text-sm font-poppins mt-1 font-normal'>{requiredFieldErrors["form"]?.totalFloor}</p>
         </div>
         </div>
         <div className='flex items-center mt-5 space-x-5'>
         <div className='flex w-1/2  flex-col'>
              <p className='text-[#3F3F46] font-medium font-poppins text-sm'>Buildup area</p>
              <input value={formData?.builtUpArea} name='builtUpArea' onChange={(e)=>handleDataChange("builtUpArea", e, formData, setFormData, "form")} min={0} placeholder='Enter area' type="number" className='border mt-1 outline-none border-[#D4D4D8] blog-button-shadow text-textColor font-poppins text-base font-normal w-full rounded-[4px] py-[10px] px-[14px]'/>
              <p className='text-red-500 text-sm font-poppins mt-1 font-normal'>{requiredFieldErrors["form"]?.builtUpArea}</p>
         </div>
          <div className='flex w-1/2  flex-col'>
              <p className='text-[#3F3F46] font-medium font-poppins text-sm'>Available from </p>
              <input min={dateOnly} name='availableFrom' value={formData?.availableFrom} onChange={(e)=>handleDataChange("availableFrom", e, formData, setFormData)}  type="date" className='border mt-1 outline-none border-[#D4D4D8] blog-button-shadow text-textColor font-poppins text-base font-normal w-full rounded-[4px] py-[10px] px-[14px]'/>
              <p className='text-red-500 text-sm font-poppins mt-1 font-normal'>{requiredFieldErrors["form"]?.availableFrom}</p>
         </div>
         </div>
        </div>
       }
       {
        formData.typeOfProperty === 'pg' && <div className='w-full mt-5'>
           <div className='flex mt-5  flex-col'>
              <p className='text-[#3F3F46] font-medium font-poppins text-sm'>Enter PG Name  </p>
              <input value={formData?.apartmentName} name='name' onChange={(e)=>handleDataChange("apartmentName", e, formData, setFormData, "form")}  type="text" placeholder='Enter PG Name ' className='border mt-1 outline-none border-[#D4D4D8] blog-button-shadow text-textColor font-poppins text-base font-normal w-full rounded-[4px] py-[10px] px-[14px]'/>
              <p className='text-red-500 text-sm font-poppins mt-1 font-normal'>{errors["form"]?.name ||requiredFieldErrors["form"]?.apartmentName}</p>
         </div>
         <div className='flex mt-5 flex-col'>
           <p className='text-secondary font-poppins font-medium text-sm'>Occupancy</p>
           <div className='md:flex md:items-start md:flex-wrap  mt-3  grid grid-cols-2 place-items-start gap-4'>
            {
              occupancyOptions && occupancyOptions.map((ele, ind)=>(
                <div  key={ind} className={`flex items-center space-x-3`}>
                  <label className="flex items-center">
         <input
        type="checkbox"
        className="hidden"
        name="propertySize"
        value={ele}
        checked={formData?.propertySize?.some(item => item.size === ele)}
        onChange={(e)=>handleDataChange("propertySize",  e, formData, setFormData, "form")}
      />
       <span
        className={`relative inline-block w-5 h-5 border border-[#D4D4D8] rounded-md cursor-pointer ${
          formData?.propertySize?.some(item => item.size === ele) ? 'background-primary' : ''
        }`}
      >
       {formData?.propertySize?.some(item => item.size === ele) && (
          <svg
            className="w-4 h-4 text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 13l4 4L19 7"
            ></path>
          </svg>
        )}
      </span>
    </label>
    <p className='font-poppins text-sm text-secondary font-medium'>{ele}</p>
              </div>
              ))
            }
           </div>
           <p className='text-red-500 text-sm font-poppins mt-1 font-normal'>{requiredFieldErrors["form"]?.propertySize}</p>
          </div>
           <div className='flex mt-5  flex-col'>
              <p className='text-[#3F3F46] font-medium font-poppins text-sm'>Available from </p>
              <input min={dateOnly} value={formData?.availableFrom} name='date' onChange={(e)=>handleDataChange("availableFrom", e, formData, setFormData, "form")}  type="date" className='border mt-1 outline-none border-[#D4D4D8] blog-button-shadow text-textColor font-poppins text-base font-normal w-full rounded-[4px] py-[10px] px-[14px]'/>
              <p className='text-red-500 text-sm font-poppins mt-1 font-normal'>{requiredFieldErrors["form"]?.availableFrom}</p>
         </div>
        </div>
       }
       </div>
       <Navigations handleSave={handleSave} handleBack={handleBack} handleNext={handleNext} activeStep={activeStep} data={formData} requiredFields={formData?.typeOfProperty === "flat" ? ["propertySize", "floor", "totalFloor", "builtUpArea", "availableFrom"] : ["apartmentName", "propertySize", "availableFrom"]} formType={"form"}/>
    </div>

)

}

const FormPartThree = ({formData,
  handleDataChange,
  handleBack,
  handleNext,  
  setFormData,
  activeStep,
  cities,
  selectedState,
   selectedCity,
   setselectedCity,
   setselectedState,
  setcities,
  errors,
  requiredFieldErrors,
  handleSave,
  searchQuery,
  searchResults,
  handleInputChange,
  inputValue,
  setSearchResults
})=>{

  const [isOpen, setIsopen] = useState(true);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const {findLocalityName} = useFindLocality();
  const localityDropdown = useRef(null);
  const toggleLocalityRef = useRef(null);
  
  useEffect(() => {
    const handleClickOutside = (event) => {
     
      
        if (localityDropdown?.current && !localityDropdown?.current.contains(event.target) && toggleLocalityRef?.current && !toggleLocalityRef?.current.contains(event.target) ) {
          setSearchResults([]);
          }        
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
        document.removeEventListener("click", handleClickOutside);
    };
}, []);
  return(
    <div className='w-full flex flex-col items-start'>
        <h5 className='font-semibold hidden md:block font-poppins tracking-[-0.2px] text-[32px] leading-10 text-secondary'>Locality details</h5>
        <p className='font-poppins hidden md:block mt-2 text-lg text-textColor font-normal'>Choose your properties type</p>
        <div className='flex md:hidden items-center justify-between w-full'><p className='font-poppins text-secondary font-semibold text-xl'>Step 3: Locality details</p><div onClick={()=>handleSave(formData, "form")} className='flex blog-button-shadow border border-[#E4E4E7] rounded-[4px] px-3 py-2 items-center space-x-3'><img src={save} alt="" /><span className='font-poppins font-medium text-xs text-[#3F3F46]'>Save</span></div></div>
        <div className='md:mt-[30px] mt-[10px] w-full mb-32 md:mb-0'>
          <div className='flex mt-6 flex-col'>
          <p className='text-[#3F3F46] font-medium font-poppins text-sm'>Address line 1</p>
          <input name='addressLine' value={formData?.permanentAddress?.addressLine1} onChange={(e)=>handleDataChange("permanentAddress.addressLine1", e, formData, setFormData, "form")} placeholder='Enter address line 1' type="text" className='border mt-1 border-[#D4D4D8] outline-none blog-button-shadow text-textColor font-poppins text-base font-normal w-full rounded-[4px] py-[10px] px-[14px]'/>
          <p className='text-red-500 text-sm font-poppins mt-1 font-normal'>{requiredFieldErrors["form"]?.addressLine1}</p>
          </div>
          <div className='flex mt-6 flex-col'>
          <p className='text-[#3F3F46] font-medium font-poppins text-sm'>Address line 2</p>
          <input name='addressLine' value={formData?.permanentAddress?.addressLine2} onChange={(e)=>handleDataChange("permanentAddress.addressLine2", e, formData, setFormData, "form")} placeholder='Enter address line 2' type="text" className='border mt-1 border-[#D4D4D8] outline-none blog-button-shadow text-textColor font-poppins text-base font-normal w-full rounded-[4px] py-[10px] px-[14px]'/>
          
          </div>
          <div className='flex mt-6 flex-col'>
          <p className='text-[#3F3F46] font-medium font-poppins text-sm'>Locality</p>
           <div className='relative'>
           <input 
             type="text" 
             name='locality'
             ref={toggleLocalityRef}
             className='border mt-1 border-[#D4D4D8] outline-none blog-button-shadow text-textColor font-poppins text-base font-normal w-full rounded-[4px] py-[10px] px-[14px]'
            placeholder="Enter locality" 
             value={(isInputFocused ? inputValue : findLocalityName(formData?.localityId) || searchQuery)  } 
            onChange={handleInputChange} 
            onFocus={()=>setIsInputFocused(true)}
          onBlur={()=>setIsInputFocused(false)}
           />
         {
          isOpen && searchResults?.length > 0 &&  <div ref={localityDropdown} className='absolute z-50 bg-white text-textColor border border-[#D4D4D8] header-box-shadow font-poppins text-base rounded-[4px] py-[10px] px-[14px] top-14 font-normal'>
          {
            searchResults && searchResults?.map((ele, name)=>(
              <p onClick={(e)=>{
                setIsopen(false)
                handleDataChange("localityId", {
                target: {
                  name: "localityId",
                  value: ele?._id,
                },
              }, formData, setFormData, "form")}} className='my-2'>{ele?.name}</p>
            ))
          }
         </div>
         }
           </div>
          <p className='text-red-500 text-sm font-poppins mt-1 font-normal'>{requiredFieldErrors["form"]?.localityId}</p>
          </div>
          <div className='flex mt-6 flex-col'>
          <p className='text-[#3F3F46] font-medium font-poppins text-sm'>Nearby landmark</p>
          <input name='landmark' value={formData?.permanentAddress?.landmark} onChange={(e)=>handleDataChange("permanentAddress.landmark", e, formData, setFormData, "form")} placeholder='Enter Nearby landmark' type="text" className='border mt-1 border-[#D4D4D8] outline-none blog-button-shadow text-textColor font-poppins text-base font-normal w-full rounded-[4px] py-[10px] px-[14px]'/>
          
          </div>
          <AddressFormLogic data={formData} setData={setFormData} selectedCity={selectedCity} selectedState={selectedState} formType={"form"} errors={errors} handleDataChange={handleDataChange} setselectedCity={setselectedCity} setselectedState={setselectedState} requiredFieldErrors={requiredFieldErrors} cities={cities} setcities={setcities}/>
       </div>
       <Navigations handleSave={handleSave} handleBack={handleBack} handleNext={handleNext} activeStep={activeStep} data={formData} formType={"form"} requiredFields={[ "permanentAddress.addressLine1", "permanentAddress.pinCode", "permanentAddress.city", "permanentAddress.state", "localityId"]}/>
    </div>

)

}

const FormPartFour = ({formData,
  handleBack,
  handleNext,
  activeStep,
  setFormData,
  handleSave,
  handleDataChange,
  errors,
  requiredFieldErrors})=>{

    const handleRentChange = (index, value) => {
      const updatedPropertySize = [...formData.propertySize];
      updatedPropertySize[index].rent = parseInt(value);

      

      setFormData({ ...formData, propertySize: updatedPropertySize});
    };
  
    const handleSecurityChange = (index, value) => {
      const updatedPropertySize = [...formData.propertySize];
      updatedPropertySize[index].security = parseInt(value);
      setFormData({ ...formData, propertySize: updatedPropertySize });
    };


   
  return(
    <div className='w-full flex flex-col items-start overflow-x-hidden'>
      <div className='flex mb-7 md:hidden items-center justify-between w-full'><p className='font-poppins text-secondary font-semibold text-xl'>Step 4: Rental details</p><div onClick={()=>handleSave(formData, "form")} className='flex blog-button-shadow border border-[#E4E4E7] rounded-[4px] px-3 py-2 items-center space-x-3'><img src={save} alt="" /><span className='font-poppins font-medium text-xs text-[#3F3F46]'>Save</span></div></div>
        <h5 className='font-semibold font-poppins tracking-[-0.2px] text-2xl md:text-[32px] md:leading-10 text-secondary'>Rent</h5>
        <p className='font-poppins mt-2 md:text-lg text-base text-textColor font-normal'>Fill your rent related details</p>
        <div className='md:mt-[30px] mb-32 md:mb-0 mt-[10px] w-full'>
         {
          formData?.typeOfProperty === 'flat' ? 
          <div>
             <div className='flex mt-6 flex-col'>
              <p className='text-[#3F3F46] font-medium font-poppins text-sm'>Rent</p>
              <div className='border mt-1 border-[#D4D4D8] outline-none blog-button-shadow text-textColor font-poppins text-base font-normal w-full flex items-center space-x-2 rounded-[4px] py-[10px] px-[14px]'><span>₹</span><input value={formData?.rent} name='rent' onChange={(e)=>handleDataChange("rent", e, formData, setFormData, "form")} min={0} type="number" className='outline-none w-full'/></div>
              <p className='text-red-500 text-sm font-poppins mt-1 font-normal'>{errors["form"]?.rent || requiredFieldErrors["form"]?.rent}</p>
            </div>
        <div className='mt-3 space-x-2 flex items-center'>
        <label className="flex  items-center">
         <input
        type="checkbox"
        className="hidden"
        checked={formData?.rentNegotiable}
        onChange={()=>handleDataChange("rentNegotiable", !formData?.rentNegotiable, formData, setFormData, "form")}
      />
       <span
        className={`relative inline-block w-5 h-5 border border-[#D4D4D8] rounded-md cursor-pointer ${
          formData?.rentNegotiable ? 'background-primary' : ''
        }`}
      >
        {formData?.rentNegotiable && (
          <svg
            className="w-4 h-4 text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 13l4 4L19 7"
            ></path>
          </svg>
        )}
      </span>
    </label>
    <p className='text-secondary font-poppins font-medium text-base'>Rent negotiable</p>
        </div>
             <div className='flex mt-6 flex-col'>
              <p className='text-[#3F3F46] font-medium font-poppins text-sm'>Security deposit</p>
              <div className='border mt-1 border-[#D4D4D8] outline-none blog-button-shadow text-textColor font-poppins text-base font-normal w-full flex items-center space-x-2 rounded-[4px] py-[10px] px-[14px]'><span>₹</span><input type="number" min={0} value={formData?.security} name='security' onChange={(e)=>handleDataChange("security", e, formData, setFormData, "form")} className='outline-none w-full'/></div>
              <p className={`font-poppins font-normal text-xs text-textColor mt-2`}>Should not exceed 2x rent</p>
              <p className='text-red-500 text-sm font-poppins mt-1 font-normal'>{errors["form"]?.security || requiredFieldErrors["form"]?.security}</p>
            </div>
            <div className='flex mt-6 flex-col'>
              <p className='text-[#3F3F46] font-medium font-poppins text-sm'>Token amount*</p>
              <div className='border mt-1 border-[#D4D4D8] outline-none blog-button-shadow text-textColor font-poppins text-base font-normal w-full flex items-center space-x-2 rounded-[4px] py-[10px] px-[14px]'><span>₹</span><input value={formData?.tokenAmount} name='tokenAmount' onChange={(e)=>handleDataChange("tokenAmount", e, formData, setFormData, "form")} min={0} type="number" className='outline-none w-full'/></div>
              <p className='text-red-500 text-sm font-poppins mt-1 font-normal'>{errors["form"]?.tokenAmount || requiredFieldErrors["form"]?.tokenAmount}</p>
            </div>
          </div>
          :
          <>
           <div className='flex mt-6 flex-col'>
              <p className='text-[#3F3F46] font-medium font-poppins text-sm'>Token amount*</p>
              <div className='border mt-1 border-[#D4D4D8] outline-none blog-button-shadow text-textColor font-poppins text-base font-normal w-full flex items-center space-x-2 rounded-[4px] py-[10px] px-[14px]'><span>₹</span><input value={formData?.tokenAmount} name='tokenAmount' onChange={(e)=>{
                handleDataChange("tokenAmount", e, formData, setFormData, "form");
              }} min={0} type="number" className='outline-none w-full'/></div>
              <p className='text-red-500 text-sm font-poppins mt-1 font-normal'>{errors["form"]?.tokenAmount || requiredFieldErrors["form"]?.tokenAmount}</p>
            </div>
           
          { formData.propertySize.map((item, index) => (
      <div key={index} className='flex w-full space-x-5'>
        <div className='flex flex-col w-1/2 mt-6'>
          <p className='text-[#3F3F46] font-medium font-poppins text-sm'> {item.size} Occupancy</p>
          <div className='border mt-1 border-[#D4D4D8] outline-none blog-button-shadow text-textColor font-poppins text-base font-normal w-full flex items-center space-x-2 rounded-[4px] py-[10px] px-[14px]'>
            <span>₹</span>
            <input 
              value={item.rent} 
              onChange={(e) => handleRentChange(index, e.target.value)} 
              min={0} 
              type="number" 
              className='outline-none w-full'
            />
          </div>
          <p className='text-red-500 text-sm font-poppins mt-1 font-normal'>{requiredFieldErrors["form"]?.propertySize?.rent}</p>
        </div>
        <div className='flex flex-col w-1/2 mt-6'>
          <p className='text-[#3F3F46] font-medium font-poppins text-sm'>Security deposit </p>
          <div className='border mt-1 border-[#D4D4D8] outline-none blog-button-shadow text-textColor font-poppins text-base font-normal w-full flex items-center space-x-2 rounded-[4px] py-[10px] px-[14px]'>
            <span>₹</span>
            <input 
              value={item.security} 
              onChange={(e) => handleSecurityChange(index, e.target.value)} 
              min={0} 
              type="number" 
              className='outline-none w-full'
            />
          </div>
          <p className={`font-poppins font-normal text-xs text-textColor mt-2`}>Should not exceed 2x rent</p>
          <p className='text-red-500 text-sm font-poppins mt-1 font-normal'>{requiredFieldErrors["form"]?.propertySize?.rent}</p>
        </div>
      </div>
    ))
  }
           
          
           <div className='mt-6 space-x-2 flex items-center'>
        <label className="flex  items-center">
         <input
        type="checkbox"
        className="hidden"
        name='rentNegotiable'
        checked={formData?.rentNegotiable}
        onChange={()=>handleDataChange("rentNegotiable", !formData?.rentNegotiable, formData, setFormData, "form")}
      />
       <span
        className={`relative inline-block w-5 h-5 border border-[#D4D4D8] rounded-md cursor-pointer ${
          formData?.rentNegotiable ? 'background-primary' : ''
        }`}
      >
        {formData?.rentNegotiable && (
          <svg
            className="w-4 h-4 text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 13l4 4L19 7"
            ></path>
          </svg>
        )}
      </span>
    </label>
    <p className='text-secondary font-poppins font-medium text-base'>Rent negotiable</p>
        </div>
          </>
         }
       </div>
       <Navigations handleBack={handleBack} handleSave={handleSave} handleNext={handleNext} activeStep={activeStep} formType={"form"} errors={errors} handleDataChange={handleDataChange} data={formData} setData={setFormData} requiredFields={formData?.typeOfProperty === "flat" ? ["rent", "security", "tokenAmount"] : ["tokenAmount"]}/>
    </div>

)
}

const FormPartFive = ({formData,
  handleDataChange,
  handleBack,
  handleNext,
  commonAmenities, 
  activeStep,
  setFormData,
  errors,
  requiredFieldErrors,
  handleSave
})=>{
  return(
    <div className='w-full flex flex-col items-start overflow-x-hidden'>
         <div className='flex mb-7 md:hidden items-center justify-between w-full'><p className='font-poppins text-secondary font-semibold text-xl'>Step 5: Amenities</p><div onClick={()=>handleSave(formData, "form")} className='flex blog-button-shadow border border-[#E4E4E7] rounded-[4px] px-3 py-2 items-center space-x-3'><img src={save} alt="" /><span className='font-poppins font-medium text-xs text-[#3F3F46]'>Save</span></div></div>
        <h5 className='font-semibold font-poppins tracking-[-0.2px] text-2xl md:text-[32px] md:leading-10 text-secondary'>Amenities</h5>
        <p className='font-poppins mt-2 md:text-lg text-base text-textColor font-normal'>Choose among the following amenities, if any</p>
        <div className='mt-[30px] mb-32 md:mb-0 w-full'>
          {
            commonAmenities && commonAmenities.map((ele, ind)=>{
              return(
                <div key={ind} className='flex flex-col items-start mb-10'>
                  <h1 className='text-lg text-secondary font-medium font-poppins'>{ele?.category}</h1>
                  <div className='mt-3 w-full grid md:grid-cols-3 grid-cols-2 gap-4'>
                   {
                     ele?.items && ele?.items.map((item, index)=>(
                      <div onClick={(e)=>handleDataChange("facilities", {
                        target: {
                          name: "facilities",
                          value: item?.name,
                        },
                      }, formData, setFormData, "form")} key={index + ind} className={`flex col-span-1 cursor-pointer flex-col items-center ${formData?.facilities?.includes(item?.name) ? 'border border-secondary bg-[#f4f4f4]' : 'border border-[#E4E4E7] bg-white'} justify-center py-4 px-6 rounded-lg`}>
                        <img src={item?.image} alt="" />
                        <p className='text-[#27272A] text-center text-base font-poppins font-medium mt-3'>{item?.name}</p>
                      </div>
                     ))
                   }
                  </div>
                </div>
              )
            })
          }
        </div>
        <Navigations handleBack={handleBack} handleSave={handleSave} handleNext={handleNext} activeStep={activeStep} formType={"form"} handleDataChange={handleDataChange} data={formData} setData={setFormData} requiredFields={[]} errors={errors} requiredFieldErrors={requiredFieldErrors}/>
    </div>
  )
}

const UploadImage = ({imageFiles,
  handleBack,
  handleNext,
  handleUpload,
  activeStep,
  setImageFiles,
  handleSave,
  setImagesList
})=>{
    return(
      <div className='w-full flex flex-col items-start overflow-x-hidden'>
         <div className='flex mb-7 md:hidden items-center justify-between w-full'><p className='font-poppins text-secondary font-semibold text-xl'>Step 6: Upload photos</p>
         <div onClick={()=>handleSave(imageFiles, "images")} className='flex blog-button-shadow border border-[#E4E4E7] rounded-[4px] px-3 py-2 items-center space-x-3'>
          <img src={save} alt="" /><span className='font-poppins font-medium text-xs text-[#3F3F46]'>Save</span></div>
          </div>
        <h5 className='font-semibold font-poppins tracking-[-0.2px] text-2xl md:text-[32px] md:leading-10 text-secondary'>Upload photos</h5>
        <p className='font-poppins mt-2 md:text-lg text-base text-textColor font-normal'>Upload at least 5 property images</p>
        <div className='mt-[20px] mb-32 md:mb-0 w-full'>
         {
           imageFiles?.length < 1 ?  (
            <label
            onDragOver={(e)=>handleDragOver(e)}
        onDragEnter={(e)=>handleDragEnter(e)}
        onDragLeave={(e)=>handleDragLeave(e)}
        onDrop={(e)=>handleDrop(e,setImageFiles)}
             className='w-full cursor-pointer border-dotted border border-[#E4E4E7] rounded-lg md:h-[408px] py-[40px] md:py-0 flex flex-col items-center justify-center'>
            <input
              type='file'
              accept='image/*'
              multiple
              onChange={(e)=>handleImageUpload(e, setImageFiles, true, setImagesList)}
              className='hidden'
            />
            <img src={imageUpload} alt="+" />
            <div className='font-poppins mt-7 flex items-center space-x-2 md:text-2xl text-lg text-center'>
              <p className='text-primary cursor-pointer font-medium'>Upload photos</p>
              <p className='text-secondary font-normal'>or drag and drop</p>
            </div>
            <p className='font-poppins mt-2 text-[#52525B] font-normal text-base text-center'>Choose at least 5 photos</p>
          </label>
          ) : 
          (
            <div className='grid grid-cols-1 sm:grid-cols-2  gap-6'>
              {imageFiles?.map((image) => (
                <div key={image.id} className='col-span-1 relative'>
                  <img src={image.file.base64 || URL.createObjectURL(image.file)} alt='uploaded' className='w-full h-[240px] object-cover rounded-lg' />
                  <button
                    className='absolute -top-3 -right-3 p-2 background-secondary text-white rounded-full'
                    onClick={() => handleRemoveImage(image.id, setImageFiles)}
                  >
                   <RxCross2 />
                  </button>
                </div>
              ))}
              <label className='w-full col-span-1 h-[240px] cursor-pointer border-dotted border border-[#E4E4E7] rounded-lg flex flex-col items-center justify-center'>
                <input
                  type='file'
                  accept='image/*'
                  multiple
                  onChange={(e)=>handleImageUpload(e, setImageFiles, true, setImagesList)}
                  style={{ display: 'none' }}
                />
                <FiPlus className='text-[#52525B] w-10 h-10'/>
                <p className='font-poppins mt-3 text-[#3F3F46] font-normal text-base text-center'>Add more</p>
              </label>
            </div>
          )
         }
        </div>
        <Navigations handleBack={handleBack} handleSave={handleSave} handleUpload={handleUpload} handleNext={handleNext} activeStep={activeStep} imageFiles={imageFiles}/>
      </div>
    )
}



const ListingForm = ({totalSteps,
    stepLabels,
    activeStep,
    onsubmit,
    cities,
    setcities,
    addpersonofcontact,
    setaddpersonofcontact, 
    selectedState,
    selectedCity,
    openModal,
    setOpenModal,
    handleOtpChange,
    handleVerifyOtp,
    otp,
    otperrormsg,
    sendotp,
    loading,
    verified,
    setownerData,
    ownerData,
    handleBack,
    handleNext,
    formData,
    setFormData,
    modalDimensions,
    setselectedCity,
    setselectedState,
    dateOnly,
    commonAmenities,
    imageFiles,
    handleUpload,
    previewModal,
    setpreviewModal,
    handleStepChange,
    screenWidth,
    setImageFiles,
    errors,
    handleDataChange,
    requiredFieldErrors,
    setOtp,
    setotperrormsg,
    setverified,
    handleSave, 
    handleSubmit,
    setImagesList,
    setRequiredFieldErrors,
    handleInputChange,
    searchQuery,
    searchResults,
    inputValue,
    setSearchResults
}) => {
  return (
    <div className=''>
       <WhatsappContactLogic/>
        {
            onsubmit ?  <div className="w-full p-5 text-center text-2xl h-screen bg-white">
           <SpinnerLogic/>
          </div> :
           <div className='md:flex md:items-start md:justify-between w-full xl:w-[90%] mx-auto lg:p-20 md:p-10 px-5 py-[20px]'>
            {
              screenWidth >= 768 && <StepperLogic handleStepChange={handleStepChange} totalSteps={totalSteps}
              stepLabels={stepLabels}
              activeStep={activeStep}
              />
            }
             <div className="min-[1200px]:w-[624px] min-[900px]:w-[400px] md:w-[350px] min-[1000px]:w-[500px]">
              {activeStep === 1 && (
                <FormPartOne
                cities={cities}
                setcities={setcities}
                addpersonofcontact={addpersonofcontact}
                 setaddpersonofcontact={setaddpersonofcontact}
                 selectedState={selectedState}
                 selectedCity={selectedCity}
                 setselectedCity={setselectedCity}
                 setselectedState={setselectedState}
                 openModal={openModal}
                 setotperrormsg={setotperrormsg}
                 setverified={setverified}
                 setOtp={setOtp}
                 setOpenModal={setOpenModal}
                 handleOtpChange={handleOtpChange}
                 otp={otp}
                 handleVerifyOtp={handleVerifyOtp}
                 otperrormsg={otperrormsg}
                 sendotp={sendotp}
                 loading={loading}
                 verified={verified}
                 ownerData={ownerData}
                 handleDataChange={handleDataChange}
                 handleBack={handleBack}
                 handleNext={handleNext}
                 modalDimensions={modalDimensions}
                 activeStep={activeStep}
                 errors={errors}
                setownerData={setownerData}
                requiredFieldErrors={requiredFieldErrors}
                handleSave={handleSave}
                />
              )}
              {activeStep === 2 && (
                <FormPartTwo
                handleBack={handleBack}
                handleNext={handleNext}
                handleDataChange={handleDataChange}
                activeStep={activeStep}
                formData={formData}
                setFormData={setFormData}
                dateOnly={dateOnly}
                errors={errors}
                requiredFieldErrors={requiredFieldErrors}
                handleSave={handleSave}
                setRequiredFieldErrors={setRequiredFieldErrors}
                />
              )}
              {activeStep === 3 && (
                <FormPartThree
                formData={formData}
                handleDataChange={handleDataChange}
                handleBack={handleBack}
                handleNext={handleNext}    
                setFormData={setFormData}
                activeStep={activeStep}
                cities={cities}
                selectedState={selectedState}
                 selectedCity={selectedCity}
                 setselectedCity={setselectedCity}
                 setselectedState={setselectedState}
                setcities={setcities}
                errors={errors}
                requiredFieldErrors={requiredFieldErrors}
                handleSave={handleSave}
                searchQuery={searchQuery}
               searchResults={searchResults}
               handleInputChange={handleInputChange}
               inputValue={inputValue}
               setSearchResults={setSearchResults}
                />
              )}
              {activeStep === 4 && (
                <FormPartFour
                formData={formData}
                handleBack={handleBack}
                handleNext={handleNext}  
                activeStep={activeStep}  
                setFormData={setFormData}
                handleDataChange={handleDataChange}
                errors={errors}
                requiredFieldErrors={requiredFieldErrors}
                handleSave={handleSave}
                />
              )}
              {activeStep === 5 && (
                <FormPartFive
                formData={formData}
                handleBack={handleBack}
                handleNext={handleNext}
                commonAmenities={commonAmenities}  
                activeStep={activeStep}
                setFormData={setFormData}
                handleDataChange={handleDataChange}
                errors={errors}
                requiredFieldErrors={requiredFieldErrors}
                handleSave={handleSave}
                />
              )}
              {activeStep === 6 && (
                <UploadImage
                handleBack={handleBack}
                handleNext={handleNext}
                imageFiles={imageFiles}
                activeStep={activeStep}
                handleUpload={handleUpload}
                setImageFiles={setImageFiles}
                handleSave={handleSave}
                setImagesList={setImagesList}
                />
              )}
            </div>
           
           </div>
        }
        {
          previewModal &&  <Preview 
          previewModal={previewModal}
          setpreviewModal={setpreviewModal}
          handleStepChange={handleStepChange}
          formData={formData}
          ownerData={ownerData}
          imageFiles={imageFiles}
          screenWidth={screenWidth}
          handleSubmit={handleSubmit}
          />
        }

    </div>
  )
}

export default ListingForm