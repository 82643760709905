import React from 'react'
import SubNavbarCollegeLogic from '../../components/subNavbarCollege/SubNavbarCollegeLogic'
import ContactUsLogic from "../../components/contactUs/ContactUsLogic"
import TestimonialLogic from "../../components/testimonial/TestimonialLogic"
import FaqLogic from "../../components/faq/FaqLogic"
import bannerImage from "../../assets/images/bannerImage.png";

import ellipse from "../../assets/images/ellipseVector.png";
import rating from "../../assets/images/ratingIcon.svg"
import WhatsappContactLogic from '../../components/whatsappContact/WhatsappContactLogic'
import booknow1 from "../../assets/images/booknow1.png"
import halfellipse1 from "../../assets/images/halfellipse.png"
import halfellipse2 from "../../assets/images/halfellipse2.png"
import halfellipse3 from "../../assets/images/halfellipse3.png"
import blank from "../../assets/images/blankImageIcon.png"
import { FaChevronRight } from "react-icons/fa6";
import { FaChevronLeft } from "react-icons/fa6";
import Modal from "react-awesome-modal"
import { RxCross1 } from "react-icons/rx";


const ApplyModal = ({openModal, setopenModal, modalContent, setModalContent})=>{
  return(
    <Modal
    visible={openModal}
      width="100%"
      height="100%"
      effect="fadeInUp"
      onClickAway={()=>setopenModal(false)}
    >
    <div className='relative overflow-y-scroll max-h-full no-scrollbar'>
      <div onClick={()=>{
        setopenModal(false);
        setModalContent(null);

      }} className='absolute top-0 z-20 right-0 m-8 text-2xl'><RxCross1 /></div>
      {modalContent}
      </div>

    </Modal>
  )
}

const CafeLounges = ({cafeData, collegeId, setopenModal, setModalContent, openModal, handleNextClick, handlePrevClick, scrollPosition, calculateMaxScroll, nextClicked, screenWidth})=>{
  const showPrev = scrollPosition.cafe > 0;
  const showNext = scrollPosition.cafe < calculateMaxScroll(cafeData);
  return(
   <div className='md:mt-[48px] relative px-5 py-6 md:py-0 md:px-0 md:mb-24 mb-0'>
   <div className='flex items-center justify-between'><h1 className='font-poppins text-secondary font-semibold  text-[36px] leading-[44px] tracking-[-0.2px]'>Cafe & Lounges</h1>
   {
    !openModal && <button  onClick={()=>{
      setopenModal(true);
      setModalContent(<CafeLounges cafeData={cafeData} collegeId={collegeId} setopenModal={setopenModal} setModalContent={setModalContent} openModal={true} handleNextClick={handleNextClick} handlePrevClick={handlePrevClick} scrollPosition={scrollPosition} calculateMaxScroll={calculateMaxScroll} nextClicked={nextClicked} screenWidth={screenWidth} />);
      }
    } className={` md:hidden block py-[10px] text-primary font-poppins font-medium text-sm`}>View all</button>
   }
   </div>
   {screenWidth >= 768 && showPrev && (
        <div
        onClick={()=>handlePrevClick("cafe")}
        className="absolute z-10 cursor-pointer top-1/2 left-4 p-6 border-[2px] bg-white border-[#52525B] rounded-full transform -translate-y-1/2  "
      >
        <FaChevronLeft  className=' text-xl  text-[#52525B]   blog-button-shadow ' />
      </div>
      )}
      
       {
       screenWidth >= 768 && showNext && (
          <div
          onClick={()=>handleNextClick(cafeData, "cafe")}
          className="absolute z-10 cursor-pointer top-1/2 right-4 p-6 border-[2px] bg-white border-[#52525B] rounded-full transform -translate-y-1/2  "
        >
          <FaChevronRight  className=' text-xl  text-[#52525B]   blog-button-shadow ' />
        </div>
        )
       }
      
   <p className={`text-textColor font-poppins ${openModal ? 'mt-7' : 'mt-2'}  font-normal text-lg`}>A cafe is a small eating and drinking establishment that typically serves coffee and other refreshments.</p>
   <div className={`${openModal ? 'flex-col ' : 'flex-row space-x-8'} flex items-center w-full mt-8  ${nextClicked ? 'overflow-x-scroll' : 'overflow-x-scroll md:overflow-x-hidden'}  no-scrollbar `}  >
    {
      cafeData.map((ele, ind)=>(
        <div key={ind} className={`flex ${openModal ? 'w-full my-4' : 'w-[296px] md:duration-1000 flex-none'}   flex-col items-start`} style={{ transform: `translateX(-${scrollPosition["cafe"]}px)` }}>
          <div className={`${openModal ? 'h-auto' : 'h-[232px]'} mb-3 w-full rounded-xl`}>
            <img src={ele?.img} alt="" className='w-full h-full rounded-xl bg-cover'/>
          </div>
          <div className='flex items-center justify-between w-full'>
            <p className='text-secondary font-poppins text-base font-medium'>{ele?.name}</p>
            <div className='flex space-x-1 items-center'><img src={rating} alt="*" /><p className='font-poppins font-normal text-base text-secondary'>{ele?.rating}</p></div>
          </div>
          <p className='mt-1 text-textColor font-poppins font-normal text-base'>{ele?.speciality}</p>
          <div className='flex mt-1 items-center justify-between w-full'><p className='text-secondary font-poppins font-medium text-base'>₹ {ele?.price} <span className='font-normal text-base font-poppins text-textColor'>for one</span></p>
          {ele.colleges.map((college) => {
          if (college.college_id === collegeId) {
            return <p key={college.college_id} className='font-poppins text-base font-medium text-secondary'>{college.distance} km</p>;
          }
          return null;
        })}
          </div>
        </div>
      ))
    }
   </div>
   </div>
  )
}
const Entertainment = ({entertainmentData, collegeName, collegeId, setopenModal, setModalContent, openModal, nextClicked, screenWidth, scrollPosition, calculateMaxScroll, handleNextClick, handlePrevClick})=>{
  const showPrev = scrollPosition.entertainment > 0;
  const showNext = scrollPosition.entertainment < calculateMaxScroll(entertainmentData);
  return(
   <div className='md:mt-[48px] relative mb-0 md:mb-24  px-5 md:px-0 pt-8 pb-6 md:pt-0 md:pb-0'>
   <div className='flex items-center justify-between'><h1 className='font-poppins text-secondary font-semibold  text-[36px] leading-[44px] tracking-[-0.2px]'>Entertainment</h1>{
    !openModal && <button  onClick={()=>{
      setopenModal(true);
      setModalContent(<Entertainment entertainmentData={entertainmentData} collegeName={collegeName} collegeId={collegeId} setopenModal={setopenModal} setModalContent={setModalContent} openModal={true} handleNextClick={handleNextClick} handlePrevClick={handlePrevClick} scrollPosition={scrollPosition} calculateMaxScroll={calculateMaxScroll} nextClicked={nextClicked} screenWidth={screenWidth}/>);
      }
    } className={` md:hidden block py-[10px] text-primary font-poppins font-medium text-sm`}>View all</button>
   }</div>
   {screenWidth >= 768 && showPrev && (
        <div
        onClick={()=>handlePrevClick("entertainment")}
        className="absolute z-10 cursor-pointer top-1/2 left-4 p-6 border-[2px] bg-white border-[#52525B] rounded-full transform -translate-y-1/2  "
      >
        <FaChevronLeft  className=' text-xl  text-[#52525B]   blog-button-shadow ' />
      </div>
      )}
      
       {
       screenWidth >= 768 && showNext && (
          <div
          onClick={()=>handleNextClick(entertainmentData, "entertainment")}
          className="absolute z-10 cursor-pointer top-1/2 right-4 p-6 border-[2px] bg-white border-[#52525B] rounded-full transform -translate-y-1/2  "
        >
          <FaChevronRight  className=' text-xl  text-[#52525B]   blog-button-shadow ' />
        </div>
        )
       }
   <p className={`text-textColor font-poppins ${openModal ? 'mt-7' : 'mt-2'} font-normal text-lg`}>Things to Do near {collegeName}y</p>
   <div className={`${openModal ? 'flex-col ' : 'flex-row space-x-8'} flex items-center w-full mt-8  ${nextClicked ? 'overflow-x-scroll' : 'overflow-x-scroll md:overflow-x-hidden'}  no-scrollbar `}  >
    {
      entertainmentData.map((ele, ind)=>(
        <div key={ind} className={`flex ${openModal ? 'w-full my-4' : 'w-[296px] flex-none md:duration-1000'}   flex-col items-start`} style={{ transform: `translateX(-${scrollPosition["entertainment"]}px)` }}>
          <div className={`${openModal ? 'h-auto' : 'h-[232px]'} mb-3 w-full rounded-xl`}>
            <img src={ele?.img} alt="" className='w-full h-full rounded-xl bg-cover'/>
          </div>
          <div className='flex items-center justify-between w-full'>
            <p className='text-secondary font-poppins text-base font-medium'>{ele?.name}</p>
            <div className='flex space-x-1 items-center'><img src={rating} alt="*" /><p className='font-poppins font-normal text-base text-secondary'>{ele?.rating}</p></div>
          </div>
          <p className='mt-1 text-textColor font-poppins font-normal text-base'>{ele?.type}</p>
          <div className='flex mt-1 items-center justify-between w-full'>
            {
              ele?.price && <p className='text-secondary font-poppins font-medium text-base'>₹ {ele?.price} <span className='font-normal text-base font-poppins text-textColor'>/ per person</span></p>
            }
              {ele.colleges.map((college, ind) => {
          if (college.college_id === collegeId) {
            return <p key={`distance-${collegeId}-${ind}`} className='font-poppins text-base font-medium text-secondary'>{college.distance} km</p>;
          }
          return null;
        })}
        </div>
        </div>
      ))
    }
   </div>
   </div>
  )
}

const ExploreSection = ({ flexType, imgurl, heading1, subheading1, heading2, subheading2, gradient }) => {
  return (
    <div className={`w-full px-5 md:px-0 pb-6 pt-8 md:pt-0 md:pb-0 md:mb-20 flex ${flexType} space-x-4`}>
      <div className='w-full min-h-[312px] min-[360px]:min-h-[212px] md:h-auto md:w-[75%] relative rounded-xl'>
        <div className='absolute top-0 right-0 w-full h-full rounded-xl z-10 bg-[#00000066]'></div>
        <div className='flex absolute w-full z-20 left-1/2 transform -translate-x-1/2 top-1/2 -translate-y-1/2 flex-col items-start p-8'>
          <h1 className='font-poppins font-semibold text-white text-xl  md:text-[36px] md:leading-10  tracking-[-0.2px]'>{heading1}</h1>
          <p className='font-poppins text-white text-sm md:text-xl font-normal mt-2'>{subheading1}</p>
          <button className='bg-[#FFF4EB] mt-5 blog-button-shadow py-[10px] px-4 rounded-[4px] text-primary text-sm md:text-base font-poppins font-medium text-center'>Book Now</button>
        </div>
        <img src={imgurl} alt="" className='w-full h-full rounded-xl' />
      </div>
      <div className={`w-full md:w-[25%] hidden md:flex relative overflow-hidden px-5 py-3 flex-col text-center items-center justify-center ${gradient} rounded-xl`}>
        <img className='absolute bottom-0 left-0 z-10' src={halfellipse3} alt="" />
        <img className='absolute bottom-0 left-1/2 transform -translate-x-1/2 z-20' src={halfellipse2} alt="" />
        <img className='absolute -bottom-2 right-0 z-10' src={halfellipse1} alt="" />
        <h1 className='font-bold text-white font-poppins text-xl md:text-2xl'>{heading2}</h1>
        <p className='text-sm md:text-base text-white font-poppins font-medium mt-1'>{subheading2}</p>
        <button  className='py-[10px] px-4 rounded-[4px] border border-white blog-button-shadow mt-7 font-poppins text-white font-medium text-sm md:text-base text-center'>Explore</button>
      </div>
    </div>
  );
};

const Society = ({collegeName, societyData, setopenModal, setModalContent, openModal, handleNextClick, handlePrevClick, scrollPosition, calculateMaxScroll, nextClicked, screenWidth})=>{
  
  const showPrev = scrollPosition.society > 0;
  const showNext = scrollPosition.society < calculateMaxScroll(societyData[0]?.societies);
  return(
    <div className='md:mt-[48px] mb-5 md:mb-24  relative px-5 md:px-0 pt-8 pb-6 md:pt-0 md:pb-0'>
   <div className='flex items-center justify-between'><h1 className='font-poppins text-secondary font-semibold  text-[36px] leading-[44px] tracking-[-0.2px]'>Society</h1>{
    !openModal && <button  onClick={()=>{
      setopenModal(true);
      setModalContent( <Society societyData={societyData} collegeName={collegeName} setopenModal={setopenModal} setModalContent={setModalContent} openModal={true} handleNextClick={handleNextClick} handlePrevClick={handlePrevClick} scrollPosition={scrollPosition} calculateMaxScroll={calculateMaxScroll} nextClicked={nextClicked} screenWidth={screenWidth}/>);
      }
    } className={` md:hidden block py-[10px] text-primary font-poppins font-medium text-sm`}>View all</button>
   }</div>
   {screenWidth >= 768 && showPrev && (
        <div
        onClick={()=>handlePrevClick("society")}
        className="absolute z-10 cursor-pointer top-1/2 left-4 p-6 border-[2px] bg-white border-[#52525B] rounded-full transform -translate-y-1/2  "
      >
        <FaChevronLeft  className=' text-xl  text-[#52525B]   blog-button-shadow ' />
      </div>
      )}
      
       {
       screenWidth >= 768 && showNext && (
          <div
          onClick={()=>handleNextClick(societyData[0].societies, "society")}
          className="absolute z-10 cursor-pointer top-1/2 right-4 p-6 border-[2px] bg-white border-[#52525B] rounded-full transform -translate-y-1/2  "
        >
          <FaChevronRight  className=' text-xl  text-[#52525B]   blog-button-shadow ' />
        </div>
        )
       }
    <p className={`text-textColor font-poppins ${openModal ? 'mt-7' : 'mt-2'} font-normal text-lg`}>{collegeName} is known for its diverse range of college societies, which play a crucial role in enhancing the overall college experience for its students.</p>
    <div className={`${openModal ? 'flex-col ' : 'flex-row space-x-8'} flex items-center w-full mt-8  ${nextClicked ? 'overflow-x-scroll' : 'overflow-x-scroll md:overflow-x-hidden'}  no-scrollbar `}  >
     {
       societyData[0]?.societies?.map((ele, ind)=>(
        <div key={ind} className={`flex ${openModal ? 'w-full my-4' : 'w-[296px] flex-none md:duration-1000'}  flex-col items-start`} style={{ transform: `translateX(-${scrollPosition["society"]}px)` }}>
           <div className={`${openModal ? 'h-auto' : 'h-[232px]'} mb-3 w-full rounded-xl`}>
             <img src={ele?.img} alt="" className='w-full h-full rounded-xl bg-cover'/>
           </div>
          
             <p className='text-secondary font-poppins text-base font-medium'>{ele?.name}</p>
           <p className='mt-1 text-textColor font-poppins font-normal text-base'>{ele?.type}</p>
          
         </div>
       ))
     }
    </div>
    </div>
   )
}

const NearbyLocations = ({locationData})=>{
  return(
    <div className='flex flex-col px-5 mt-[32px] md:my-[48px] py-8 md:px-0 md:pt-6 items-center'>
      <h1 className='font-poppins font-semibold text-secondary md:mt-[12px] text-[32px] leading-10 md:text-[40px] tracking-[-0.2px] md:leading-[48px] text-center'>Nearby Location</h1>
      <p className='font-poppins font-normal text-textColor text-center text-base md:text-lg mt-2'>Strategically placed nearby key Metro, Buses and roads </p>
      <div className='md:bg-[#FAFAFA] mt-10 grid md:grid-cols-3 grid-cols-2 lg:grid-cols-5 gap-4  w-full  md:py-10 md:px-20 rounded-lg'>
        {
          locationData[0]?.locations?.map((ele, ind)=>(
            <div key={ind} className='bg-[#FAFAFA] col-span-1 py-6 px-5 md:py-0 rounded-lg  md:px-0 flex flex-col items-center'>
              <img src={blank} alt="" className='bg-[#E4E4E7] rounded-full p-8'/>
              <p className='mt-3 font-poppins text-textColor font-normal text-sm text-center'>{ele?.name}</p>
              <p className='mt-1 font-poppins text-base font-medium text-center text-[#0F172A]'>{ele?.distance}</p>
            </div>
          ))
        }
      </div>
    </div>
  )
}


const SearchByCollege = ({collegeName, cafeData,entertainmentData, collegeId, societyData, locationData, openModal, setopenModal, modalContent, setModalContent, handleNextClick, handlePrevClick, scrollPosition, calculateMaxScroll, nextClicked, screenWidth}) => {
  return (
    <div>
     
      <WhatsappContactLogic/>
        <SubNavbarCollegeLogic
        collegeName={collegeName}
        collegeId={collegeId}
        />
        <div className='background md:px-20 md:pt-16 md:pb-24'>
            <ExploreSection flexType={"flex-row"} imgurl={booknow1} heading1={"Booking a house tour will alleviate any concerns about waiting."} subheading1={"Elevate Your Space with Exclusive Furnishing Offers!"} heading2={"Booking a house tour will alleviate any concerns about waiting."} subheading2={"Convenient locations with easy access to transportation."} gradient={"linear-blue-gradient"}/>
        {
          cafeData && <CafeLounges cafeData={cafeData} collegeId={collegeId} setopenModal={setopenModal} setModalContent={setModalContent} openModal={openModal} handleNextClick={handleNextClick} handlePrevClick={handlePrevClick} scrollPosition={scrollPosition} calculateMaxScroll={calculateMaxScroll} nextClicked={nextClicked} screenWidth={screenWidth} />
        }
          <ExploreSection flexType={"flex-row-reverse gap-4"} imgurl={booknow1} heading1={"Get Cozy with Our Exclusive Furnishing Deals! "} subheading1={"Elevate Your Space with Exclusive Furnishing Offers!"} heading2={"Booking a house tour will alleviate any concerns about waiting."} subheading2={"Convenient locations with easy access to transportation."} gradient={"linear-orange-gradient"}/>
      
        {
          entertainmentData && <Entertainment entertainmentData={entertainmentData} collegeName={collegeName} collegeId={collegeId} setopenModal={setopenModal} setModalContent={setModalContent} openModal={openModal} handleNextClick={handleNextClick} handlePrevClick={handlePrevClick} scrollPosition={scrollPosition} calculateMaxScroll={calculateMaxScroll} nextClicked={nextClicked} screenWidth={screenWidth}/>
        }
        {
          societyData && <Society societyData={societyData} collegeName={collegeName} setopenModal={setopenModal} setModalContent={setModalContent} openModal={openModal} handleNextClick={handleNextClick} handlePrevClick={handlePrevClick} scrollPosition={scrollPosition} calculateMaxScroll={calculateMaxScroll} nextClicked={nextClicked} screenWidth={screenWidth}/>
        }
        <div className='md:my-[48px] pt-5 flex items-center justify-between min-h-[228px] md:min-h-[480px] relative w-full bg-[#2295EF] overflow-hidden mx-auto'>
        <img src={ellipse} alt="" className='absolute  top-0 right-0'/>
          <div className='flex flex-col w-[60%]  md:pl-20 pl-10 items-start'>
            <p className='md:text-lg text-sm font-poppins uppercase text-white font-medium'><span className='w-[20px] h-[2px] mr-1 text-white'>-</span>Local Assistance</p>
            <p className='font-poppins font-semibold mt-5 text-white tracking-[-0.2px] text-2xl md:text-[40px] md:leading-[48px]'>Your Local Assistance Hub</p>
            <button className='blog-button-shadow z-30 background-primary text-white rounded-[4px] px-5 py-3 text-base font-poppins font-medium text-center mt-5 mb-7 md:mb-0 md:mt-10'>Explore</button>
          </div>
         
          <img src={bannerImage} alt="" className='absolute w-[260px] md:w-auto right-0 bottom-0 z-20 '/>
        
        </div>

       {
        locationData && <NearbyLocations locationData={locationData}/>
       }
        <TestimonialLogic/>
        <FaqLogic/>
        <ContactUsLogic/>
 
        </div>
        <ApplyModal openModal={openModal} setopenModal={setopenModal} modalContent={modalContent} setModalContent={setModalContent}/>
           </div>
  )
}

export default SearchByCollege