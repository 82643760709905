import RoutePath from "../routes/RoutePath";
import { showErrorToast, showSuccessToast } from "./toastNotif";
export const handleUpdateCart = async (ele, tokenId,  setCartLoading,setLocalCartLoading, navigate, setKey, quantity, location, setProgress)=>{
        if(quantity > 99){
          showErrorToast("Item out of stock");
          return;
        }
         try{
            setCartLoading(true);
            setProgress(30)
            setLocalCartLoading(true);
           const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/users/updateCart`, {
            method: 'PATCH',
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${tokenId}`
            },
            body: JSON.stringify({
              itemId: ele?._id,
              quantity: parseInt(quantity),
              collegeId: ""
            })
           });
  
           if(response === null || response === undefined){
             throw new Error("Response is null");
           }
           if(response?.status === 401){
               showErrorToast("kindly login again to continue");
               navigate(RoutePath.login, { state: { data: location?.pathname + location?.search }});
           }
           if(!response.ok){
            console.log(response.status);
             throw new Error("Something went wrong");
           }
           const jsonData = await response.json();
              if (jsonData === null || jsonData === undefined) {
                  console.log("Data is null or undefined")
                  throw new Error("Data is null or undefined");
              }
           showSuccessToast("Item updated in cart");
           setKey(Date.now()); 
         }catch(error){
            console.log(error);
            showErrorToast("Item cannot be updated in cart");
  
         }finally{
            setCartLoading(false);
            setLocalCartLoading(false);
            setProgress(100);
         }
        }
       
