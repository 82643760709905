import React, {useState, useEffect} from 'react'
import PayRent from './PayRent'
import { useSelector } from 'react-redux'
import { checkForErrors } from '../../utils/checkForErrors';
import { validateField } from '../../utils/formValidationFunctions'
import RoutePath from '../../routes/RoutePath';
import { useLocation, useNavigate } from 'react-router-dom';
import house from "../../assets/images/house.svg"
import tokenIcon from "../../assets/images/tokenAmountIcon.svg"
import furnishingIcon from "../../assets/images/furnishingIcon.svg"
import validationRules from '../../utils/validationRules'
import { showErrorToast, showInfoToast } from '../../utils/toastNotif';

const PayRentLogic = () => {
  const testimonialData = useSelector(state=>state.dashboard.testimonials)
  const screenWidth = useSelector(state=>state.screenWidth.screenWidth)
  const [currentSlide, setCurrentSlide] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const [tokenLoading, setTokenLoading] = useState(false);
  const [rentLoading, setRentLoading] = useState(false);
  const [furnishingLoading, setFurnishingLoading] = useState(false);
  const [tokenSearchData, setTokenSearchData] = useState(null);
  const [rentSearchData, setRentSearchData] = useState(null);
  const [furnishingSearchData, setFurnishingSearchData] = useState(null);
  const [errors, setErrors] = useState({});
  const [requirederrors, setrequirederrors] = useState({})
  const [tokenPaymentLoading, setTokenPaymentLoading] = useState(false);
  const [rentPaymentLoading, setRentPaymentLoading] = useState(false);
  const [furnishingPaymentLoading, setFurnishingPaymentLoading] = useState(false);
  const requiredFields = ["name", "email","phoneNumber"]
  const [showGetStarted, setShowGetStarted] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const email = localStorage.getItem("email");
  const phoneNumber = localStorage.getItem("phoneNumber");
  const userData = (localStorage.getItem("userData")  && localStorage.getItem("userData") !== "undefined") ? JSON.parse(localStorage.getItem("userData")) : "";
  const token = localStorage.getItem('token');
  const paymentTypes  = [
    { name: "House Rent", img: house},
    { name: "Token Amount", img: tokenIcon},
    { name: "Furnishing", img: furnishingIcon},
  ]
  const [selectedPayment, setSelectedPayment] = useState( { name: "House Rent", img: house});
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [formData, setFormData] = useState({name: userData?.name ? userData?.name : "", phoneNumber: (phoneNumber && phoneNumber !== "undefined" && parseInt(phoneNumber) !== -1) ? parseInt(phoneNumber) : "", email: (email && email !== "undefined") ? email : "", termsAndConditionsAccepted: false})
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % testimonialData?.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [testimonialData]);

  const handleIndicatorClick = (index) => {
    setCurrentSlide(index);
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handlePaymentSelect = (payment) => {
    setSelectedPayment(payment);
    setIsDropdownOpen(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (value.trim() === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: '',
      }));
    } else if (validationRules[name]) {
      const isValid = validateField(name, value, formData);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: isValid ? '' : validationRules[name].message,
      }));
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  } 

  // const handleGetStarted = (e)=>{
  //   e.preventDefault();
  //   const isErrors = checkForErrors(errors, formData, requiredFields, setrequirederrors);
  //   if (isErrors) {
  //     return;
  //   } else {
  //      if(!token || token === "undefined"){
  //          showInfoToast("kindly login to continue");
  //          navigate(RoutePath.login, {state: {data: location?.pathname + location?.search}});
  //      }else{
  //         const email = localStorage.getItem("email");
  //         if(!email || email === "undefined"){
  //           localStorage.setItem("email", formData?.email);
  //         }
  //         setShowGetStarted(false); 
  //      } 
  //   }
  // }

  const handleGetStarted = (e) =>{
    e.preventDefault();
    const isErrors = checkForErrors(errors, formData, requiredFields, setrequirederrors);
    if (isErrors) {
      return;
    } else{
          const email = localStorage.getItem("email");
          if(!email || email === "undefined"){
            localStorage.setItem("email", formData?.email);
          }
          setShowGetStarted(false);
    }
  }

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setFormData(prevFormData => ({
      ...prevFormData,
      termsAndConditionsAccepted: !prevFormData.termsAndConditionsAccepted
  }));
  };

  const handleRentSearch = async (selectedOption, phoneNumber, houseId)=>{
    if(selectedOption === "Phone Number"){
      if(!phoneNumber){
      showErrorToast("Enter phoneNumber");
      return;
      }
      if (!validationRules["phoneNumber"].regex.test(phoneNumber)){
        showErrorToast("Enter valid phone number");
        return;
      }
    }else{
      if(!houseId){
        showErrorToast("Enter house Id");
        return;
      }
    }

    try{
      setRentLoading(true);
      let url = "";
      selectedOption === "Phone Number" ? url = `${process.env.REACT_APP_API_ENDPOINT}/api/payments/getRentAndDuesOfAUser?userPhone=${phoneNumber}` : url = `${process.env.REACT_APP_API_ENDPOINT}/api/payments/getRentAndDuesOfAUser?listingId=${houseId}`
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
        },
    });
    if(response === null){
      throw new Error("Response is null");
    }
    if(!response.ok){
      const message = await response.text();
      const parsedMessage = JSON.parse(message);
      if(parsedMessage?.message){
        showErrorToast(parsedMessage?.message);
        return;
      }else{
        throw new Error(response.status)
      }
      
     
    }
    const data = await response.json();
    if(data === null || data === undefined){
      throw new Error("Data is null");
    }
    setRentSearchData(data);
    }catch(error){
      console.log(error);
      showErrorToast("Something went wrong")
    }finally{
      setRentLoading(false);
    }
  }
  const handleTokenSearch = async (houseId)=>{
    if(!houseId){
      showErrorToast("Enter house Id");
      return;
    }

    try{
      setTokenLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/payments/getTokenAmountOfProperty?listingId=${houseId}`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
        },
    });
    if(response === null){
      throw new Error("Response is null");
    }
    if(!response.ok){
      const message = await response.text();
      const parsedMessage = JSON.parse(message);
      if(parsedMessage?.message){
        showErrorToast(parsedMessage?.message);
        return;
      }else{
        throw new Error(response.status)
      }
      
     
    }
    const data = await response.json();
    if(data === null || data === undefined){
      throw new Error("Data is null");
    }
    setTokenSearchData(data);
    }catch(error){
      console.log(error);
      showErrorToast("Something went wrong")
    }finally{
      setTokenLoading(false);
    }
  }
  const handleFurnishingSearch = async (phoneNumber)=>{
    if(!phoneNumber){
      showErrorToast("Enter Phone number");
      return;
    }
    if (!validationRules["phoneNumber"].regex.test(phoneNumber)){
      showErrorToast("Enter valid phone number");
      return;
    }
    else{

    try{
      setFurnishingLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/payments/getFurnishingRentOfAUser?tenantPhone=${phoneNumber}`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
        },
    });
    if(response === null){
      throw new Error("Response is null");
    }
    if(!response.ok){
      const message = await response.text();
      const parsedMessage = JSON.parse(message);
      if(parsedMessage?.message){
        showErrorToast(parsedMessage?.message);
        return;
      }else{
        throw new Error(response.status)
      }
      
     
    }
    const data = await response.json();
    if(data === null || data === undefined){
      throw new Error("Data is null");
    }
    setFurnishingSearchData(data);
    }catch(error){
      console.log(error);
      showErrorToast("Something went wrong")
    }finally{
      setFurnishingLoading(false);
    }
  }
  }

  const [selectedMethod, setSelectedMethod] = useState('UPI');
  const [charge, setCharge] = useState(0);
  const [netAmount,setNetAmount] = useState(0);
  const [netRentAmount,setNetRentAmount] = useState(0);
  const [tokenAmount , setTokenAmount] = useState(0);
  const [rentAmount , setRentAmount] = useState(0);

  useEffect(() =>{
    if(selectedPayment?.name === "House Rent"){
      const amount = parseFloat(rentAmount) || 0;
      setNetRentAmount(amount+(amount*charge));
    }
    else if(selectedPayment?.name === "Token Amount"){
      const amount = parseFloat(tokenAmount) || 0;
      setNetAmount(amount+(amount*charge));
    }
    
  },[charge, rentAmount, selectedPayment?.name, tokenAmount]);

  useEffect(()=>{
    console.log(selectedMethod);
    switch (selectedMethod) {
      case 'DC':
        if(parseInt(tokenAmount) < 2000){
          setCharge(0.005);
          
        }
        else{
          setCharge(0.01);
        }

        break;
      case 'CC':
        setCharge(0.02);
        break;
      case 'UPI':
        setCharge(0);
        break;
      case 'NB':
        setCharge(0.0175);
        break;
      default:
        setCharge(0);
        break;
    }
  },[selectedMethod])

  const handleTokenPayment = async()=>{
    if(!token || token === "undefined"){
      showErrorToast("Kindly login again to continue");
      navigate(RoutePath.login, {state: {data: location?.pathname + location?.search}});
      return;
    }
     const email = localStorage.getItem("email");
     if(!email){
      showErrorToast("Email not provided");
      return;
     }
     try{
      setTokenPaymentLoading(true);
      const apibody = {
        netAmountToBePaid: netAmount,
        payerEmail: email,
        surl: `https://www.gharbar.co.in${RoutePath.paymentSuccessful}`,
        furl:`https://www.gharbar.co.in${RoutePath.paymentFail}`,
        paymentMode: selectedMethod      
      }
        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/payments/payPropertyToken`, {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "Authorization" : `Bearer ${token}`
          },
          body: JSON.stringify(apibody)
      });
      if(response === null){
        throw new Error("Response is null");
      }
      if(!response.ok){
        if(response.status === 401){
          showErrorToast("Kindly login again to continue");
          navigate(RoutePath.login, {state: {data: location?.pathname + location?.search}});
          return;
        }
        const message = await response.text();
        const parsedMessage = JSON.parse(message);
        if(parsedMessage?.message){
          showErrorToast(parsedMessage?.message);
          return;
        }else{
          throw new Error(response.status)
        }
          
      }
      const data = await response.json();
      if(data === null || data === undefined){
        throw new Error("Data is null");
      }
      if(!data?.paymentLink){
        throw new Error("payment link not provided");
      }
      const url = data?.paymentLink;
      window.open(url, "_self");
     }catch(error){
      console.log(error);
      showErrorToast("something went wrong")
     }finally{
      setTokenPaymentLoading(false)
     }
  }
  const handleRentPayment = async()=>{
    // if(!token || token === "undefined"){
    //   showErrorToast("Kindly login again to continue");
    //   navigate(RoutePath.login, {state: {data: location?.pathname + location?.search}});
    //   return;
    // }
    //  const email = localStorage.getItem("email");
    //  if(!email){
    //   showErrorToast("Email not provided");
    //   return;
    //  }
     try{
      setRentPaymentLoading(true);
      // const apibody = {
      //   amountDetails: rentSearchData?.duesDetail,
      //   payerEmail: email,
      //   isPropertyOccupied: rentSearchData?.isPropertyOccupied,
      //   tenantDetails: rentSearchData?.property?.primeUser,
      //   surl: `https://monkhood-frontend.vercel.app${RoutePath.paymentSuccessful}`,
      //   furl:`https://monkhood-frontend.vercel.app${RoutePath.paymentFail}`,
      //   listingDetails: {
      //     listingId: rentSearchData?.property?.listingId,
      //     _id: rentSearchData?.property?._id,
      //     rent: rentSearchData?.property?.listingDetails?.rent
      //   }
      // }
        const apibody = {
          netAmount: netRentAmount,
          actualAmount: parseInt(rentAmount),
          paymentFor: 'houseRent',
          tenantDetails: {
            name: formData.name,
            phone: formData.phoneNumber,
            email: formData.email
          } ,
          surl: `https://www.gharbar.co.in${RoutePath.paymentSuccessful}`,
          furl:`https://www.gharbar.co.in${RoutePath.paymentFail}`,
          paymentMode: selectedMethod
        }
        console.log("House rent api body" , apibody);
        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/payments/payHouseRent`, {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "Authorization" : `Bearer ${token ? token : ''}`
          },
          body: JSON.stringify(apibody)
      });
      if(response === null){
        throw new Error("Response is null");
      }
      if(!response.ok){
        if(response.status === 401){
          showErrorToast("Kindly login again to continue");
          navigate(RoutePath.login, {state: {data: location?.pathname + location?.search}});
          return;
        }
        const message = await response.text();
        const parsedMessage = JSON.parse(message);
        if(parsedMessage?.message){
          showErrorToast(parsedMessage?.message);
          return;
        }else{
          throw new Error(response.status)
        }
          
      }
      const data = await response.json();
      if(data === null || data === undefined){
        throw new Error("Data is null");
      }
      if(!data?.paymentLink){
        throw new Error("payment link not provided");
      }
      const url = data?.paymentLink;
      window.open(url, "_self");
     }catch(error){
      console.log(error);
      showErrorToast("something went wrong")
     }finally{
      setRentPaymentLoading(false)
     }
  }

  const handleFurnishingPayment = async(totalAmount, serviceDetails, phone)=>{
    if(!token || token === "undefined"){
      showErrorToast("Kindly login again to continue");
      navigate(RoutePath.login, {state: {data: location?.pathname + location?.search}});
      return;
    }
     const email = localStorage.getItem("email");
     if(!email){
      showErrorToast("Email not provided");
      return;
     }
     if(!phone){
      showErrorToast("enter phone number");
      return;
     }
    
     try{
      setFurnishingPaymentLoading(true);
      const apibody = {
        totalAmount: parseInt(totalAmount),
        servicesDetails : serviceDetails,
        tenantDetails: {
          phone: phone
        },
        payerEmail: email,
        surl: `https://monkhood-frontend.vercel.app${RoutePath.paymentSuccessful}`,
        furl:`https://monkhood-frontend.vercel.app${RoutePath.paymentFail}`,
        
      }

     
     
        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/payments/payFurnishingRentOfAUser`, {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "Authorization" : `Bearer ${token}`
          },
          body: JSON.stringify(apibody)
      });

      if(response === null){
        throw new Error("Response is null");
      }
      if(!response.ok){
        if(response.status === 401){
          showErrorToast("Kindly login again to continue");
          navigate(RoutePath.login, {state: {data: location?.pathname + location?.search}});
          return;
        }
        const message = await response.text();
        const parsedMessage = JSON.parse(message);
        if(parsedMessage?.message){
          showErrorToast(parsedMessage?.message);
          return;
        }else{
          throw new Error(response.status)
        }
          
      }
      const data = await response.json();
      if(data === null || data === undefined){
        throw new Error("Data is null");
      }
      if(!data?.paymentLink){
        throw new Error("payment link not provided");
      }
      const url = data?.paymentLink;
      window.open(url, "_self");
     }catch(error){
      console.log(error);
      showErrorToast("something went wrong")
     }finally{
      setFurnishingPaymentLoading(false)
     }
  }
  
  return (
    <><PayRent netRentAmount={netRentAmount} setNetRentAmount={setNetRentAmount} rentAmount={rentAmount} setRentAmount={setRentAmount} tokenAmount={tokenAmount} setTokenAmount={setTokenAmount} netAmount={netAmount} selectedMethod={selectedMethod} setSelectedMethod={setSelectedMethod}   testimonialData={testimonialData}
    currentSlide={currentSlide}
    screenWidth={screenWidth}
    handleIndicatorClick={handleIndicatorClick}
    formData={formData}
    handleChange={handleChange}
    handleCheckboxChange={handleCheckboxChange}
    isChecked={isChecked}
    errors={errors}
    handleTokenPayment={handleTokenPayment}
    requirederrors={requirederrors}
    paymentTypes={paymentTypes}
    handlePaymentSelect={handlePaymentSelect}
    handleDropdownToggle={handleDropdownToggle}
    isDropdownOpen={isDropdownOpen}
    selectedPayment={selectedPayment}
    handleGetStarted={handleGetStarted}
    showGetStarted={showGetStarted}
    setIsDropdownOpen={setIsDropdownOpen}
    handleTokenSearch={handleTokenSearch}
    tokenLoading={tokenLoading}
    tokenSearchData={tokenSearchData}
    email={email}
    phoneNumber={phoneNumber}
    userData={userData}
    tokenPaymentLoading={tokenPaymentLoading}
    handleRentSearch={handleRentSearch}
    rentLoading={rentLoading}
    rentSearchData={rentSearchData}
    setRentLoading={setRentLoading}
    setRentSearchData={setRentSearchData}
    rentPaymentLoading={rentPaymentLoading}
    handleRentPayment={handleRentPayment}
    furnishingLoading={furnishingLoading}
    furnishingSearchData={furnishingSearchData}
    handleFurnishingSearch={handleFurnishingSearch}
    furnishingPaymentLoading={furnishingPaymentLoading}
    handleFurnishingPayment={handleFurnishingPayment}
    /></>
  )
}

export default PayRentLogic