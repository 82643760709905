import React, { useEffect, useState } from 'react';
import { Form, Input, InputNumber, DatePicker, Select, Button, Row, Col, Card, Modal } from 'antd';
import { showErrorToast, showSuccessToast } from '../../utils/toastNotif';

const { Option } = Select;

const RequestListingForm = () => {
    const [colleges, setColleges] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    useEffect(() => {
        async function fetchColleges() {
            if (colleges.length === 0) {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/colleges/getCollegeList`);
                    const data = await response.json();

                    // Correctly format the label using template literals
                    const formattedData = data.data.map((item) => ({
                        label: `${item.collegeName}(${item.collegeCode})`, // Use backticks for template literals
                        value: item._id,
                    }));

                    console.log(formattedData);
                    setColleges(formattedData);
                } catch (error) {
                    console.error('Failed to fetch colleges:', error);
                }
            }
        }
        fetchColleges();
    }, [colleges.length]);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onFinish = async (values) => {
        try {
            const transformedValues = {
                query: {
                    userDetails: {
                        name: values.name,
                        phone: values.phone,
                        email: values.email,
                    },
                    requirement: {
                        locality: values.locality,
                        houseType: values.houseType,
                        furnishing: values.furnishing,
                        budget: values.budget,
                        moveInDate: values.moveInDate.format(), // Ensure date is in correct format
                    },
                    collegeId: values.college,
                    userType: "student", // Assuming a fixed value for userType
                    subject: "homeNotFound", // Assuming a fixed value for subject
                    query: "Looking for housing", // Assuming a fixed value for query
                    source: "website", // Assuming a fixed value for source
                    termsAndConditionsAccepted: true, // Assuming a fixed value for terms acceptance
                }
            };

            console.log(transformedValues)
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/contactUs/submitQuery`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(transformedValues),
            });

            const result = await response.json();

            if (!response.ok) {
                throw new Error(result.message || 'Something went wrong');
            }

            // message.success('Form submitted successfully!');
            setIsModalVisible(false);
            showSuccessToast("We got you query, will reach out to you soon");
            form.resetFields();

        } catch (error) {
            // message.error(Failed to submit form: ${error.message});
            console.log(error);
        }
    };

    return (
        <div className='flex justify-center p-4 shadow-sm shadow-gray-300 w-1/2 md:w-[250px] bg-orange-100 rounded-full'>
            <button className='text-orange-400 font-semibold md:text-lg text-xs hover:text-orange-500 '  onClick={showModal}>
                Didn't like anything here ? <span className="underline">We got more</span> 😉
            </button>
            <Modal title="Requirement Form"
                maskClosable={false}
                open={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null}>
                <Form form={form}
                    onFinish={onFinish}
                    layout="vertical"
                    rootClassName='p-[0px]'>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="Name*"
                                name="name"
                                rules={[{ required: true, message: 'Please enter your name' }]}
                            >
                                <Input placeholder="Enter your name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Phone No*"
                                name="phone"
                                rules={[{ required: true, message: 'Please enter your phone number' }]}
                            >
                                <Input placeholder="Enter your phone number" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="Email*"
                                name="email"
                                rules={[{ required: true, type: 'email', message: 'Please enter a valid email' }]}
                            >
                                <Input placeholder="Enter your email" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="College" name="college">
                                <Select
                                    showSearch
                                    placeholder="Nearby Colleges"
                                    optionFilterProp="children"
                                    allowClear
                                    filterOption={filterOption}
                                    options={colleges}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Preferred Locality" name="locality">
                                <Input placeholder="Enter preferred locality" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="House Type*"
                                name="houseType"
                                rules={[{ required: true, message: 'Please select house type' }]}
                            >
                                <Select placeholder="Select house type">
                                    <Option value="1BHK">1BHK</Option>
                                    <Option value="1RK">1RK</Option>
                                    <Option value="2BHK">2BHK</Option>
                                    <Option value="3BHK">3BHK</Option>
                                    <Option value="4BHK">4BHK</Option>
                                    <Option value="5BHK">5BHK</Option>
                                    <Option value="PG">PG</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="Furnishing*"
                                name="furnishing"
                                rules={[{ required: true, message: 'Please select furnishing type' }]}
                            >
                                <Select placeholder="Select furnishing type">
                                    <Option value="furnished">Furnished</Option>
                                    <Option value="semi-furnished">Semi-Furnished</Option>
                                    <Option value="unfurnished">Unfurnished</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Budget*"
                                name="budget"
                                rules={[{ required: true, message: 'Please enter your budget' }]}
                            >
                                <InputNumber addonBefore="₹" type="number" placeholder="Enter your budget" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        label="Tentative Move-In Date*"
                        name="moveInDate"
                        rules={[{ required: true, message: 'Please select move-in date' }]}
                    >
                        <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ width: '100%', backgroundColor: '#EF7C22' }}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default RequestListingForm;