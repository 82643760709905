import React from 'react'
import whatsapp from "../../assets/images/whatsappIcon.svg";

const WhatsappContact = () => {
  return (
    <div className='fixed z-50 bottom-[-1rem] md:left-5 -right-8'>
      <div className="relative w-[113px] mb-10">
        <img onClick={() => window.open("https://wa.me/919560346254?text=Hey%20Team%20Gharbar", "_blank")
        } className='cursor-pointer animate-bounce duration-1000 ease-in-out w-fit h-fit object-contain' src={whatsapp} alt="whatsapp" />
      </div>
    </div>
  )
}

export default WhatsappContact