import React, {useState} from 'react'
import bed from "../../assets/images/bedIcon.png"
import { IoIosStar } from 'react-icons/io'
import { LuPlus } from "react-icons/lu";
import RoutePath from '../../routes/RoutePath';
import { RxCross1 } from "react-icons/rx";
import { useNavigate } from 'react-router-dom';
import { IoChevronDown } from "react-icons/io5";
import ImageCarouselLogic from '../imageCarousel/ImageCarouselLogic';
import plus from "../../assets/images/plus.svg";
import minus from "../../assets/images/minus.svg"
import { useLocation } from 'react-router-dom';
import {handleUpdateCart} from '../../utils/handleUpdateCart';
import BarLoaderLogic from '../barLoader/BarLoaderLogic';
import { Tag } from 'antd';



const FurnishingCard = ({ele, navigate, location, setCartLoading, cartLoading, setKey, handleNavigate}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [inputQuantity, setInputQuantity] = useState(false);
  const [quantity, setQuantity] = useState(ele?.quantity)
  const [localCartLoading, setLocalCartLoading] = useState(false);
  const tokenId = localStorage.getItem('token');
  const [progress, setProgress] = useState(0);
  const [quantityInput, setQuantityInput] = useState(ele?.quantity)

  return (
    <div onClick={() => !location?.pathname?.includes(RoutePath.cart) && handleNavigate(ele)} className={` md:mt-6 flex md:mb-6  ${location?.pathname?.includes(RoutePath.cart) ? 'flex-row justify-between items-start w-full' : 'flex-col mt-4 w-full md:flex-row cursor-pointer md:p-0 p-2 bg-gray-50 border border-gray md:border-none md:bg-white rounded-lg'}  items-start  md:space-x-8 `}>
         <div className={` w-full h-full md:px-0    rounded-xl bg-cover  ${location?.pathname?.includes(RoutePath.cart) ? 'md:h-[234px] md:aspect-[16/9] md:w-4/12 w-[80px] h-[112px] px-4 mt-5 md:mt-0' : 'md:w-3/12 aspect-auto md:h-[320px]'}  cursor-pointer`}>
        <ImageCarouselLogic indicator={false} images={ele?.images} id={ele?._id} apibody={ele} alt="" className={`rounded-xl bg-cover h-full w-full `} />
      </div>
      <div className={` md:w-8/12 w-full mt-5 md:mt-0`}>
      <div className={`flex  items-start justify-between ${location?.pathname?.includes(RoutePath.cart) ? '' : 'flex-col space-y-1'}`}>
          <div className='w-full' >
          <p className={`font-poppins cursor-pointer hover:text-[#0f172ad0] font-semibold text-[#0F172A] text-xs  md:text-lg overflow-x-hidden text-ellipsis ${location?.pathname?.includes(RoutePath.cart) ? '' : 'whitespace-nowrap'}`}>{ele?.name}</p>
          <p className={`font-poppins text-xs md:text-sm font-normal text-textColor mt-1 ${location?.pathname?.includes(RoutePath.cart) ? 'hidden' : 'hidden md:block'}`}>{ele?.category?.value}</p>
          </div>
           <div className={` ${location?.pathname?.includes(RoutePath.cart) ? 'hidden' : 'md:hidden flex space-x-2 items-center'} `}>
          {/* <p className='text-textColor text-right font-poppins font-normal text-xs'>Rent</p> */}
          <p className='font-poppins text-xs text-secondary font-semibold text-[#0F172A] flex'>₹{ele?.rent} <span className=' font-poppins font-normal text-xs'>/month</span></p>
          <Tag bordered={false} color={ele?.status === 'available' ? 'success' : 'error'} className='text-xs' >{ele?.status}</Tag>
        </div>
        <button disabled={cartLoading} onClick={()=>handleUpdateCart(ele, tokenId, setCartLoading, setLocalCartLoading, navigate, setKey, 0, location,setProgress)} className={`${location?.pathname?.includes(RoutePath.cart) ? 'block' : 'hidden'}`}>
         <RxCross1   className='text-2xl text-[#52525B] cursor-pointer'/>
        </button>
           {
            ele?.averageRating &&  <div className='md:flex hidden items-center space-x-2'>
            <IoIosStar className='text-[#475569]' />
            <p className='text-[#222222] font-normal text-[15px] leading-[20px]'>{ele?.averageRating}</p>
          </div>
           }
        </div>
        {
          ele?.productDetails?.size && <div className={` mt-7  ${location?.pathname?.includes(RoutePath.cart) ? 'md:block hidden' : 'hidden md:block'}`}>
          <p className='font-poppins text-textColor text-xs font-normal'>Size</p>
      <div className='px-3 py-2 font-poppins inline-flex bg-[#FFF4EB]  rounded-[24px] font-normal text-xs text-[#0F172A]  items-center space-x-2 mt-2'>
        <img src={bed} alt="" />
        <p>{ele?.productDetails?.size}</p>
      </div>
      </div>
        }
        <div className={`${location?.pathname?.includes(RoutePath.cart) ? 'flex flex-col' : 'hidden'}`}>
        <div className="relative mt-3 block md:hidden">
      <button
        type="button"
        className=" flex items-center rounded px-2 py-[6px] space-x-2 blog-button-shadow bg-[#FAFAFA] font-poppins text-xs text-[#3F3F46] font-medium"
        onClick={() => setIsDropdownOpen(prevState => !prevState)}
      >
        Qty: {ele?.quantity}
        <span><IoChevronDown className='text-[#52525B] ml-2 text-lg'/></span>
      </button>

      {isDropdownOpen && (
        <div className="absolute top-10 z-30 left-0 bg-white border border-gray-200 p-2 rounded-md">
          <div
            className="block w-full mb-2"
          >
            {[...Array(9).keys()].map(num => (
              <div key={num + 1} onClick={()=>{
                setIsDropdownOpen(false);
                handleUpdateCart(ele, tokenId, setCartLoading, setLocalCartLoading, navigate, setKey, num + 1, location, setProgress)}}>{num + 1}</div>
            ))}
            <div onClick={()=>{setInputQuantity(true);
            setIsDropdownOpen(false)
            }}>10+</div>
          </div>
        </div>
      )}
      {inputQuantity && (
            <div className="flex space-x-3 items-center mt-3">
              <input
                type="number"
                className="w-16 mr-2 border rounded border-gray-300 p-1"
                value={quantityInput}
                onChange={(e)=>setQuantityInput(e.target.value)}
              />
              <button disabled={localCartLoading}
                type="button"
                className=' text-primary font-poppins text-xs font-medium'
                onClick={()=>{
                  setIsDropdownOpen(false);
                  handleUpdateCart(ele, tokenId, setCartLoading, setLocalCartLoading, navigate, setKey, quantityInput, location, setProgress)}}
              >
                Update
              </button>
            </div>
          )}
        </div>
        <div className={`md:hidden block mt-3`}>
          <p className='text-textColor  font-poppins font-normal text-xs'>Price</p>
          <p className='font-poppins text-sm text-secondary font-semibold'>₹{ele?.rent} <span className='text-textColor font-poppins font-normal text-sm'>/month</span></p>
        </div>
        
        </div>
        <div className='flex flex-wrap gap-3 items-center md:mt-7 justify-between w-full'>
          <div className={` hidden md:block `}>
            <p className='text-textColor  font-poppins font-normal text-xs'>Price</p>
            <p className='font-poppins text-lg text-secondary font-semibold'>₹{ele?.rent} <span className='text-textColor font-poppins font-normal text-sm'>/month</span></p>
          </div>
          {
           location?.pathname?.includes(RoutePath.cart) &&
           <>
           {
            localCartLoading ? <BarLoaderLogic progress={progress}/> :
            <div className='md:flex md:flex-col hidden'>
            <div className='flex w-[100px]  items-center justify-between rounded'>
          <button className='aspect-square rounded-md bg-[#EF7C22] flex justify-center align-middle p-1' disabled={localCartLoading} onClick={()=>handleUpdateCart(ele, tokenId, setCartLoading, setLocalCartLoading, navigate, setKey, quantity - 1, location, setProgress)}><img src={minus} alt="-" className='h-6'/></button>
          <p className='text-xl w-1/2 flex justify-center align-middle font-poppins font-semibold text-[#3F3F46]'>{ele?.quantity}</p>
          <button className='aspect-square rounded-md bg-[#EF7C22] flex justify-center align-middle p-1' disabled={localCartLoading} onClick={()=>handleUpdateCart(ele, tokenId, setCartLoading, setLocalCartLoading, navigate, setKey, quantity + 1, location, setProgress)}><img src={plus} alt="+" className='h-6'/></button>
          </div>
          </div> 
           }
           </>
          }
        </div>

      </div>
    </div>
  )
}

export default FurnishingCard