import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchAddVisit = createAsyncThunk(
    'addVisit/fetchAddVisit',
    async({apiBody, token, listingId, collegeId}) => {
        const formData = new FormData();
        formData.append("visit", JSON.stringify(apiBody));
        formData.append("collegeIdPhoto", collegeId)
        try{
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/visit/addVisit`, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${token}`

                },
                
                body:formData     
            });
            if (response === null) {
                console.log("Response is null");
                throw new Error("Response is null");
            }

            if(response?.status === 401) {
                console.log("Unauthorized");
                throw new Error("Unauthorized");
            }

            

            if(!response.ok){
                const message = await response?.text();
               const parsedMessage = JSON.parse(message);
                if(parsedMessage?.message){
                     throw new Error(parsedMessage?.message)
                 } else{
                console.log(`Error fetching add visiting data: ${response.status} - ${response.statusText}`);
                throw new Error(`Error fetching add visiting data: ${response.status} - ${response.statusText}`);}
            }
            const data = await response.json();

            if (data === null || data === undefined) {
                console.log("Data is null or undefined")
                throw new Error("Data is null or undefined");
            }
            

            return { listingId, visitingDetails: data };

        }catch(error){ 
        console.log("Error fetching add visiting api", error);
        throw error;
    }
    }
);

const addVisitSlice = createSlice({
    name: "addVisit",
    initialState: {
        colleges: {},
    },

    reducers: {},

    extraReducers: (builder) => {
        builder
        .addCase(fetchAddVisit.pending, (state, action) => {
            const { listingId } = action.meta.arg;
            state.colleges[listingId] = {
                ...state.colleges[listingId],
                status: 'loading',
                error: null
            };
        })
        .addCase(fetchAddVisit.fulfilled, (state, action) => {
            const { listingId, visitingDetails } = action.payload;
            state.colleges[listingId] = {
                ...state.colleges[listingId],
                status: 'succeeded',
                visitingDetails
            };
        })
        .addCase(fetchAddVisit.rejected, (state, action) => {
            const { listingId } = action.meta.arg;
            state.colleges[listingId] = {
                ...state.colleges[listingId],
                status: 'failed',
                error: action.error.message
            };
        });
      }
})

export default addVisitSlice.reducer