import React, { useEffect, useRef, useState } from 'react'
import ContactUsLogic from "../../components/contactUs/ContactUsLogic"
import star from "../../assets/images/starIcon.svg"
import truncateText from '../../utils/truncateText'
import up from "../../assets/images/upArrow.svg"
import info from "../../assets/images/infoIcon.svg"
import down from "../../assets/images/downArrow.svg"
import useFindCollege from '../../utils/findCollege'
import WhatsappContactLogic from '../../components/whatsappContact/WhatsappContactLogic'
import headavatar from "../../assets/images/headAvatar.svg"
import { BiSearch } from 'react-icons/bi'
import search from "../../assets/images/navbarSearchIcon.svg"
import TestimonialCarouselLogic from '../../components/testimonialCarousel/TestimonialCarouselLogic'
import { useSelector } from 'react-redux'
import { showErrorToast } from '../../utils/toastNotif'
import { Modal } from 'antd'
import Terms from '../../components/termsAndConditions/Terms'
import PaymentSelect from '../../components/paymentSelection/PaymentSelect'


const HouseRent = ({ phone, handleRentSearch, rentLoading, rentSearchData, setRentLoading,
  setRentSearchData, rentPaymentLoading,handleRentPayment, calculateTotalDue
}) => {
  const options = ["Phone Number", "House ID"]
  const [selectedOption, setSelectedOption] = useState("Phone Number")
  const [houseId, setHouseId] = useState("")
  const [phoneNumber, setPhoneNumber] = useState(phone);
  const screenWidth = useSelector(state=>state.screenWidth.screenWidth);


useEffect(()=>{
  setRentSearchData(null);
}, [selectedOption])
  return (
    <div>
      <hr className='w-full text-[#D4D4D8] my-6' />
      <p className='text-[#3F3F46] font-poppins text-sm font-medium mt-[16px]'>Choose Find House option</p>
     <div className='flex items-center space-x-5 mt-[16px]'>
     {
        options && options?.map((ele, ind) => (
          <div key={ind} className={`flex items-center space-x-3`}>
            <label className="flex items-center">
              <input
                type="radio"
                className="hidden"
                value={ele}
                checked={ele === selectedOption}
                onChange={(e) => {
                  if (!rentPaymentLoading && !rentLoading) {
                    setSelectedOption(e.target.value);
                  } else {
                    showErrorToast("Options cannot be changed while loading.");
                  }
                }}
                disabled={rentPaymentLoading || rentLoading}
              />
              <span
                className={`relative inline-block w-5 h-5  rounded-full cursor-pointer ${ele === selectedOption ? 'background-primary border border-primary' : 'border border-[#D4D4D8]'
                  }`}
              >
               
              </span>
            </label>
            <p className='font-poppins text-sm text-secondary font-medium'>{ele}</p>
          </div>
        ))
      }
     </div>
     <div className='mt-[16px]'>
      <p className='text-[#3F3F46] font-poppins text-sm mt-[6px] font-medium '>{selectedOption}</p>
      <div className='w-full flex mt-[12px] items-center justify-between'>
      {
        selectedOption === "Phone Number" ? <input type="number" placeholder={"+91  000-000-0000"} name="phoneNumber" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} className='border-[1px] outline-none md:w-[75%] w-[80%]  login-box-shadow h-[44px] text-textColor font-normal text-base font-poppins rounded-[4px] border-[#D4D4D8] py-[10px] px-[14px]' /> 
        :
        <input type="text" placeholder={"Enter house Id"} name="houseId" value={houseId} onChange={(e) => setHouseId(e.target.value)} className='border-[1px] outline-none md:w-[75%] w-[80%]  login-box-shadow h-[44px] text-textColor font-normal text-base font-poppins rounded-[4px] border-[#D4D4D8] py-[10px] px-[14px]' />
      }
     {
      screenWidth >= 768 ?  <div onClick={()=>handleRentSearch(selectedOption, phoneNumber, houseId)} className='flex items-center space-x-2 hover:bg-[#FEE3CD] bg-[#FFF4EB] py-[12px] px-[16px] text-primary font-poppins cursor-pointer rounded-[4px] font-medium text-sm text-center'>
      <BiSearch className='text-primary text-2xl'/>
          <p>Search</p>
        </div> :
        <div onClick={()=>handleRentSearch(selectedOption, phoneNumber, houseId)}  className="bg-[#FFF4EB] p-3  cursor-pointer rounded"><img src={search}/></div>
     }
      </div>

      {
        rentLoading ?
         <div className='flex flex-col items-center justify-center mt-6 w-full'>
          <div className='animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-gray-900'></div>
          </div> 
          : 
          <>
          {
            rentSearchData!== null ?
            <>
            {
              rentSearchData ?
              <>
              <div className='flex flex-col mt-[16px] md:mt-[20px] w-full'>
                <div className={`rounded border  ${screenWidth >= 768 && 'ticket'} relative border-[#E4E4E7] flex flex-col`}>
                  <div className='p-4'>
                  <div className='md:flex  md:items-start md:space-x-5 w-full'>
              <div className=' h-[120px] md:h-[100px] w-full md:w-[100px]'>
              <img 
    src={rentSearchData?.property?.listingDetails?.imgArr && Array.isArray(rentSearchData.property.listingDetails.imgArr) ? rentSearchData.property.listingDetails.imgArr[0] : ''}
    alt="" 
    className='w-full h-full rounded'
/>
              <div className=" border-dashed hidden  md:mt-6  border-t-[0.5px]  border-[#D4D4D8]  w-full"></div>
              </div>
               <div className='mt-[16px] md:w-[412px] md:mt-0'>
               <div className='flex flex-col items-start'>
                     <p className='text-[#0F172A] font-poppins font-medium text-sm'>House ID</p>
                     <p className='mt-[2px] font-poppins font-normal text-sm text-secondary'>{rentSearchData?.property?.listingId}</p>
                   </div>
                   <div className=' flex items-start mt-[16px] justify-start space-x-3 w-full'>
                    <div className='flex flex-col w-1/2 items-start'>
                      <p className='text-[#0F172A] font-poppins font-medium text-sm'>Name</p>
                      <p className='text-secondary text-sm font-normal font-poppins mt-[2px]'>{rentSearchData?.property?.primeUser?.name}</p>
                    </div>
                    <div className='flex flex-col items-start'>
                      <p className='text-[#0F172A] font-poppins font-medium text-sm'>{(rentSearchData?.property?.primeUser?.phone && rentSearchData?.property?.primeUser?.phone !== -1) ? 'Phone number' : 'Email'}</p>
                      <p className='text-secondary text-sm font-normal font-poppins mt-[2px]'>{(rentSearchData?.property?.primeUser?.phone && rentSearchData?.property?.primeUser?.phone !== -1) ? rentSearchData?.property?.primeUser?.phone : rentSearchData?.property?.primeUser?.email}</p>
                    </div>
                  </div>
                  <div className=" border-dashed my-2 md:mt-6  border-t-[0.5px]  border-[#D4D4D8]  w-full"></div>
                  <div className='mt-[16px] flex flex-wrap  gap-[4px] justify-between items-start'>
                    <div className='flex flex-col items-start'>
                      <p className='text-[#0F172A] font-poppins font-medium text-sm'>Rent</p>
                      <div className='flex items-center mt-[2px] tooltip space-x-2'>
                        <div className='tooltiptext background-secondary  p-3 rounded-[6px] flex flex-col items-center w-[216px]'>
                          {
                            Object.keys(rentSearchData?.duesDetail)?.map((key) => (
                              key !== "netAmount" && key!== "lateRentPenalty" && (
                                  <div className='flex items-start justify-between w-full my-[2px]' key={key}>
                                      <p className='font-poppins font-normal text-xs text-white' style={{ textTransform: 'capitalize', marginRight: '10px' }}>
                                          {key.replace(/([a-z])([A-Z])/g, '$1 $2')}:
                                      </p>
                                      <p className='font-poppins font-semibold text-white text-xs'> ₹{rentSearchData?.duesDetail[key]}</p>
                                  </div>
                              )
                          ))}
                          
                        </div>
                        <p className='text-secondary font-poppins font-normal  text-sm'>₹{calculateTotalDue(rentSearchData?.duesDetail)}</p>
                        <img src={info} alt="" />
                      </div>
                    </div>
                 {
                  rentSearchData?.duesDetail?.lateRentPenalty &&    <div className='flex flex-col items-start'>
                  <p className='text-[#0F172A] font-poppins font-medium text-sm'>Penalty</p>
                
                    <p className='text-secondary font-poppins mt-[2px] font-normal text-sm'>₹{rentSearchData?.duesDetail?.lateRentPenalty}</p>
                  
                </div>
                 }
                    <div className='flex flex-col items-start'>
                      <p className='text-[#0F172A] font-poppins font-medium text-sm'>Net Amount</p>
                     
                        <p className='text-secondary font-poppins mt-[2px] font-semibold text-sm'>₹{rentSearchData?.duesDetail?.netAmount}</p>
                      
                    </div>
                  </div>
               </div>
               </div>
                  </div>
                  <div className="border-t border-t-[#E4E4E7] py-3 px-4 bg-[#FAFAFA]">
                    <p className='text-[#181738] text-xs font-medium font-poppins'>For any help <span className='text-primary ml-2 cursor-pointer'>Contact Us</span></p>
                  </div>
                </div>
              </div>
              </>
              :
              <div className='h-[100px] w-full mt-[12px] rounded border border-[#E4E4E7] flex flex-col items-center justify-center'>
              <p className='font-poppins font-normal text-sm text-[#52525B]'>No data available</p>
            </div>
            }
            </>
            :
            <></>
          }
          </>
      }
     </div>
     {
     rentPaymentLoading ?  <div  className={`background-primary flex items-center justify-center  w-full rounded-lg py-3 px-5 font-medium text-base mt-8 blog-button-shadow font-poppins text-white text-center`}><div className='animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-gray-900'></div></div> :
      <button onClick={handleRentPayment}  disabled={rentSearchData ? false : true} className={`${!rentSearchData ? 'bg-[#FCB57D]' : 'background-primary'}  w-full rounded-lg py-3 px-5 font-medium text-base mt-8 blog-button-shadow font-poppins text-white text-center`}>Pay Now</button>
    }
    </div>
  )
}

const TokenAmount = ({handleTokenSearch, tokenSearchData,
  phoneNumber,
  email,
  userData,
  handleTokenPayment,
  tokenPaymentLoading,
  tokenLoading}) => {
  const [houseId, setHouseId] = useState("")
  const screenWidth = useSelector(state=>state.screenWidth.screenWidth)
  return(
    <div>
       <hr className='w-full text-[#D4D4D8] my-6' />
       <div className='mt-6'>
      <p className='text-[#3F3F46] font-poppins text-sm font-medium mt-5'>House ID</p>
      <div className='w-full mt-[12px] flex items-center justify-between'>
      <input type="text" placeholder={"Enter house Id"} name="houseId" value={houseId} onChange={(e) => setHouseId(e.target.value)} className='border-[1px] outline-none md:w-[75%] w-[80%]  login-box-shadow h-[44px] text-textColor font-normal text-base font-poppins rounded-[4px] border-[#D4D4D8] py-[10px] px-[14px]' />
        
      
     {
      screenWidth >= 768 ?  <div onClick={()=>handleTokenSearch(houseId)} className='flex items-center space-x-2 hover:bg-[#FEE3CD] bg-[#FFF4EB] py-[12px] px-[16px] text-primary font-poppins cursor-pointer rounded-[4px] font-medium text-sm text-center'>
      <BiSearch className='text-primary text-2xl'/>
          <p>Search</p>
        </div> :
        <div onClick={()=>handleTokenSearch(houseId)} className="bg-[#FFF4EB] p-3  cursor-pointer rounded"><img src={search}/></div>
     }
      </div>
      {
        tokenLoading ?
         <div className='flex flex-col items-center justify-center mt-6 w-full'>
          <div className='animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-gray-900'></div>
          </div> 
          : 
          <>
        {
          tokenSearchData !== null ? 
          <>
          {
            tokenSearchData ? 
            <div className='flex flex-col mt-[16px] md:mt-[20px] w-full'>
              <div className='border relative  rounded border-[#E4E4E7] flex flex-col  w-full p-4'>
                
               <div className='md:flex  md:items-start md:space-x-5 w-full'>
               <img 
    src={tokenSearchData?.imgArr && Array.isArray(tokenSearchData.imgArr) ? tokenSearchData.imgArr[0] : ''}
    alt="" 
    className='rounded h-[120px] md:h-[100px] w-full md:w-[100px]'
/>

               
               <div className='w-full md:w-[412px] mt-[16px] md:mt-0'>
                 <div className='flex items-start justify-between w-full'>
                   <div className='flex flex-col items-start w-[113.33px]'>
                     <p className='text-[#0F172A] font-poppins font-medium text-sm'>House ID</p>
                     <p className='mt-[2px] font-poppins font-normal text-sm text-secondary'>{tokenSearchData?.listingId}</p>
                   </div>
                   <div className='rounded-[100px] py-[4px] px-[10px] bg-[#EDFCF2] flex items-center space-x-2'>
                   <div className='w-[3px] h-[3px] bg-[#099250] rounded-full'></div>
                     <p className='text-center text-[#099250] font-poppins text-xs font-medium'>Available</p>
                   </div>
                 
                 </div>
                 <div className='flex flex-col mt-[12px] items-start w-[113.33px]'>
                     <p className='text-[#0F172A] font-poppins font-medium text-sm'>Token Amount</p>
                     <p className='mt-[2px] font-poppins font-semibold text-sm text-secondary'>₹{tokenSearchData?.tokenAmount}</p>
                   </div>
               </div>
               </div>
                  <div className=" border-dashed my-2 md:mt-6  border-t-[0.5px]  border-[#D4D4D8]  w-full"></div>
                  <div className='mt-2 flex items-start justify-start space-x-3 w-full'>
                    <div className='flex flex-col w-1/2 items-start'>
                      <p className='text-[#0F172A] font-poppins font-medium text-sm'>Name</p>
                      <p className='text-secondary text-sm font-normal font-poppins mt-[2px]'>{userData?.name}</p>
                    </div>
                    <div className='flex flex-col items-start'>
                      <p className='text-[#0F172A] font-poppins font-medium text-sm'>{(phoneNumber && phoneNumber !== "undefined" && parseInt(phoneNumber) !== -1) ? 'Phone number' : 'Email'}</p>
                      <p className='text-secondary text-sm font-normal font-poppins mt-[2px]'>{(phoneNumber && phoneNumber !== "undefined" && parseInt(phoneNumber) !== -1) ? phoneNumber : email}</p>
                    </div>
                  </div>
              
              </div>
              <div className='mt-[8px] md:mt-[12px] bg-[#FAFAFA] border-[#E4E4E7] rounded border p-3'>
                <p className='font-poppins font-medium text-xs text-[#3F3F46]'>Note:</p>
                <ul className='md:mt-1'>
                 <div className='flex items-center md:my-[6px] space-x-2'><div className='w-[3px] h-[3px] background-secondary'></div> <li className='font-normal mt-[6px] text-xs font-poppins text-[#27272A]'>Payer will be added as a tenant of this property</li></div>
                  <div className='flex items-center space-x-2'><div className='w-[3px] h-[3px] background-secondary'></div><li className='font-normal text-xs font-poppins text-[#27272A]'>Check your details before payment</li></div>
                </ul>
              </div>
            </div> : <div className='h-[100px] w-full mt-[16px] rounded border border-[#E4E4E7] flex flex-col items-center justify-center'>
              <p className='font-poppins font-normal text-sm text-[#52525B]'>No data available</p>
            </div>
          }
          </> :
          
          <></>
        }
        </>
      }
     </div>
    {
      tokenPaymentLoading ?  <div  className={`background-primary flex items-center justify-center  w-full rounded-lg py-3 px-5 font-medium text-base mt-8 blog-button-shadow font-poppins text-white text-center`}><div className='animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-gray-900'></div></div> :
      <button onClick={handleTokenPayment} disabled={tokenSearchData ? false : true} className={`${!tokenSearchData ? 'bg-[#FCB57D]' : 'background-primary'}  w-full rounded-lg py-3 px-5 font-medium text-base mt-8 blog-button-shadow font-poppins text-white text-center`}>Pay Now</button>
    }
    </div>
  )
}



const Furnishing = ({
  phone,
  furnishingLoading,
  furnishingSearchData,
  handleFurnishingSearch,
  furnishingPaymentLoading,
  handleFurnishingPayment,
  calculateTotalDue
}) => {
  const screenWidth = useSelector(state => state.screenWidth.screenWidth);
  const [phoneNumber, setPhoneNumber] = useState(phone);
  const [selectedItems, setSelectedItems] = useState([]);
  const [totalNetAmount, setTotalNetAmount] = useState(0);

  const handleCheckboxChange = (isChecked, item) => {
    if (isChecked) {
      const selectedItem = {
        serviceDetail: item.serviceDetail,
        dueAmountDetails: item.dueAmountDetails,
        _id: item._id
      };
      setSelectedItems(prevItems => [...prevItems, selectedItem]);
      setTotalNetAmount(prevAmount => prevAmount + item.dueAmountDetails.netAmount);
    } else {
      setSelectedItems(prevItems => prevItems.filter(selectedItem => selectedItem._id !== item._id));
      setTotalNetAmount(prevAmount => prevAmount - item.dueAmountDetails.netAmount);
    }
  };
  
  

  return (
    <div>
      <hr className='w-full text-[#D4D4D8] my-6' />
      <div className='mt-6'>
        <p className='text-[#3F3F46] font-poppins text-sm font-medium mt-5'>Phone Number</p>
        <div className='w-full mt-[12px] flex items-center justify-between'>
          <input
            type="number"
            placeholder={"+91  000-000-0000"}
            name="phoneNumber"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            className='border-[1px] outline-none md:w-[75%] w-[80%]  login-box-shadow h-[44px] text-textColor font-normal text-base font-poppins rounded-[4px] border-[#D4D4D8] py-[10px] px-[14px]'
          />

          {screenWidth >= 768 ? (
            <div onClick={() => handleFurnishingSearch(phoneNumber)} className='flex items-center space-x-2 hover:bg-[#FEE3CD] bg-[#FFF4EB] py-[12px] px-[16px] text-primary font-poppins cursor-pointer rounded-[4px] font-medium text-sm text-center'>
              <BiSearch className='text-primary text-2xl' />
              <p>Search</p>
            </div>
          ) : (
            <div onClick={() => handleFurnishingSearch(phoneNumber)} className="bg-[#FFF4EB] p-3  cursor-pointer rounded">
              <img src={search} alt="Search" />
            </div>
          )}
        </div>

        {furnishingLoading ? (
          <div className='flex flex-col items-center justify-center mt-6 w-full'>
            <div className='animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-gray-900'></div>
          </div>
        ) : (
          <>
            {furnishingSearchData !== null ? (
              <>
                {furnishingSearchData?.length > 0 ? (
                  <div className='flex rounded border border-[#E4E4E7] flex-col mt-[16px] md:mt-[20px] w-full'>
                    <div className=' p-4  flex flex-col'>
                      {furnishingSearchData?.map((ele, ind) => (
                        <div key={ind}>
                        <div  className='gap-[16px] md:flex-row flex md:space-x-3  flex-col'>
                          <div className='w-full md:w-[100px] md:h-[100px] h-[120px] rounded relative'>
                            <img src={ele?.serviceDetail?.image} alt="" className='w-full h-full bg-cover rounded' />
                            <label className="flex md:hidden absolute top-2 right-2 items-center">
                              <input
                                type="checkbox"
                                onChange={(e) => handleCheckboxChange(e.target.checked, ele)}
                                className="hidden"
                              />
                              <span
                                className={`relative inline-block w-5 h-5 border border-[#D4D4D8] rounded-md cursor-pointer ${selectedItems.includes(ele.serviceDetail) ? 'background-primary' : 'bg-[#18181B33]'}`}
                              >
                                {selectedItems.includes(ele.serviceDetail) && (
                                  <svg
                                    className="w-4 h-4 text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                  </svg>
                                )}
                              </span>
                            </label>
                          </div>
                          <div className='flex  md:w-[412px] w-full flex-col items-start'>
                            <div className='flex items-start justify-between w-full'>
                            <div className='flex flex-col items-start gap-[2px]'>
                              <p className='text-base text-[#0F172A] font-medium font-poppins'>{ele?.serviceDetail?.name}</p>
                              <p className='font-poppins text-textColor font-normal text-sm'>{ele?.serviceDetail?.name}</p>
                            </div>
                            <label className="md:flex hidden items-center">
                              <input
                                type="checkbox"
                                onChange={(e) => handleCheckboxChange(e.target.checked, ele)}
                                className="hidden"
                              />
                              <span
                                className={`relative inline-block w-5 h-5 border border-[#D4D4D8] rounded-md cursor-pointer ${selectedItems.some(item => item._id === ele._id) ? 'background-primary' : 'bg-[#18181B33]'}`}
                              >
                               {selectedItems.some(item => item._id === ele._id) && (
                                  <svg
                                    className="w-4 h-4 text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                  </svg>
                                )}
                              </span>
                            </label>
                            </div>
                            <div className='mt-[16px] flex items-start justify-between w-full'>
                              <div className='flex flex-col gap-[4px] items-start'>
                                <p className='text-[#0F172A] font-poppins font-medium text-sm'>Rent</p>
                                <div className='flex items-center mt-[2px] tooltip '>
                                  <div className='tooltiptext background-secondary  p-3 rounded-[6px] flex flex-col items-center w-[216px]'>
                                    {Object.keys(ele?.dueAmountDetails)?.map((key) => (
                                      key !== "netAmount" && key !== "lateRentPenalty" && (
                                        <div className='flex items-start justify-between w-full my-[2px]' key={key}>
                                          <p className='font-poppins font-normal text-xs text-white' style={{ textTransform: 'capitalize', marginRight: '10px' }}>
                                            {key.replace(/([a-z])([A-Z])/g, '$1 $2')}:
                                          </p>
                                          <p className='font-poppins font-semibold text-white text-xs'> ₹{ele?.dueAmountDetails[key]}</p>
                                        </div>
                                      )
                                    ))}
                                  </div>
                                  <p className='text-secondary mr-2 font-poppins font-normal  text-sm'>₹{calculateTotalDue(ele?.dueAmountDetails)}</p>
                                  <img src={info} alt="Info" />
                                </div>
                              </div>
                              <div className='flex flex-col items-start'>
                                <p className='text-[#0F172A] font-poppins font-medium text-sm'>Net Amount</p>
                                <p className='text-secondary font-poppins mt-[2px] font-semibold text-sm'>₹{ele?.dueAmountDetails?.netAmount}</p>
                              </div>
                            </div>
                          </div>
                         
                        </div>
                         <div className=" border-dashed my-2 md:mt-6  border-t-[0.5px]  border-[#D4D4D8]  w-full"></div>
         
                        </div>
                        ))}
                      <div className='gap-[8px] py-2 flex items-center justify-end'>
                      <p className='font-poppins font-medium text-base text-[#0F172A]'>Total Amount:</p>
                      <p className='text-secondary font-semibold text-base font-poppins'>₹{totalNetAmount}</p>
                    </div>
                 
                    </div>
                    <div className="border-t border-t-[#E4E4E7] py-3 px-4 bg-[#FAFAFA]">
                    <p className='text-[#181738] text-xs font-medium font-poppins'>For any help <span className='text-primary ml-2 cursor-pointer'>Contact Us</span></p>
                  </div>
                    
                  </div>
                ) : (
                  <div className='h-[100px] w-full mt-[16px] rounded border border-[#E4E4E7] flex flex-col items-center justify-center'>
                    <p className='font-poppins font-normal text-sm text-[#52525B]'>No data available</p>
                  </div>
                )}
              </>
            ) : (
              <></>
            )}
          </>
        )}

        {furnishingPaymentLoading ? (
          <div className={`background-primary flex items-center justify-center  w-full rounded-lg py-3 px-5 font-medium text-base mt-8 blog-button-shadow font-poppins text-white text-center`}>
            <div className='animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-gray-900'></div>
          </div>
        ) : (
          <button onClick={()=>handleFurnishingPayment(totalNetAmount,selectedItems ,phoneNumber)} disabled={(furnishingSearchData?.length > 0  && totalNetAmount > 0)? false : true} className={`${!(furnishingSearchData?.length > 0 && totalNetAmount > 0)? 'bg-[#FCB57D]' : 'background-primary'}  w-full rounded-lg py-3 px-5 font-medium text-base mt-8 blog-button-shadow font-poppins text-white text-center`}>
            Pay Now
          </button>
        )}
      </div>
    </div>
  );
};

const GetStarted = ({ requirederrors, formData, handleChange, errors, handleCheckboxChange, isChecked, handleGetStarted }) => {
  const formFields = [
    { name: 'name', label: 'Your name', type: 'text', placeholder: 'Enter your name', },
    { name: 'phoneNumber', label: 'Phone number', type: 'number', placeholder: '+91  000-000-0000' },
    { name: 'email', label: 'Email', type: 'text', placeholder: 'You@college.com' },
  ];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      {formFields.map(field => (
        <div key={field.name} className=''>
          <p className='font-poppins font-medium mt-4 text-sm text-[#3F3F46]'>{field.label}*</p>
          <input type={field.type} placeholder={field?.placeholder} name={field.name} value={formData?.[field.name]} onChange={(e) => handleChange(e)} className='border-[1px] outline-none w-full  mt-2 login-box-shadow h-[44px] text-textColor font-normal text-base font-poppins rounded-[4px] border-[#D4D4D8] py-[10px] px-[14px]' />
          <p className='mt-1 text-red-800 font-poppins text-xs font-normal'>{errors?.[field.name] || requirederrors?.[field.name]}</p>
        </div>
      ))}

      <div className='mt-5 space-x-2 flex items-center'>
        <label className="flex items-center">
          <input

            type="checkbox"
            name="termsAndConditionsAccepted"
            value={formData?.termsAndConditionsAccepted}
            className="hidden"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <span
            className={`relative inline-block w-5 h-5 border border-[#D4D4D8] rounded-md cursor-pointer ${isChecked ? 'background-primary' : ''
              }`}
          >
            {isChecked && (
              <svg
                className="w-4 h-4 text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
            )}
          </span>
        </label>
        <p onClick={showModal} className='cursor-pointer underline underline-offset-4 font-poppins text-textColor font-normal text-sm'>I agree to the Monkhood Terms and Conditions</p>

      </div>

      <button disabled={!isChecked} onClick={handleGetStarted} className={`${isChecked ? "" : "disabled:opacity-50"} background-primary w-full rounded-lg py-3 px-5 font-medium text-base mt-12 blog-button-shadow font-poppins text-white text-center`}>Get Started</button>
      <Modal title={<div className="font-bold text-xl md:text-2xl px-1">Terms & Conditions</div>} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null} width={800} bodyStyle={{ height: '500px', overflowY: 'auto' }} >
        <Terms/>
      </Modal>
    </>
  )
}

const ListingTestimonials = ({ testimonialData, currentSlide, handleIndicatorClick }) => {
  const { findCollegeName } = useFindCollege();
  return (
    <div className='md:px-20 md:py-24 py-8 flex flex-col items-center'>
      <h3 className='font-poppins font-semibold text-[32px] leading-10 px-5 md:px-0 md:text-[40px] md:leading-[48px] text-center tracking-[-0.2px] text-secondary'>Don't just take our word for it</h3>
      <p className='text-textColor mt-2 text-base text-center px-5 md:px-0 md:text-lg font-poppins font-normal'>Hear from some of our amazing customers who are building faster.</p>
      <div className='mt-8 relative flex  items-center md:w-[90%] mx-auto w-full min-h-[462px] bg-[#FAFAFA] py-8 px-5 md:rounded-xl md:p-16 overflow-hidden '>
        {testimonialData.map((ele, index) => (
          <div key={index} className={`flex w-full items-center flex-none mx-auto flex-col transition-transform duration-1000 ease-in-out ${currentSlide === index ? 'opacity-100' : 'opacity-0'} `} style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
            <h1 className='font-poppins md:font-medium font-normal text-[28px] leading-[36px] tracking-[-0.2px] text-secondary text-center md:text-[36px] md:leading-[44px]'>"{truncateText(ele?.description, 20)}"</h1>
            <img className='rounded-full mt-5' src={headavatar} alt="pic" />
            <p className='mt-2 text-secondary text-lg font-poppins text-center font-semibold md:font-medium'>{ele?.user?.name}</p>
            <p className='font-normal text-base font-poppins mt-1 text-textColor'>{findCollegeName(ele?.user?.college)}</p>
            <div className='flex items-center mt-2 space-x-1'>
              {Array.from({ length: ele?.rating }).map((_, index) => (
                <>
                  <img src={star} alt={index} />
                </>
              ))}
            </div>

          </div>
        ))}
        <div className='absolute z-10 bottom-[20px] left-1/2 transform -translate-x-1/2 flex space-x-2'>
          {Array.from({ length: testimonialData?.length }).map((_, index) => (
            <div
              key={index}
              className={`w-[10px] h-[10px] rounded-full ${index === currentSlide ? 'background-primary' : 'bg-[#F4F4F5]'
                } cursor-pointer`}

            ></div>
          ))}
        </div>

      </div>

    </div>

  )
}



const PayRent = ({netRentAmount, netAmount,setSelectedMethod,selectedMethod,testimonialData, screenWidth, currentSlide, handleIndicatorClick, formData,
  handleChange,
  handleCheckboxChange,
  isChecked,
  requirederrors,
  errors,
  paymentTypes,
  handlePaymentSelect,
  handleDropdownToggle,
  isDropdownOpen,
  selectedPayment,
  handleGetStarted,
  showGetStarted,
  setIsDropdownOpen,
  handleTokenSearch,
  tokenSearchData,
  tokenLoading,
  phoneNumber,
  email,
  userData,
  handleTokenPayment,
  tokenPaymentLoading,
  handleRentSearch,
  rentLoading,
  rentSearchData,
  setRentLoading,
  setRentSearchData,
  rentPaymentLoading,
  handleRentPayment,
  furnishingLoading,
  furnishingSearchData,
  handleFurnishingSearch,
  furnishingPaymentLoading,
  handleFurnishingPayment,
  tokenAmount,
  setTokenAmount,
  rentAmount,
  setRentAmount
}) => {
  const paymentDropdown = useRef(null);
  const togglePayment = useRef(null);
  const calculateTotalDue = (duesDetail) => {
    let total = 0;
    for (let key in duesDetail) {
        if (key !== "netAmount" && key !== "lateRentPenalty") {
            total += duesDetail[key];
        }
    }
    return total;
};
  useEffect(()=>{
    const handleClickOutside = (event)=>{
      if(paymentDropdown?.current && !paymentDropdown?.current.contains(event.target) && togglePayment?.current && !togglePayment?.current.contains(event.target)){
        setIsDropdownOpen(false);
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [])
  
  const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOk = () => {
        setIsModalOpen(false);
      };
      const handleCancel = () => {
        setIsModalOpen(false);
        setSelectedMethod('UPI')
      };
      const showmodal = () =>{
        setIsModalOpen(true);
      }
  return (
    <div className='min-h-screen w-full'>
    <WhatsappContactLogic/>
      <div className='w-full md:h-[870px] h-auto flex items-start'>
        {screenWidth >= 768 && (
          <TestimonialCarouselLogic page={"payRent"} />
        )}
        <div className={` ${screenWidth >= 768 ? 'w-1/2 px-[80px] items-start justify-center ' : 'w-full items-start'} flex flex-col  relative  `}>
          <div className={screenWidth < 768 ? 'w-full px-5 py-6' : 'w-full '}>
            <div className='w-[100%] md:mt-[30px] mb-5'>
              <h1 className='text-[#0F172A]  text-[36px] font-poppins font-semibold tracking-[-0.2px] leading-[44px]'>Pay Rent</h1>
              <p className='text-[#475569] text-lg  font-normal font-poppins mt-2'>Convenient online rent payment solution for hassle-free transactions.</p>
              <div className='md:mt-8 mt-[20px]'>
                <p className='font-poppins font-medium mt-4 text-sm text-[#3F3F46]'>Payment Type</p>
                <div ref={togglePayment} onClick={(e)=>{
                  e.stopPropagation();
                  handleDropdownToggle()
                }} className='border-[1px] relative cursor-pointer outline-none w-full  mt-2 login-box-shadow h-[44px] text-textColor font-normal text-base font-poppins rounded-[4px] flex items-center justify-between border-[#D4D4D8] py-[10px] px-[14px]'>
                  <div className='flex space-x-3 items-center'>
                    <img src={selectedPayment?.img} alt="" />
                    <p className='text-secondary font-poppins font-normal text-base'>{selectedPayment?.name}</p>
                  </div>
                  <img src={isDropdownOpen ? up : down} alt="" />
                  {isDropdownOpen && (
                    <div ref={paymentDropdown} className='text-textColor font-normal rounded bg-white text-base font-poppins py-[10px] px-[14px] left-0 w-full blog-button-shadow border border-[#D4D4D8] absolute z-50 top-12'>
                      {paymentTypes?.map((payment, index) => (
                        <div
                          key={index}
                          className='flex items-center space-x-2 my-3'
                          onClick={() => handlePaymentSelect(payment)}
                        >
                          <img src={payment?.img} alt={payment?.name} className='mr-2' />
                          <p>{payment?.name}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                {
                  showGetStarted ? <GetStarted formData={formData}
                    handleChange={handleChange}
                    errors={errors}
                    handleGetStarted={handleGetStarted}
                    requirederrors={requirederrors}
                    handleCheckboxChange={handleCheckboxChange}
                    isChecked={isChecked} />

                    :
                    (
                      selectedPayment?.name === "House Rent" ?
                      // <HouseRent phone={formData?.phoneNumber} handleRentPayment={handleRentPayment} calculateTotalDue={calculateTotalDue} rentPaymentLoading={rentPaymentLoading} setRentLoading={setRentLoading}
                      // setRentSearchData={setRentSearchData} rentSearchData={rentSearchData} rentLoading={rentLoading} handleRentSearch={handleRentSearch}/>
                      <div className='flex flex-col md:mt-10' >
                          <p className='text-[#3F3F46] font-poppins text-md font-medium py-2 '>Amount (₹)</p>
                          <input type="text" placeholder={"Enter Amount in ₹"} name="rentAmount" value={rentAmount} onChange={(e) => setRentAmount(e.target.value)} className='border-[1px] outline-none w-full  login-box-shadow h-[44px] text-textColor font-normal text-base font-poppins rounded-[4px] border-[#D4D4D8] py-[10px] px-[14px]' />
                          <button onClick={showmodal} disabled={!rentAmount} className={`${!rentAmount ? 'bg-[#FCB57D]' : 'background-primary'}  w-full rounded-lg py-3 px-5 font-medium text-base mt-8 blog-button-shadow font-poppins text-white text-center`}>Proceed to Pay</button>
                          <Modal title={<div className="font-bold text-xl md:text-2xl px-1">Payment Options</div>} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null} maskClosable={false} >
                                    <PaymentSelect selectedMethod={selectedMethod} setSelectedMethod={setSelectedMethod}/>
                                    <div className='flex items-center mt-5 font-poppins text-right font-normal text-sm text-[#52525B] w-full justify-between'><p>Total Amount</p><p>₹{rentAmount}</p></div>
                                    <div className='flex items-center mt-5 font-poppins text-right font-normal text-sm text-[#52525B] w-full justify-between'><p>Platform Fee</p><p>+₹{parseFloat(netRentAmount-rentAmount).toFixed(2)}</p></div>
                                    <hr className='w-full text-[#D4D4D8] my-4' />
                                    <div className='flex items-center mt-5 font-poppins text-right font-normal text-sm text-[#52525B] w-full justify-between'><p>Final Amount</p><p className='text-md font-semibold' >₹{parseFloat(netRentAmount).toFixed(2)}</p></div>
                                    <button onClick={handleRentPayment} disabled={!rentAmount} className='background-primary px-5 py-3 mt-10 w-full rounded blog-button-shadow font-poppins text-white font-medium text-base'>Pay ₹{parseFloat(netRentAmount).toFixed(2)}</button>

                          </Modal>
                        </div>
                       : (
                        (selectedPayment?.name === "Token Amount" ? 
                        // <TokenAmount tokenPaymentLoading={tokenPaymentLoading} handleTokenPayment={handleTokenPayment} handleTokenSearch={handleTokenSearch} tokenLoading={tokenLoading} email={email}
                        // phoneNumber={phoneNumber}
                        // userData={userData}
                        // tokenSearchData={tokenSearchData} />
                        <div className='flex flex-col md:mt-10' >
                          <p className='text-[#3F3F46] font-poppins text-md font-medium py-2 '>Amount (₹)</p>
                          <input type="text" placeholder={"Enter Amount in ₹"} name="tokenAmount" value={tokenAmount} onChange={(e) => setTokenAmount(e.target.value)} className='border-[1px] outline-none w-full  login-box-shadow h-[44px] text-textColor font-normal text-base font-poppins rounded-[4px] border-[#D4D4D8] py-[10px] px-[14px]' />
                          <button onClick={showmodal} disabled={!tokenAmount} className={`${!tokenAmount ? 'bg-[#FCB57D]' : 'background-primary'}  w-full rounded-lg py-3 px-5 font-medium text-base mt-8 blog-button-shadow font-poppins text-white text-center`}>Proceed to Pay</button>
                          <Modal title={<div className="font-bold text-xl md:text-2xl px-1">Payment Options</div>} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null} maskClosable={false} >
                                    <PaymentSelect selectedMethod={selectedMethod} setSelectedMethod={setSelectedMethod}/>
                                    <div className='flex items-center mt-5 font-poppins text-right font-normal text-sm text-[#52525B] w-full justify-between'><p>Total Amount</p><p>₹{tokenAmount}</p></div>
                                    <div className='flex items-center mt-5 font-poppins text-right font-normal text-sm text-[#52525B] w-full justify-between'><p>Platform Fee</p><p>+₹{parseFloat(netAmount-tokenAmount).toFixed(2)}</p></div>
                                    <hr className='w-full text-[#D4D4D8] my-4' />
                                    <div className='flex items-center mt-5 font-poppins text-right font-normal text-sm text-[#52525B] w-full justify-between'><p>Final Amount</p><p className='text-md font-semibold' >₹{parseFloat(netAmount).toFixed(2)}</p></div>
                                    <button onClick={handleTokenPayment} disabled={!tokenAmount} className='background-primary px-5 py-3 mt-10 w-full rounded blog-button-shadow font-poppins text-white font-medium text-base'>Pay ₹{parseFloat(netAmount).toFixed(2)}</button>

                                </Modal>
                        </div> : <Furnishing phone={formData?.phoneNumber} calculateTotalDue={calculateTotalDue} furnishingLoading={furnishingLoading}
                        furnishingSearchData={furnishingSearchData}
                        handleFurnishingSearch={handleFurnishingSearch}
                        furnishingPaymentLoading={furnishingPaymentLoading}
                        handleFurnishingPayment={handleFurnishingPayment}
                        />)
                      )

                    )
                }


              </div>
            </div>
          </div>
        </div>

      </div>
      {
        testimonialData?.length > 0 && <ListingTestimonials testimonialData={testimonialData} currentSlide={currentSlide}
          handleIndicatorClick={handleIndicatorClick} />
      }
      <ContactUsLogic />
    </div>
  )
}

export default PayRent