import React, { useState, useEffect } from 'react'
import OccupiedServices from './OccupiedServices'

const OccupiedServicesLogic = ({setActiveOption}) => {
  const [serviceType, setServiceType] = useState("furniture");
  const [filteredData, setFilteredData] = useState([]);
  const [occupiedServicesData, setOccupiedServicesData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const token = localStorage.getItem('token');
  const phone = localStorage.getItem('phoneNumber');
  
  useEffect(()=>{
    const fetchData = async()=>{
      try{
        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/occupiedService/getOccupiedServicesByUserPhone?phone=${phone}`,{
          method: "GET",
          headers: {
            'Authorization': `Bearer ${token}`,
          },
      });
        
        if(!response.ok){
          throw new Error("Network error");
        }
        const jsonData = await response.json();
        if(jsonData.length === 0){
          console.log("No Occupied services found");
          return;
        }
        console.log(jsonData);
        setOccupiedServicesData(jsonData)
        setFilteredData(jsonData)
      }catch(error){
        console.log("Error fetching occupied services data", error)
      }
    }
    fetchData()
  },[])

  useEffect(()=>{
    const temp = occupiedServicesData?.filter((ele)=>(ele?.category?.value === serviceType));
    setFilteredData(temp);
  }, [serviceType, occupiedServicesData])
  return (
    <><OccupiedServices
       setActiveOption={setActiveOption}
       serviceType={serviceType}
       setServiceType={setServiceType}
       filteredData={filteredData}
       openModal={openModal}
       setOpenModal={setOpenModal}
    /></>
  )
}

export default OccupiedServicesLogic