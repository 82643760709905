import React, { useState, useEffect } from 'react'
import ListingForm from './ListingForm'
import { useSelector, useDispatch } from 'react-redux'
import { Route, useLocation, useNavigate } from 'react-router-dom'
import { validateField } from '../../utils/formValidationFunctions'
import validationRules from '../../utils/validationRules'
import { fetchLocalityData } from '../../redux/slices/getLocalitiesSlice'
import { showErrorToast, showInfoToast, showSuccessToast } from '../../utils/toastNotif'
import RoutePath from "../../routes/RoutePath"
import { convertDate } from '../../utils/formatDate'
import { debounce } from '../../utils/debounce'


const ListingFormLogic = () => {
  const screenWidth = useSelector(state => state.screenWidth.screenWidth);
  const token = localStorage.getItem("token");
  const location = useLocation();
  const navigate = useNavigate();
  const userData = (localStorage.getItem("userData")  && localStorage.getItem("userData") !== "undefined") ? JSON.parse(localStorage.getItem("userData")) : "";
  const listingData = (localStorage.getItem("listingData")  && localStorage.getItem("listingData") !== "undefined") ? JSON.parse(localStorage.getItem("listingData")) : "";
  const phoneNumber = (localStorage.getItem("phoneNumber") && localStorage.getItem("phoneNumber") !== "undefined" && parseInt(localStorage.getItem("phoneNumber")) !== -1) ? parseInt(localStorage.getItem("phoneNumber")) : ""

  const [ownerData, setownerData] = useState({
    name: (listingData?.owner?.name && listingData?.owner?.name !== "undefined") ? listingData?.owner?.name :( userData?.name ? userData?.name : ""), phoneNumber: (listingData?.owner?.phoneNumber && listingData?.owner?.phoneNumber !== "undefined" && parseInt(listingData?.owner?.phoneNumber) !== -1) ? parseInt(listingData?.owner?.phoneNumber) : (phoneNumber ? phoneNumber : ""), email: listingData?.owner?.email ? listingData?.owner?.email :( userData?.email ? userData?.email : ""),
    permanentAddress: {
      addressLine: listingData?.owner?.permanentAddress?.addressLine ? listingData?.owner?.permanentAddress?.addressLine : ( userData?.permanentAddress?.addressLine ? userData?.permanentAddress?.addressLine : ""),
      pinCode: listingData?.owner?.permanentAddress?.pinCode ? listingData?.owner?.permanentAddress?.pinCode :( userData?.permanentAddress?.pinCode ? userData?.permanentAddress?.pinCode : ""),
      city: listingData?.owner?.permanentAddress?.city ? listingData?.owner?.permanentAddress?.city :( userData?.permanentAddress?.city ? userData?.permanentAddress?.city : ""),
      state: listingData?.owner?.permanentAddress?.state ? listingData?.owner?.permanentAddress?.state :( userData?.permanentAddress?.state ? userData?.permanentAddress?.state : ""),
    }
  })

  const [formData, setFormData] = useState({
    typeOfProperty: listingData?.form?.typeOfProperty ? listingData?.form?.typeOfProperty : "", propertySize: listingData?.form?.propertySize ? listingData?.form?.propertySize : [], floor: listingData?.form?.floor ? listingData?.form?.floor : 0, totalFloor: listingData?.form?.totalFloor ? listingData?.form?.totalFloor : "", builtUpArea: listingData?.form?.builtUpArea ? listingData?.form?.builtUpArea : "", availableFrom: listingData?.form?.availableFrom ? listingData?.form?.availableFrom : "", apartmentName: listingData?.form?.apartmentName ? listingData?.form?.apartmentName : "",
    rentNegotiable: listingData?.form?.rentNegotiable ? listingData?.form?.rentNegotiable : false, security: listingData?.form?.security ? listingData?.form?.security : "", rent: listingData?.form?.rent ? listingData?.form?.rent : "", tokenAmount: listingData?.form?.tokenAmount ? listingData?.form?.tokenAmount : "", facilities: listingData?.form?.facilities ? listingData?.form?.facilities : [], localityId: listingData?.form?.localityId ? listingData?.form?.localityId : "",
    permanentAddress: {
      addressLine1: listingData?.form?.permanentAddress?.addressLine1 ? listingData?.form?.permanentAddress?.addressLine1 : "",
      addressLine2: listingData?.form?.permanentAddress?.addressLine2 ? listingData?.form?.permanentAddress?.addressLine2 : "",
      landmark: listingData?.form?.permanentAddress?.landmark ? listingData?.form?.permanentAddress?.landmark : "",
      state: listingData?.form?.permanentAddress?.state ? listingData?.form?.permanentAddress?.state : "",
      city: listingData?.form?.permanentAddress?.city ? listingData?.form?.permanentAddress?.city : "",
      pinCode: listingData?.form?.permanentAddress?.pinCode ? listingData?.form?.permanentAddress?.pinCode : ""
    }
  })

  const [activeStep, setActiveStep] = useState(1);
  const [cities, setcities] = useState({owner: [], form: []});
  const [onsubmit, setonsubmit] = useState(false);
  const [selectedCity, setselectedCity] = useState({owner: ownerData?.permanentAddress?.city, form: formData?.permanentAddress?.city});
  const [selectedState, setselectedState] = useState({owner: ownerData?.permanentAddress?.state, form: formData?.permanentAddress?.state})
  const [commonAmenities, setcommonAmenities] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [otp, setOtp] = useState('');
  const [errors, setErrors] = useState({})
  const [requiredFieldErrors, setRequiredFieldErrors] = useState({});
  const [otperrormsg, setotperrormsg] = useState("");
  const [addpersonofcontact, setaddpersonofcontact] = useState(false);
  const [sendotp, setsendotp] = useState(false)
  const [loading, setloading] = useState(false);
  const [previewModal, setpreviewModal] = useState(false);
  const [verified, setverified] = useState(false);
  const [modalDimensions, setModalDimensions] = useState({ width: "408", height: "364" })
  const totalSteps = 6;
  const [imageFiles, setImageFiles] = useState([]);
  const [imagesList, setImagesList] = useState([]);
  const [dateOnly, setdateOnly] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0];
    setdateOnly(today);
  }, []);
  // useEffect(()=>{
  //   const retrievedImages = listingData?.images || [];
  //   const imageFile = retrievedImages.map(image => {
  //     const byteNumbers = new Array(image.file.base64?.length);
  //     for (let i = 0; i < image.file.base64?.length; i++) {
  //       byteNumbers[i] = image.file.base64.charCodeAt(i);
  //     }
  //     const byteArray = new Uint8Array(byteNumbers);
  //     const blob = new Blob([byteArray], { type: image.file.type });
  //     return {
  //       id: image.id,
  //      file: new File([blob], image.file.name, {
  //         type: image.file.type,
  //         lastModified: image.file.lastModified,
  //         lastModifiedDate: new Date(image.file.lastModifiedDate),
  //       })
  //     }
  //   });
 

  // setImageFiles(imageFile ? imageFile : []);

  // }, [])
  const stepLabels = ['Personal details', 'Properties details', 'Locality details', 'Rental details', 'Amenities', 'Upload photos'];
  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(process.env.PUBLIC_URL + '/data/commonAmenities.json');
        if (!response.ok) {
          throw new Error("Network error");
        }
        const jsonData = await response.json();
        setcommonAmenities(jsonData?.commonAmenities)
      } catch (error) {
        console.log("Error fetching common ammenities data", error)
      }
    }
    fetchData();
  }, [])

  const fetchLocality = async (query) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/locality/searchLocality?localityName=${query}&city=&state=&pinCode=`);
      if(response === null){
         throw new Error("response is null")
      }
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if(data === null || data === undefined){
        throw new Error("Data is null");
      }
      setSearchResults(data?.localities); 
    } catch (error) {
      showErrorToast("we are facing some issue while fetching localities")
      console.error("Error fetching data:", error);
    }
  };
 

  const debouncedFetchLocality = debounce(fetchLocality, 300);


 const handleInputChange = (event)=>{
  const query = event.target.value;
  setSearchQuery(query);
  setInputValue(query);
  if (query.length > 1) {
    debouncedFetchLocality(query);
  } else {
    setSearchResults([]);
  }

 }

  const handleDataChange = (field, e, data, setDataFunction, formType) => {
   if(field === "rentNegotiable"){
    setDataFunction({ ...data, [field]: e});
   }
   
   else{
    const { name, value } = e.target;
    if(field === "rent" || field === "security" || field === "tokenAmount"){
      if(data?.typeOfProperty === "flat"){
        const updatedPropertySize = [...data.propertySize];
      updatedPropertySize[0][field] = parseInt(value);
      setFormData({ ...formData, propertySize: updatedPropertySize});

      }
     }
    // if (field === 'personOfContact') {
    //   if (!validationRules["phoneNumber"].regex.test(value)) {
    //     var temp = validationRules["phoneNumber"]?.message;
    //   }
    //   setErrors((prevErrors) => ({
    //     ...prevErrors,
    //     [formType]: {
    //       ...prevErrors[formType],
    //       personOfContact: temp ? temp : '',
    //     },
    //   }));
    // }
    if (field !== "propertySize" && value.trim() === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [formType]: {
          ...prevErrors[formType],
          [name]: '',
        },
      }));
    }
    else if (validationRules[name]) {
      const isValid = validateField(name, value, data);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [formType]: {
          ...prevErrors[formType],
          [name]: isValid ? '' : validationRules[name].message,
        },
      }));
    }


    if (field.startsWith("permanentAddress.")) {
      const nestedField = field.replace("permanentAddress.", "");
      setDataFunction({
        ...data,
        permanentAddress: {
          ...data.permanentAddress,
          [nestedField]: value,
        },
      });
    } else if (field === 'facilities') {
      const isValuePresent = data.facilities?.includes(value);
      if (isValuePresent) {
        const updatedCommonAmenities = data.facilities?.filter(item => item !== value);
        setDataFunction({ ...data, facilities: updatedCommonAmenities });
      } else {
        setDataFunction({ ...data, facilities: [...data.facilities, value] });
      }
    }
    else if (field === "propertySize") {
      const selectedSize = {
        size: value,
        rent: 0,
        tokenAmount: 0,
        security: 0,
      };
      
      if (formData.typeOfProperty === 'pg') {
      
         
            if (!data?.propertySize.some(item => item.size === selectedSize.size)) {
              setDataFunction({ ...data, propertySize: [...data?.propertySize, selectedSize] });
            }
           else {
            const updatedSizes = data?.propertySize?.filter((item) => item?.size !== selectedSize?.size);
            setDataFunction({ ...data, propertySize: updatedSizes });
          }
        
      } else if (formData.typeOfProperty === 'flat') {
        setDataFunction({ ...data, propertySize: [selectedSize] });
      }
    }
     else {
      setDataFunction({ ...data, [field]: value });
    }
   }
  };

  const handleBack = () => {
    if (activeStep !== 1) {
      handleStepChange(activeStep - 1)
    }
    else {
      return;
    }
  }

  const handleNext = (e, data, requiredFields, formType) => {
    e.preventDefault();
    if (activeStep === 2 && !formData.typeOfProperty) {
      return;
    }
    if(activeStep === 4){
      for (let property of formData?.propertySize) {
        if (property?.rent === 0 || property?.security === 0 || !formData?.tokenAmount) {
           showErrorToast("kindly fill all rental details");
           return;
        }


        if (property.security > 2 * property.rent) {
           showErrorToast("security can't be greater than 2 times of rent")
           return; 
        }
    }
    handleStepChange(activeStep + 1);
    }
    for (const key in errors[formType]) {
      if (errors[formType][key]) {
        return;
      }
    }
    let requiredFieldsErrors = { ...requiredFieldErrors };

    requiredFieldsErrors[formType] = requiredFieldsErrors[formType] || {};
    for (const field of requiredFields) {
      if (field.startsWith("permanentAddress.")) {
        const nestedField = field.replace("permanentAddress.", "");
        if (!data.permanentAddress[nestedField]) {
          requiredFieldsErrors[formType][nestedField] = 'This field is required';

        } else {
          requiredFieldsErrors[formType][nestedField] = '';
        }
      }
      else {

        if (!data[field] || (Array.isArray(data[field]) && data[field].length === 0)) {
          requiredFieldsErrors[formType][field] = 'This field is required';

        } else {
          requiredFieldsErrors[formType][field] = '';
        }
      }
    }

    setRequiredFieldErrors(requiredFieldsErrors);
    const hasRequiredFieldError = Object.keys(requiredFieldsErrors[formType]).some((field) => !!requiredFieldsErrors[formType][field]);
    if (hasRequiredFieldError) {
      return;
    }

    handleStepChange(activeStep + 1);

  }



  const handleUpload = () => {
    if (imageFiles.length < 5) {
      showInfoToast("kindly upload atleast 5 images")
      return;
    }
    if (screenWidth <= 500) {
      setActiveStep(0)
    }
    setpreviewModal(true);

  }

  const handleSave = async (data, formType)=>{
    for (const key in errors[formType]) {
      if (errors[formType][key]) {
        showErrorToast("Data cannot be saved, fix errors")
        return;
      }
    }

    const existingData = JSON.parse(localStorage.getItem('listingData')) || {}
    
    if(formType === "images"){

    }else{
      existingData[formType] = data;
    }
  
 
    localStorage.setItem('listingData', JSON.stringify(existingData));
    showSuccessToast("Data saved successfully")
  }

  const handleSubmit = async ()=>{
    setonsubmit(true);
    setpreviewModal(false);
    try{
      let minRent = formData?.rent ? formData?.rent : Infinity;
      let minSecurity = formData?.security ? formData?.security : Infinity;
      let propertySize = formData?.propertySize;
      if(formData?.typeOfProperty === "pg"){
       
     formData?.propertySize?.forEach((item) => {
        if (parseInt(item.rent) < minRent) {
          
          minRent = parseInt(item.rent);
        }
      });
       
     formData?.propertySize?.forEach((item) => {
        if (parseInt(item.rent) < minSecurity) {
          
          minSecurity = parseInt(item.security);
        }
      });

      formData?.propertySize?.forEach((item)=>{
          item.tokenAmount = formData.tokenAmount
      });

   
      }
      const listings = {
        owner: {
          name: ownerData?.name,
          phone: ownerData?.phoneNumber
         },
         nearbyColleges: [],
         permanentAddress: {
          addressLine1: formData?.permanentAddress?.addressLine1,
          addressLine2: formData?.permanentAddress?.addressLine2,
          pinCode: parseInt(formData?.permanentAddress?.pinCode),
          city: formData?.permanentAddress?.city,
          state: formData?.permanentAddress?.state,
          landmark: formData?.permanentAddress?.landmark
      },
          localityId: formData?.localityId,
          createdOn: convertDate(Date.now()),
          lastModified: convertDate(Date.now()),
          facilities: formData?.facilities,
          features: [],
          houseRules: [],
          builtUpArea: formData?.builtUpArea,
          description: "NA",
          availableFrom: convertDate(formData?.availableFrom),
          rent: formData?.typeOfProperty === "flat" ? parseInt(formData?.rent) : parseInt(minRent),
          rentNegotiable: formData?.rentNegotiable,
          security: formData?.typeOfProperty === "flat" ? parseInt(formData?.security) : parseInt(minSecurity),
          tokenAmount: parseInt(formData?.tokenAmount),
          typeOfProperty: formData?.typeOfProperty,
          propertySize: propertySize,
          status: "unverified",
          isVerified: false,
          totalFloor: formData?.floor ? parseInt(formData?.totalFloor) : 0 ,
          furnishing: "semiFurnished",
          nearbyServices: [],
          occupied: false,
          apartmentName: formData?.apartmentName,
          floor: formData?.floor ? parseInt(formData?.floor) : 0 ,
          messIncluded: [],
          servicesIncludedForPg: [],
          lockInPeriod: 30

      }
      const ListingFormData = new FormData();
      ListingFormData.append("listing", JSON.stringify(listings));
      imagesList.map((url)=>{
        ListingFormData.append("images", url); 
      })
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/listing/addListing`, {
        method: 'POST',
        headers: {
          "Authorization": `Bearer ${token}`
        },
        body: ListingFormData
      
      });
      if(response === null){
        throw new Error("response is null");
      }
      if(!response.ok){
        if(response?.status === 401){
          showErrorToast("kindly login again to continue");
          navigate(RoutePath.login, {state: {data: location?.pathname + location?.search}});
          
        }else if(response.status >= 400 && response.status < 500){
          const message = await response.text();
          const parsedMessage = JSON.parse(message);
          showErrorToast(parsedMessage?.message);
          return;
        }
        else{
            throw new Error(`Something went wrong ${response.status}`)
         
          
        }
      }
      const data = await response.json();
      if(data === null || data === undefined){
        throw new Error("data is null")
      };
      showSuccessToast("listing added successfully")
      localStorage.removeItem("listingData");
      const encodedEleString = btoa(encodeURIComponent(JSON.stringify(data)));
      const name = data?.apartmentName ? data?.apartmentName : data?.permanentAddress?.addressLine1;
      const url = `${RoutePath.listingById?.replace(":listingId", name)}?data=${encodedEleString}`;
      navigate(url, { state: { data: data} })
      
    }catch(error){
      console.log(error); 
      showErrorToast("listing cannot be added, something went wrong, please try again")

    }
    finally{
      setonsubmit(false);
    }
  }

  return (
    <><ListingForm
      totalSteps={totalSteps}
      stepLabels={stepLabels}
      activeStep={activeStep}
      onsubmit={onsubmit}
      setonsubmit={setonsubmit}
      addpersonofcontact={addpersonofcontact}
      setaddpersonofcontact={setaddpersonofcontact}
      setselectedCity={setselectedCity}
      setselectedState={setselectedState}
      selectedState={selectedState}
      selectedCity={selectedCity}
      setOpenModal={setOpenModal}
      openModal={openModal}
      otp={otp}
      setOtp={setOtp}
      otperrormsg={otperrormsg}
      sendotp={sendotp}
      loading={loading}
      verified={verified}
      setverified={setverified}
      setotperrormsg={setotperrormsg}
      ownerData={ownerData}
      setownerData={setownerData}
      handleBack={handleBack}
      handleNext={handleNext}
      formData={formData}
      setFormData={setFormData}
      modalDimensions={modalDimensions}
      dateOnly={dateOnly}
      commonAmenities={commonAmenities}
      imageFiles={imageFiles}
      handleUpload={handleUpload}
      previewModal={previewModal}
      setpreviewModal={setpreviewModal}
      handleStepChange={handleStepChange}
      screenWidth={screenWidth}
      setImageFiles={setImageFiles}
      errors={errors}
      handleDataChange={handleDataChange}
      requiredFieldErrors={requiredFieldErrors}
      cities={cities}
      setcities={setcities}
      handleSave={handleSave}
      handleSubmit={handleSubmit}
      setImagesList={setImagesList}
      setRequiredFieldErrors={setRequiredFieldErrors}
      searchQuery={searchQuery}
      searchResults={searchResults}
      handleInputChange={handleInputChange}
      inputValue={inputValue}
      setSearchResults={setSearchResults}
    /></>

  )
}

export default ListingFormLogic