import React, { useState } from 'react'
import Share from './Share'
import facebook from "../../assets/images/Facebook.png"
import copy from "../../assets/images/copy.png"
import twitter from "../../assets/images/Twitter.svg"
import linkedin from "../../assets/images/Linkedin.png"
import mail from "../../assets/images/mail.png"
import whatsapp from "../../assets/images/Whatsapp.svg"
import { useSelector } from 'react-redux'

const ShareLogic = () => {
    const [openModal, setOpenModal] = useState(false);
    const screenWidth = useSelector(state=>state.screenWidth.screenWidth);
    const handleNativeShare = async () => {
      try {
        await navigator.share({
          title: 'Check this out',
          text: 'Check this out:',
          url: window.location.href,
        });
      } catch (error) {
        console.error('Error sharing:', error.message);
      }
    };
    const handleShare = (method) => {
        switch (method) {
          case 'whatsapp':
            window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent('Check this out: ' + window.location.href)}`);
            break;
          case 'copy':
            navigator.clipboard.writeText(window.location.href);
            alert('Link copied to clipboard');
            break;
          case 'email':
            window.location.href = `mailto:?subject=Check%20this%20out&body=${encodeURIComponent('Check this out: ' + window.location.href)}`;
            break;
          case 'facebook':
            window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`);
            break;
          case 'linkedin':
            window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(window.location.href)}`);
            break;
          case 'twitter':
            window.open(`https://twitter.com/share?url=${encodeURIComponent(window.location.href)}`);
            break;
          default:
            break;
        }
      };
    const shareOptions = [
        {label: "Copy Link", case: "copy", img: copy},
        {label: "Email", case: "email", img: mail},
        {label: "WhatsApp", case: "whatsapp", img: whatsapp},
        {label: "Facebook", case: "facebook", img: facebook},
        {label: "Twitter", case: "twitter", img: twitter},
        {label: "LinkedIn", case: "linkedin", img:linkedin},
    ]
  return (
    <><Share openModal={openModal} screenWidth={screenWidth} handleNativeShare={handleNativeShare} setOpenModal={setOpenModal} shareOptions={shareOptions} handleShare={handleShare}/></>
  )
}

export default ShareLogic