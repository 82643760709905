import React, {useState, useEffect} from 'react'
import Testimonial from './Testimonial'
import { useSelector } from 'react-redux';

const TestimonialLogic = () => {
  const screenWidth = useSelector(state=>state.screenWidth.screenWidth)
  const testimonialData = useSelector(state=>state.dashboard.testimonials)
    const [activeDot, setActiveDot] = useState(0);
    useEffect(() => {
      const handleScroll = () => {
        const testimonialsContainer = document.getElementById('testimonials-container');
        if (testimonialsContainer) {
          const scrollPosition = testimonialsContainer.scrollLeft;
          const containerWidth = testimonialsContainer.clientWidth;
          const totalWidth = testimonialsContainer.scrollWidth;
          let totalDots = 5;
          if(testimonialData?.length < 5){
            totalDots = testimonialData?.length;
          }
          const activeIndex = Math.floor((scrollPosition / (totalWidth - containerWidth)) * (totalDots - 1));
          setActiveDot(activeIndex);
        }
      };
  
      document.getElementById('testimonials-container')?.addEventListener('scroll', handleScroll);
      return () => {
        document.getElementById('testimonials-container')?.removeEventListener('scroll', handleScroll);
      };
    }, []);
    const dotColors = [
      '#EF7C22',
      'rgba(248, 159, 89, 0.6)',
      'rgba(248, 159, 89, 0.4)',
      'rgba(248, 159, 89, 0.25)',
      'rgba(254, 209, 174, 0.6)',
    ];
    
  return (
    <><Testimonial
     testimonialData={testimonialData} 
     dotColors={dotColors}
     activeDot={activeDot}
     screenWidth={screenWidth}
    /></>
  )
}

export default TestimonialLogic