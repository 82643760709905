import React, { useState, useEffect } from 'react'
import PersonalDetails from './PersonalDetails'
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import validationRules from '../../utils/validationRules'
import { showErrorToast, showInfoToast, showSuccessToast } from '../../utils/toastNotif';
import RoutePath from '../../routes/RoutePath';
import { verify } from '../../utils/verifyUser';
import { setUserState } from "../../redux/slices/authSlice";
import { convertImageToUrl } from '../../utils/convertImageToUrl'
import StatesService from '../../utils/statesService';

const PersonalDetailsLogic = () => {
  const collegeList = useSelector(state=>state.dashboard.colleges);
  const statesData = useSelector(state=>state.states)
  const statesService = new StatesService()
  const dispatch = useDispatch();
  const location = useLocation();
  const [editableFields, setEditableFields] = useState({});
  const [imageFiles, setImageFiles] = useState([])
  const [cities, setcities] = useState([]);
  const [imageLoading, setImageLoading] = useState(false);
  const token = localStorage.getItem('token');
  const userData = (localStorage.getItem("userData")  && localStorage.getItem("userData") !== "undefined") ? JSON.parse(localStorage.getItem("userData")) : "";
  const [profileImage, setProfileImage] = useState(null);
  let collegeIdUrl = "";
  const navigate = useNavigate();
  const [key, setKey] = useState(Date.now());
  const [loading, setLoading] = useState(false);
  const [imageList, setImageList] = useState(null);
  const status = useSelector(state => state.dashboard.status);
  const error = useSelector(state => state.dashboard.error);

  useEffect(() => {
    const handleStorageChange = () => {
        setKey(Date.now());  
    };
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);


  useEffect(() => {
    const fetchData = async()=>{
      try{
        setLoading(true)
        await verify(navigate, dispatch);
      }catch(error){
        if (error.message === "Unauthorized") { 
          showInfoToast("Kindly login to continue"); 
          navigate(RoutePath.login, {state: {data: location?.pathname + location?.search}});
        } else {
          console.log(error);
          showErrorToast("Error fetching user data")
        }
        console.log(error);
      }finally{
        setLoading(false);
      }
    }
     if(!userData){
      fetchData();
     }
  }, []);
 

 
 
  const fields = [
    { name: 'name', label: 'Name', subLabel: "This is the name on your travel document, which could be a licence or a passport." },
    { name: 'email', label: 'Email address', subLabel: "Use an address you’ll always have access to."  },
    { name: 'phoneNumber', label: 'Phone number', subLabel: ""  },
    { name: 'collegeName', label: 'College name', subLabel: "Enter your college name here."  },
    { name: 'collegeId', label: 'College ID', subLabel: "Upload your college ID here."  },
    { name: 'permanentAddress', label: 'Address', subLabel: "Use a permanent address where you can receive mail." },
  ];

  const handleEditClick = (field) => {
    const localStorageValue = localStorage.getItem(field);
    if ((field === 'email' || field === 'phoneNumber') && localStorageValue !== null && localStorageValue !== 'undefined' && localStorageValue !== "-1") {
        setEditableFields({ ...editableFields, [field]: localStorageValue });
    } else if (userData[field] === null || userData[field] === undefined || userData[field] === '' || userData[field] === "undefined" || userData[field] === "-1") {
        setEditableFields({ ...editableFields, [field]: '' });
    } else {
        setEditableFields({ ...editableFields, [field]: userData[field] });
    }
};

  
  const handleCancelClick = (field) => {
    const { [field]: omit, ...restEditableFields } = editableFields;
    setEditableFields(restEditableFields);
  };

  const handleInputChange = (e, field) => {
    if (field.startsWith("permanentAddress.")) {
      const nestedField = field.replace("permanentAddress.", "");
      setEditableFields({
        ...editableFields,
        permanentAddress: {
          ...editableFields.permanentAddress,
          [nestedField]: e.target.value,
        },
      });
    if(nestedField === 'state'){
      const temp = statesService.getCity(e.target.value);
      setcities( temp);
    }
    }
    else{
    setEditableFields({ ...editableFields, [field]: e.target.value });}
  };



  const handleUpdateClick = async() => {
    if(editableFields?.email){
      if (!validationRules?.email?.regex?.test(editableFields?.email)){
         showErrorToast("Kindly enter valid email address");
         return;
      }
    }
    if(editableFields?.phoneNumber){
      if (!validationRules?.phoneNumber?.regex?.test(editableFields?.phoneNumber)){
        showErrorToast("Kindly enter valid phone number");
        return;
     }
    }

    if(editableFields?.permanentAddress?.pinCode){
      if (!validationRules?.pinCode?.regex?.test(parseInt(editableFields?.permanentAddress?.pinCode))){
        showErrorToast("Kindly enter valid pincode");
        return;
     }
    }
    setLoading(true);
    if(imageList){
      try{
        collegeIdUrl =  await convertImageToUrl(imageList);
      }catch(error){
        console.log(error);
        showErrorToast("Error updating image");
      }
    }

    if(editableFields?.email || editableFields?.phoneNumber){
      try{
         const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/users/updateCredentials`, {
          method: 'PATCH',
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
            email: editableFields?.email,
            phone: parseInt(editableFields?.phoneNumber)
          })
         });
          
        if(response === null){
          throw new Error("Response is null");
        } 

        if(!response.ok){
          if(response.status === 401){
            showInfoToast("kindly login again to continue");
            navigate(RoutePath.login, {state: {data: location?.pathname + location?.search}});
            return;
          }
          else{
            const message = await response.text();
            const parsedMessage = JSON.parse(message);
            if(parsedMessage?.message){
              showErrorToast(parsedMessage?.message);
              return;
            }else{
              throw new Error("something went wrong");
            }
             
          }
        }

        const data = await response.json();
        if(data === null || data === undefined){
          throw new Error("Data is null");
        }
        showSuccessToast("phone/email updated successfully")
         localStorage.setItem("phoneNumber", data?.phoneNumber);
         localStorage.setItem("email", data?.email);
      }catch(error){
        console.log(error)
        showErrorToast("Error updating phone/email");
      }
    }

    if(editableFields?.name || editableFields?.collegeId || editableFields?.permanentAddress?.addressLine1 || editableFields?.permanentAddress?.addressLine2 || editableFields?.permanentAddress?.pinCode || editableFields?.permanentAddress?.state || editableFields?.permanentAddress?.city || (collegeIdUrl && imageList) || editableFields?.collegeName){
       try{
        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/users/updateuser`, {
          method: 'PATCH',
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`
          },

          body: JSON.stringify({
            name: editableFields?.name ? editableFields?.name : "",
            collegeId: editableFields?.collegeName ? editableFields?.collegeName : "",
            collegeIdCard: collegeIdUrl ? collegeIdUrl : "",
            permanentAddress : {
              addressLine1: editableFields?.permanentAddress?.addressLine1 ? editableFields?.permanentAddress?.addressLine1 : "",
              addressLine2: editableFields?.permanentAddress?.addressLine2 ? editableFields?.permanentAddress?.addressLine2 : "",
              state: editableFields?.permanentAddress?.state ? editableFields?.permanentAddress?.state : "",
              city: editableFields?.permanentAddress?.city ? editableFields?.permanentAddress?.city : "",
              pinCode: editableFields?.permanentAddress?.pinCode ? parseInt(editableFields?.permanentAddress?.pinCode) : "",
            }
          })
        });

        if(response === null){
          throw new Error("Response is null");
        }
        if(!response.ok){
          if(response.status === 401){
            showInfoToast("kindly login again to continue");
            navigate(RoutePath.login, {state: {data: location?.pathname + location?.search}});
            return;
          }
          else{
            console.log(response.status);
            throw new Error("something went wrong")
          }
        }

        const data = await response.json();
        if(data === null || data === undefined){
          throw new Error("Data is null");
        }
        localStorage.setItem("userData", JSON.stringify(data));
        dispatch(setUserState(data));
        showSuccessToast("Details updated successfully")
       }catch(error){
        console.log(error);
        showErrorToast("Error updating details");
       }
    }
    setLoading(false);
    setEditableFields({});
    setImageList(null)
    window.dispatchEvent(new Event('storage'))
    
   
  };

  const handleProfileImage = async(event)=>{
    const imageFile = event.target.files[0];
    if(imageFile){
      
        if(!token || token === "undefined" || token === ""){
          showErrorToast("kindly login again to continue");
          navigate(RoutePath.login, {state: {data: location?.pathname + location?.search}});
          return;
        }
        const ProfileImage = new FormData();
        ProfileImage.append("profilePhoto", imageFile); 
        try{
          setImageLoading(true);
          const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/users/updateProfilePicture`, {
            method: 'PATCH',
            headers: {
              'Authorization': `Bearer ${token}`
            },
            body: ProfileImage
          });

          if(response === null){
            throw new Error("Response is null");
          }

          if(!response.ok){
            if(response.status === 401){
              showInfoToast("kindly login again to continue");
              navigate(RoutePath.login, {state: {data: location?.pathname + location?.search}});
              return;
 
            }else{
             throw new Error("Something went wrong");
            }
          }

          const data = await response.json();
          if(data === null || data === undefined){
            throw new Error("Data is null");
          }

          const updatedUserData = {
            ...userData,
            profilePhotoUrl: data.profilePhotoUrl
          };
          localStorage.setItem("userData", JSON.stringify(updatedUserData));

          setProfileImage(data.profilePhotoUrl);
          showSuccessToast("Profile Image uploaded successfully")
          
        }catch(error){
          console.log("Error uploading image", error);
          showErrorToast("Error uploading image, please try again later");
        }finally{
          setImageLoading(false);
        }
    }else{
      showErrorToast("please select an image");
    }
  }
 
  return (
    <><PersonalDetails
    userData={userData}
    fields={fields}
    handleCancelClick={handleCancelClick}
    handleEditClick={handleEditClick}
    handleInputChange={handleInputChange}
    handleUpdateClick={handleUpdateClick}
    editableFields={editableFields}
    collegeList={collegeList}
    imageFiles={imageFiles}
    profileImage={profileImage}
    setImageFiles={setImageFiles}
    statesData={statesData}
    citiesData={cities}
    handleProfileImage={handleProfileImage}
    imageLoading={imageLoading}
    loading={loading}
    setImageList={setImageList}
    /></>
  )
}

export default PersonalDetailsLogic