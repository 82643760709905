import React from 'react'
import "./Newspinner.css";

const Spinner = () => {
    return (
        
          <div class="loader"></div>
        
      );
}

export default Spinner