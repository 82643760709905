import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchLocalityData = createAsyncThunk(
    'locality/fetchLocalityData',
     async ()=> {
        try{
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/locality/getAllLocalities`, {
                method: 'GET',
            });

            if (response === null) {
                console.log("Response is null");
                throw new Error("Response is null");
            }
            if(!response.ok) {
                console.log(`Error fetching locality data: ${response.status} - ${response.statusText}`);
                throw new Error(`Error fetching locality data: ${response.status} - ${response.statusText}`);
              }
            const data = await response.json();
              if (data === null || data === undefined) {
                console.log("Data is null or undefined")
                throw new Error("Data is null or undefined");
            } 
            return data; 
        } catch (error) {
            console.log("Error fetching locality data", error);
            throw error; 
          }
     }
);

const getLocalitiesSlice = createSlice({
    name: "locality",
    initialState: {
        localities: [],
        status: 'idle',
        error: null
    },

    reducers: {},

    extraReducers: (builder) => {
        builder
          .addCase(fetchLocalityData.pending, (state) => {
            state.status = 'loading';
          })
          .addCase(fetchLocalityData.fulfilled, (state, action) => {
            state.status = 'succeeded';
             state.localities = action.payload;
          })
          .addCase(fetchLocalityData.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
          });
      }
})

export default getLocalitiesSlice.reducer