import React, { useEffect, useState } from 'react'
import RoutePath from '../../routes/RoutePath'
import SpinnerLogic from "../../components/spinner/SpinnerLogic"
import downarrow from "../../assets/images/downArrow.svg"
import uparrow from "../../assets/images/upArrow.svg"
import BreadCrumbsLogic from '../../components/breadCrumbs/BreadCrumbsLogic'
import { useNavigate } from 'react-router-dom'
// import StepperLogic from '../../components/stepper/StepperLogic'
// import filter from "../../assets/images/filterIcon.png"
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { IoChevronDown } from "react-icons/io5";
import { IoChevronUp } from "react-icons/io5";
import { CiCalendar } from "react-icons/ci";
import { ORDERS_ITEMS_PER_PAGE } from '../../utils/constants'
import ImageCarouselLogic from '../../components/imageCarousel/ImageCarouselLogic'
// import { LuPlus } from 'react-icons/lu'
// import { IoIosStar } from 'react-icons/io'
// import bed from "../../assets/images/bedIcon.png"
import {RxCross2} from "react-icons/rx"
import Modal from "react-awesome-modal";
import { useSelector } from 'react-redux'
import PaginationLogic from '../../components/pagination/PaginationLogic'
import { formatDate } from '../../utils/formatDate'
import HorizontalStepperLogic from '../../components/horizontalStepper/HorizontalStepperLogic'

const MobileFilter = ({setOpenMobileFilter, handleDateChange,selectedDate, handleApplyFilter, apibody, setCategory, handleClear, categoryoptions, categoryId, setCategoryId})=>{
  return(
    <div className='fixed top-0 left-0 w-full h-full flex items-center justify-center z-50'>
      <div className='fixed top-0 left-0 w-full h-full bg-black opacity-30 backdrop-filter backdrop-blur-md'></div>
      <div className='relative w-full h-full top-[60px] z-50 bg-white rounded-tl-[24px] rounded-tr-[24px] '>
         <div className='w-full justify-between flex items-center border-b border-[#E4E4E7] py-4 px-5'>
        <RxCross2 className='text-[#52525B] text-xl' onClick={()=>setOpenMobileFilter(false)}/>
           <p className='text-xl font-poppins font-medium text-secondary'>Filter</p>
           <p onClick={()=>{
            setOpenMobileFilter(false);
            handleClear()}} className='font-poppins blog-button-shadow text-base text-[#A1A1AA] font-medium'>Clear</p>
         </div>
         <div className='flex flex-col items-start w-full py-6 px-5'>
           <div className='w-full'>
           <select value={categoryId ? categoryId : ""} onChange={(e)=>setCategoryId(e.target.value)} className='w-full cursor-pointer text-textColor outline-none text-base font-normal font-poppins rounded border border-[#D4D4D8] blog-button-shadow py-[10px] px-[14px]'>
                <option value="">Type of service</option>
                {
                    categoryoptions && categoryoptions[0]?.value?.map((ele, ind)=>(
                      <option key={ind} value={ele?._id} >{ele?.name}</option>
                    ))
                }
              </select>
           </div>
          <div className='mt-5 w-full'>
          <label htmlFor="datepicker" className='blog-button-shadow cursor-pointer border flex items-center space-x-3 border-[#D4D4D8] py-[10px] px-[14px] rounded'>
  <p className='text-textColor font-poppins text-base font-normal'>
    { 'Time'}
  </p>
  <div className="relative">
    <span className="absolute left-0 inset-y-0 flex items-center pl-3 cursor-pointer">
      <CiCalendar className="h-5 w-5 text-gray-400" />
    </span>
 {
   <DatePicker  
  id="datepicker"
  selected={selectedDate}
  onChange={handleDateChange}
  className="pl-8 border-none text-textColor font-poppins text-base font-normal bg-transparent outline-none cursor-pointer"
/>
 }
  </div>
</label>
           </div>
        </div>
        <div className='w-full fixed bottom-0 py-6 px-5'>
         <button onClick={()=>{
            handleApplyFilter()
            setOpenMobileFilter(false)
          }} className='background-primary w-full  blog-button-shadow py-3 px-5 rounded-[4px] text-white text-base font-poppins font-medium'>Apply</button>
        </div>
      </div>
      </div>
  
  )

}

const CancelModal = ({openModal, setOpenModal, orderId, productDetailsId, modalWidth, handleCancelItems})=>{
  const response = ["Ordered by mistake.", "Received wrong item.", "Item damaged in transit.", "Size/color not as described."]
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [selectedResponse, setSelectedResponse] = useState("");
  const [customReason, setCustomReason] = useState("");
  const filteredResponses = response.filter(res => res.toLowerCase().includes(customReason.toLowerCase()));
 
 
  return(
    <Modal
    visible={openModal}
    width={modalWidth}
    height="295"
    effect="fadeInUp"
  >
    <div className='w-full h-full rounded-xl'>
      <div className='w-full px-6 pt-6 pb-5 flex justify-between items-center'>
        <p className='text-secondary font-poppins font-semibold text-xl'>Are you sure you want to cancel?</p>
        <RxCross2 onClick={()=>setOpenModal(false)} className='text-xl cursor-pointer text-[#52525B]'/>
      </div>
      <div className='py-3 px-6'>
        <p className='text-secondary font-poppins text-lg font-medium'>Why are you cancelling this?</p>
        <div onClick={()=>setIsDropDownOpen(!isDropDownOpen)} className='w-full mt-3 cursor-pointer relative rounded border border-[#D4D4D8] blog-button-shadow py-[10px] px-[14px] font-poppins font-normal text-base text-textColor flex justify-between items-center'>
        <p>{selectedResponse || customReason ? selectedResponse || customReason : 'Choose a response'}</p>
          <img  src={isDropDownOpen ? uparrow : downarrow} alt="arrow" className='cursor-pointer'/>
          {
            isDropDownOpen && <div onClick={(e)=>e.stopPropagation()} className='absolute bg-white z-10 left-0 top-12 w-full h-[253px] rounded-lg border p-4 border-[#E4E4E7]'>
              <input type="text" value={customReason}
                  onChange={(e) => setCustomReason(e.target.value)} placeholder='Enter the reason' className='text-base text-textColor font-poppins w-full rounded border border-[#D4D4D8] outline-none py-[10px] px-[14px]'/>
              <p className='text-[#52525B] text-sm mb-2 mt-3 font-medium font-poppins'>Choose a response</p>
              <div className='flex flex-col'>
                {
                 filteredResponses && filteredResponses?.map((ele, ind)=>(
                    <div onClick={()=>setSelectedResponse(ele)} key={ind} className='py-[6px] text-secondary text-base font-poppins font-medium'>{ele}</div>
                  ))
                }
              </div>
            </div>
          }
        </div>
      </div>
      <div className='w-full p-6 flex items-center space-x-3'>
        <button className='blog-button-shadow border w-1/2 border-[#E4E4E7] px-5 py-3 rounded font-poppins text-[#3F3F46] text-base font-medium' onClick={()=>setOpenModal(false)}>Cancel</button>
        <button onClick={()=>{
          setOpenModal(false);
          handleCancelItems(orderId, productDetailsId)}} className='background-primary text-white w-1/2 px-5 py-3 rounded font-poppins text-base font-medium blog-button-shadow'>Yes</button>
      </div>
    </div>
    </Modal>
  )
}

const Order = ({viewStatusOpen, totalSteps,
  stepLabels,
  handleStepChange,
  activeStep, setViewStatusOpen, showDatePicker, setShowDatePicker, handleNavigate, loading, ordersData,handleCancelItems,setCategory, failed, categoriesData,apibody, setApibody, currentPage, handlePageChange, handleClear, handleApplyFilter, categoryId, setCategoryId, handleDateChange, handleIconClick, selectedDate, toggleExpand, expandedOrders,}) => {
    const navigate = useNavigate()
    const categoryoptions = categoriesData?.filter((ele)=>ele?.name === "furnishing");
    const [openMobileFilter, setOpenMobileFilter] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [orderId, setOrderId] = useState("");
    const [productDetailsId, setProductDetailsId] = useState("");
    const [modalWidth, setModalWidth] = useState("")
    const screenWidth = useSelector(state=>state.screenWidth.screenWidth);
    useEffect(()=>{
      if(screenWidth < 768){
        setModalWidth("360");
      }else{
        setModalWidth("550")
      }
    }, [screenWidth])

  return (
  <div className='py-6 px-5 md:py-12 md:px-20 w-full'>
    <BreadCrumbsLogic/>
  {
    
    loading ? <><SpinnerLogic/></>
     :
    <>
    {
      failed ?  
      <div className='flex flex-col w-full min-h-screen  items-center justify-center'>
      <div className='text-center'>
      <h1 className="text-secondary font-poppins font-semibold text-5xl">Error fetching orders data</h1>
       <p className="text-2xl font-poppins text-textColor mt-6 font-normal">We are facing some internal server error while fetching data, sorry for the inconvinience caused</p>
       <button onClick={()=>navigate(RoutePath.homepage)} className='blog-button-shadow background-primary mt-10 text-white text-lg rounded-[4px] px-4 py-2 font-medium font-poppins'>Go Back</button>
      </div>
       </div>   : 
      <>
       <div className='w-full'>
          <div className='w-full flex items-center justify-between'>
            <p className='text-secondary font-semibold font-poppins text-[32px] leading-[40px] tracking-[-0.2px]'>Your Order</p>
            <div className='hidden md:flex items-center space-x-3'>
              <select value={apibody?.categoryId ? apibody?.categoryId : ""} onChange={(e)=>setApibody(prevState => ({...prevState, categoryId: e.target.value}))} className='w-[212px] cursor-pointer text-textColor outline-none text-base font-normal font-poppins rounded border border-[#D4D4D8] blog-button-shadow py-[10px] px-[14px]'>
                <option value="">Type of service</option>
                {
                    categoryoptions && categoryoptions[0]?.value?.map((ele, ind)=>(
                      <option key={ind} value={ele?._id} >{ele?.name}</option>
                    ))
                }
              </select>
              <label htmlFor="datepicker" className='blog-button-shadow cursor-pointer border flex items-center space-x-3 border-[#D4D4D8] py-[10px] px-[14px] rounded'>
  <p className='text-textColor font-poppins text-base font-normal'>
    { 'Time'}
  </p>
  <div className="relative">
    <span className="absolute left-0 inset-y-0 flex items-center pl-3 cursor-pointer">
      <CiCalendar className="h-5 w-5 text-gray-400" />
    </span>
 {
   <DatePicker  
  id="datepicker"
  selected={selectedDate}
  onChange={handleDateChange}
  className="pl-8 border-none text-textColor font-poppins text-base font-normal bg-transparent outline-none cursor-pointer"
/>
 }
  </div>
</label>
            </div>
          </div>
          <div onClick={()=>setOpenMobileFilter(true)} className='flex items-center md:hidden my-4 justify-between font-poppins font-medium text-base text-secondary py-[12px] px-4 w-full rounded-lg border border-[#D4D4D8]'>
            <p>Filters</p>
            <img  src={openMobileFilter ? uparrow : downarrow} alt="" />
          </div>
          {
            ordersData?.orders?.length === 0 ?  <div className='min-h-screen w-full flex flex-col items-center justify-center'>
            <div className="text-center">
              <h1 className="text-secondary font-poppins font-semibold text-5xl">No Orders available</h1>
              <p className="text-2xl font-poppins text-textColor mt-6 font-normal">There are currently no orders available, kindly check back later for updates</p>
              <button onClick={() => navigate(RoutePath.homepage)} className='blog-button-shadow background-primary mt-10 text-white text-lg rounded-[4px] px-4 py-2 font-medium font-poppins'>Go Back</button>
            </div>
          </div> :
           <div className='mt-[30px]'>
            {
              ordersData?.orders && ordersData?.orders?.map((ele, ind)=>{
                const isExpanded = expandedOrders[ele?._id] || false;
                return (
                  <div key={ind} className='flex border border-[#E4E4E7] rounded-lg my-8 flex-col w-full'>
                    <div className='w-full border-b border-b-[#E4E4E7] gap-3 py-4 px-5 grid md:grid-cols-4 grid-cols-3 lg:grid-cols-6 bg-[#FAFAFA]'>
                      <div className=' col-span-1'>
                        <p  className='text-[#0F172A] text-base font-semibold font-poppins'>Order ID</p>
                        <p className='text-secondary break-words font-poppins mt-2 font-normal text-base'>{ele?._id}</p>
                        </div>
                      <div className='col-span-1'>
                        <p  className='text-[#0F172A] text-base font-semibold font-poppins'>Number of order</p>
                        <p className='text-secondary font-poppins mt-2 font-normal text-base'>{ele?.productDetails?.length}</p>
                        </div>
                      <div className='col-span-1'>
                        <p  className='text-[#0F172A] text-base font-semibold font-poppins'>Date</p>
                        <p className='text-secondary font-poppins mt-2 font-normal text-base'>{formatDate(ele?.date)}</p>
                        </div>
                      <div className='col-span-1'>
                        <p  className='text-[#0F172A] text-base font-semibold font-poppins'>Net Amount</p>
                        <p className='text-secondary font-poppins mt-2 font-normal text-base'>₹{ele?.netAmount}</p>
                        </div>
                      <div className='col-span-1'>
                        <p  className='text-[#0F172A] text-base font-semibold font-poppins'>Payment Status</p>
                  <p className={`${ele?.status === "paymentSuccessful" ? 'bg-[#EDFCF2] py-[4px] text-center px-3 rounded-[100px] text-[#099250]' : (ele?.status === "paymentfailed" ? 'bg-[#FEF2F2] py-[4px] text-center px-3 rounded-[100px] text-[#DC2626]' : 'text-secondary')}  font-poppins mt-2 font-medium text-sm`}>{ele?.status === "paymentfailed" ? 'Failed' : (ele?.status === "paymentSuccessful" ? 'Success' : ele?.status)}</p>
                        </div>
                      <div className='col-span-1'>
                       <div onClick={() => toggleExpand(ele?._id)} className='flex cursor-pointer text-secondary font-poppins font-medium text-base items-center space-x-3'>
                        <p> {isExpanded ? 'view less' : 'view more'}</p>
                        {isExpanded ? <IoChevronUp /> : <IoChevronDown />}
                       </div>
                        </div>
                    </div>
                    {
                      isExpanded && <>
                       {
                    ele?.productDetails?.map((item, ind)=>(
                     <div className='px-5'>
                      <div key={ind} className={`w-full mt-6 flex mb-6  flex-col md:flex-row  items-start  md:space-x-8 `}>
                      <div  className={` w-full  rounded-xl bg-cover aspect-[16/9] md:w-[200px] h-[160px]  `}>
                     <ImageCarouselLogic images={item?.images} id={item?._id} nofavourite={true} apibody={item}  />
                   </div>
                   <div className={` md:w-[79%] w-full px-2 mt-5 md:mt-0`}>
                   <div className='flex w-full items-start flex-wrap gap-3  justify-between'>
                       <div>
                       <p  className={`font-poppins cursor-pointer  font-semibold text-[#0F172A] text-base  md:text-lg`}>{ele?.productDetails[0]?.name}</p>
                       <p className='font-poppins text-xs md:text-sm font-normal text-textColor mt-1'>{item?.category?.value}</p>
                       </div>
                       <div className='border lg:w-[370px] md:w-[auto] rounded-lg border-[#E4E4E7] py-[10px] px-3'>     
                            <p className='font-poppins text-secondary font-medium text-base'>Order placed at {formatDate(ele?.date)}</p>   
                            <p className='mt-1 font-poppins font-normal text-textColor text-base'>Return will be at the time of delivery.</p>                                                    
                           </div>     
                     </div>
 
                     <div className='flex flex-wrap gap-3 items-center mt-8 justify-between w-full'>
                       <div className='grid grid-cols-3 lg:w-[60%] md:w-[80%]'>
                          <div>
                            <p className='text-[#0F172A] text-base font-medium font-poppins'>Product ID</p>
                            <p className='text-secondary text-base font-normal font-poppins'>{item?.productId}</p>
                          </div>
                          <div>
                            <p className='text-[#0F172A] text-base font-medium font-poppins'>Quantity</p>
                            <p className='text-secondary text-base font-normal font-poppins'>{item?.quantity}</p>
                          </div>
                          <div>
                            <p className='text-[#0F172A] text-base font-medium font-poppins'>Rent</p>
                            <p className='text-secondary text-base font-normal font-poppins'>{item?.rent}</p>
                          </div>
                       </div>
                       {
                       
                          <div className={`${ele?.status !== "paymentfailed" ? 'flex' : 'hidden'}  items-center w-full md:w-auto space-x-3`}>
                            <div  onClick={() => {
                      setViewStatusOpen(prev => ({
                        ...prev,
                        [ele?._id]: {
                          ...prev[ele?._id],
                          [item?._id]: !prev[ele?._id]?.[item?._id]
                        }
                      }));
                    }} className='border w-1/2 cursor-pointer hover:bg-[#FAFAFA] md:w-auto blog-button-shadow px-5 py-3 flex items-center space-x-3 rounded border-[#E4E4E7] font-poppins text-base font-medium text-[#3F3F46]'><p>View Status</p>
                            <p>{viewStatusOpen[ele?._id]?.[item?._id] ? <IoChevronUp/> : <IoChevronDown/>}</p>
                             </div>
                          <button onClick={()=>{
                            setOrderId(ele?._id);
                            setProductDetailsId(item?._id)
                            setOpenModal(true)}}  className={`font-poppins w-1/2 md:w-auto  justify-center text-white flex items-center space-x-2 text-base font-medium blog-button-shadow background-primary rounded-[4px] py-[12px] px-5`}>
                              <p>Cancel Items</p></button>    
                        </div>
                       }
                     </div>
             
                   </div>
                
                 </div>
                 <hr className='text-[#E4E4E7] my-5 '/>
                
                 {
                   viewStatusOpen[ele?._id]?.[item?._id] && <div className='py-4'>
                   <HorizontalStepperLogic status = {item?.deliveryDetails?.status}/>
                   <hr className='text-[#E4E4E7] my-5 '/>
                   
                   </div>
                 }

                     </div>
                    ))
                   }
                      
                      </>
                    }

                  
                  
                    </div>
                )
              })
            }
               <CancelModal handleCancelItems={handleCancelItems} modalWidth={modalWidth} openModal={openModal} setOpenModal={setOpenModal} orderId={orderId} productDetailsId={productDetailsId}/>
               {
           openMobileFilter && <MobileFilter selectedDate={selectedDate} handleDateChange={handleDateChange} categoryId={categoryId} setCategoryId={setCategoryId}  handleApplyFilter={handleApplyFilter} setOpenMobileFilter={setOpenMobileFilter} handleClear={handleClear} categoryoptions={categoryoptions} apibody={apibody} setCategory={setCategory}/>
      }
             <PaginationLogic totalPages={Math.ceil(ordersData?.totalOrdersCount / ORDERS_ITEMS_PER_PAGE)} currentPage={currentPage} handlePageChange={handlePageChange} />
           </div>
          }
       </div>
      </>
    }
    </>
  }
  </div>
  )
}

export default Order