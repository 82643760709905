import React, {useState, useEffect} from 'react'
import TestimonialCarousel from './TestimonialCarousel'
import { useSelector } from 'react-redux';

const TestimonialCarouselLogic = ({page}) => {
    const testimonialData = useSelector(state=>state.dashboard.testimonials)
    const [currentIndex, setCurrentIndex] = useState(0);
     
    
      const handlePrevClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex > 0 ?  prevIndex - 1 : currentIndex));
      };
    
      const handleNextClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex === testimonialData?.length - 1 ? currentIndex : prevIndex + 1));
      };
  return (
    <><TestimonialCarousel
    testimonialData={testimonialData}
    handleNextClick={handleNextClick}
    handlePrevClick={handlePrevClick}
    page={page}
    currentIndex={currentIndex}
    /></>
  )
}

export default TestimonialCarouselLogic