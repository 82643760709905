import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchFavouriteData = createAsyncThunk(
    'favourite/fetchFavouriteData',
    async ({token})=>{
        try{
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/users/getFavourites`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`
                  }
            });
            
            if (response === null) {
                console.log("Response is null");
                throw new Error("Response is null");
            }

            if(response?.status === 401) {
                console.log("Unauthorized");
                throw new Error("Unauthorized");
            }

            if(!response.ok){
                console.log(`Error fetching favourite data: ${response.status} - ${response.statusText}`);
                throw new Error(`Error fetching favourite data: ${response.status} - ${response.statusText}`);
            }

            const data = await response.json();
            if (data === null || data === undefined) {
                console.log("Data is null or undefined")
                throw new Error("Data is null or undefined");
            }
            return data?.favourites;
        }catch (error) {
            console.log("Error fetching user favourite data", error);
            throw error; 
          }
    }
);

const getFavouritesSlice = createSlice({
    name: "favourites",
    initialState: {
        favouriteData: [],
        status: 'idle',
        error: null
    },

    reducers: {},
    extraReducers: (builder) => {
        builder
          .addCase(fetchFavouriteData.pending, (state) => {
            state.status = 'loading';
          })
          .addCase(fetchFavouriteData.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.favouriteData = action.payload;
            
          })
          .addCase(fetchFavouriteData.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message === "Unauthorized" ? "Unauthorized" : action.error.message;
          });
      }
})

export default getFavouritesSlice.reducer;