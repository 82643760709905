import React from 'react'
import headAvatar from "../../assets/images/headAvatar.svg"
import truncateText from '../../utils/truncateText';
import useFindCollege from '../../utils/findCollege';
import { Tooltip } from 'react-tooltip';
import { IoMdInformationCircle } from "react-icons/io";

const Testimonial = ({ testimonialData, dotColors, activeDot, screenWidth }) => {
  const { findCollegeName } = useFindCollege();
  const showSecondRow = screenWidth >= 768 && testimonialData?.length > 12;
  const firstRowData = showSecondRow ? testimonialData?.slice(0, testimonialData?.length / 2) : testimonialData;
  const secondRowData = showSecondRow ? testimonialData?.slice(testimonialData?.length / 2) : [];
  return (
    <>
      {
        testimonialData?.length > 0 && <>
          <div className='pt-8 md:pt-[96px] px-5  flex flex-col items-center justify-center mx-auto'>
            <h2 className='font-poppins text-center text-[32px] leading-[40px] font-semibold text-secondary'>Don't just take our word for it</h2>
            <p className='text-textColor font-normal font-poppins text-base text-center mt-3'>Hear from some of our amazing customers who are building faster.</p>

          </div>
          <div className='px-5 pb-8 md:pb-0 flex flex-col items-center justify-center mx-auto md:px-0'>
            <div id="testimonials-container" className={`flex overflow-x-scroll mt-7 no-scrollbar ${testimonialData?.length > 2 ? '' : 'justify-center'}  space-x-6 w-full items-center`}>
              {
                firstRowData.map((ele, ind) => (
                  <div key={ind} className={`${ind % 2 === 0 ? 'bg-[#FFF4EB]' : 'bg-[#FEE3CD]'} cursor-pointer testimonial-box-shadow rounded-xl flex-none w-[350px] md:w-[456px] h-[256px] p-8`}>
                    <p data-tooltip-id={`tooltip-${ind}`} data-tooltip-content={ele?.description
                    } className='font-poppins flex items-end space-x-1 font-medium text-lg text-secondary'> "{truncateText(ele?.description, 20)}" </p>
                    <Tooltip
                      place='bottom-end'
                      id={`tooltip-${ind}`}
                      style={{ backgroundColor: "", display: "flex", flexDirection: 'column', alignItems: "center", justifyContent: "center", maxWidth: "250px", textAlign: "center", padding: "16px" }}
                    />
                    <div className='flex items-center mt-7 space-x-2'>
                      <img src={headAvatar} alt="profile pic" className='w-10 h-10 rounded-md' />
                      <div className='flex flex-col'>
                        <p className='text-secondary font-poppins font-medium text-sm'>{ele?.user?.name}</p>
                        <p className='text-textColor font-poppins font-normal text-sm'>{findCollegeName(ele?.user?.college)}</p>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
            {
              showSecondRow && <>
                <div id="testimonials-container" className='flex overflow-x-scroll mt-7 no-scrollbar space-x-6 w-full items-center'>
                  {
                    secondRowData?.length > 0 && secondRowData.map((ele, ind) => (
                      <div key={ind} className={`${ind % 2 === 0 ? 'bg-[#FFF4EB]' : 'bg-[#FEE3CD]'} cursor-pointer testimonial-box-shadow rounded-xl flex-none w-[350px] md:w-[456px] h-[256px] p-8`}>
                        <p data-tooltip-id={`tooltip-${ind}`} data-tooltip-content={ele?.description} className='font-poppins font-medium text-lg text-secondary'> "{truncateText(ele?.description, 20)}"</p>
                        <Tooltip
                          place='bottom-end'
                          id={`tooltip-${ind}`}
                          style={{ backgroundColor: "", display: "flex", flexDirection: 'column', alignItems: "center", justifyContent: "center", maxWidth: "250px", textAlign: "center", padding: "16px" }}
                        />
                        <div className='flex items-center mt-7 space-x-2'>
                          <img src={headAvatar} alt="profile pic" className='w-10 h-10 rounded-md' />
                          <div className='flex flex-col'>
                            <p className='text-secondary font-poppins font-medium text-sm'>{ele?.user?.name}</p>
                            <p className='text-textColor font-poppins font-normal text-sm'>{""}</p>
                          </div>
                        </div>
                      </div>
                    ))

                  }
                </div>
              </>
            }
          </div>

          {
            testimonialData?.length > 1 && <div className="flex md:hidden items-center text-center mx-auto w-28 ">
              {[...Array(5).keys()].map((index) => {
                const size = 10 - Math.abs(index - activeDot);
                return (
                  <div
                    key={index}
                    className={` mx-1 rounded-full cursor-pointer`}
                    style={{
                      width: `${size}px`,
                      height: `${size}px`,
                      backgroundColor: index === activeDot ? dotColors[0] : dotColors[index],
                    }}
                  ></div>
                );
              })}
            </div>
          }
          <hr className='w-[90%] hidden md:block mx-auto bg-[#D4D4D8] h-[1px]' />
        </>
      }
    </>
  )
}

export default Testimonial