import React from 'react'
import DemoVideo from './DemoVideo'

const DemoVideoLogic = ({videoUrl}) => {
  return (
    <><DemoVideo
    videoUrl={videoUrl}
    /></>
  )
}

export default DemoVideoLogic