import { showErrorToast } from "./toastNotif"
export const convertImageToUrl = async(imageList)=>{
     if(!imageList){
        showErrorToast("please select image file");
        return;
     }

     try{
        const imageUrl = new FormData();
        imageUrl.append("image", imageList); 
        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/getImageUrl`, {
            method: 'POST',
            body : imageUrl
        });

        if(response === null){
            throw new Error("Response is null");
        }

        if(!response.ok){
            console.log(response.status);
            throw new Error("response not okay");
        }
        const data = await response.json();
        if(data === null || data === undefined){
            throw new Error("data is null");
        }
        return data;
     }catch(error){
        console.log(error);
        throw error
     }
}