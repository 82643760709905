import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setScreenWidth } from '../redux/slices/screenWidthSlice';

const ScreenWidthProvider = ({ children }) => {
  const dispatch = useDispatch();

  const handleResize = () => {
    dispatch(setScreenWidth(window.innerWidth));
  };

  useEffect(() => {
    handleResize(); 
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return <>{children}</>;
};

export default ScreenWidthProvider;
