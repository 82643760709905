import React from 'react'
import RoutePath from '../../routes/RoutePath'
import { useNavigate } from 'react-router-dom'
import { Result } from 'antd';

const PaymentFailed = () => {
    const navigate = useNavigate();
  return (
    <Result
    className='h-screen'
    status="error"
    title={<p className='text-5xl font-semibold font-poppins' >Payment Failed!</p>}
    subTitle={<p className='text-xl font-poppins' >Their was some issue while processing your Payment</p>}
    extra={[
      <button onClick={() => navigate(RoutePath.homepage)} className='bg-orange-400 py-3 px-6 text-xl text-white rounded-xl'>
        Go to Home
      </button>,
      
    ]}
  >
  </Result>
  )
}

export default PaymentFailed