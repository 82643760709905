import React, {useEffect, useState} from 'react'
import LocalAssistByCollege from './LocalAssistByCollege'
import { useParams } from 'react-router-dom';
import useFindCollege from '../../utils/findCollege';
import { useDispatch } from 'react-redux';
import { LOCAL_ASSIST_ITEMS_PER_PAGE } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {fetchLocalAssistData} from "../../redux/slices/localAssistSlice"

const LocalAssistByCollegeLogic = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLocalAssistAvailable, setIsLocalAssistAvailable] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [categoryId, setCategoryId] = useState("");
  const [sortValue, setSortValue] = useState(`_id-${-1}`);
  const [charge, setCharge] = useState({startCharge: 0, endCharge: ""})
  const [minRent, setMinRent] = useState(0);
  const [maxRent, setMaxRent] = useState(10000);
  const [apibody, setApibody] = useState({ currPage: 1, itemsPerPage: LOCAL_ASSIST_ITEMS_PER_PAGE, categoryId: "", sortKey: "_id", sortValue: -1})
  const {findCollegeDetailsById } = useFindCollege();
  const collegeData = findCollegeDetailsById(id);
  const sortOptions = [{label: "Price: Low to High", sortKey: "baseChargePerVisit", sortValue: 1}, {label: "Price: High to Low", sortKey: "baseChargePerVisit", sortValue: -1}, {label: "id", sortKey: "_id", sortValue: -1}]

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setApibody(prevState => ({...prevState, currPage: page}));
  };




  useEffect(()=>{
   if(collegeData?.localAssistanceAvailable){
    setIsLocalAssistAvailable(true);
    dispatch(fetchLocalAssistData({apibody}));
   }else{
    setIsLocalAssistAvailable(false);
   }
  }, [collegeData, id, apibody, dispatch])

  const { status, localAssistData, error } = useSelector(state => state.localAssist);
  

  return (
    <><LocalAssistByCollege
    isLocalAssistAvailable={isLocalAssistAvailable}
    collegeId={id}
    currentPage={currentPage}
    handlePageChange={handlePageChange}
    setApibody={setApibody}
    apibody={apibody}
    navigate={navigate}
    status={status}
    error={error}
    setCharge={setCharge}
    sortOptions={sortOptions}
    localAssistData={localAssistData}
    setCategoryId={setCategoryId}
    categoryId={categoryId}
    sortValue={sortValue}
    setSortValue={setSortValue}
    minRent={minRent}
    maxRent={maxRent}
    setMaxRent={setMaxRent}
    setMinRent={setMinRent}
    /></>
  )
}

export default LocalAssistByCollegeLogic