import { useSelector } from "react-redux";

const useFindCollege = () => {
    const collegeList = useSelector(state => state.dashboard.colleges);
  
    const findCollegeName = (collegeId) => {
      if (collegeId === "") {

        return "Unknown College"; 
      }

      const college = collegeList?.find(college => college._id === collegeId);
      return college ? college.collegeName : "Unknown College";
    };

    const findCollegeCode = (collegeId) => {
      if (collegeId === "") {

        return "Unknown College"; 
      }

      const college = collegeList?.find(college => college._id === collegeId);
      return college ? college.collegeCode : "Unknown College";
    };



    const findCollegeDetailsById = (collegeId) => {
      if (!collegeId) {
        return null; 
      }
      const college = collegeList?.find(college => college._id === collegeId);
      return college ? college : null;
    };
  
    return { findCollegeName, findCollegeDetailsById, findCollegeCode };
  
  };
  
  export default useFindCollege;