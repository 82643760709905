import React from 'react'
import PersonalDetailsLogic from "../../components/personalDetails/PersonalDetailsLogic"
import OccupiedPropertyLogic from "../../components/occupiedProperty/OccupiedPropertyLogic"
import OccupiedServicesLogic from "../../components/occupiedServices/OccupiedServicesLogic"
import AgreementsLogic from "../../components/agreements/AgreementsLogic"
import PaymentsLogic from '../../components/payments/PaymentsLogic'
import WhatsappContactLogic from '../../components/whatsappContact/WhatsappContactLogic'
import AllVisitsLogic from '../../components/allVisits/AllVisitsLogic'

const Account = ({options, activeOption, setActiveOption}) => {
   
  return (
    <div>
       <WhatsappContactLogic/>
        <div className='md:py-16 md:px-20 px-5 py-8'>
        <nav className="flex my-5 w-full items-center space-x-2 text-textColor font-poppins font-medium text-sm">
         <p>Account</p> <p>{' > '}</p><p>{activeOption}</p>
        </nav>
        <h1 className='text-secondary tracking-[-0.2px] font-poppins font-semibold text-[36px] md:text-[56px] leading-[44px]  md:leading-[64px]'>Account</h1>
        <p className='font-poppins text-textColor text-base font-normal mt-2'>Provide personal details and how we can reach you</p>
        <div className='overflow-x-scroll  mt-10 no-scrollbar flex items-center space-x-6 border-b border-b-[#D4D4D8]'>
        {
            options && options.map((ele, ind)=>(
                <p key={ind} onClick={()=>setActiveOption(ele)} className={`${activeOption === ele ? 'text-primary border-b border-primary' : 'text-textColor '} flex-none pb-2 cursor-pointer font-poppins font-medium  text-center text-sm`}>{ele}</p>
            ))
        }
         </div>
         {
            activeOption === "Personal details" && <><PersonalDetailsLogic/></>
         }
         {
            activeOption === "Occupied Property" && <><OccupiedPropertyLogic setActiveOption={setActiveOption}/></>
         }
         {
            activeOption === "Occupied Services" && <><OccupiedServicesLogic setActiveOption={setActiveOption}/></>
         }
         {
            // activeOption === "Rent agreement & Police verification" && <><AgreementsLogic/></>
         }
         {
            activeOption === "Payment" && <><PaymentsLogic/></>
         }
         {
            activeOption === "Schedule visit" && <><AllVisitsLogic/></>
         }
        </div>
    </div>
  )
}

export default Account