import React from 'react'
import download from "../../assets/images/downloadIcon.svg"
import payment from "../../assets/images/paymentIcon.svg"
import { formatDate } from "../../utils/formatDate"
import ShareLogic from '../share/ShareLogic'
import { IoIosStar } from "react-icons/io";
import location from "../../assets/images/locationIcon.png"
import useFindLocality from "../../utils/findLocality";
import SpinnerLogic from "../../components/spinner/SpinnerLogic"
import ImageCarouselLogic from '../imageCarousel/ImageCarouselLogic'
import NoticePeriodModalLogic from '../noticePeriodModal/NoticePeriodModalLogic';
import FeedBackLogic from '../feedback/FeedBackLogic'

const Flat = ({occupiedPropertyData, openModal, setOpenModal, setActiveOption, handleFeedBack})=>{
  const {findLocalityName} = useFindLocality();
  return(
    <div className='w-full mt-[20px] md:mt-0'>
    <div className='flex items-start justify-between'>
      <div className='flex flex-col items-start'>
        <p className='font-poppins text-lg font-medium text-secondary'>Flat</p>
        <p className='font-poppins text-textColor font-normal text-sm'>In our gharbar-inspired living space, simplicity reigns in our flat, where minimalism meets tranquility.</p>
      </div>
      <div className='flex flex-wrap items-center mx-2 md:gap-2 md:space-x-4'>
        {/* <div className='md:flex items-center space-x-2 text-base font-medium font-poppins hidden cursor-pointer text-primary'><img src={download} alt="" /><p>Download agreement</p></div> */}
        <div onClick={()=>setActiveOption('Payment')} className='flex items-center space-x-2 text-base font-medium font-poppins cursor-pointer text-primary'><img src={payment} alt="" /><p>Payments</p></div>
      </div>
    </div>
    <div className='mt-[30px] w-full flex flex-col md:flex-row md:space-x-8 items-center'>
      <div className='md:w-4/12 w-full'>
       <ImageCarouselLogic images={occupiedPropertyData?.listingDetails?.imgArr} nofavourite={true}/>
      </div>
      <div className=' md:w-8/12 w-full mt-5 md:mt-0'>
        <div className='flex md:items-center items-start  justify-between'>
          <p className='font-poppins font-semibold text-[#0F172A] text-base md:text-lg'>{occupiedPropertyData?.listingDetails?.apartmentName ? occupiedPropertyData?.listingDetails?.apartmentName : occupiedPropertyData?.permanentAddress?.addressLine1}</p>
          <div className='block md:hidden'>
            <p className='text-textColor font-poppins font-normal text-xs'>Fees/month</p>
            <p className='font-poppins md:text-lg text-sm text-secondary font-semibold'>₹{occupiedPropertyData?.rent} <span className='text-textColor font-poppins font-normal text-sm'>/Inclusive GST</span></p>
          </div>
          <div className='md:flex hidden items-center space-x-3'>
            <ShareLogic/>
           {
            occupiedPropertyData?.listingDetails?.averageRating &&  <div className='flex items-center space-x-2'>
            <IoIosStar className='text-[#475569]' />
            <p className='text-[#222222] font-normal text-[15px] leading-[20px]'>{occupiedPropertyData?.listingDetails?.averageRating}</p>
          </div>
           }
          </div>
        </div>
        <p className='font-poppins font-normal text-sm text-textColor mt-2'>{findLocalityName(occupiedPropertyData?.listingDetails?.localityId)}</p>
       {
        occupiedPropertyData?.listingDetails?.listingId &&  <p className='font-poppins font-normal text-sm text-textColor'>Room # : {occupiedPropertyData?.listingDetails?.listingId}</p>
       }
       {/* <p>| {occupiedPropertyData?.flat?.occupancyType} sharing</p> */}
       {
        occupiedPropertyData?.listingDetails?.lockInPeriod &&  <div className='mt-5 border lg:w-[60%] md:w-[auto] rounded-lg border-[#E4E4E7] p-3'>
        <div className='text-[#3F3F46] font-poppins text-sm w-full font-medium flex items-center justify-between'><p>Lock-in tenure end date</p><p>{occupiedPropertyData?.listingDetails?.lockInPeriod}</p></div>
        <p className='font-poppins mt-1 text-sm text-textColor font-normal'>(Minimum notice period : 25 days)</p>
      </div> 
       }
        {
          occupiedPropertyData?.listingDetails?.features?.length > 0 && <div className='flex mt-7 flex-col items-start'>
            <p className='font-poppins text-xs font-normal text-textColor'>Amenities</p>
            <div className='flex mt-2 flex-wrap items-center gap-3'>
              {
                occupiedPropertyData?.listingDetails?.features.map((ele, ind) => (
                  <div key={ind} className='flex items-center border border-[#E2E8F0] space-x-3 rounded-[24px] px-3 py-2'>
                    {/* <img src={ele?.image} alt="" className='w-[15px] h-[15px]' /> */}
                    <p className='font-poppins text-xs text-secondary font-normal'>{ele}</p>
                  </div>
                ))
              }
            </div>
          </div>
        }

        <div className='flex flex-wrap gap-3 items-center mt-6 justify-between w-full'>
          <div className='hidden md:block'>
            <p className='text-textColor font-poppins font-normal text-xs'>Fees/month</p>
            <p className='font-poppins text-lg text-secondary font-semibold'>₹{occupiedPropertyData?.rent} <span className='text-textColor font-poppins font-normal text-sm'>/Inclusive GST</span></p>
          </div>
          <div className='flex items-center w-full md:w-auto space-x-3'>
           {
            occupiedPropertyData?.isOnNoticePeriod ? <p className='font-poppins text-primary text-sm'>On Notice period</p> :  <button onClick={()=>setOpenModal(true)} className='font-poppins w-1/2 md:w-auto text-white text-sm font-medium blog-button-shadow background-primary rounded-[4px] py-[10px] px-4'>Start notice period</button>
           }
           <FeedBackLogic listingId={occupiedPropertyData?.listingId}/>
          </div>
        </div>
      </div>
    </div>
    <NoticePeriodModalLogic occupiedPropertyData={occupiedPropertyData} openModal={openModal} setOpenModal={setOpenModal} type={"flat"} />
  </div>
  )
}

// const Flatmate = ({occupiedPropertyData, openModal, setOpenModal})=>{
//   return(
//     <div className='w-full md:mt-[50px] mt-[20px]'>
//     <div className='flex items-start justify-between w-full'>
//       <div className='flex flex-col items-start'>
//       <p className='font-poppins text-lg font-medium text-secondary'>Flatmate</p>
//       <p className='font-poppins text-textColor font-normal text-sm'>Update you photo and personal details here.</p>
//       </div>
//       <div className='md:hidden mx-2 items-center space-x-2 text-base font-medium font-poppins flex cursor-pointer text-primary'><img src={download} alt="" /><p> agreement</p></div>
//     </div>
//     <div className='mt-[30px] w-full flex flex-col md:flex-row md:space-x-8 items-start'>
//       <div className='md:w-4/12 w-full'>
//         <img src={occupiedPropertyData?.flatmate?.imgUrl} alt="" className='rounded-xl object-cover w-full min-h-[316px]  ' />
//       </div>
//       <div className='w-full mt-[20px] md:mt-0 md:w-8/12'>
//       <div>
//           <h1 className='font-poppins font-semibold text-[#0F172A] text-lg'>{occupiedPropertyData?.flatmate?.name}</h1>
//           <p className='font-poppins font-normal text-sm text-textColor mt-2'>{occupiedPropertyData?.flatmate?.gender}</p>
//         </div>
//         <div className='px-3 py-2 font-poppins bg-[#FFF4EB] lg:w-[50%] w-auto rounded-[24px] font-normal text-xs text-[#0F172A] flex items-center space-x-2 mt-5'>
//           <img src={location} alt="" />
//           <p>{occupiedPropertyData?.flatmate?.distanceFromCollege} away from Delhi Technological University (DTU)</p>
//         </div>
//         <div className='mt-7'>
//           <p className='text-xs font-poppins font-normal text-textColor'>Looking for</p>
//             <p className='text-[#0F172A] text-xs font-poppins font-normal mt-2'>{occupiedPropertyData?.flatmate?.lookingFor}</p>
//         </div>
//       <div className='flex flex-wrap gap-3 items-center mt-6 justify-between w-full'> 
//           <div>
//             <p className='text-textColor font-poppins font-normal text-xs'>Rent</p>
//             <p className='font-poppins text-lg mt-2 text-secondary font-semibold'>₹{occupiedPropertyData?.flatmate?.rent} <span className='text-textColor font-poppins font-normal text-sm'>/month</span></p>
//           </div>
//           <div className='flex items-center w-full md:w-auto space-x-3'>
//             <button onClick={()=>setOpenModal(true)} className='font-poppins w-1/2 md:w-auto text-white text-sm font-medium blog-button-shadow background-primary rounded-[4px] py-[10px] px-4'>Start notice period</button>
//             <button className='font-poppins w-1/2 md:w-auto text-sm font-medium text-primary rounded-[4px] py-[10px] px-4 border hover-primary border-primary blog-button-shadow'>Give feedback</button>
//           </div>
//         </div>
//       </div>
//     </div>
//     <NoticePeriodModalLogic openModal={openModal} setOpenModal={setOpenModal} type={"flatmate"}/>
//   </div>
//   )
// }

const OccupiedProperty = ({handleFeedBack, occupiedPropertyData,setActiveOption, loading, setLoading, openModal, setOpenModal }) => {
  return (
    <div>
     {
      loading ? <><div className="absolute min-h-screen inset-0 bg-black opacity-50"></div><div className='absolute  z-30 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'><SpinnerLogic /></div></> : 
      <>
       {
        occupiedPropertyData?.length === 0 ? <div className='w-full min-h-screen flex flex-col items-center justify-center'>
          <h1 className='text-secondary font-poppins text-2xl font-medium'>No data available</h1>
          <p className='text-textColor text-base mt-3 font-poppins font-normal'>There is no occupied property</p>
        </div>
          :
          <div className='flex mt-[30px] flex-col'>
            {/* <select value={selectType} onChange={(e)=>setSelectType(e.target.value)} className='border md:hidden rounded-lg cursor-pointer mt-1 border-[#D4D4D8] blog-button-shadow text-secondary outline-none font-poppins text-base font-normal w-full  py-[10px] px-[14px]'>
          <option value="Flat">Flat</option>
          <option value="Flatmate">Flat mate</option>
        </select> */}
           {
             <Flat handleFeedBack={handleFeedBack} setActiveOption={setActiveOption} occupiedPropertyData={occupiedPropertyData}  openModal={openModal} setOpenModal={setOpenModal} />
           }
          {/* {
            (screenWidth >= 768 || (screenWidth < 768 && selectType === "Flatmate")) &&  <Flatmate occupiedPropertyData={occupiedPropertyData} openModal={openModal} setOpenModal={setOpenModal} />
          } */}
          </div>
      }
      </>
     }
    </div>
  )
}

export default OccupiedProperty