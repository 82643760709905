export const handleFiles = (files, setImageFiles) => {
    const updatedImageFiles = [];

    for (let i = 0; i < files.length; i++) {
      updatedImageFiles.push({
        file: files[i],
        id: Math.random().toString(36).substring(7),
      });
    }

    setImageFiles((prevImageFiles) => [...prevImageFiles, ...updatedImageFiles]);
  };

export const handleDragOver = (event) => {
    event.preventDefault();
  };
  
  export const handleDragEnter = (event) => {
    event.preventDefault();
  };
  
  export const handleDragLeave = (event) => {
    event.preventDefault();
  };

 export const handleDrop = (event, setImageFiles) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    handleFiles(files, setImageFiles);
  };
  
 export const handleImageUpload = (event, setImageFiles, multiple=true, setImagesList) => {
    const files = event.target.files;
    if(!multiple){
      const firstFile = files[0];
      setImagesList(firstFile);
      if (firstFile) {
    handleFiles([firstFile], setImageFiles);
  }
    }
    else{
      if(setImagesList){
        setImagesList([...event.target.files])
      }
      handleFiles(files, setImageFiles);
    }
  };

  export const handleRemoveImage = (id, setImageFiles, setImageList) => {
    setImageFiles((prevImageFiles) => prevImageFiles.filter((file) => file.id !== id));
    if(setImageList){
      setImageList(null)
    }
  };
 