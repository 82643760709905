import React, {useState} from 'react'
import ShippingAddressForm from './ShippingAddressForm'
import StatesService from '../../utils/statesService';
import { showErrorToast } from '../../utils/toastNotif';
import validationRules from '../../utils/validationRules';

const ShippingAddressFormLogic = ({handleSelectAddress, address, addNew , setTempAddress, setOpenAddressForm}) => {
    const statesService = new StatesService()
    const [cities, setcities] = useState([]);
    const phoneNumber = localStorage.getItem("phoneNumber");
    const userData = (localStorage.getItem("userData")  && localStorage.getItem("userData") !== "undefined") ? JSON.parse(localStorage.getItem("userData")) : "";
    const [isChecked, setIsChecked] = useState(false);
    const [editedAddress, setEditedAddress] = useState(
      addNew 
        ? {
            name: (userData?.name && userData.name !== "undefined") ? userData.name : "",
            phoneNumber: (phoneNumber && phoneNumber !== "undefined" && parseInt(phoneNumber) !== -1) ? parseInt(phoneNumber) : ""
          }
        : address
    );
    const token = localStorage.getItem('token');
    const Id = localStorage.getItem('user_id');
    
    const handleChange = async (e) => {
      const { name, value } = e.target;
      
      if (name === 'state') {
        try {
          // Store the state value
          const selectedState = value;
          const temp = await statesService.getCity(selectedState); // Use async/await
          setEditedAddress({ 
            ...editedAddress, 
            city: temp[0]?.city,
            state: selectedState 
          }); 
          setcities(temp);
        } catch (error) {
          console.error("Error fetching city:", error);
        }
      } else {
        setEditedAddress({ ...editedAddress, [name]: value });
      }
    };

      const handleSave = async ()=>{
        if(!editedAddress?.name || !editedAddress?.phoneNumber || !editedAddress?.addressLine1 || !editedAddress?.state || !editedAddress?.city || !editedAddress?.pinCode){
            console.log(editedAddress)
            showErrorToast("kindly fill all the required fields");
            return;
        }

        if (!validationRules?.phoneNumber?.regex?.test(editedAddress?.phoneNumber)){
            showErrorToast("Kindly enter valid phone number");
            return;
         }
        if (!validationRules?.pinCode?.regex?.test(editedAddress?.pinCode)){
            showErrorToast("Kindly enter valid pincode");
            return;
         }
         try {
          const existingAddresses = JSON.parse(localStorage.getItem("shipping-address")) || [];
         if(isChecked){
                console.log("existing address",existingAddresses);
                console.log("new address",editedAddress);
                const updatedAddresses = [...existingAddresses, editedAddress];
                localStorage.setItem("shipping-address", JSON.stringify(updatedAddresses));
                
                const apiBody = {
                  addresses:[
                    {
                      addressLine1:editedAddress?.addressLine1,
                      city:editedAddress?.city,
                      state:editedAddress?.state,
                      pinCode:editedAddress?.pinCode,
                      landmark: ""
                    }
                  ],
                  userId : Id
                }
                const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/users/updateAddress`, {
                  method: 'POST',
                  headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                  },
          
                  body: JSON.stringify(apiBody)
                });
                if(!response.ok){
                  throw new Error("Failed to save the address for future use");
                }
            
         }
         setTempAddress([...existingAddresses, editedAddress])
         handleSelectAddress(editedAddress)
         setOpenAddressForm(false);
         } catch (error) {
          console.log(error);
          showErrorToast(error)
         }
      }
  return (
    <><ShippingAddressForm editedAddress={editedAddress} isChecked={isChecked} handleSave={handleSave} setIsChecked={setIsChecked} cities={cities} handleChange={handleChange}/></>
  )
}

export default ShippingAddressFormLogic