import React, { useState } from 'react'
import RoutePath from '../../routes/RoutePath';
import TestimonialCarouselLogic from "../../components/testimonialCarousel/TestimonialCarouselLogic"
import { RxCross2 } from 'react-icons/rx';
import mainLogo from "../../assets/images/gharbarLogo.svg"
import SpinnerLogic from "../../components/spinner/SpinnerLogic"
import FirebaseOtpModalLogic from '../../components/firebaseOtpModal/FirebaseOtpModalLogic';

const Login = ({ checkLoginOrSignup,handleKeyPress,checkIsValidUser,handleSignUp,requirederrors, errors, handleChange, formData, verifyAndLogin, signup, checkExistingUser, screenWidth, navigate, phoneNumber, loading, setPhoneNumber, verifying, openModal, setOpenModal, handleVerifyOTP, setOtp, otp, errormsg, otperrormsg, setOtpErrormsg, timerotp, confirmationResult, setConfirmationResult, setLoading, settimerotp, handleLoginWithOtp }) => {


  return (
    <>
      {
        loading && <><div className="absolute min-h-screen inset-0 bg-black opacity-50"></div><div className='absolute  z-30 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'><SpinnerLogic /></div></>
      }
      <div className='flex w-full justify-between'>

        <div className={` ${screenWidth >= 768 ? 'w-1/2 items-center justify-center' : 'w-full items-start'} flex flex-col min-h-screen relative  `}>
          <RxCross2 onClick={() => {
            if (!loading && !openModal) {
              navigate(RoutePath.homepage)
            }
          }} className={`${screenWidth < 768 ? 'top-0 right-0' : 'top-0 left-0'} absolute cursor-pointer m-7 text-2xl text-secondary`} />
          {
            screenWidth < 768 && <>
              <div className='mt-[40px] w-20 mx-4 relative'>
                <img src={mainLogo} alt="Gharbar" className='w-full' />
                {
                  loading && <div className="absolute inset-0 bg-black opacity-50"></div>
                }
              </div>
            </>
          }
          <div className={screenWidth < 768 ? 'w-[90%] mx-auto mt-[30px]' : 'w-[90%] md:w-auto'}>
            <h5 className='font-poppins font-semibold md:text-[32px] text-[24px] md:leading-10 leading-8 text-secondary tracking-[-0.2px]'>
              Welcome back
            </h5>
            <p className='font-poppins mt-4 mb-7 font-[400] text-base text-textColor'>
              Welcome back! Please enter your details
            </p>
            <p className='font-poppins font-medium text-sm text-[#3F3F46]'>Phone number*</p>
            <input
              required
              type="tel"
              maxLength={10}
              disabled={loading || signup}
              placeholder='+91 000-000-0000'
              value={phoneNumber}
              name='phoneNumber'
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
              onKeyPress={handleKeyPress}
              className={`border-[1px] disabled:cursor-not-allowed disabled:opacity-50 outline-none w-full md:w-[360px] mt-2 login-box-shadow h-[44px] rounded-[4px] border-[#D4D4D8] py-[10px] px-[14px]`}
            />
            <p className='mt-1 text-red-800 font-poppins text-xs font-normal'>{errormsg && errormsg}</p>
            {signup && (
              <>
                <p className='font-poppins font-medium text-sm text-[#3F3F46]'>Username*</p>
                <input
                  required
                  type="text"
                  maxLength={30}
                  disabled={loading}
                  placeholder='Full Name'
                  value={formData?.['userName']}
                  name='userName'
                  onChange={(e) => handleChange(e)}
                  className='border-[1px] outline-none w-full md:w-[360px] mt-2 login-box-shadow h-[44px] rounded-[4px] border-[#D4D4D8] py-[10px] px-[14px]'
                />
                <p className='mt-1 text-red-800 font-poppins text-xs font-normal'>{errors?.['userName'] || requirederrors?.['userName']}</p>
                <p className='font-poppins font-medium text-sm text-[#3F3F46]'>Email*</p>
                <input
                  required
                  type="text"
                  maxLength={30}
                  disabled={loading}
                  placeholder='example@gmail.com'
                  value={formData?.['email']}
                  name='email'
                  onChange={(e) => handleChange(e)}
                  className='border-[1px] outline-none w-full md:w-[360px] mt-2 login-box-shadow h-[44px] rounded-[4px] border-[#D4D4D8] py-[10px] px-[14px]'
                />
                <p className='mt-1 text-red-800 font-poppins text-xs font-normal'>{errors?.['email'] || requirederrors?.['email']}</p>
                <p className='font-poppins font-medium text-sm text-[#3F3F46]'>Gender</p>
                <div className="flex items-center  mt-2">
                <div className='mr-4 flex items-center '>
                <input type="radio" id="male" name="gender" value="male" checked={formData?.['gender'] === 'male'} onChange={(e) => handleChange(e)} className="mr-2" />
                <label htmlFor="male" className='font-poppins font-medium text-sm text-[#3F3F46]'>Male</label>
                </div>
                <div className='mr-4 flex items-center '>
                <input type="radio" id="female" name="gender" value="female" checked={formData?.['gender'] === 'female'} onChange={(e) => handleChange(e)} className="mr-2" />
                <label htmlFor="female" className='font-poppins font-medium text-sm text-[#3F3F46]'>Female</label>
                </div>
                </div>


                <button disabled={loading || openModal}
                  id="login-btn" onClick={checkIsValidUser} className='block w-full md:w-[360px] mt-6 h-[48px] background-primary login-box-shadow rounded-[4px] font-poppins text-white text-base font-medium text-center py-[12px] px-[20px]'>
                  SignUp
                </button>
              </>
            )}

            {!signup && (
              <button disabled={loading || openModal}
                id="login-btn" onClick={checkExistingUser} className='block w-full md:w-[360px] mt-6 h-[48px] background-primary login-box-shadow rounded-[4px] font-poppins text-white text-base font-medium text-center py-[12px] px-[20px]'>
                Login with OTP
              </button>
            )}
            {/* <div className='flex items-center justify-center mt-7'>
          <p className='font-poppins font-normal text-sm text-textColor mr-1'>Don't have an account?</p>
          <button disabled={loading || openModal} onClick={() => navigate(RoutePath.signup)} className='text-primary font-medium text-sm font-poppins'>Sign up</button>
        </div> */}
          </div>
        </div>
        <FirebaseOtpModalLogic checkLoginOrSignup={checkLoginOrSignup} signup={signup} verifyAndLogin={verifyAndLogin} openModal={openModal} setOpenModal={setOpenModal} phoneNumber={phoneNumber} setOtp={setOtp} otp={otp} verifying={verifying} otperrormsg={otperrormsg} setOtpErrormsg={setOtpErrormsg} timerotp={timerotp}
          confirmationResult={confirmationResult}
          setLoading={setLoading}
         
          setConfirmationResult={setConfirmationResult}
          
          handleLoginWithOtp={handleLoginWithOtp}
          settimerotp={settimerotp} />
        {screenWidth >= 768 && (
          <TestimonialCarouselLogic />
        )}
      </div></>
  )
}

export default Login