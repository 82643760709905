import React from 'react'
import Spinner from "./Spinner.jsx";


const SpinnerLogic = () => {
  return (
    <Spinner/>
  )
}

export default SpinnerLogic