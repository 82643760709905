import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useFindCollege from '../../utils/findCollege';
import RoutePath from '../../routes/RoutePath';
import { useSelector } from 'react-redux';

const BreadCrumbs = ({ pathname }) => {
  const [pathParts, setPathParts] = useState([]);
  const [originalParts, setOriginalParts] = useState([]);
  const { findCollegeCode } = useFindCollege();
  const collegeList = useSelector(state => state.dashboard.colleges);

  useEffect(() => {
    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const replaceIdWithName = async (path) => {
      const parts = path.split('/').filter((part) => part !== '');
      const replacedParts = [];
      const originalPartsArray = [];

      for (const part of parts) {
        if (/^\d/.test(part)) { 
          const id = part; // Remove the colon
          const name =  findCollegeCode(id); 
          replacedParts.push(capitalizeFirstLetter(name));
          originalPartsArray.push(part); // Store the original part
        } else {
          replacedParts.push(capitalizeFirstLetter(part.replace(/-|%20/g, ' ')));
          originalPartsArray.push(part); // Store the original part
        }
      }

      setPathParts(replacedParts);
      setOriginalParts(originalPartsArray);
    };

    replaceIdWithName(pathname);
  }, [pathname, collegeList]);

  return (
    <nav className="flex mb-5 w-full flex-wrap justify-start gap-1 space-x-2 items-center text-textColor font-poppins font-medium text-sm">
      <p>
        <Link to="/">Home</Link>
      </p>
      <p>{' > '}</p>
      {pathParts.map((part, index) => {
        let url = '';
        for (let i = 0; i <= index; i++) {
          url += '/' + (/\d/.test(originalParts[i]) ? originalParts[i] : pathParts[i].toLowerCase().replace(/\s/g, '-'));
        }
        return (
          <p key={index}>
            {index === pathParts.length - 1 ? (
              part
            ) : (
              <Link to={url}>{part}</Link>
            )}
            {index !== pathParts.length - 1 && ' > '}
          </p>
        );
      })}
    </nav>
  );
};

export default BreadCrumbs;
