import React from 'react';

const AddressForm = ({
  data,
  selectedState,
  selectedCity,
  handleStateChange,
  handleCityChange,
  handleDataChange,
  states,
  cities,
  errors,
  setData,
  formType,
  requiredFieldErrors
}) => {
  return (
    <div className='md:mt-6 flex md:flex-row md:space-x-3 flex-col justify-evenly w-full'>
      <div className='flex mt-6 md:mt-0 md:w-1/3 w-full flex-col'>
        <p className='text-[#3F3F46] font-medium font-poppins text-sm'>State</p>
        <select
          value={selectedState[formType]}
          onChange={(e)=>handleStateChange(e, setData, data)}
          className='border cursor-pointer mt-1 border-[#D4D4D8] blog-button-shadow text-textColor outline-none font-poppins text-base font-normal w-full rounded-[4px] py-[10px] px-[14px]'>
          <option value="">Enter your state</option>
          {states &&
            states.map((ele, ind) => (
              <option key={ind} value={ele?.state}>
                {ele?.state}
              </option>
            ))}
        </select>
       {
        requiredFieldErrors[formType]?.["state"] &&
         <p className='text-red-500 text-sm font-poppins mt-1 font-normal'>{requiredFieldErrors[formType]["state"]}</p>
       }
      </div>
      <div className='flex mt-6 md:mt-0 md:w-1/3 w-full flex-col'>
        <p className='text-[#3F3F46] font-medium font-poppins text-sm'>City</p>
        <select
          value={selectedCity[formType]}
          onChange={(e)=>handleCityChange(e, setData, data)}
          className='border cursor-pointer mt-1 border-[#D4D4D8] blog-button-shadow text-textColor outline-none font-poppins text-base font-normal w-full rounded-[4px] py-[10px] px-[14px]'>
          <option value="">Enter your city</option>
          {cities[formType] &&
            cities[formType].map((ele, ind) => (
              <option key={ind} value={ele?.city}>
                {ele?.city}
              </option>
            ))}
        </select>
        {
          requiredFieldErrors[formType]?.["city"] &&
          <p className='text-red-500 text-sm font-poppins mt-1 font-normal'>{requiredFieldErrors[formType]["city"]
        }</p>
        }
      </div>
      <div className='flex mt-6 md:mt-0 flex-col'>
        <p className='text-[#3F3F46] font-medium font-poppins text-sm'>Pin code</p>
        <input
          name='pinCode'
          onChange={(e) => handleDataChange("permanentAddress.pinCode", e, data, setData, formType)}
          value={data.permanentAddress?.pinCode}
          placeholder='Enter PIN code'
          type='number'
          className='border mt-1 border-[#D4D4D8] blog-button-shadow text-textColor font-poppins text-base font-normal outline-none w-full rounded-[4px] py-[10px] px-[14px]'
        />
        <p className='text-red-500 text-sm font-poppins mt-1 font-normal'>{errors[formType]?.["pinCode"] || requiredFieldErrors[formType]?.["pinCode"]}</p>
      </div>
    </div>
  );
};

export default AddressForm;
