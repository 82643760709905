import React from 'react';
import { Result } from 'antd';
import { useNavigate } from 'react-router-dom';
import RoutePath from '../../routes/RoutePath';
const PaymentSuccessful = () => {

  const navigate = useNavigate();
  return (
    <Result
      className='h-screen'
      status="success"
      title={<p className='text-5xl font-semibold font-poppins' >Payment Successful</p>}
      subTitle={<p className='text-xl font-poppins' >Your payment has been done successfully</p>}
      extra={<button onClick={() => navigate(RoutePath.homepage)} className='bg-orange-400 py-3 px-6 text-xl text-white rounded-xl'>Go to Home</button>}
    />
  )

};
export default PaymentSuccessful;