import 'react-toastify/dist/ReactToastify.css';
import { showErrorToast} from './toastNotif';


export const handleOtpChange = (index, value, setOtp, formType) => {
  setOtp((prevOtp) => {
    const newOtp = prevOtp.split('');
    newOtp[index] = value;
    return newOtp.join('');
  });
  if (index < 3 && value !== '') {
    document.getElementById(`${formType}-otp-input-${index + 1}`).focus();
  }
};

export const handleVerifyOtp = (setotperrormsg, setverified, otp) => {
  setotperrormsg("");
  if (otp?.length < 4) {
    setotperrormsg("Enter valid otp");
    return
  }
  setverified(true)
}

// CHECK EXISTING USER API CALL
export const handleLoginWithOtp = async (phoneNumber, setLoading, setOpenModal, setConfirmationResult, id, settimerotp) => {
  if (id === 'stud-resend-btn') {
    settimerotp(10);
  }
  if (!phoneNumber) {
    showErrorToast("phoneNumber cannot be empty");
    return;
  }

  try {
    console.log("trying")
    setLoading(true);

    const containerElement = document.getElementById(id);
    if (!containerElement || containerElement.childElementCount !== 0) {
      console.error(`Error: DOM container element with ID '${id}' either does not exist or is not empty.`);
      showErrorToast("Something went wrong, please try again or refresh the page")
      return;
    }


    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/users/checkPhoneNumber?phone=${phoneNumber}&userType=user`);
    const data = await response.json();
    console.log(data);
    if (data.status === 'USER_EXISTS') {
      setOpenModal(true)
    }

    setConfirmationResult(data);





  } catch (error) {
    console.error("Error sending OTP:", error);
    //  if(!newRecaptchaVerifier){
    //    newAppVerifier = new RecaptchaVerifier(auth, id, {
    //   size: "invisible",
    // });
    // setRecaptchaVerifier(newAppVerifier);
    //  }
    showErrorToast("Error sending otp, kindly refresh the page")
  } finally {
    setLoading(false);
  }
}

// CHECK VALID USER BEFORE SIGNUP
export const handleISValidUser = async (formData, phoneNumber,setLoading, setOpenModal, setConfirmationResult, id, settimerotp) =>{
  if (id === 'stud-resend-btn') {
    settimerotp(30);
  }
  if (!formData) {
    showErrorToast("user cannot be empty");
    return;
  }

  try {
    console.log("trying")
    setLoading(true);

    const containerElement = document.getElementById(id);
    if (!containerElement || containerElement.childElementCount !== 0) {
      console.error(`Error: DOM container element with ID '${id}' either does not exist or is not empty.`);
      showErrorToast("Something went wrong, please try again or refresh the page")
      return;
    }

    const user = {
      email : formData.email,
      name : formData.userName,
      phoneNumber : phoneNumber
    }
    console.log(user);
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/users/isValidUser`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({user})
    });
    const data = await response.json();
    console.log(data);
    if (data.status === 'OTP sent') {
      setOpenModal(true)
    }
    console.log(data);
    setConfirmationResult(data);





  } catch (error) {
    console.error("Error sending OTP:", error);
    //  if(!newRecaptchaVerifier){
    //    newAppVerifier = new RecaptchaVerifier(auth, id, {
    //   size: "invisible",
    // });
    // setRecaptchaVerifier(newAppVerifier);
    //  }
    showErrorToast("Error sending otp, kindly refresh the page")
  } finally {
    setLoading(false);
  }
}

export const handleFirebaseOTPVerification = async (confirmationResult, otp, setOtpErrormsg, handleAction, verifying, setVerifying, setOpenModal) => {
  setOtpErrormsg("");
  if (otp === "") {
    setOtpErrormsg("Kindly enter OTP");
    return;
  }
  try {
    setVerifying(true);
    // await confirmationResult
    //   .confirm(otp)
    //   .then(async (userCredential) => {
    //     const user = userCredential.user;
    //     await handleAction(user);
    //   })
    //   .catch((error) => {
    //     setOtpErrormsg("Invalid OTP");
    //     console.error("Error:", error);
    //   });

    const requestBody = {
      otp: '123456', // Replace with actual OTP
      mobile: '1234567890', // Replace with actual mobile number
      userType: 'customer' // Replace with actual user type
    };

    const response = await fetch('/api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const responseData = await response.json();
    console.log('Response:', responseData);

  } catch (error) {
    console.error("Error verifying OTP:", error);
    showErrorToast("Error verifying otp, kindly try again after some time")
    setOpenModal(false)
  }
  finally {
    setVerifying(false)
  }
};
