import React from 'react'
import SubNavbarCollegeLogic from '../../components/subNavbarCollege/SubNavbarCollegeLogic'
import PaginationLogic from '../../components/pagination/PaginationLogic';
import RoutePath from '../../routes/RoutePath';
import SpinnerLogic from '../../components/spinner/SpinnerLogic';
import useFindCollege from '../../utils/findCollege';
import { LOCAL_ASSIST_ITEMS_PER_PAGE } from '../../utils/constants';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import WhatsappContactLogic from '../../components/whatsappContact/WhatsappContactLogic';
import LocalAssistCardLogic from '../../components/localAssistCard/LocalAssistCardLogic';
import FiltersLogic from '../../components/filters/FiltersLogic'


const LocalAssistByCollege = ({setMinRent, setMaxRent, minRent, maxRent, collegeId, isLocalAssistAvailable, navigate, localAssistData, currentPage, handlePageChange, status, sortValue, setSortValue, setApibody, apibody, setCharge, sortOptions, setCategoryId, categoryId }) => {
  const {findCollegeName} = useFindCollege();
  const dispatch = useDispatch();
  const tokenId = useSelector(state=>state.auth.tokenId)
  return (
    <div>
    
        <WhatsappContactLogic/>
      <SubNavbarCollegeLogic
        collegeId={collegeId}
      />
       
        {
         status === "failed" ?  <div className='flex flex-col w-full min-h-screen  items-center justify-center'>
         <div className='text-center'>
         <h1 className="text-secondary font-poppins font-semibold text-5xl">Internal server error</h1>
          <p className="text-2xl font-poppins text-textColor mt-6 font-normal">We are facing some internal server error while fetching data, sorry for the inconvinience caused</p>
          <button onClick={()=>navigate(RoutePath.homepage)} className='blog-button-shadow background-primary mt-10 text-white text-lg rounded-[4px] px-4 py-2 font-medium font-poppins'>Go Back</button>
         </div>
          </div>  
         : <>
         {
          status === "loading" ? <><div className="absolute min-h-screen inset-0 bg-black opacity-50"></div><div className='absolute  z-30 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'><SpinnerLogic /></div></> : <>
           <div className='py-6 px-5  md:px-20 w-full flex items-center space-x-5'>
        <FiltersLogic localAssistCategories={localAssistData?.categoryDetails} setMaxRent={setMaxRent} setMinRent={setMinRent} minRent={minRent} maxRent={maxRent} setCharge={setCharge} sortValue={sortValue} setSortValue={setSortValue} sortOptions={sortOptions} setApibody={setApibody} apibody={apibody} serviceType={"localAssistance"} categoryId={categoryId} setCategoryId={setCategoryId}/></div>
           {
          !isLocalAssistAvailable || (localAssistData?.localAssistances?.length === 0)   ?
           <div className='min-h-screen w-full flex flex-col items-center justify-center'>
             <div className="text-center">
               <h1 className="text-secondary font-poppins font-semibold text-5xl">No local assistance available</h1>
               <p className="text-2xl font-poppins text-textColor mt-6 font-normal">There are currently no local assistances available, kindly check back later for updates</p>
               <button onClick={() => navigate(RoutePath.homepage)} className='blog-button-shadow background-primary mt-10 text-white text-lg rounded-[4px] px-4 py-2 font-medium font-poppins'>Go Back</button>
             </div>
           </div> :
           <>
            <div className='w-full md:px-20 md:pt-16 md:pb-24 px-5 py-6'>
               <p className="font-poppins text-secondary font-medium text-xs md:text-base">{localAssistData?.totalLocalAssistances} Local Assist <span className="text-textColor md:text-base text-sm font-normal"> near {findCollegeName(collegeId)}</span></p>
               {
                 localAssistData?.localAssistances?.map((ele, ind)=>(
                   <div key={ind} className='flex flex-col w-full'>
                  <LocalAssistCardLogic key={ind} ele={ele} id={collegeId} dispatch={dispatch} tokenId={tokenId}/>
                   <hr className='text-[#D4D4D8] mt-[30px] w-full'/>
                   </div>
                 ))
               }
               <PaginationLogic totalPages={Math.ceil(localAssistData?.totalLocalAssistances / LOCAL_ASSIST_ITEMS_PER_PAGE)} currentPage={currentPage} handlePageChange={handlePageChange} />
             </div>
          
           </>
         }
          </>
         }
        
         </>
        }
      </div>
 
  )
}

export default LocalAssistByCollege