const validationRules = {
    email : {
        regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        message: "Kindly enter valid email"
    },

    phoneNumber : {
        regex: /^[0-9]{10}$/,
        message: "Kindly enter valid phone number"
    },

    name : {
      regex: /^[a-zA-Z\s]{2,30}$/,
      message: "kindly enter valid name"
    },

    pinCode: {
        regex: /^[0-9]{6}$/,
        message: "kindly enter valid pincode"
        
      },

      rent: {
        regex: /^\d+$/, 
        message: "Rent cannot be negative"
      },

    security: {
        validate: (value, formData) => {
          const rentValue = formData.rent || 0;
          const securityValue = value || 0;
          return parseInt(securityValue, 10) <= 2 * parseInt(rentValue, 10);
        },
        message: 'Security should not exceed 2 times the rent value',
       
      },  
}

export default validationRules;