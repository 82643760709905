import React, {useState, useEffect} from 'react'
import Blogs from './Blogs'
import { useSelector } from 'react-redux';

const BlogsLogic = () => {
    const [activeDot, setActiveDot] = useState(0);
    const screenWidth = useSelector(state=>state.screenWidth.screenWidth);
    const blogData = useSelector(state=>state.dashboard.blogs);

    useEffect(() => {
        const handleScroll = () => {
          const blogContainer = document.getElementById('blog-container');
          if (blogContainer) {
            const scrollPosition = blogContainer.scrollLeft;
            const containerWidth = blogContainer.clientWidth;
            const totalWidth = blogContainer.scrollWidth;
            const totalDots = 5;
            const activeIndex = Math.floor((scrollPosition / (totalWidth - containerWidth)) * (totalDots - 1));
        
            setActiveDot(activeIndex);
          }
        };
    
        document.getElementById('blog-container')?.addEventListener('scroll', handleScroll);
        return () => {
          document.getElementById('blog-container')?.removeEventListener('scroll', handleScroll);
        };
      }, []);
      const dotColors = [
        '#EF7C22',
        'rgba(248, 159, 89, 0.6)',
        'rgba(248, 159, 89, 0.4)',
        'rgba(248, 159, 89, 0.25)',
        'rgba(254, 209, 174, 0.6)',
      ];
      

  return (
    <><Blogs
    blogData={blogData}
    screenWidth={screenWidth}
    activeDot={activeDot}
    dotColors={dotColors}
    /></>
  )
}

export default BlogsLogic