import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchFurnishings = createAsyncThunk(
    'furnishings/fetchFurnishings',
    async ({apibody})=>{
        try{
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/furnishing/getAllFurnishings`, {
                method: 'POST',
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(apibody)
            });
            if (response === null) {
                console.log("Response is null");
                throw new Error("Response is null");
            }
            if(!response.ok){
                console.log(`Error fetching furnishings data: ${response.status} - ${response.statusText}`);
                throw new Error(`Error fetching furnishings data: ${response.status} - ${response.statusText}`);
            }
            const data = await response.json();
            if (data === null || data === undefined) {
                console.log("Data is null or undefined")
                throw new Error("Data is null or undefined");
            }
            return data;
        }catch(error){
            console.log("Error fetching furnishings data", error);
            throw error; 
        }
    }
);

const getFurnishingsSlice = createSlice({
    name: "getFurnishings",
    initialState: {
        furnishingData: [],
        status: 'idle',
        error: null
    },

    reducers: {},
    extraReducers: (builder) => {
        builder
          .addCase(fetchFurnishings.pending, (state) => {
            state.status = 'loading';
          })
          .addCase(fetchFurnishings.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.furnishingData = action.payload;
            
          })
          .addCase(fetchFurnishings.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
          });
      }
})

export default getFurnishingsSlice.reducer;