import React, {useState} from 'react'
import { useSelector } from 'react-redux';

import flag from "../../assets/images/indianFlag.png"

const ShippingAddressForm = ({editedAddress, handleChange, cities, isChecked, setIsChecked, handleSave}) => {
    const statesData = useSelector(state=>state.states)
  
    
  return (
    <div className='flex flex-col w-full space-y-[15px] items-start'>
        <div className='w-full'>
            <p className='font-poppins font-medium text-sm text-[#3F3F46]'>Name</p>
            <input type="text" name="name" value={editedAddress?.name} onChange={handleChange} placeholder='Enter Your Name' className='w-full outline-none text-textColor font-poppins text-base font-normal mt-2 blog-button-shadow border border-[#D4D4D8] rounded py-[10px] px-[14px] '/>
        </div>
        <div className='w-full'>
            <p className='font-poppins font-medium text-sm text-[#3F3F46]'>Phone number</p>
            <div className='border mt-1 flex justify-between items-center outline-none  w-full rounded-[4px] py-[10px] px-[14px] border-[#D4D4D8] blog-button-shadow'><div className='flex w-[60%] min-[360px]:w-auto items-center space-x-2'><div className='flex items-center space-x-1'><img src={flag} alt="india" className='w-5' /><p className='text-[#27272A] font-poppins w-10 min-[360px]:text-base text-sm font-medium'>+91</p></div><input  name='phoneNumber' value={editedAddress?.phoneNumber} onChange={handleChange} placeholder='000-000-0000' type="number" className=' text-textColor outline-none font-poppins min-[360px]:text-base text-sm font-normal'/></div></div>
        </div>
        <div className='w-full'>
            <p className='font-poppins font-medium text-sm text-[#3F3F46]'>Address line</p>
            <input type="text" placeholder='Enter Your address' name="addressLine1" value={editedAddress?.addressLine1} onChange={handleChange} className='w-full outline-none text-textColor font-poppins text-base font-normal mt-2 blog-button-shadow border border-[#D4D4D8] rounded py-[10px] px-[14px] '/>
        </div>

        <div className='flex flex-col w-full md:space-x-4  md:flex-row items-center'>
        <div className='w-full md:w-1/3'>
            <p className='font-poppins font-medium text-sm text-[#3F3F46]'>State</p>
            <select  placeholder='Select a State' name="state" value={editedAddress?.state} onChange={handleChange} className='w-full outline-none text-textColor font-poppins text-base font-normal mt-2 blog-button-shadow border border-[#D4D4D8] rounded py-[10px] px-[14px] '>
            {
              statesData && statesData.map((ele, ind)=>(
                <option key={ind} value={ele?.state}>{ele?.state}</option>
              ))
             }
            </select>
        </div>
        <div className='w-full md:w-1/3'>
            <p className='font-poppins font-medium text-sm text-[#3F3F46]'>City</p>
            <select placeholder='Select a City' name="city" value={editedAddress?.city} onChange={handleChange} className='w-full outline-none text-textColor font-poppins text-base font-normal mt-2 blog-button-shadow border border-[#D4D4D8] rounded py-[10px] px-[14px] '>
            {
                cities && cities.map((city) => (
                  <option key={city.id} value={city.city}>
                    {city.city}
                  </option>  ))
              }
            </select>
        </div>
        <div className='w-full md:w-1/3'>
            <p className='font-poppins font-medium text-sm text-[#3F3F46]'>Pincode</p>
            <input type="number" placeholder='Enter Your pincode' name="pinCode" value={editedAddress?.pinCode} onChange={handleChange} className='w-full outline-none text-textColor font-poppins text-base font-normal mt-2 blog-button-shadow border border-[#D4D4D8] rounded py-[10px] px-[14px] '/>
        </div>
        </div>
       <div className='mt-5 space-x-2 flex items-center'>
       <label className="flex items-center">
         <input

        type="checkbox"
        className="hidden"
        checked={isChecked}
        onChange={()=>setIsChecked(!isChecked)}
      />
       <span
        className={`relative inline-block w-5 h-5 border border-[#D4D4D8] rounded-md cursor-pointer ${
          isChecked ? 'background-secondary' : ''
        }`}
      >
        {isChecked && (
          <svg
            className="w-4 h-4 text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 13l4 4L19 7"
            ></path>
          </svg>
        )}
      </span>
    </label>
    <p className='text-secondary font-poppins text-sm font-medium'>Save for future use</p>
       </div>
       <hr className='w-full text-[#E4E4E7] my-5' />
       <button onClick={handleSave} className='background-primary blog-button-shadow py-2 px-3 rounded text-white'>Save</button>
    </div>
  )
}

export default ShippingAddressForm