import React, {useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import RoutePath from '../../routes/RoutePath';
import { useSelector } from 'react-redux';
import { showErrorToast, showSuccessToast } from '../../utils/toastNotif';
import { ORDERS_ITEMS_PER_PAGE } from '../../utils/constants';
import Order from './Order'
import { convertToformat } from '../../utils/formatDate';

const OrderLogic = () => {
  const [ordersData, setOrdersData] = useState([]);
  const screenWidth = useSelector(state=>state.screenWidth.screenWidth)
  const { status, categoriesData, error } = useSelector(state => state.getCategories);
  const navigate = useNavigate();
  const userId = localStorage.getItem("user_id");
  const [expandedOrders, setExpandedOrders] = useState({});
  const [activeStep, setActiveStep] = useState(1);
  const totalSteps = 3;
  const [viewStatusOpen, setViewStatusOpen] = useState({});
  const [selectedDate, setSelectedDate] = useState('');
  const [showDatePicker, setShowDatePicker] = useState(true);
  const token = localStorage.getItem('token');
  const [currentPage, setCurrentPage] = useState(1);
  const [failed, setFailed] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const stepLabels = ['Order Placed', 'Dispatched', 'Delivery'];
  const [apibody, setApibody] = useState({sortKey: "_id", sortValue: -1, currentPage: currentPage, itemsPerPage: ORDERS_ITEMS_PER_PAGE, userId: userId})
  useEffect(()=>{

   if(!userId || userId === "undefined"){
      showErrorToast("kindly login again to continue");
      navigate(RoutePath.login, {state: {data: location?.pathname + location?.search}});
      return;
   }
   const fetchData = async()=>{
  
    try{
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/order/getAllOrdersOfAUser`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },

        body: JSON.stringify(apibody)
      });

      if(response === null){
        throw new Error('Response is null');
      }

      if(!response.ok){
       if(response.status === 401){
        showErrorToast("kindly login again to continue");
        navigate(RoutePath.login, {state: {data: location?.pathname + location?.search}});
        return;
       }else{
        console.log(response.status);
        throw new Error("Response not ok")
       }
      }

      const data = await response.json();
      if(data === null || data === undefined){
        throw new Error('data is null');
      }
      setOrdersData(data);
      // console.log(data)
      setFailed(false);
     }catch(error){
      console.log(error);
      showErrorToast("Error fetching orders data");
      setFailed(true);
     }finally{
      setLoading(false);
     }
   }
   fetchData();
  },[apibody, currentPage])

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setApibody(prevState => ({...prevState, currPage: page}));
  };

  const handleCancelItems = async(orderId, productId)=>{
   

     try{
        setLoading(true);
        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/order/returnOrCancelOrder`, {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
  
          body: JSON.stringify({
            orderId: orderId,
            productDetailsId: productId,
            action: "cancel"
          })
        });
  
        if(response === null){
          throw new Error('Response is null');
        }
  
        if(!response.ok){
         if(response.status === 401){
          showErrorToast("kindly login again to continue");
          navigate(RoutePath.login, {state: {data: location?.pathname + location?.search}});
          return;
         }else{
          console.log(response.status);
          throw new Error("Response not ok")
         }
        }
  
        const data = await response.json();
        if(data === null || data === undefined){
          throw new Error('data is null');
        }
        showSuccessToast("order has been successfully placed for return")
       
  
     }catch(error){
        showErrorToast("Something went wrong while trying to return items, please try again later");
     }finally{
      setLoading(false)
     }
  }

  const handleApplyFilter = ()=>{
    let date = ""
    if(selectedDate){
      date =  convertToformat(selectedDate)
    }
   setApibody({...apibody, categoryId: categoryId, startDate: date ? date : "" })
  }

  const handleClear = ()=>{
    setCategoryId("");
    setApibody({sortKey: "_id", sortValue: -1, currentPage: currentPage, itemsPerPage: ORDERS_ITEMS_PER_PAGE, userId: userId})
  }
 

    const handleDateChange = (date) => {
      setSelectedDate(date);
      setShowDatePicker(false);
      if(screenWidth >= 768){
        let newdate = ""
       
    if(date){
      newdate =  convertToformat(new Date(date))
      setApibody({...apibody, startDate: newdate ? newdate : ""})
    }
        
      }
    };


    const handleStepChange = (step) => {
      setActiveStep(step);
    };

   const toggleExpand = (orderId) => {
    setExpandedOrders((prevExpanded) => ({
      ...prevExpanded,
      [orderId]: !prevExpanded[orderId],
    }));
  };

  
  return (
    <><Order totalSteps={totalSteps}
    stepLabels={stepLabels}
    handleStepChange={handleStepChange}
    activeStep={activeStep} showDatePicker={showDatePicker} viewStatusOpen={viewStatusOpen} setViewStatusOpen={setViewStatusOpen} toggleExpand={toggleExpand} expandedOrders={expandedOrders}   setShowDatePicker={setShowDatePicker} handleClear={handleClear}  handleDateChange={handleDateChange} selectedDate={selectedDate} categoryId={categoryId} setCategoryId={setCategoryId} handleApplyFilter={handleApplyFilter} loading={loading} handleCancelItems={handleCancelItems} apibody={apibody} currentPage={currentPage} handlePageChange={handlePageChange} setApibody={setApibody} ordersData={ordersData} failed={failed} categoriesData={categoriesData}/></>
  )
}

export default OrderLogic