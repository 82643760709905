import React from 'react'
import { useNavigate } from 'react-router'
import RoutePath from '../../routes/RoutePath';

const ComingSoon = ({message}) => {
  const navigate = useNavigate();
  return (
    <div className="min-h-screen flex items-center justify-center bg-white">
    <div className="text-center">
      <h1 className="text-secondary font-poppins font-semibold text-5xl">Coming Soon</h1>
      <p className="text-2xl font-poppins text-textColor mt-6 font-normal">{message ? message : "Something awesome is on the way! Stay tuned for updates."}</p>
      <button onClick={()=>navigate(RoutePath.homepage)} className='blog-button-shadow background-primary mt-10 text-white text-lg rounded-[4px] px-4 py-2 font-medium font-poppins'>Go Back</button>
    </div>
  </div>
  )
}

export default ComingSoon