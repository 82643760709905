export const checkForErrors = (errors, formData, requiredFields, setrequirederrors) => {
    for (const key in errors) {
      if (errors[key]) {
        return true; 
      }
    }
  
    const newRequiredErrors = {};
    for (const field of requiredFields) {
      newRequiredErrors[field] = formData[field] === "" ? "This field is required" : "";
    }
    setrequirederrors(newRequiredErrors);
  
    const hasRequiredFieldError = Object.values(newRequiredErrors).some((error) => error !== "");
    if (hasRequiredFieldError) {
      return true; 
    } else {
      return false; 
    }
  };