import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchContactUs = createAsyncThunk(
    'contactUs/fetchContactUs',
    async ({apiBody}) => {
        try{
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/contactUs/submitQuery`,{
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                
                body: JSON.stringify(apiBody)
            });

            if (response === null) {
                console.log("Response is null");
                throw new Error("Response is null");
            }
            if(!response.ok){
                console.log(`Error fetching contact us api: ${response.status} - ${response.statusText}`);
                throw new Error(`Error fetching contact us api: ${response.status} - ${response.statusText}`);
            }

            const data = await response.json();

            if (data === null || data === undefined) {
                console.log("Data is null or undefined")
                throw new Error("Data is null or undefined");
            }
            return data;

        }catch(error){
        console.log("Error fetching contact us api", error);
        throw error;}
    }
);

const contactUsSlice = createSlice({
    name: "contactUs",
    initialState: {
        queryDetails: [],
        status: 'idle',
        error: null
    },

    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchContactUs.pending, (state) => {
            state.status = 'loading';
          })

        .addCase(fetchContactUs.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.queryDetails = action.payload;
         })

          .addCase(fetchContactUs.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
          });
    }
});

export default contactUsSlice.reducer