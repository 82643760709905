import React from 'react'
import WhatsappContactLogic from '../../components/whatsappContact/WhatsappContactLogic'
import rightarrow from "../../assets/images/rightarrowIcon.png"
import up from "../../assets/images/upArrow.svg"
import down from "../../assets/images/downArrow.svg"

const ChooseServiceType = ({serviceType, setserviceType, serviceTypeOptions, setFormData})=>{
    return(
        <div className='bg-white relative w-[90%] mx-auto md:w-auto h-screen flex flex-col items-center'>
         
         <div className='md:w-[624px] w-full md:mt-[60px] mt-[10px] mx-auto flex flex-col items-start'>
            <h2 className='font-poppins text-secondary font-semibold md:text-[32px] text-xl md:leading-10'>Choose your service</h2>
            <div className='grid md:grid-cols-2 mt-8 grid-cols-1 gap-4 w-full'>
                {
                    serviceTypeOptions && serviceTypeOptions.map((ele, ind)=>(
                        <div onClick={()=>{
                          setserviceType(ele?.name);
                          setFormData({serviceType: ele?.name})
                        }} key={ind} className='col-span-1 hover:bg-[#FAFAFA]  hover-secondary-border cursor-pointer space-x-3 flex blog-button-shadow items-center border border-[#E4E4E7] rounded-lg p-6'>
                            <div className='p-[8px] bg-[#F4F4F5] rounded-[4px]'>
                                <img src={ele?.img} alt={ele?.name} />
                            </div>
                            <p className='font-poppins font-medium text-secondary text-base'>{ele?.name}</p>
                        </div>
                    ))
                }
            </div>
         </div>
        </div>
    )
}

const ServiceForm = ({serviceType, setserviceType, screenWidth, handleChange, handleSubmit, formData ,fields, isDropdownOpen,
  toggleDropdown,
  selectedTenures,
  handleTenureChange,
  handleRemoveTenure, 
  setFormData
})=>{
  const househelpTypeOptions = ['Cook', 'Maid', 'Plumber', 'Laundry', 'Electrician'];

    return(
        <>
        <div className='bg-white min-h-screen mb-36 flex flex-col items-center  w-full md:mt-[60px]'>
      
          <div className='flex md:w-[624px] w-[90%] mx-auto flex-col items-start'>
          <div className='flex mt-5 md:mt-0 items-center space-x-1'>
            <p className='font-medium font-poppins  text-sm text-textColor'>Choose your service</p>
            <img src={rightarrow} alt=">" />
            <p className='font-medium font-poppins text-sm text-textColor'>{serviceType}</p>
          </div>
          <h2 className='text-secondary mt-2 md:text-[32px] md:leading-10 text-lg font-poppins md:mb-6 mb-4 font-semibold'>Add your <span className='lowercase'>{serviceType}</span> details</h2>
          {fields.map((field, index) => (
          <div className='w-full' key={index}>
            {field.name === 'type' && serviceType === 'House Help' && (
            <div className='mt-5'>
              <label className='text-[#3F3F46] font-poppins mb-2 text-sm font-medium'>
                {field.label} {field.required && '*'}
              </label>
              <select
                className='w-full cursor-pointer rounded-[4px] outline-none blog-button-shadow text-base text-textColor font-normal font-poppins border border-[#D4D4D8] py-[10px] px-[14px]'
                value={formData[field.name] || ''}
                onChange={(e) => handleChange(e, field.name)}
                required={field.required}
              >
                {househelpTypeOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              {serviceType === 'House Help' && formData['type'] && (
        <>
          <p className='text-[#3F3F46] font-poppins mb-2 mt-5 text-sm font-medium'>
            Enter {formData['type']}'s Address *
          </p>
          <input
            className='w-full rounded-[4px] outline-none blog-button-shadow text-base text-textColor font-normal font-poppins border border-[#D4D4D8] py-[10px] px-[14px]'
            type='text'
            placeholder={`Enter ${formData['type']}'s Address`}
            value={formData[`${formData['type']}Address`] || ''}
            onChange={(e) => handleChange(e, `${formData['type']}Address`)}
            required
          />
          <p className='text-[#3F3F46] font-poppins mb-2 mt-5 text-sm font-medium'>
            Enter {formData['type']}'s Charges *
          </p>
          <input
            className='w-full rounded-[4px] outline-none blog-button-shadow text-base text-textColor font-normal font-poppins border border-[#D4D4D8] py-[10px] px-[14px]'
            type='number'
            placeholder={`Enter ${formData['type']}'s Charges`}
            value={formData[`${formData['type']}Charges`] || ''}
            onChange={(e) => handleChange(e, `${formData['type']}Charges`)}
            required
            min={1}
          />
          <p className='text-[#3F3F46] font-poppins mb-2 mt-5 text-sm font-medium'>
            Enter {formData['type']}'s Details *
          </p>
          <textarea
            className='w-full h-32 rounded-[4px] outline-none blog-button-shadow text-base text-textColor font-normal font-poppins border border-[#D4D4D8] py-[10px] px-[14px]'
            type='text'
            placeholder={`Enter ${formData['type']}'s Details`}
            value={formData[`${formData['type']}Details`] || ''}
            onChange={(e) => handleChange(e, `${formData['type']}Details`)}
            required
          ></textarea>
        </>
      )}
            </div>
          )}
            
             {field.type === 'multipleSelect' && serviceType !== 'House Help' ? (
            <div className="mt-5 mb-32">
              <label className="text-[#3F3F46] font-poppins mb-2 text-sm font-medium">
                {field.label} {field.required && '*'}
              </label>
              <div className="relative">
              {selectedTenures?.length > 0
                    && selectedTenures?.map((tenure) => (
                      <span key={tenure} className="mr-2 flex font-poppins text-textColor text-xs items-center">
                      {tenure}
                      <span
                        className="cursor-pointer ml-2"
                        onClick={() => handleRemoveTenure(tenure)}
                      >
                        ❌ 
                      </span>
                    </span>
                      ))
                    }
                <div
                  
                  className="w-full flex items-center justify-between rounded-[4px] outline-none blog-button-shadow text-base text-textColor font-normal font-poppins border border-[#D4D4D8] py-[10px] px-[14px] cursor-pointer"
                >
                  <span>{field.placeholder}</span>
                  <img onClick={toggleDropdown} src={isDropdownOpen ? up : down} alt="" />
                </div>
                {isDropdownOpen && (
                  <select
                    multiple
                    className="absolute top-full left-0 w-full no-scrollbar rounded-[4px] outline-none blog-button-shadow text-base text-textColor font-normal font-poppins border border-[#D4D4D8] py-[10px] px-[14px] z-10"
                    value={selectedTenures?.map((option) => option.value)}
                    onChange={(e) => handleTenureChange(Array.from(e.target.selectedOptions, (option) => option?.value))}
                  >
                    {field.options?.map((option) => (
                      <option key={option?.value} value={option?.value}>
                        {option?.label}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            </div>
          ) : (
                <>
                {
                  serviceType === 'House Help' ? (field.name=== 'phoneNumber' || field.name === 'name') && <>
                   <p className='text-[#3F3F46] font-poppins mb-2 mt-5 text-sm font-medium'>
              {field.label} {field.required && '*'}
            </p>
            <input
              className='w-full rounded-[4px] outline-none blog-button-shadow text-base text-textColor font-normal font-poppins border border-[#D4D4D8] py-[10px] px-[14px]'
              type={field.type || 'text'}
              placeholder={field.placeholder}
              value={formData[field.name] || ''}
              onChange={(e) => handleChange(e, field.name)}
              required={field.required}
              min={field.min}
              max={field.max}
            />
                  </>
                  :
                  <>
                   <p className='text-[#3F3F46] font-poppins mb-2 mt-5 text-sm font-medium'>
              {field.label} {field.required && '*'}
            </p>
            <input
              className='w-full rounded-[4px] outline-none blog-button-shadow text-base text-textColor font-normal font-poppins border border-[#D4D4D8] py-[10px] px-[14px]'
              type={field.type || 'text'}
              placeholder={field.placeholder}
              value={formData[field.name] || ''}
              onChange={(e) => handleChange(e, field.name)}
              required={field.required}
              min={field.min}
              max={field.max}
            />
                  </>
                }
            {field.name === 'phoneNumber' && <hr className="mt-7 border-[#E4E4E7]" />}
                </>
          )}
           
          </div>
        ))}
        
          </div>
          <div className={`${screenWidth < 768 && 'submit-button'} bg-white z-10 fixed bottom-0 w-full left-0 py-4 px-[18px] md:border-t md:border-[#E4E4E7] flex justify-between items-center`}>
            <button onClick={()=>{
              setserviceType('');
              setFormData({serviceType: serviceType})
          }} className='w-[48%] md:w-auto hover:bg-[#FAFAFA] font-poppins text-base font-medium text-[#3F3F46] px-5 py-3 rounded-[4px] border border-[#E4E4E7] blog-button-shadow'>Back</button>
            <button onClick={handleSubmit} className='w-[48%] md:w-auto py-3 px-5 blog-button-shadow text-base font-medium background-primary rounded-[4px] text-white font-poppins'>Submit</button>
        </div>
        </div>
        
        </>
    )
}

const ServicePage = ({serviceType, setserviceType, serviceTypeOptions, screenWidth, handleChange, formData,setFormData, handleSubmit, selectedTenures, isDropdownOpen, toggleDropdown, handleTenureChange, handleRemoveTenure}) => {
  return (
    <>
    <WhatsappContactLogic/>
    {
        !serviceType ?
        <ChooseServiceType serviceType={serviceType} setserviceType={setserviceType} serviceTypeOptions={serviceTypeOptions} setFormData={setFormData}/>
        : 
        <>
        <ServiceForm 
        serviceType={serviceType} 
        setserviceType={setserviceType} 
        screenWidth={screenWidth}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        formData={formData}
        isDropdownOpen={isDropdownOpen}
        toggleDropdown={toggleDropdown}
        selectedTenures={selectedTenures}
        handleTenureChange={handleTenureChange}
        handleRemoveTenure={handleRemoveTenure}
        setFormData={setFormData}
        fields={[
            { name: 'name', label: 'Enter your name',placeholder: 'Enter your name', required: true },
            { name: 'phoneNumber', label: 'Enter phone number',placeholder: 'Enter phone number', required: true },
            { name: 'type', label: `Enter ${serviceType} type`, placeholder: `Enter ${serviceType} type` , required: true},
            { name: 'serviceName', label: `Enter ${serviceType} name`,placeholder: `Enter ${serviceType} name` , required: true },
            { name: 'quantity', label: `Enter ${serviceType} Quantity`, type: 'number', placeholder: '0', required: true, min: 1 },
            { name: 'rent', label: `Enter ${serviceType} rent`, type: 'number', placeholder: '₹', required: true, min: 1 },
            { name: 'tenures', label: 'Select rent tenures (Multiple selection allowed)', type: 'multipleSelect',placeholder: 'Select rent tenures', required: true, options: [
              { value: 'monthly', label: 'Monthly' },
              { value: 'quarterly', label: 'Quarterly' },
              { value: 'yearly', label: 'Yearly' },
            ],
          },
            
          ]}
        />
        </>


    }
    </>
  )
}

export default ServicePage