import RoutePath from "../routes/RoutePath";
import { setUserState } from "../redux/slices/authSlice";


// GETS USER DETAILS
export const verify = async (navigate, dispatch) => {
    const token = localStorage.getItem("token");
    const user_id = localStorage.getItem("user_id");
  
    if (user_id === null || user_id === "undefined" || user_id === "" || token === null || token === "undefined" || token === ""){
      navigate(RoutePath.login);
      return;
    }
  
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/users/${user_id}`, {
        method: "GET",
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if(res === null){
        throw new Error("Response null");
      }

      if(!res.ok){
        if(res.status === 401){
          console.log("Unauthorized");
          throw new Error("Unauthorized");
        }
        console.log("response is null")
        throw new Error("Response not ok");
      }

      const data = await res.json();

      if (data === null || data === undefined) {
        console.log("user data null")
        throw new Error("Data is null or undefined");
      }
       localStorage.setItem("userData", JSON.stringify(data));
       window.dispatchEvent(new Event('storage'))
      dispatch(setUserState(data));
    } catch (err) {
      console.error(err);
      throw err;
    }
  };
  