import React from 'react'
import tick from "../../assets/images/tickIcon.svg"
import { CiCalendar } from "react-icons/ci";
import { RxCross2 } from "react-icons/rx";
import SpinnerLogic from '../spinner/SpinnerLogic';
import RoutePath from '../../routes/RoutePath'
import { useNavigate } from 'react-router-dom';
import { MdDateRange } from "react-icons/md";
import { FaRegClock } from "react-icons/fa";
import { combineDateTime, formatDate, formatDateAndTime } from '../../utils/formatDate';



const ScheduleVisit = ({setShowLoginModal,isLoggedIn, screenWidth, errors, requirederrors, formData, handleChange, handleBookVisit, status, visitingDetails, openVisitForm, setOpenVisitForm, collegeId, handleCollegeIdChange, removeCollegeId}) => {
    const formFields = [
        { name: 'name', label: 'Name', type: 'text', placeholder: 'Enter your Name' },
        { name: 'phoneNumber', label: 'Phone number', type: 'text', placeholder: '+91  000-000-0000' },
        { name: 'date', label: 'Date', type: 'date', placeholder: 'DD-MM-YYYY', min: new Date().toISOString().split('T')[0] },
        { name: 'time', label: 'Time', type: 'time', placeholder: '' }
      ];
      const handlemodalbtn = () =>{
        setShowLoginModal(true);
      }
      const navigate = useNavigate()
;
      const handleScheduleClick = () =>{
        navigate(RoutePath.account , {state:'Schedule visit'});
      }
      
  return (
    <div className={`w-full md:border md:border-[#D4D4D8] md:rounded-[16px] p-6 ${screenWidth >= 768 && 'header-box-shadow'}`}>
     <div className={`${status==='succeeded' ? 'hidden' : ''} flex items-center justify-between w-full`}><p className='text-xl text-secondary font-poppins font-medium md:font-semibold'>{isLoggedIn ? 'Schedule Visit' : 'Login to Schedule Visit'}</p><RxCross2 onClick={()=>setOpenVisitForm(false)} className={`text-xl md:hidden block text-[#52525B]`}/></div>
      {
        status === 'loading' ? <><div className="absolute min-h-screen inset-0 bg-black opacity-50"></div><div className='absolute  z-30 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'><SpinnerLogic /></div></> : <>

        {
          status === 'succeeded' && visitingDetails?.status === "pending" ? <div className='text-center flex flex-col items-center mt-2'>
            <div className='w-full flex items-center justify-end' ><RxCross2 onClick={()=>setOpenVisitForm(false)} className={`text-xl md:hidden block text-#52525B]`}/></div>
             <img src={tick} alt="" />
            <h1 className='font-poppins mt-5 text-black font-semibold text-2xl'>Waiting to see you :)</h1>
            <p className='font-poppins text-textColor text-lg text-center font-normal mt-1 mb-6'>We've booked a visit slot for you <span onClick={handleScheduleClick} className='text-[#EF7C22] font-bold cursor-pointer' >View schedule visit</span></p>
            <div className="flex justify-evenly w-full border-t-2 p-2">
            <p className='flex align-middle p-3 font-semibold text-xl gap-2' ><MdDateRange className='text-[#16B364] text-2xl' />{formatDateAndTime(visitingDetails?.visitTime)?.formattedDate}</p>
            <p className='flex align-middle p-3 font-semibold text-xl gap-2' ><FaRegClock className='text-[#16B364] text-2xl' />{formatDateAndTime(visitingDetails?.visitTime)?.formattedTime}</p>
            </div>
            
            </div> :
           <>{isLoggedIn && formFields.map(field => (
            <div key={field.name} className=''>
              <p className='font-poppins font-medium mt-4 text-sm text-[#3F3F46]'>{field.label}</p>
                <input disabled={field.name==='name' || field.name==='phoneNumber'} type={field.type} min={field?.min} placeholder={field?.placeholder} name={field.name} value={formData?.[field.name]} onChange={(e) => handleChange(e)}  className={`${(field.name==='name' || field.name==='phoneNumber') ? 'cursor-not-allowed' : '' } border outline-none w-full  mt-2 login-box-shadow h-[44px] text-textColor font-normal text-base font-poppins rounded-[4px] border-[#D4D4D8] py-[10px] px-[14px]`} />
              <p className='mt-1 text-red-800 font-poppins text-xs font-normal'>{errors?.[field.name] || requirederrors?.[field.name]}</p>
            </div>
          ))}
          {isLoggedIn && <div className='mt-4'>
                                <p className='font-poppins font-medium mt-4 text-sm text-[#3F3F46]'>Upload College ID</p>
                                <div className='flex items-center'>
                                    <input type='file' id='collegeId' onChange={handleCollegeIdChange} style={{ display: 'none' }} />
                                    <label htmlFor='collegeId' className='border outline-none cursor-pointer bg-transparent flex items-center justify-center h-[44px] w-full mt-2 login-box-shadow text-textColor font-normal text-base font-poppins rounded-[4px] border-[#D4D4D8] py-[10px] px-[14px]'>
                                        <span>{collegeId ? collegeId.name : 'Choose File'}</span>
                                    </label>
                                    {collegeId && (
                                        <RxCross2 onClick={removeCollegeId} className='ml-2 text-[#52525B] cursor-pointer' />
                                    )}
                                </div>
                            </div>}
      {isLoggedIn && <button onClick={handleBookVisit} className='font-poppins mt-7 w-full  text-white text-sm font-medium blog-button-shadow background-primary flex items-center justify-center space-x-2 rounded-[4px] py-[10px] px-4'><CiCalendar /><p>Book visit</p></button>}
      {!isLoggedIn && <button onClick={handlemodalbtn} className='font-poppins mt-7 w-full  text-white text-sm font-medium blog-button-shadow background-primary flex items-center justify-center space-x-2 rounded-[4px] py-[10px] px-4'><CiCalendar /><p>Login</p></button>}
      </>
        }
        </>
      }
    </div>
  )
}

export default ScheduleVisit