import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchCollegeListings = createAsyncThunk(
    'collegeListings/fetchCollegeListings',
    async ({id, token, apibody})=>{
        try{
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/listing/viewCollegeListings/${id}`, {
                method: 'POST',
                headers: {
                  "Content-Type": "application/json"
                },
                body: JSON.stringify(apibody)
            });
            if (response === null) {
                console.log("Response is null");
                throw new Error("Response is null");
            }
            if(response?.status === 401) {
                console.log("Unauthorized");
                throw new Error("Unauthorized");
            }
            if(!response.ok){
                console.log(`Error fetching listings data: ${response.status} - ${response.statusText}`);
                throw new Error(`Error fetching listings data: ${response.status} - ${response.statusText}`);
            }
            const data = await response.json();
            if (data === null || data === undefined) {
                console.log("Data is null or undefined")
                throw new Error("Data is null or undefined");
            }
            return data;
          
        }catch(error){
            console.log("Error fetching college listings data", error);
            throw error; 
        }
    }
)

const collegeListingsSlice = createSlice({
    name: "collegeListings",
    initialState: {
        listingsData: [],
        status: 'idle',
        error: null
    },

    reducers: {},
    extraReducers: (builder) => {
        builder
          .addCase(fetchCollegeListings.pending, (state) => {
            state.status = 'loading';
          })
          .addCase(fetchCollegeListings.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.listingsData = action.payload;
            
          })
          .addCase(fetchCollegeListings.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
          });
      }
})

export default collegeListingsSlice.reducer;