import React, {useState} from 'react'
import FeedBack from './FeedBack'
import { showErrorToast, showSuccessToast } from '../../utils/toastNotif';
import RoutePath from '../../routes/RoutePath';
import { useLocation, useNavigate } from 'react-router-dom';

const FeedBackLogic = ({listingId}) => {
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0)
    const location = useLocation();
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const [formData, setFormData] = useState({comment: "", rating: ""});
    const handleFeedback = async()=>{
       if(!formData?.rating || !formData?.comment){
        showErrorToast("Rating and comment fields are mandatory, kindly fill them");
       }else{
        try{
            setLoading(true);
            setProgress(30)
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/listing/addReview`, {
                method: 'POST',
                headers: {
                  "Content-Type": "application/json",
                  "Authorization": `Bearer ${token}`
                },
        
                body: JSON.stringify({
                    review: {
                        rating: parseInt(formData?.rating),
                        comment: formData?.comment
                    },
                    listingId: listingId
                })
              });
              
              if(response === null){
                throw new Error("response is null");
              }
              if(!response.ok){
                if(response.status === 401){
                    showErrorToast("kindly login again to continue");
                    navigate(RoutePath.login, {state: {data: location?.pathname + location?.search}});
                    return;
                   }else{
                    console.log(response.status);
                    throw new Error("Response not ok")
                   }
              }

              const data = await response.json();
              if(data === null || data === undefined){
                throw new Error("data is null");
              }

              showSuccessToast("review added successfully");
              setOpenModal(false);
        
        }catch(error){
            console.log(error);
            showErrorToast("Something went wrong while adding review");
        }finally{
            setLoading(false);
            setProgress(100);
        }
       }
    }
  return (
    <><FeedBack  progress={progress} handleFeedback={handleFeedback} formData={formData} setFormData={setFormData} loading={loading} openModal={openModal} setOpenModal={setOpenModal}/></>
  )
}

export default FeedBackLogic