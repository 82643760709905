import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import AllVisits from './AllVisits'
import RoutePath from '../../routes/RoutePath';
import { showErrorToast, showSuccessToast } from '../../utils/toastNotif';
import { combineDateTime } from '../../utils/formatDate';
import { VISIT_ITEMS_PER_PAGE } from '../../utils/constants';

const AllVisitsLogic = () => {
    const [visitsData, setVisitsData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();
    const [visitLoading, setVisitLoading] = useState(false);
    const userId = localStorage.getItem("user_id");
    const token = localStorage.getItem('token');
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const [openModal, setOpenModal] = useState(false);
    const [failed, setFailed] = useState(false);
    const [dateOnly, setdateOnly] = useState(null)
    const [apibody, setApibody] = useState({sortKey: "_id", sortValue: -1, currentPage: currentPage, itemsPerPage: VISIT_ITEMS_PER_PAGE, })

    useEffect(() => {
      const today = new Date().toISOString().split('T')[0];
      setdateOnly(today);
    }, []);

    useEffect(()=>{
        if(!token || token === "undefined"){
           showErrorToast("kindly login again to continue");
           navigate(RoutePath.login, {state: {data: location?.pathname + location?.search}});
           return;
        }
        const fetchData = async()=>{
         try{
           setLoading(true);
           const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/visit/getAllVisitsOfAUser`, {
             method: 'POST',
             headers: {
               "Content-Type": "application/json",
               "Authorization": `Bearer ${token}`
             },
     
             body: JSON.stringify(apibody)
           });
     
           if(response === null){
             throw new Error('Response is null');
           }
     
           if(!response.ok){
            if(response.status === 401){
             showErrorToast("kindly login again to continue");
             navigate(RoutePath.login, {state: {data: location?.pathname + location?.search}});
             return;
            }else{
             console.log(response.status);
             throw new Error("Response not ok")
            }
           }
     
           const data = await response.json();
           if(data === null || data === undefined){
             throw new Error('data is null');
           }
           setVisitsData(data);
          //  console.log(data)
           setFailed(false);
          }catch(error){
           console.log(error);
           showErrorToast("Error fetching visiting data");
           setFailed(true);
          }finally{
           setLoading(false);
          }
        }
        fetchData();
       },[apibody, currentPage])

       const handleRescheduleVisit = async(visitDetails, formData)=>{
        const time = combineDateTime(formData?.date, formData?.time);
        if(!time){
          showErrorToast("Kindly enter valid date and time");
          return;
        }
        try{
          setVisitLoading(true);
          const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/visit/rescheduleVisit`, {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`
            },
    
            body: JSON.stringify({
              visitId: visitDetails?._id,
              newVisitingTime: time,
              user: {
                name: visitDetails?.userDetails?.name,
                phone: visitDetails?.userDetails?.phone,
                type: visitDetails?.userDetails?.type,
                _id: visitDetails?.userDetails?._id
              }
            })
          });
    
          if(response === null){
            throw new Error('Response is null');
          }
    
          if(!response.ok){
           if(response.status === 401){
            setOpenModal(false)
            showErrorToast("kindly login again to continue");
            navigate(RoutePath.login, {state: {data: location?.pathname + location?.search}});
            return;
           }else{
            const message = await response.text();
            const parsedMessage = JSON.parse(message);
            showErrorToast(parsedMessage?.message);
            return;
            
           }
          }
    
          const data = await response.json();
          if(data === null || data === undefined){
            throw new Error('data is null');
          }
          showSuccessToast("visit rescheduled successfully");
          setOpenModal(false);
        }catch(error){
          console.log(error);
          showErrorToast("something went wrong");
          setOpenModal(false);
        }
        finally{
          setVisitLoading(false);
        }
       }

       const handlePageChange = (page) => {
        setCurrentPage(page);
        setApibody(prevState => ({...prevState, currPage: page}));
      };
  return (
    <><AllVisits dateOnly={dateOnly} visitsData={visitsData} visitLoading={visitLoading} setOpenModal={setOpenModal} openModal={openModal} handleRescheduleVisit={handleRescheduleVisit} loading={loading} failed={failed} currentPage={currentPage} handlePageChange={handlePageChange}/></>
  )
}

export default AllVisitsLogic