import React, { useState } from 'react'
import { formatDateTime } from '../../utils/formatDate'
import PaginationLogic from '../pagination/PaginationLogic'
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import calendar from "../../assets/images/calendar.png"
import filter from "../../assets/images/filterIcon.png"
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from 'react-router';
import up from "../../assets/images/upArrow.svg"
import down from "../../assets/images/downArrow.svg"
import RoutePath from '../../routes/RoutePath';
import { PAYMENT_ITEMS_PER_PAGE } from '../../utils/constants';
import SpinnerLogic from '../spinner/SpinnerLogic';
import { useSelector } from 'react-redux';

const MobileFilter = ({ setOpenMobileFilter, selectedService, setSelectedService, serviceOptions, dateRange, handleDateSelect, showDatePicker, setShowDatePicker, handleClear, setAppliedFilter }) => {
  return (
    <div className='fixed top-0 left-0 w-full h-full flex items-center justify-center z-50'>
      <div className='fixed top-0 left-0 w-full h-full bg-black opacity-30 backdrop-filter backdrop-blur-md'></div>
      <div className='relative w-full h-full top-[60px] z-50 bg-white rounded-tl-[24px] rounded-tr-[24px] '>
        <div className='w-full justify-between flex items-center border-b border-[#E4E4E7] py-4 px-5'>
          <RxCross2 className='text-[#52525B] text-xl' onClick={() => setOpenMobileFilter(false)} />
          <p className='text-xl font-poppins font-medium text-secondary'>Filter</p>
          <p onClick={handleClear} className='font-poppins blog-button-shadow text-base text-[#A1A1AA] font-medium'>Clear</p>
        </div>
        <div className='flex flex-col items-start w-full py-6 px-5'>
          <div className='w-full'>
            <p className='font-poppins font-medium text-sm text-[#3F3F46] mb-2'>Choose service</p>
            <ChooseService selectedService={selectedService} setSelectedService={setSelectedService} serviceOptions={serviceOptions} />
          </div>
          <div className='mt-5 w-full'>
            <p className='font-poppins font-medium text-sm text-[#3F3F46] mb-2'>Date range</p>
            <DateRangeSelector handleDateSelect={handleDateSelect} dateRange={dateRange} showDatePicker={showDatePicker} setShowDatePicker={setShowDatePicker} />
          </div>
        </div>
        <div className='w-full fixed bottom-0 py-6 px-5'>
          <button onClick={() => {
            setAppliedFilter(true);
            setOpenMobileFilter(false)
          }} className='background-primary w-full  blog-button-shadow py-3 px-5 rounded-[4px] text-white text-base font-poppins font-medium'>Apply</button>
        </div>
      </div>
    </div>

  )

}

const DateRangeSelector = ({ showDatePicker, setShowDatePicker, dateRange, handleDateSelect }) => {
  return (
    <div className="border border-[#D4D4D8] blog-button-shadow py-[10px] px-[14px] text-base font-poppins font-normal text-textColor relative rounded-[4px]">
      <div className="flex items-center space-x-4">
        <div className="flex-1">
          <div className="flex items-center justify-between">
            <div>
              <span>{dateRange[0].startDate?.toLocaleDateString() || 'DD/MM/YYYY'} - {dateRange[0].endDate?.toLocaleDateString() || 'DD/MM/YYYY'}</span>
            </div>
            <img src={calendar} className="ml-2 cursor-pointer"
              onClick={() => setShowDatePicker(!showDatePicker)} />
          </div>
        </div>
      </div>
      {showDatePicker && (
        <div className='absolute z-20 top-12 right-0'>
          <style>
            {`
              .rdrDefinedRangesWrapper
              {
                display: none !important;
              },
              
            `}
          </style>
          <DateRangePicker
            ranges={dateRange}
            onChange={handleDateSelect}
            showSelectionPreview={false}
            showPreview={false}

          /></div>
      )}
    </div>
  );
}

const ChooseService = ({ selectedService, serviceOptions, setSelectedService, apibody, setApibody }) => {
  const screenWidth = useSelector(state => state.screenWidth.screenWidth);
  return (
    <select value={selectedService} onChange={(e) => {
      if (screenWidth >= 768) {
        setApibody({ ...apibody, paymentFor: e.target.value })
      }
      setSelectedService(e.target.value)
    }} className='md:w-[200px] w-full outline-none cursor-pointer border border-[#D4D4D8] text-base font-medium font-poppins text-textColor blog-button-shadow rounded-[4px] py-[10px] px-[14px]'>
      <option value="">Choose Service</option>
      {
        serviceOptions && serviceOptions.map((ele, ind) => (
          <option value={ele?.name} key={ind}>{ele?.label}</option>
        ))
      }
    </select>
  )
}

const Payments = ({ failed, apibody, setApibody, toggleExpandedOrder, expandedOrderId, loading, screenWidth, paymentData, serviceOptions, tableHeadings, selectedService, setSelectedService, handlePageChange, currentPage, itemsPerPage, totalItems, currentItems, showDatePicker, setShowDatePicker, dateRange, handleDateSelect, monthlyData, openMobileFilter, setOpenMobileFilter, handleClear, setAppliedFilter, productDetails }) => {
  const navigate = useNavigate();
  const detailstableHeadings = ["#", "Product name", "ID", "Rent"];
  const [mobileexpandedOrderId, setMobileExpandedOrderId] = useState(null)

  return (
    <div>
      {
        loading ? <><div className="absolute min-h-screen inset-0 bg-black opacity-50"></div><div className='absolute  z-30 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'><SpinnerLogic /></div></>
          :
          <>
            <div className='w-full hidden md:flex mt-[30px]  flex-col items-start'>
              <div className='w-full justify-between items-center flex'>
                <ChooseService apibody={apibody} setApibody={setApibody} selectedService={selectedService} setSelectedService={setSelectedService} serviceOptions={serviceOptions} />
                <DateRangeSelector showDatePicker={showDatePicker} setShowDatePicker={setShowDatePicker} dateRange={dateRange} handleDateSelect={handleDateSelect} />
              </div>
              {
                failed ? <div className='flex flex-col w-full min-h-screen  items-center justify-center'>
                  <div className='text-center'>
                    <h1 className="text-secondary font-poppins font-semibold text-5xl">Error fetching payment data</h1>
                    <p className="text-2xl font-poppins text-textColor mt-6 font-normal">We are facing some internal server error while fetching data, sorry for the inconvinience caused</p>
                    <button onClick={() => navigate(RoutePath.homepage)} className='blog-button-shadow background-primary mt-10 text-white text-lg rounded-[4px] px-4 py-2 font-medium font-poppins'>Go Back</button>
                  </div>
                </div>
                  :
                  (paymentData?.totalPaymentsCount === 0 ? <div className='flex flex-col w-full min-h-screen  items-center justify-center'>
                    <div className='text-center'>
                      <h1 className="text-secondary font-poppins font-semibold text-5xl">No payment data</h1>
                      <p className="text-2xl font-poppins text-textColor mt-6 font-normal">There is currently no payment data available</p>
                      <button onClick={() => navigate(RoutePath.homepage)} className='blog-button-shadow background-primary mt-10 text-white text-lg rounded-[4px] px-4 py-2 font-medium font-poppins'>Go Back</button>
                    </div>
                  </div> :
                    <div className='w-full mt-[30px]'>
                      <table className="w-full bg-white rounded-[6px] border border-[#E4E4E7]">
                        <thead>
                          <tr className='w-full'>
                            {
                              tableHeadings && tableHeadings.map((ele, ind) => (
                                <th key={ind} className='h-[44px] border-b border-[#E4E4E7] py-3 px-6 text-center font-poppins bg-[#FAFAFA] text-[#292522] font-medium text-sm'><p>{ele}</p></th>
                              ))
                            }
                          </tr>
                        </thead>
                        <tbody className='w-full'>
                          {paymentData?.payments?.map((payment, index) => (
                            <>

                              <tr key={index}>
                                <td className="h-[72px] text-center py-4 px-6 border-b font-poppins font-normal text-sm text-secondary border-[#E4E4E7]">{payment?.paymentType === "servicePurchase" ? payment?.orderId : payment?._id}</td>
                                <td className="h-[72px] text-center py-4 px-6 border-b font-poppins font-normal text-sm text-[#52525B] border-[#E4E4E7]">{formatDateTime(payment?.createdOn)}</td>

                                <td className="h-[72px] text-center py-4 px-6 border-b font-poppins font-normal text-sm text-[#52525B] capitalize border-[#E4E4E7]">{payment?.paymentType?.replace(/([a-z])([A-Z])/g, '$1 $2')}</td>
                                <td className="h-[72px] text-center py-4 px-6 border-b font-poppins font-normal text-sm text-secondary border-[#E4E4E7]">₹{payment?.netAmount}</td>
                                <td className={`h-[72px] text-center py-4 px-6 border-b  border-[#E4E4E7]`}><span className={`font-poppins font-medium text-sm ${payment?.status === 'paymentSuccessful' ? 'text-[#099250] rounded-[100px] bg-[#EDFCF2] px-3 py-1' : 'text-red-500 rounded-[100px] px-3 py-1 bg-red-300'}`}>{payment?.status === "paymentSuccessful" ? 'Paid' : 'Failed'}</span></td>
                                <td className={`h-[72px] text-center py-4 px-6 border-b  border-[#E4E4E7] cursor-pointer text-secondary font-poppins text-sm font-medium flex items-center space-x-3`} onClick={() => {
                                  if(payment?.paymentType === "serviceRent" || payment?.paymentType === "servicePurchase"){
                                    toggleExpandedOrder(payment?._id)
                                  }
                                }}>
                                  <p>{expandedOrderId === payment?._id ? 'View less' : 'View more'}</p>
                                  <img src={expandedOrderId === payment?._id ? up : down} alt="" />
                                </td>
                              </tr>

                              {expandedOrderId === payment?._id && (
                                <tr key={`expanded-${index}`}>
                                  <td colSpan={tableHeadings.length} className='p-0'>
                                    <div className='px-5 gap-[8px] mt-[16px] border-b border-[#e4e4e7] pb-4 w-full'>
                                      <table className="w-full bg-white  rounded-[6px] border border-[#E4E4E7]">
                                        <thead>
                                          <tr className='w-full'>
                                            {
                                              detailstableHeadings && detailstableHeadings.map((ele, ind) => (
                                                <th key={ind} className='h-[44px] border-b border-[#E4E4E7] py-3 px-6 text-center font-poppins bg-[#FAFAFA] text-[#292522] font-medium text-sm'><p>{ele}</p></th>
                                              ))
                                            }
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {
                                            payment?.services?.map((ele, ind) => (
                                              <>
                                                <tr key={index}>
                                                  <td className="h-[72px] text-center py-4 px-6 border-b font-poppins font-normal text-sm text-secondary border-[#E4E4E7]">{ind + 1}</td>
                                                  <td className="h-[72px] text-center flex items-start space-x-3 py-4 px-6 border-b font-poppins font-normal text-sm text-secondary">
                                                    <img src={payment?.paymentType === "serviceRent" ? ele?.serviceDetail?.image : ele?.images[0]} alt="" className='w-[36px] h-[36px] rounded' />
                                                    <div className='flex w-full h-full items-center justify-center'>
                                                      <p className='text-[#52525B] text-center font-normal font-poppins text-sm'>{payment?.paymentType === "serviceRent" ? ele?.serviceDetail?.name : ele?.name}</p>
                                                    </div>
                                                  </td>
                                                  <td className="h-[72px] text-center py-4 px-6 border-b font-poppins font-normal text-sm text-secondary border-[#E4E4E7]">{ele?._id}</td>
                                                  <td className="h-[72px] text-center py-4 px-6 border-b font-poppins font-normal text-sm text-secondary border-[#E4E4E7]">₹{payment?.paymentType === "serviceRent" ? ele?.serviceDetail?.rent : ele?.rent}</td>
                                                </tr>
                                              </>
                                            ))
                                          }
                                        </tbody>
                                      </table>
                                    </div>
                                  </td>
                                </tr>
                              )}


                            </>

                          ))}
                        </tbody>
                      </table>
                    </div>
                  )
              }
            </div>

            <div className='w-full flex md:hidden mt-[30px] flex-col items-center'>
              <div onClick={() => setOpenMobileFilter(true)} className='flex items-center justify-between font-poppins font-medium text-base text-secondary py-[12px] px-4 w-full rounded-lg border border-[#D4D4D8]'>
                <p>Filters</p>
                <img src={filter} alt="" />
              </div>
              <div className='w-full mt-[20px]'>
                {
                  monthlyData && Object.entries(monthlyData).map(([monthYear, payments]) => (
                    <div key={monthYear}>
                      <h2 className="text-textColor font-poppins text-xs font-normal">{monthYear}</h2>
                      <div className='mt-[20px]'>
                        {payments.map((payment, ind) => (
                          <div onClick={() => {
                           if(payment?.paymentType === "serviceRent" || payment?.paymentType === "servicePurchase"){
                            if (mobileexpandedOrderId === payment?._id) {
                              setMobileExpandedOrderId(null);
                            } else {
                              setMobileExpandedOrderId(payment?._id);
                            }
                           }
                          }} key={ind} className="flex flex-col  mb-4  border border-[#E4E4E7] blog-button-shadow rounded-[4px] p-3">
                            <div className='flex items-center justify-between'>
                              <div className='flex flex-col'>
                                <p className='text-sm font-poppins font-normal  text-secondary'>{payment?._id}</p>
                                <p className='font-poppins text-xs   text-textColor font-normal'>{formatDateTime(payment?.createdOn)}</p>
                              </div>
                              <div className='flex flex-col'>
                                <p className='text-sm font-poppins font-medium text-right text-[#099250]'>₹{payment?.netAmount}</p>
                                <p className='font-poppins text-xs text-right text-textColor font-normal'>{payment?.paymentType?.replace(/([a-z])([A-Z])/g, '$1 $2')}</p>
                              </div>
                            </div>
                            {
                              mobileexpandedOrderId === payment?._id &&
                              <div className='px-5 gap-[8px] mt-[16px] border-b border-[#e4e4e7] pb-4 w-full'>
                                <div className="overflow-x-auto no-scrollbar whitespace-nowrap">
                                  <table className="w-full bg-white rounded-[6px] border border-[#E4E4E7]">
                                    <thead>
                                      <tr className='w-full'>
                                        {
                                          detailstableHeadings && detailstableHeadings.map((ele, ind) => (
                                            <th key={ind} className='h-[44px] border-b border-[#E4E4E7] py-3 px-6 text-center font-poppins bg-[#FAFAFA] text-[#292522] font-medium text-sm'>
                                              <p>{ele}</p>
                                            </th>
                                          ))
                                        }
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {
                                        payment?.services?.map((ele, ind) => (
                                          <tr key={ind}>
                                            <td className="h-[72px] text-center py-4 px-6 border-b font-poppins font-normal text-sm text-secondary border-[#E4E4E7]">{ind + 1}</td>
                                            <td className="h-[72px] text-center flex items-start space-x-3 py-4 px-6 border-b font-poppins font-normal text-sm text-secondary border-[#E4E4E7]">
                                              <img src={ele?.serviceDetail?.image} alt="" className='w-[36px] h-[36px] rounded' />
                                              <div className='flex flex-col'>
                                                <p className='text-secondary font-poppins font-medium text-sm'>{ele?.serviceDetail?.name}</p>
                                                <p className='text-[#52525B] font-normal font-poppins text-sm'>{ele?.serviceDetail?.name}</p>
                                              </div>
                                            </td>
                                            <td className="h-[72px] text-center py-4 px-6 border-b font-poppins font-normal text-sm text-secondary border-[#E4E4E7]">{ele?._id}</td>
                                            <td className="h-[72px] text-center py-4 px-6 border-b font-poppins font-normal text-sm text-secondary border-[#E4E4E7]">₹{ele?.serviceDetail?.rent}</td>
                                          </tr>
                                        ))
                                      }
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            }

                          </div>
                        ))}
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </>
      }
      {
        openMobileFilter && <MobileFilter setAppliedFilter={setAppliedFilter} setOpenMobileFilter={setOpenMobileFilter} selectedService={selectedService} setSelectedService={setSelectedService} serviceOptions={serviceOptions} dateRange={dateRange} handleDateSelect={handleDateSelect} showDatePicker={showDatePicker} setShowDatePicker={setShowDatePicker} handleClear={handleClear} />
      }
      {
         <PaginationLogic
          totalPages={Math.ceil(paymentData?.totalPaymentsCount / PAYMENT_ITEMS_PER_PAGE)}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      }
    </div>

  )
}

export default Payments