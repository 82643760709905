import React, {useState, useEffect} from 'react'
import AddressForm from './AddressForm'
import { useSelector } from 'react-redux'
import StatesService from '../../utils/statesService'

const AddressFormLogic = ({data, setData, selectedCity,cities, setcities, selectedState, handleDataChange, formType, errors, setselectedCity, setselectedState, requiredFieldErrors}) => {
    const states = useSelector(state=>state.states)
    const statesService = new StatesService();

    const handleStateChange = (e, setData, data)=>{
      
        setData({...data, permanentAddress: {
            ...data.permanentAddress, state: e.target.value
        }})
        setselectedState({...selectedState, [formType]: e.target.value});
       const temp = statesService.getCity(e.target.value);
       setcities({...cities, [formType]: temp});

       
    }

    const handleCityChange = (e, setData, data)=>{
        setData({...data, permanentAddress: {
            ...data.permanentAddress, city: e.target.value
        }})
        setselectedCity({...selectedCity, [formType]: e.target.value});
    }
  return (
    <><AddressForm
    data={data}
    setData={setData}
    selectedState={selectedState}
    selectedCity={selectedCity}
    handleStateChange={handleStateChange}
    handleCityChange={handleCityChange}
    handleDataChange={handleDataChange}
    states={states}
    cities={cities}
    formType={formType}
    errors={errors}
    requiredFieldErrors={requiredFieldErrors}
    /></>
  )
}

export default AddressFormLogic