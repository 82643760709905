import React from 'react'
import { FiShare2 } from "react-icons/fi";
import Modal from "react-awesome-modal";
import { RxCross2 } from 'react-icons/rx'

const Share = ({openModal, setOpenModal, shareOptions, handleShare, handleNativeShare, screenWidth}) => {
  return (
    <div>
        {
            openModal ?   <Modal
            visible={openModal}
              width="568"
              height="332"
              effect="fadeInUp"
              onClickAway={()=>setOpenModal(false)}
            >
               <div className='flex flex-col'>
               <div className='px-6 pt-6 pb-5 flex w-full items-center justify-between'>
                <p className='text-secondary font-poppins font-semibold text-xl'>Share this place</p>
                <RxCross2 onClick={()=>setOpenModal(false)} className='text-2xl cursor-pointer text-[#52525B]'/>
               </div>
               <div className='pt-3 px-6 pb-6'>
                <div className='grid grid-cols-2  gap-4'>
                    {
                        shareOptions && shareOptions.map((ele, ind)=>{
                            return(
                            <div key={ind} onClick={()=>handleShare(ele?.case)} className='col-span-1 cursor-pointer rounded-xl py-5 px-6 border border-[#D4D4D8] flex items-center space-x-3'>
                                <img src={ele?.img} alt="" className='' />
                                <p className='font-poppins font-medium text-base text-secondary'>{ele?.label}</p>
                            </div>
                            )
                        })
                    }
                </div>
               </div>
               </div>
            </Modal>    : screenWidth < 600 ? (
        <FiShare2
          onClick={handleNativeShare}
          className="text-[#52525B] cursor-pointer text-2xl"
        />
      ) : (
        <FiShare2
          onClick={() => setOpenModal(true)}
          className="text-[#52525B] cursor-pointer text-2xl"
        />
      )
        }
        </div>
  )
}

export default Share