import React, { useState, useEffect, useRef } from 'react'
import uparrow from "../../assets/images/upArrow.svg"
import downarrow from "../../assets/images/downArrow.svg"
import searchIcon from "../../assets/images/desktopNavbarSearchIcon.svg"
import darksearchIcon from "../../assets/images/darksearchIcon.svg"
import homepage1 from "../../assets/images/homepage1.png"
import homepage2 from "../../assets/images/homepage2.png"
import homepage3 from "../../assets/images/homepage3.png"
import ServiceDropDownLogic from '../serviceDropDown/ServiceDropDownLogic'
import CollegeDropdownLogic from '../collegeDropdown/CollegeDropdownLogic'
import WhatsappContactLogic from '../whatsappContact/WhatsappContactLogic'
import leftarrow from "../../assets/images/leftarrow.svg"

const MobileSearch = ({setMobileSearch, setisCollegeDropdownOpen, setisServiceDropdownOpen, isCollegeDropdownOpen, selectCollege, collegeList, searchQuery, setSearchQuery, filteredColleges, topSearchColleges, isServiceDropdownOpen, serviceOptions, selectService, toggleCollegeDropdown, selectedCollege, selectedService, toggleServiceDropdown, handleSearch}) => {
  const togglingServiceMobileHeaderRef = useRef(null);
  const togglingCollegeMobileHeaderRef = useRef(null)
      
    return (
      <div className='bg-white w-full fixed z-50 top-0 left-0 min-h-screen'>
        <WhatsappContactLogic/>
          <div className='py-4 px-5'>
              <img onClick={()=>setMobileSearch(false)} src={leftarrow} alt="back" className='cursor-pointer'/>
          </div>
          <div className='w-full flex flex-col py-6 px-5'>
              <div  className='flex flex-col  items-start space-y-2'>
                  <p  className='font-poppins font-medium text-sm text-[#3F3F46]'>Enter Your College</p>
                  <div ref={togglingCollegeMobileHeaderRef} onClick={(e)=>{
                    e.stopPropagation();
                    toggleCollegeDropdown();
                  }} className=' w-full rounded-xl relative bg-white items-center py-[10px] px-[14px] border border-[#D4D4D8] blog-button-shadow flex md:hidden'>
               <img src={darksearchIcon} alt="search" className='mr-2'/>
             {
                isCollegeDropdownOpen ? 
                   
                <input type="text" onClick={(e)=>e.stopPropagation()} className='outline-none font-poppins text-base w-full font-normal text-textColor' placeholder='Search college' value={searchQuery}
                onChange={(e) =>{
                  e.stopPropagation();
                  setSearchQuery(e.target.value)
                }}/>
              :   <p  className={`font-poppins font-normal text-base ${selectedCollege ? 'text-secondary' : 'text-textColor'}`}>{selectedCollege ? selectedCollege?.collegeName : 'Enter Your College' }</p>
             }
               <CollegeDropdownLogic togglingCollegeMobileHeaderRef={togglingCollegeMobileHeaderRef} setisCollegeDropdownOpen={setisCollegeDropdownOpen} isCollegeDropdownOpen={isCollegeDropdownOpen} selectCollege={selectCollege} collegeList={collegeList} searchQuery={searchQuery} setSearchQuery={setSearchQuery} filteredColleges={filteredColleges} topSearchColleges={topSearchColleges}/>
            </div>
              </div>
              <div   className='flex   mt-8 flex-col items-start space-y-2'>
                  <p className='font-poppins font-medium text-sm text-[#3F3F46]'>Choose service</p>
                  <div ref={togglingServiceMobileHeaderRef} onClick={(e)=>{
                    e.stopPropagation();
                    toggleServiceDropdown();
                  }} className=' w-full relative rounded-xl bg-white justify-between items-center py-[10px] px-[14px] border border-[#D4D4D8] blog-button-shadow flex md:hidden'>
              
               <p className='font-poppins font-normal text-base text-orange-400'>{selectedService ? selectedService : 'Choose Service' }</p>
               <img src={isServiceDropdownOpen ? uparrow : downarrow} alt="search" className=''/>
               <ServiceDropDownLogic setisServiceDropdownOpen={setisServiceDropdownOpen} togglingServiceMobileHeaderRef={togglingServiceMobileHeaderRef} isServiceDropdownOpen={isServiceDropdownOpen} serviceOptions={serviceOptions} selectService={selectService}/>
            </div>
              </div>
              
              <div onClick={handleSearch} className='w-full background-primary text-white font-poppins cursor-pointer mt-8 rounded-[4px] py-[12px] px-5 font-medium text-base text-center'>Search</div>
          </div>
      </div>
    )
  }

const ServiceSection = ({servicesData, setMobileSearch, setselectedService})=>{

    const handleScroll = (id) =>{
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      } else {
        console.error("Element with id:", id, "not found.");
      }
    }

    return (
        <div className='mt-[60px] mb-8 px-[20px]  gap-3 md:hidden w-full grid grid-cols-2'>
          {
            servicesData && servicesData.map((ele, ind)=>(
                <div onClick={()=>{
                  setMobileSearch(true);
                  setselectedService(ele?.value)
              }}  className=' col-span-1 h-[151px] rounded-[4px] relative'>
                     <div className='absolute rounded-[4px] top-0 md:hidden z-10 left-0 w-full h-full'></div>
                     <div className='flex flex-col top-1/2 w-full left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute z-20 items-center justify-center'>
                        {/* <h5 className='text-lg text-white font-poppins font-semibold text-center'>{ele?.name}</h5> */}
                        {/* <button onClick={()=>{
                            setMobileSearch(true);
                            setselectedService(ele?.value)
                        }} className='bg-[#FFF4EB] mt-3 py-[8px] px-[12px] rounded-[4px] text-center text-primary text-xs font-medium font-poppins'>Explore</button> */}
                     </div>
                   <img className='w-full h-full object-cover rounded-[4px]' src={ele?.img} alt={ele?.name} />
                </div>
            ))
          }
        </div>
    )
}




const Header = ({isServiceDropdownOpen, setisCollegeDropdownOpen, setisServiceDropdownOpen, toggleServiceDropdown, selectService, selectedService, serviceOptions,toggleCollegeDropdown, isCollegeDropdownOpen, setselectedService, selectCollege, collegeList, selectedCollege, searchQuery, setSearchQuery, filteredColleges, topSearchColleges, metricsData, servicesData, navigate, handleSearch}) => {
    const [mobileSearch, setMobileSearch] = useState(false);
    const togglingCollegeDesktopHeaderRef = useRef(null);
    const togglingServiceDesktopHeaderRef = useRef(null);
    
  return (
   <>
   
    <div className='md:bg-none background relative'>
       
    <div className='md:py-16 md:px-20 w-full flex flex-col items-center justify-center'>
        <h1 className='font-poppins font-semibold text-center text-secondary md:text-[60px] text-[36px] leading-[44px] hidden md:block md:leading-[68px] tracking-[-0.2px]'>Bachelor Housing, Beyond the Bar</h1>
        <div className='desktop-search-bar px-6 py-4 w-[700px] lg:w-[792px] mt-12 hidden header-box-shadow md:flex md:items-center md:justify-between rounded-xl border border-[#E4E4E7]'>
            <div ref={togglingCollegeDesktopHeaderRef}  onClick={(e) => {
    e.stopPropagation();  
    toggleCollegeDropdown()}} className='cursor-pointer flex w-[45%]  relative flex-col items-start '>
                <p className='font-poppins font-medium text-base text-secondary'>Enter Your College</p>
                {
                    isCollegeDropdownOpen ?  
                   
                    <input type="text" onClick={(e)=>e.stopPropagation()} className='outline-none font-poppins font-medium text-sm text-textColor' placeholder='Enter Your College' value={searchQuery}
                    onChange={(e) =>{
                      e.stopPropagation();
                      setSearchQuery(e.target.value)
                    }}/>
                  :  <p  className={`font-poppins cursor-pointer font-medium text-sm ${selectedCollege ? 'text-secondary' : 'text-textColor'} `}>{selectedCollege ? selectedCollege?.collegeName : 'Enter Your College' }</p>
                }
               
                <CollegeDropdownLogic togglingCollegeDesktopHeaderRef={togglingCollegeDesktopHeaderRef}  setisCollegeDropdownOpen={setisCollegeDropdownOpen} isCollegeDropdownOpen={isCollegeDropdownOpen} selectCollege={selectCollege} collegeList={collegeList} searchQuery={searchQuery} setSearchQuery={setSearchQuery} filteredColleges={filteredColleges} topSearchColleges={topSearchColleges}/>
            </div>
            <div className='flex w-[55%] items-center  pl-4 justify-between border-l border-[#E4E4E7]'>
                <div ref={togglingServiceDesktopHeaderRef}  onClick={(e) => {
    e.stopPropagation();  
    toggleServiceDropdown()}} className='cursor-pointer flex w-[55%] relative items-center justify-between '>
                    <div>
                        <p className='font-poppins font-medium text-base text-secondary'>Choose Service </p>
                        <p className={`font-poppins font-medium text-sm  text-textColor`}>{selectedService ? selectedService : 'Choose Service' }</p>
                    </div>
                    <img  src={isServiceDropdownOpen ? uparrow : downarrow} alt="arrow" className='cursor-pointer'/>
                    <ServiceDropDownLogic setisServiceDropdownOpen={setisServiceDropdownOpen} togglingServiceDesktopHeaderRef={togglingServiceDesktopHeaderRef} isServiceDropdownOpen={isServiceDropdownOpen} serviceOptions={serviceOptions} selectService={selectService}/>
                </div>
                <div onClick={handleSearch} className='flex cursor-pointer space-x-3 hover:bg-[#DA6910] items-center background-primary login-box-shadow text-white rounded-[4px] py-3 px-5'>
                    <img src={searchIcon} alt="search" />
                    <p className='font-poppins font-medium text-base'>Search</p>
                </div>
            </div>
        </div>
        <div className='flex items-center md:justify-center  md:mt-[40px] w-full space-x-3'>
            <div className='md:w-[61%]  relative w-full'>
          
          <div className='absolute top-0 md:hidden z-10 left-0 w-full h-full'></div>
          <div onClick={()=>setMobileSearch(true)} className='w-[90%] z-20 absolute -bottom-10 left-1/2 transform -translate-x-1/2 h-[60px] rounded-xl bg-white items-center py-[10px] px-[14px] border border-[#D4D4D8] blog-button-shadow flex md:hidden'>
             <img src={darksearchIcon} alt="search" className='mr-2'/>
             <p  className='font-poppins cursor-pointer font-normal text-base text-textColor'>Enter Your College</p>
          </div>
        <img src={homepage1} alt="pic 1" className='w-full h-full md:rounded-lg object-cover' />
    

            </div>
            <div className='w-[30%] hidden md:flex flex-col items-center space-y-3'>
                <img src={homepage2} alt="pic 2" className='w-full rounded-lg bg-cover'/>
                <img src={homepage3} alt="pic 3" className='w-full rounded-lg bg-cover'/>
            </div>
        </div>
        <ServiceSection servicesData={servicesData} setMobileSearch={setMobileSearch} setselectedService={setselectedService}/>
    </div>
    {/* metric section */}
    <div className='w-full mb-8  flex items-center md:items-center md:py-4  md:px-[60px] justify-evenly'>
        {
            metricsData?.map((ele, ind)=>(
                <div key={ind} className={`flex ${ind === 1 && 'border-x border-[#E4E4E7] md:border-none  bg-orange-200 md:bg-white  rounded-xl px-2 md:px-0' } py-5 md:py-0 w-full md:w-fit lg:flex-row lg:flex-wrap flex-col items-center space-y-2 md:space-x-3`}>
                    <img src={ele?.img} alt={ele?.name} />
                    <h4 className='font-poppins text-secondary font-semibold md:text-2xl text-xl text-center'>{ele?.value}+</h4>
                    <p className='font-poppins text-textColor font-normal text-center md:text-lg text-base'>{ele?.name}</p>
                </div>
            ))
        }
    </div>
    </div>
   {
    mobileSearch &&  <MobileSearch  setisServiceDropdownOpen={setisServiceDropdownOpen} setisCollegeDropdownOpen={setisCollegeDropdownOpen} setMobileSearch={setMobileSearch} isCollegeDropdownOpen={isCollegeDropdownOpen} selectCollege={selectCollege} collegeList={collegeList} searchQuery={searchQuery} setSearchQuery={setSearchQuery} filteredColleges={filteredColleges} topSearchColleges={topSearchColleges} isServiceDropdownOpen={isServiceDropdownOpen} serviceOptions={serviceOptions} selectService={selectService} toggleCollegeDropdown={toggleCollegeDropdown} selectedCollege={selectedCollege} selectedService={selectedService} toggleServiceDropdown={toggleServiceDropdown} handleSearch = {handleSearch}/>
   }
   
   </>
  )
}

export default Header