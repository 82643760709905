import { useSelector } from "react-redux";

const useFindLocality = () => {
    const localities = useSelector(state=>state.getLocalities.localities);
   
    const findLocalityName = (localityId) => {
        if (!localityId) {
          return "Unknown Locality"; 
        }
        const locality = localities?.find(locality => locality._id === localityId);
        return locality ? locality.name : "Unknown Locality";
      };

      return { findLocalityName};
}

export default useFindLocality;