export const formatDate = (inputDate) => {
    const inputDateTime = new Date(inputDate);
    const day = inputDateTime.getDate();
    const month = inputDateTime.toLocaleString('default', { month: 'short' });
    const year = inputDateTime.getFullYear();

    const outputDate = `${day} ${month} ${year}`;

    return outputDate;
  };

 export  const formatDateAndTime = (dateTimeString) => {
  const dateTime = new Date(dateTimeString);

  // Format the date
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  const formattedDate = dateTime.toLocaleDateString('en-US', options);

  // Format the time
  let hours = dateTime.getHours();
  const minutes = dateTime.getMinutes();
  const amOrPm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12 || 12; // Convert to 12-hour format
  const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')} ${amOrPm}`;

  return { formattedDate, formattedTime };

  };
  export const convertDate = (inputDate)=>{
    const date = new Date(inputDate);
    const isoString = date.toISOString();
    return isoString
  }
 
 export const formatDateTime = (inputDate)=>{
  const date = new Date(inputDate);

  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.getFullYear().toString().slice(2);
  const hour = date.getHours();
  const minute = date.getMinutes();
  const ampm = hour >= 12 ? 'pm' : 'am';
  const finalFormat = `${day} ${month}' ${year} ${hour}:${minute < 10 ? '0' : ''}${minute} ${ampm}`;

  return finalFormat;
 }

export const convertToformat = (date)=>{
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  const milliseconds = String(date.getMilliseconds()).padStart(3, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
}
 export const  combineDateTime = (date, time) => {
  const combinedDateTime = `${date}T${time}Z`;
  return combinedDateTime;
};

export const calculateEndDate = (durationInDays)=>{
  const millisecondsInADay = 24 * 60 * 60 * 1000; 
  const currentDate = new Date(); 
  const endDate = new Date(currentDate.getTime() + durationInDays * millisecondsInADay);
  return endDate?.toISOString();
}