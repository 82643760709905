import React from 'react'
import DemoVideoLogic from "../../components/demoVideo/DemoVideoLogic"
import useFindCollege from '../../utils/findCollege';
import { useLocation } from 'react-router-dom';
import RoutePath from '../../routes/RoutePath';
import BreadCrumbsLogic from '../breadCrumbs/BreadCrumbsLogic';

const SubNavbarCollege = ({navbarData,collegeId, currentPath, navigate, videoUrls, currentSlide}) => {
  const {findCollegeName} = useFindCollege();
  const location = useLocation();
  
  return (
    <div>
      <div className='md:pt-12 md:px-20 px-5 pt-6'>
        <BreadCrumbsLogic className=""/>
    <h1 className='text-secondary tracking-[-0.2px] font-poppins font-semibold text-xl md:text-[56px]   md:leading-[64px]'>{findCollegeName(collegeId)}</h1>
    <div className='overflow-x-scroll  mt-7 no-scrollbar flex items-center space-x-6 border-b border-b-[#D4D4D8]'>
        {
            navbarData && navbarData.map((ele, ind)=>(
                
                <p key={ind} onClick={()=>navigate(ele?.path)} className={`${currentPath?.includes(ele.id) ? 'text-primary border-b border-primary' : 'text-textColor hover-text-primary '} flex-none pb-2 cursor-pointer font-poppins font-medium  text-center  text-base`}>{ele?.heading}</p>
            ))
        }
         </div>
         </div>
    
    </div>
  )
}

export default SubNavbarCollege