import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchLocalAssistData = createAsyncThunk(
    'localAssist/fetchLocalAssistData',
    async ({apibody}) =>{
        try{
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/localAssistance/getLocalAssistancePaged`,{
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(apibody)
            });
            if (response === null) {
                console.log("Response is null");
                throw new Error("Response is null");
            }
          
                if(!response.ok){
                    console.log(`Error fetching local assistance data: ${response.status} - ${response.statusText}`);
                    throw new Error(`Error fetching local assistance data: ${response.status} - ${response.statusText}`);
                }
                const data = await response.json();
                if (data === null || data === undefined) {
                    console.log("Data is null or undefined")
                    throw new Error("Data is null or undefined");
                }
                return data;
        }catch(error){
            console.log("Error fetching local assistance data", error);
            throw error; 
        }
    }
);

const localAssistSlice = createSlice({
    name: "localAssist",
    initialState: {
        localAssistData: [],
        status: 'idle',
        error: null
    },

    reducers: {},
    extraReducers: (builder) => {
        builder
          .addCase(fetchLocalAssistData.pending, (state) => {
            state.status = 'loading';
          })
          .addCase(fetchLocalAssistData.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.localAssistData = action.payload;
            
          })
          .addCase(fetchLocalAssistData.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
          });
      }
})

export default localAssistSlice.reducer;