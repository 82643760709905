import React from 'react'
import SpinnerLogic from '../spinner/SpinnerLogic'
import RoutePath from '../../routes/RoutePath'
import { useNavigate } from 'react-router-dom'
import PaginationLogic from '../pagination/PaginationLogic'
import { VISIT_ITEMS_PER_PAGE } from '../../utils/constants'
import ImageCarouselLogic from '../imageCarousel/ImageCarouselLogic'
import { IoIosStar } from 'react-icons/io'
import useFindLocality from '../../utils/findLocality'
import { formatDateAndTime } from '../../utils/formatDate'
import { CiCalendar } from "react-icons/ci";
import { IoCalendarClearOutline } from "react-icons/io5";
import { IoMdTime } from "react-icons/io";
import Modal from "react-awesome-modal";
import { RxCross2 } from 'react-icons/rx'
import { useState } from 'react'

const RescheduleForm = ({openModal,dateOnly, setOpenModal, handleRescheduleVisit, visitDetails, visitLoading})=>{
    const [formData, setFormData] = useState({ date: "", time: ""})
    
  return(
    <Modal
    visible={openModal}
    width="400"
    height="550"
    effect="fadeInUp"
    >
       {
        visitLoading ? <div className='animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-gray-900'></div> : 
        <div className='flex relative flex-col'>
        <div className='px-6 pt-6 pb-[8px]'>
            <div className='flex flex-col items-center justify-center'>
                <div className='flex items-start'>
                <div className='bg-[#FEE3CD] rounded-[48px] border-[8px] border-[#FFF4EB]'>
                <IoCalendarClearOutline className='text-primary'/>
                </div>
                <RxCross2 onClick={()=>setOpenModal(false)} className='text-[#52525b] absolute top-3 right-3 text-xl'/>
                </div>
                <p className='font-poppins mt-3 text-secondary text-center font-semibold text-lg'>Schedule Visit</p>
            </div>
        <div>
            <div className='w-full flex flex-col my-3'>
            <p className='text-[#3F3F46] font-poppins font-medium text-sm'>Name</p>
            <input value={visitDetails?.userDetails?.name} readOnly type="text" className='blog-button-shadow mt-2 outline-none border border-[#D4D4D8] py-[10px] px-[14px] rounded' />
            </div>
            <div className='w-full flex flex-col my-3'>
            <p className='text-[#3F3F46] font-poppins font-medium text-sm'>Phone number</p>
            <input value={visitDetails?.userDetails?.phone} readOnly type="text" className='blog-button-shadow mt-2  outline-none border border-[#D4D4D8] py-[10px] px-[14px] rounded' />
            </div>
            <div className='w-full flex flex-col my-3'>
            <p className='text-[#3F3F46] font-poppins font-medium text-sm'>Date</p>
            <input min={dateOnly} value={formData?.date} onChange={(e)=>setFormData({...formData, date: e.target.value})} type="date" className='blog-button-shadow mt-2 outline-none border border-[#D4D4D8] py-[10px] px-[14px] rounded' />
            </div>
            <div className='w-full flex flex-col my-3'>
            <p className='text-[#3F3F46] font-poppins font-medium text-sm'>Time</p>
            <input value={formData?.time} onChange={(e)=>setFormData({...formData, time: e.target.value})} type="time" className='blog-button-shadow mt-2 outline-none border border-[#D4D4D8] py-[10px] px-[14px] rounded' />
            </div>
        </div>
        </div>
        <div className='p-6 w-full'>
        <div onClick={()=>{handleRescheduleVisit(visitDetails, formData)}} className='background-primary cursor-pointer justify-center text-white w-full md:w-auto flex items-center space-x-3 px-5 py-3 rounded blog-button-shadow font-poppins font-medium text-base'><IoCalendarClearOutline /><p className=''>Reschedule a visit</p> </div>
        </div>
    </div>
       }

    </Modal>
  )
}

const AllVisits = ({ loading, dateOnly, failed,visitLoading, visitsData, handlePageChange, currentPage, handleRescheduleVisit, openModal, setOpenModal }) => {
    const navigate = useNavigate();
    const [visitDetails, setVisitDetails] = useState(null)
    const {findLocalityName} = useFindLocality();
    return (
        <div>
            {
                loading ? <><SpinnerLogic /></> :
                    (failed ?
                        <div className='flex flex-col w-full min-h-screen  items-center justify-center'>
                            <div className='text-center'>
                                <h1 className="text-secondary font-poppins font-semibold text-5xl">Error fetching visitings data</h1>
                                <p className="text-2xl font-poppins text-textColor mt-6 font-normal">We are facing some internal server error while fetching data, sorry for the inconvinience caused</p>
                                <button onClick={() => navigate(RoutePath.homepage)} className='blog-button-shadow background-primary mt-10 text-white text-lg rounded-[4px] px-4 py-2 font-medium font-poppins'>Go Back</button>
                            </div>
                        </div> :
                        <div className='w-full flex flex-col'>
                            {
                                visitsData?.visits?.length === 0 ?
                                    <div className='min-h-screen w-full flex flex-col items-center justify-center'>
                                        <div className="text-center">
                                            <h1 className="text-secondary font-poppins font-semibold text-5xl">No visits available</h1>
                                            <p className="text-2xl font-poppins text-textColor mt-6 font-normal">There are currently no scheduled visits available, kindly check back later for updates</p>
                                            <button onClick={() => navigate(RoutePath.homepage)} className='blog-button-shadow background-primary mt-10 text-white text-lg rounded-[4px] px-4 py-2 font-medium font-poppins'>Go Back</button>
                                        </div>
                                    </div> :
                                    <div className='mt-[30px]'>
                                        {
                                            visitsData && visitsData?.visits?.map((ele, ind) => (
                                                <div className='flex flex-col my-4 w-full'>
                                                    <div className=' w-full flex flex-col md:flex-row md:space-x-8 items-start'>
                                                        <div className='md:w-4/12 w-full h-[256px]'>
                                                            <ImageCarouselLogic images={ele?.listingDetails?.imgArr} nofavourite={true} />
                                                        </div>
                                                        <div className=' md:w-8/12 w-full mt-5 md:mt-0'>
                                                            <div className='flex md:items-center items-start  justify-between'>
                                                                <p className='font-poppins font-semibold text-[#0F172A] text-base md:text-lg'>{ele?.listingDetails?.apartmentName ? ele?.listingDetails?.apartmentName : ele?.permanentAddress?.addressLine1}</p>
                                                                <div className='block md:hidden'>
                                                                    <p className='text-textColor font-poppins font-normal text-xs'>Rent/month</p>
                                                                    <p className='font-poppins md:text-lg text-sm text-secondary font-semibold'>₹{ele?.listingDetails?.rent} <span className='text-textColor font-poppins font-normal text-sm'>/Inclusive GST</span></p>
                                                                </div>
                                                                <div className='md:flex hidden items-center space-x-3'>
                                                                    {
                                                                        ele?.listingDetails?.averageRating && <div className='flex items-center space-x-2'>
                                                                            <IoIosStar className='text-[#475569]' />
                                                                            <p className='text-[#222222] font-normal text-[15px] leading-[20px]'>{ele?.listingDetails?.averageRating}</p>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                           <div className='flex items-start mt-2 space-x-3'>
                                                           <p className='font-poppins font-normal text-sm text-textColor'>{findLocalityName(ele?.listingDetails?.localityId)}</p>
                                                            {
                                                                ele?.listingDetails?.genderPreference && <p className='font-poppins font-normal text-sm text-textColor'>| {ele?.listingDetails?.genderPreference}</p>
                                                            }
                                                           </div>
                                                            {
                                                                ele?.visitTime && <div className='mt-5 border lg:w-[370px] md:w-[auto] rounded-lg border-[#E4E4E7] p-3'>
                                                                      <p className='font-poppins mt-1 text-sm text-[#3F3F46] font-medium'>We’ve booked a visit slot for you</p>
                                                                     <div className='mt-1 flex items-start space-x-4 w-full'>
                                                                        <div className='flex items-center space-x-2 pr-3 border-r-[2px] border-r-[#E4E4E7]'><CiCalendar className='text-[#16B364] text-2xl font-bold'/><p className='font-poppins font-medium text-base text-[#27272A]'>{formatDateAndTime(ele?.visitTime)?.formattedDate}</p></div>
                                                                        <div className='flex items-center space-x-2'><IoMdTime className='text-[#16B364] text-2xl font-bold'/><p className='font-poppins font-medium text-base text-[#27272A]'>{formatDateAndTime(ele?.visitTime)?.formattedTime}</p></div>
                                                                     </div>
                                                                  
                                                                </div>
                                                            }
                                                            {
                                                                ele?.listingDetails?.features?.length > 0 && <div className='flex mt-7 flex-col items-start'>
                                                                    <p className='font-poppins text-xs font-normal text-textColor'>Amenities</p>
                                                                    <div className='flex mt-2 flex-wrap items-center gap-3'>
                                                                        {
                                                                            ele?.listingDetails?.features.map((ele, ind) => (
                                                                                <div key={ind} className='flex items-center border border-[#E2E8F0] space-x-3 rounded-[24px] px-3 py-2'>
                                                                                    {/* <img src={ele?.image} alt="" className='w-[15px] h-[15px]' /> */}
                                                                                    <p className='font-poppins text-xs text-secondary font-normal'>{ele}</p>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }

                                                            <div className='flex flex-wrap gap-3 items-center mt-6 justify-between w-full'>
                                                                <div className='hidden md:block'>
                                                                    <p className='text-textColor font-poppins font-normal text-xs'>Rent/month</p>
                                                                    <p className='font-poppins text-lg text-secondary font-semibold'>₹{ele?.listingDetails?.rent} <span className='text-textColor font-poppins font-normal text-sm'>/Inclusive GST</span></p>
                                                                </div>
                                                                <div className='flex items-center w-full md:w-auto space-x-3'>
                                                                    {
                                                                        ele?.status === "confirm" && 
                                                                         <div className='flex flex-col md:items-end items-center w-full md:w-auto'>
                                                                         <div onClick={()=>{
                                                                            setOpenModal(true)
                                                                            setVisitDetails(ele)
                                                                            }} className='background-primary cursor-pointer justify-center text-white w-full md:w-auto flex items-center space-x-3 px-5 py-3 rounded blog-button-shadow font-poppins font-medium text-base'><IoCalendarClearOutline /><p className=''>Reschedule a visit</p> </div>
                                                                         <p className='mt-3 text-[#52525B] font-poppins font-normal text-sm'>You can reschedule within 7 day after booking.</p>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        ele?.status === "completed" &&  <div className='text-primary font-poppins font-medium text-base'><p className=''>Visit completed</p> </div>
                                                                    }
                                                                    {
                                                                        ele?.status === "pending" &&  <div className='text-primary font-poppins font-medium text-base'><p className=''>Visit pending</p> </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr className='text-[#D4D4D8] mt-[30px] w-full' />
                                                </div>
                                            ))
                                        }
                                        {
                                          openModal && <RescheduleForm dateOnly={dateOnly} openModal={openModal} setOpenModal={setOpenModal} visitDetails={visitDetails} handleRescheduleVisit={handleRescheduleVisit}/>
                                        }
                                        <PaginationLogic totalPages={Math.ceil(visitsData?.totalVisitsCount / VISIT_ITEMS_PER_PAGE)} currentPage={currentPage} handlePageChange={handlePageChange} />
                                    </div>
                            }
                        </div>
                    )
            }
        </div>
    )
}

export default AllVisits