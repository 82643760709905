import React from 'react'
import truncateText from '../../utils/truncateText'
import {formatDate} from '../../utils/formatDate'
import ImageCarouselLogic from '../imageCarousel/ImageCarouselLogic'

const Blogs = ({blogData, screenWidth, activeDot, dotColors}) => {

  return (
 <>
 {
  blogData?.length > 0 &&   <>
  <div className='lg:py-[96px] py-[32px] px-[20px] mt-[40px] lg:mt-0 lg:px-[80px] background flex flex-col items-center justify-center'>
     <h3 className='font-semibold font-poppins text-center text-[32px] leading-[40px]'>Our Blog</h3>
     <p className='text-textColor font-normal mb-7  font-poppins text-base  text-center mt-3'>The latest industry news, interviews, technologies, and resources.</p>
     <div className={`lg:flex lg:flex-row lg:mt-7 lg:items-start   lg:justify-between  w-full `}>
      <div id='blog-container' className={`flex lg:w-1/2 w-full   overflow-x-scroll  space-x-6 no-scrollbar items-center`}>
      {
 blogData.map((ele, ind) => {
  return (
    screenWidth > 768 && ind > 0 ? (
      <React.Fragment key={ind}></React.Fragment>
    ) : (
      <div key={ind} className='lg:flex lg:flex-col flex  flex-none flex-col  items-start w-full '>
        <div className='w-full lg:h-full h-[250px] rounded-xl'>
          <img src={ele?.blogImages[0]} alt="" className='w-full h-full rounded-xl'/>
        
        </div>
        <p className='font-poppins uppercase font-semibold text-primary lg:text-sm text-xs tracking-[2px] text-center mb-2 mt-5'>{ele?.category}</p>
        <h4 className='text-secondary font-semibold font-poppins lg:text-2xl text-xl'>{truncateText(ele?.heading, 5)}</h4>
        <p className='font-poppins font-normal text-textColor text-base my-2'>{truncateText(ele?.content, 15)}</p>
        <div className='flex items-center mt-5 space-x-2'>
                          <img src={ele?.authorDetails?.imgUrl} alt="profile pic" className='w-10 h-10 rounded-full' />
                          <div className='flex flex-col'>
                              <p className='text-secondary font-poppins font-medium text-sm'>{ele?.authorDetails?.name}</p>
                              <p className='text-textColor font-poppins font-normal text-sm'>{formatDate(ele?.createdOn)}</p>
                          </div>
                      </div>
      </div>
    )
  );
}) 
}

      </div>
      <div className='hidden w-[47%] lg:flex lg:flex-col lg:items-start'>
        {
         blogData.map((ele, ind) => {
            return (
               ind > 4 || ind === 0 ? (
                <React.Fragment key={ind}></React.Fragment>
              ) : (
                <div key={ind} className={`flex flex-row space-x-4 ${ind === 1 ? 'pb-5' : 'py-5'} ${ind !== 4 && 'border-b border-[#D4D4D8]'} justify-between items-start w-full `}>
                  <img src={ele?.blogImages[0]} alt="" className='w-[188px] rounded-lg h-full  bg-cover'/>
                  <div className='w-full'>
                  <p className='font-poppins uppercase font-semibold text-primary text-sm tracking-[2px] mb-2 '>{ele?.category}</p>
                  <h4 className='text-secondary font-semibold font-poppins text-xl'>{truncateText(ele?.heading, 5)}</h4>
                  <p className='font-poppins w-full font-normal text-textColor text-base my-2'>{truncateText(ele?.content, 15)}</p>
                  
                  </div>
                </div>
              )
            );
          }) 
        }

      </div>
     </div>
  {
    blogData?.length > 1 &&    <div className="flex lg:hidden mt-6 items-center text-center mx-auto w-28 ">
    {[...Array(5).keys()].map((index) => {
          const size = 10 - Math.abs(index - activeDot);
          return (
            <div
              key={index}
              className={` mx-1 rounded-full cursor-pointer`}
              style={{
                width: `${size}px`,
                height: `${size}px`,
                backgroundColor: index === activeDot ? dotColors[0] : dotColors[index],
              }}
            ></div>
          );
        })}
  </div>
  }
     {blogData?.length > 5 && <button className='w-[126px] mt-[40px] hidden hover:bg-[#E4E4E7] lg:block blog-button-shadow h-[48px] rounded-[4px] py-[12px] px-[20px] text-secondary border border-[#E4E4E7]'>View more</button> }
      </div>
      <hr className='w-[90%] mx-auto bg-[#D4D4D8] h-[1px]'/>
 </>
 }
 </>
  )
}

export default Blogs