import React from 'react'
import { FaArrowLeft } from "react-icons/fa6";
import { FaArrowRight } from "react-icons/fa6";
import headAvatar from "../../assets/images/headAvatar.svg"
import truncateText from "../../utils/truncateText"
import background from '../../assets/images/contactUs-alt.png';
import useFindCollege from '../../utils/findCollege';

const TestimonialCarousel = ({ handleNextClick,
  handlePrevClick,
  currentIndex,
  testimonialData,
  page
}) => {
  const currentTestimonial = testimonialData?.[currentIndex];
  const { findCollegeName } = useFindCollege();
  return (
    <>

      <div className={` lg:w-auto w-1/2 relative overflow-hidden bg-cover flex items-center justify-center  h-3/4`}>
        <>
          {
            !page && testimonialData?.length > 0 &&
            <><div className='absolute z-10 w-[90%] mx-auto min-h-[260px] bg-white rounded-[12px] bottom-[34px] left-1/2 transform -translate-x-1/2 p-7'>
              <h6 className='font-poppins font-medium text-secondary text-[23px] leading-[32px] tracing-[-0.2px]'> {truncateText(currentTestimonial?.description, 20)}</h6>
              <div className='md:flex md:flex-row flex flex-col md:items-center items-start space-y-5 md:space-y-0 md:justify-evenly  w-full  mt-[50px]'>
                <img src={headAvatar} alt="avatar" className='w-12 h-12 rounded-full' />
                <div className='flex flex-col'>
                  <p className='text-secondary font-poppins font-medium text-lg'>{currentTestimonial?.user?.name}</p>
                  <p className='text-textColor font-normal font-poppins text-base'>{findCollegeName(currentTestimonial?.user?.college)}</p>
                </div>
                <div className='flex items-center space-x-6'>
                  <button disabled={currentIndex === 0} onClick={handlePrevClick} className='border-[1px] cursor-pointer h-14 w-14 flex items-center justify-center rounded-full border-[#D4D4D8]'><FaArrowLeft className={` ${currentIndex === 0 ? 'text-[#A1A1AA]' : 'text-[#52525B]'}`} /></button>
                  <button disabled={currentIndex === testimonialData?.length - 1} onClick={handleNextClick} className='border-[1px] cursor-pointer h-14 w-14 flex items-center justify-center rounded-full border-[#D4D4D8]'><FaArrowRight className={`${currentIndex === testimonialData?.length - 1 ? 'text-[#A1A1AA]' : 'text-[#52525B]'}`} /></button>
                </div>
              </div>
            </div>
              <div className='absolute z-10 bottom-[13px] left-1/2 transform -translate-x-1/2 flex space-x-2'>
                {Array.from({ length: testimonialData?.length }).map((_, index) => (
                  <div
                    key={index}
                    className={`w-[10px] h-[10px] rounded-full ${index === currentIndex ? 'bg-white' : 'bg-[#E4E4E7]'
                      } cursor-pointer`}

                  ></div>
                ))}
              </div>
            </>}
        </>

        <img src={background} alt='' className={`w-8/12   ${!page && 'rounded-tl-[30px] rounded-bl-[30px]'}`} />
      </div>

    </>
  )
}

export default TestimonialCarousel