import React, { useEffect, useRef, useState } from 'react'
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { IoChevronDown } from "react-icons/io5";
import truncateText from '../../utils/truncateText';
import filterIcon from "../../assets/images/filterIcon.png"
import { RxCross2 } from "react-icons/rx";
import { showErrorToast } from '../../utils/toastNotif';


const MobileFilter = ({propertySize, setPropertySize, furnishing, setFurnishing, handleSliderChange, toggleCategory, localAssistCategories, minRent, maxRent, setCategoryId, handleApplyFilter, genderPreference, setGenderPreference,apibody, setOpenMobileFilter, sortOptions, serviceType, categoriesData, categoryId, setSortValue, sortValue, handleClear})=>{
 
  const options = ["Boys", "Girls", "Unisex"]
  const propertyOptions = ["1 RK", "1 BHK", "2 BHK", "3 BHK", "4 BHK"];
  const furnishingOptions = ["Semi Furnished", "Fully Furnished", "Unfurnished"]
  const categoryoptions = categoriesData?.filter((ele)=>ele?.name === serviceType);
  
  return(
    <div className='fixed top-0 left-0 w-full h-full flex items-center justify-center z-50'>
         <div className='fixed top-0 left-0 w-full h-full bg-black opacity-30 backdrop-filter backdrop-blur-md'></div>
         <div className='relative w-full h-full top-[60px] z-50 bg-white rounded-tl-[24px] rounded-tr-[24px] '>
         <div className='w-full justify-between flex items-center border-b border-[#E4E4E7] py-4 px-5'>
        <RxCross2 className='text-[#52525B] text-xl' onClick={()=>setOpenMobileFilter(false)}/>
          <p className='text-xl font-poppins font-medium text-secondary'>Filter</p>
          <p onClick={handleClear}  className='font-poppins blog-button-shadow text-base text-[#A1A1AA] font-medium'>Clear</p>
        </div>
        <div style={{ maxHeight: 'calc(100% - 160px)' }} className='flex flex-col items-start overflow-y-auto w-full py-6 px-5'>
         {/* sort by */}
         <div className='flex flex-col items-start'>
          <p className='font-poppins mb-3 text-secondary text-base font-medium'>Sort By</p>
          {
            sortOptions && sortOptions?.map((ele, ind)=>(
              <div key={ind} className='flex items-center my-1 space-x-3'>
                 <button onClick={()=>setSortValue(`${ele?.sortKey}-${ele?.sortValue}`)} className={`w-[20px] h-[20px] border border-[#D4D4D8] ${sortValue === `${ele?.sortKey}-${ele?.sortValue}` ? 'bg-[#3F3F46]' : 'bg-white'} rounded-full`}></button>
                <p>{ele?.label}</p>
              </div>
            ))
          }
         </div>
         {/* gender */}
        {
          serviceType === "listing" &&  <div className='flex flex-col mt-5 items-start'>
          <p className='font-poppins mb-3 text-secondary text-base font-medium'>Gender</p>
          {
            options && options?.map((ele, ind)=>(
              <div key={ind} className='flex items-center my-1 space-x-3'>
                 <button onClick={()=>setGenderPreference(ele)} className={`w-[20px] h-[20px] border border-[#D4D4D8] ${genderPreference === ele ? 'bg-[#3F3F46]' : 'bg-white'} rounded-full`}></button>
                <p>{ele}</p>
              </div>
            ))
          }
         </div>
        }

        {/* Property type */}
        {
          serviceType === "listing" && apibody?.filters?.typeOfProperty === "flat" && <div className='flex flex-col mt-5 items-start'>
             <p className='font-poppins mb-3 text-secondary text-base font-medium'>Property type</p>
             {
            propertyOptions && propertyOptions?.map((ele, ind)=>(
              <div key={ind} className='flex items-center my-1 space-x-3'>
                 <button onClick={()=>setPropertySize(ele)} className={`w-[20px] h-[20px] border border-[#D4D4D8] ${propertySize === ele ? 'bg-[#3F3F46]' : 'bg-white'} rounded-full`}></button>
                <p>{ele}</p>
              </div>
            ))
          }
          </div>
        }
        {/* furnishing type */}
        {
          serviceType === "listing" && apibody?.filters?.typeOfProperty === "flat" && <div className='flex flex-col mt-5 items-start'>
             <p className='font-poppins mb-3 text-secondary text-base font-medium'>Furnishing type</p>
             {
            furnishingOptions && furnishingOptions?.map((ele, ind)=>(
              <div key={ind} className='flex items-center my-1 space-x-3'>
                 <button onClick={()=>setFurnishing(ele)} className={`w-[20px] h-[20px] border border-[#D4D4D8] ${furnishing === ele ? 'bg-[#3F3F46]' : 'bg-white'} rounded-full`}></button>
                <p>{ele}</p>
              </div>
            ))
          }
          </div>
        }
         {/* category */}
        {
          (serviceType === "furnishing") && (categoryoptions) &&  <div className='flex flex-col mt-5 items-start'>
          <p className='font-poppins mb-3 text-secondary text-base font-medium'>{serviceType === "furnishing" ? 'Category' : 'Type'}</p>
          {
            categoryoptions && categoryoptions[0]?.value?.map((ele, ind)=>(
              <div className='flex items-center my-1 space-x-3'>
                 <button onClick={()=>setCategoryId(ele?._id)} className={`w-[20px] h-[20px] border border-[#D4D4D8] ${categoryId === ele?._id ? 'bg-[#3F3F46]' : 'bg-white'} rounded-full`}></button>
                <p>{ele?.name}</p>
              </div>
            ))
          }
         </div>
        }
        {
          (serviceType === "localAssistance") && (localAssistCategories) &&  <div className='flex flex-col mt-5 items-start'>
          <p className='font-poppins mb-3 text-secondary text-base font-medium'>{serviceType === "furnishing" ? 'Category' : 'Type'}</p>
          {
            localAssistCategories && localAssistCategories?.map((ele, ind)=>(
              <div key={ind} className='flex items-center my-1 space-x-3'>
              <input
                type="checkbox"
                checked={categoryId?.includes(ele?._id)}
                onChange={() => toggleCategory(ele?._id)}
                className="mr-2 cursor-pointer"
              />
              <p>{ele?.name}</p>
            </div>
            ))
          }
         </div>
        }
        <div className='flex flex-col mb-5 mt-5 '>
        <p className='font-poppins mb-3 text-secondary text-base font-medium'>{serviceType === "listing" ? 'Budget' : 'Price'}</p>
                <div className="my-2">
                  <Slider
                    min={0}
                    max={50000}
                    value={[minRent, maxRent]}
                    onChange={handleSliderChange}
                    className=""
                    railClassName="slider-rail" 
                    trackClassName="slider-track"
                    range
                    allowCross={false}
                  />
                  <div className="flex w-full space-x-3 mt-5 items-center">
                    <div className="py-[4px] h-[44px] font-bold px-[14px] blog-button-shadow border border-[#D4D4D8] flex items-center rounded-[6px]">
                      <p className='font-poppins text-xs font-normal text-[#475467]'>Min.</p>
                      <p className='w-[36px] border border-[#E4E4E7] transform -rotate-90'></p>
                      <p className='font-poppins text-base text-secondary font-medium'>₹{minRent}</p>
                    </div>
                    <div className="py-[4px] h-[44px] font-bold px-[14px] blog-button-shadow border border-[#D4D4D8] flex items-center rounded-[6px]">
                      <p className='font-poppins text-xs font-normal text-[#475467]'>Max.</p>
                      <p className='w-[36px] border border-[#E4E4E7] transform -rotate-90'></p>
                      <p className='font-poppins text-base text-secondary font-medium'>₹{maxRent}</p>
                    </div>
                   
                  </div>
                </div>
        </div>
        </div>
        <div className='w-full fixed bottom-0 py-6 px-5'>
          <button onClick={()=>{
            handleApplyFilter();
            setOpenMobileFilter(false)
          }} className='background-primary w-full  blog-button-shadow py-3 px-5 rounded-[4px] text-white text-base font-poppins font-medium'>Apply</button>
        </div>
         </div>
    </div>
  )
}

const PropertyTypeFilter = ({apibody, handlePropertyChange})=>{
  const options = apibody?.filters?.typeOfProperty === "flat" ? ["1 RK", "1 BHK", "2 BHK", "3 BHK", "4 BHK"] : ["single", "double", "triple", "quad"];
  return(
    <select value={apibody?.furnishing} onChange={(e)=>handlePropertyChange(e.target.value)} className='border outline-none border-[#D4D4D8] font-poppins font-normal cursor-pointer text-lg text-[#3F3F46] rounded-xl w-[168px] py-[14px] px-4'>
      <option value="">Property size</option>
      {
        options.map((ele, ind)=>(
          <option value={ele} key={ind}>{ele}</option>
        ))
      }
    </select>
  )
}

const FurnishingFilter = ({apibody, handleFurnishingChange})=>{
  const options = ["Semi Furnished", "Fully Furnished", "Unfurnished"];
  return(
    <select value={apibody?.furnishing} onChange={(e)=>handleFurnishingChange(e.target.value)} className='border outline-none border-[#D4D4D8] font-poppins font-normal cursor-pointer text-lg text-[#3F3F46] rounded-xl w-[168px] py-[14px] px-4'>
      <option value="">Furnishing</option>
      {
        options.map((ele, ind)=>(
          <option value={ele} key={ind}>{ele}</option>
        ))
      }
    </select>
  )
}

const GenderFilter = ({ handleGenderChange, apibody})=>{
  const options = ["Boys", "Girls", "Unisex"]
  return(
    <select value={apibody?.genderPreference} onChange={(e)=>handleGenderChange(e.target.value)} className='border outline-none border-[#D4D4D8] font-poppins font-normal cursor-pointer text-lg text-[#3F3F46] rounded-xl w-[168px] py-[14px] px-4'>
      <option value="">Gender</option>
      {
        options.map((ele, ind)=>(
          <option value={ele} key={ind}>{ele}</option>
        ))
      }
      
    </select>
    
    
  )
}

const PriceRangeFilter = ({dropdownRef,openPriceRange,setMaxRent, setMinRent, handleClearRange, setOpenPriceRange, handlePriceRange, maxRent, minRent, serviceType})=>{
  return(
    <>
    <div ref={dropdownRef} onClick={()=>setOpenPriceRange(!openPriceRange)} className='border relative cursor-pointer outline-none border-[#D4D4D8] font-poppins flex items-center justify-between font-normal text-lg text-[#3F3F46] rounded-xl w-[168px] py-[14px] px-6'>
      <p>{serviceType === "listing" ? 'Budget' : 'Price'}</p>
      <IoChevronDown />
      {openPriceRange && (
        <div onClick={(e)=>e.stopPropagation()} className='absolute z-50 top-16 header-box-shadow right-0 bg-white  p-4'>
          <div className="flex  flex-col space-y-4">
            <div className="flex flex-col">
              <label className='text-sm' htmlFor="minRent">Min Rent:</label>
              <input
                type="number"
                id="minRent"
                min={0}
                value={minRent}
                onChange={(e) => {
                  const value = parseInt(e.target.value);
                  if(value < 0){
                    showErrorToast("value cannot be negative")
                  }else if(value > 50000){
                     showErrorToast("Maximum value limit exceed")
                  }else{
                    setMinRent(parseInt(e.target.value))
                  }
                }}
                className="border outline-none border-[#D4D4D8] font-poppins font-normal text-sm text-[#3F3F46] rounded-xl py-2 px-4"
              />
            </div>
            <div className="flex flex-col">
              <label className='text-sm' htmlFor="maxRent">Max Rent:</label>
              <input
                type="number"
                id="maxRent"
                min={0}
                value={maxRent}
                max={50000}
                onChange={(e) => {
                  const value = parseInt(e.target.value);
                  if(value < 0){
                    showErrorToast("value cannot be negative")
                  }else if(value > 50000){
                     showErrorToast("Maximum value limit exceed")
                  }else{
                    setMaxRent(parseInt(e.target.value))
                  }
                }}
                className="border outline-none border-[#D4D4D8] font-poppins font-normal text-sm text-[#3F3F46] rounded-xl py-2 px-4"
              />
            </div>
            <div className="flex items-center justify-between space-x-2">
        <button onClick={handleClearRange} className="bg-gray-200 text-gray-700 text-xs rounded-md py-2 px-4">
          Clear Range
        </button>
        <button onClick={handlePriceRange} className="background-primary text-white text-xs rounded-md py-2 px-4">
          Go
        </button>
      </div>
          </div>
        </div>
      )}
    </div>
       
    </>
  )
}


const SortBy = ({sortOptions, sortkey,value, onSortChange })=>{
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const handleSelect = (value) => {
    onSortChange(value);
    setIsOpen(false);
  };
  const getLabelFromSortKey = (key, value) => {
    const option = sortOptions?.find(option => option.sortKey === key && option.sortValue === value);

    return option ? option.label : '';
  };
  useEffect(() => {
    // Function to close dropdown when clicking outside
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Unbind the event listener on cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return(
    <div ref={dropdownRef} className='relative'>
      <div
        className='border cursor-pointer outline-none border-[#D4D4D8] font-poppins font-normal text-lg text-[#3F3F46] rounded-xl w-[220px] py-[14px] px-4'
        onClick={() => setIsOpen(!isOpen)}
      >
       <p className='flex items-center justify-between w-full'> Sort by: <span className='text-primary'>{truncateText(getLabelFromSortKey(sortkey, value), 1)}</span><div><IoChevronDown /></div></p>
      </div>
      {isOpen && (
        <div className='absolute top-full left-0 mt-1 w-[250px] bg-white shadow-lg border border-gray-200 rounded-md z-50'>
          <ul>
            {sortOptions.map((option, index) => (
              <li
                key={index}
                className='cursor-pointer py-2 px-4 hover:bg-gray-100'
                onClick={() => handleSelect(`${option?.sortKey}-${option?.sortValue}`)}
              >
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>

  )
}

const CategoryFilter = ({categoriesData, serviceType, handleCategoryChange, apibody, localAssistCategories, toggleDropdown, isOpen, selectedCategories, handleCheckboxChange, handleApply})=>{
  const isFurnishing = serviceType === "furnishing";
  const isLocalAssist = serviceType === "localAssistance";
  const options = isFurnishing ? categoriesData?.filter((ele) => ele?.name === serviceType) : localAssistCategories;
  return(
    <>
    {isLocalAssist ? (
     <div className="relative">
     <button
       onClick={toggleDropdown}
       className='border outline-none flex justify-between items-center border-[#D4D4D8] font-poppins font-normal cursor-pointer text-lg text-[#3F3F46] rounded-xl w-[168px] py-[14px] px-4'
     >
      <p> {serviceType === "furnishing" ? 'Category' : 'Type'}</p>
       <IoChevronDown />
     </button>
     {isOpen && (
       <div className="absolute top-full left-0 z-50 bg-white border border-[#D4D4D8] py-[14px] px-4 rounded-xl shadow-lg">
         {options?.map((ele, ind) => (
           <label key={ind} className="block font-poppins text-lg text-[#3F3F46] font-normal my-2 cursor-pointer">
             <input
               type="checkbox"
               value={ele?._id}
               checked={selectedCategories?.includes(ele?._id)}
               onChange={() => handleCheckboxChange(ele?._id)}
               className="mr-2 cursor-pointer"
             />
             {ele?.name}
           </label>
         ))}
         <button onClick={handleApply} className="block w-full px-4 py-2 text-center background-primary rounded-[4px] text-white font-poppins font-medium text-sm">
           Apply
         </button>
       </div>
     )}
   </div>
    ) : (
      <select 
        value={apibody?.filters?.categoryId || ""} 
        onChange={(e) => handleCategoryChange(e.target.value)} 
        className='border outline-none border-[#D4D4D8] font-poppins font-normal cursor-pointer text-lg text-[#3F3F46] rounded-xl w-[168px] py-[14px] px-4'
      >
        <option value="">{serviceType === "furnishing" ? 'Category' : 'Type'}</option>
        {options[0]?.value?.map((ele, ind) => (
          <option value={ele?._id} key={ind}>{ele?.name}</option>
        ))}
      </select>
    )}
  </>
  )
}

const Filters = ({ dropdownRef,propertySize, setPropertySize, furnishing, setFurnishing,toggleDropdown, handleFurnishingChange,handlePropertyChange, toggleCategory, isOpen, selectedCategories, handleCheckboxChange, handleApply, localAssistCategories,handleSliderChange, genderPreference, setGenderPreference,apibody, setApibody, setCategoryId, rentValue, setRentValue,handleClear, handleApplyFilter, categoryId, handleGenderChange,handleCategoryChange, openPriceRange, setMaxRent, setMinRent, handleClearRange, handlePriceRange, maxRent, minRent, setOpenPriceRange, sortOptions, sortkey, value, onSortChange, serviceType, categoriesData, openMobileFilter, setOpenMobileFilter, setSortValue, sortValue}) => {
  return (
  <div className={`relative ${serviceType === "listing" ? 'md:w-[70%]' : 'w-full'}`}>
  {/* desktop filter */}
   <div className={`${serviceType === "listing" ? ' flex md:flex-wrap gap-y-2 justify-between items-center' : "w-full flex justify-between items-center"} md:flex  hidden`}>
    <div className=' hidden md:flex  md:gap-3'>
    {
       serviceType === "listing" && <GenderFilter  handleGenderChange={handleGenderChange} apibody={apibody} setApibody={setApibody}/>
    }
    
    {
      (serviceType === "furnishing" || serviceType === "localAssistance") && <CategoryFilter toggleDropdown={toggleDropdown} isOpen={isOpen} selectedCategories={selectedCategories} handleCheckboxChange={handleCheckboxChange} handleApply={handleApply} localAssistCategories={localAssistCategories} apibody={apibody} handleCategoryChange={handleCategoryChange} categoriesData={categoriesData} serviceType={serviceType}/>
    }
    <PriceRangeFilter dropdownRef={dropdownRef} openPriceRange={openPriceRange} setMaxRent={setMaxRent} setMinRent={setMinRent} handleClearRange={handleClearRange} setOpenPriceRange={setOpenPriceRange}  handlePriceRange={handlePriceRange} maxRent={maxRent} minRent={minRent} serviceType={serviceType}/>
   
    {
      (serviceType === "listing" ) && <>
      
      <PropertyTypeFilter apibody={apibody} handlePropertyChange={handlePropertyChange}/>
      <FurnishingFilter  apibody={apibody} handleFurnishingChange={handleFurnishingChange}/>
      </>
    }
    </div>
    <SortBy sortOptions={sortOptions} onSortChange={onSortChange} sortkey={sortkey} value={value}/>
   </div>


   {/* Mobile filter */}
   <div className={`${serviceType === "listing" ? 'w-auto': 'w-full'} justify-between flex md:hidden items-center space-x-4`}>
    <div onClick={()=>setOpenMobileFilter(true)} className='flex cursor-pointer items-center rounded-lg py-2 px-3 border border-[#D4D4D8] font-poppins text-[#3F3F46] text-sm font-normal'>
      <p className="mr-2">Filters</p>
      <img src={filterIcon} alt="" />
    </div>
    
   </div>
   {
    openMobileFilter && <MobileFilter propertySize={propertySize} setPropertySize={setPropertySize} furnishing={furnishing} setFurnishing={setFurnishing} toggleCategory={toggleCategory} localAssistCategories={localAssistCategories} handleSliderChange={handleSliderChange} minRent={minRent} maxRent={maxRent} setGenderPreference={setGenderPreference} genderPreference={genderPreference} apibody={apibody} categoryId={categoryId} setCategoryId={setCategoryId}   rentValue={rentValue} setRentValue={setRentValue} handleClear={handleClear} handleApplyFilter={handleApplyFilter} openMobileFilter={openMobileFilter} setOpenMobileFilter={setOpenMobileFilter} sortOptions={sortOptions} serviceType={serviceType} categoriesData={categoriesData}  setSortValue={setSortValue} sortValue={sortValue}/>
   }
  </div>
  )
}

export default Filters