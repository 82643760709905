import React from 'react'
import FirebaseOtpModal from './FirebaseOtpModal'

const FirebaseOtpModalLogic = ({checkLoginOrSignup,signup , verifyAndLogin, openModal, setOpenModal, phoneNumber, setOtp, otp, verifying, otperrormsg, setOtpErrormsg, timerotp, confirmationResult, setLoading, setConfirmationResult, settimerotp, handleLoginWithOtp}) => {
  return (
    <><FirebaseOtpModal 
    checkLoginOrSignup={checkLoginOrSignup}
    signup={signup}
    verifyAndLogin={verifyAndLogin}
     openModal={openModal}
     setOpenModal={setOpenModal}
     phoneNumber={phoneNumber}
     setOtp={setOtp}
     otp={otp}
     verifying={verifying}
     otperrormsg={otperrormsg}
     setOtpErrormsg={setOtpErrormsg}
     timerotp={timerotp}
      confirmationResult={confirmationResult}
      setLoading={setLoading} 
      
      setConfirmationResult={setConfirmationResult}
     
      settimerotp={settimerotp}
      handleLoginWithOtp={handleLoginWithOtp}
    /></>
  )
}

export default FirebaseOtpModalLogic