import React, {useState, useEffect} from 'react'
import OccupiedProperty from "./OccupiedProperty"
import { useSelector } from 'react-redux';
import RoutePath from '../../routes/RoutePath';
import { useLocation, useNavigate } from 'react-router-dom';
import { showErrorToast, showInfoToast } from '../../utils/toastNotif';

const OccupiedPropertyLogic = ({setActiveOption}) => {
  const screenWidth = useSelector(state=>state.screenWidth.screenWidth)
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("user_Id");
  const navigate = useNavigate();
  const location = useLocation();
  const phoneNumber = localStorage.getItem("phoneNumber")
  const [occupiedPropertyData, setOccupiedPropertyData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  useEffect(()=>{
    const fetchData = async()=>{
      try{
        setLoading(true)
        let url;
        if (userId && userId!== "undefined" && userId!=="") {
          url = `${process.env.REACT_APP_API_ENDPOINT}/api/occupiedProperties/getOccupiedPropertyOfAUser?userId=${userId}`;
        } else if (phoneNumber && phoneNumber !== "undefined" && phoneNumber !== "" && parseInt(phoneNumber) !== -1) {
          url = `${process.env.REACT_APP_API_ENDPOINT}/api/occupiedProperties/getOccupiedPropertyOfAUser?userPhone=${phoneNumber}`;
        } else {
          showErrorToast("kindly login again to continue");
          navigate(RoutePath.login, {state: {data: location?.pathname + location?.search}});
          return;
        }
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
          },
         });
       
         if(response === null){
          throw new Error("Response is null");
         }

         if(!response.ok){
           if(response.status === 401){
             showInfoToast("kindly login again to continue");
             navigate(RoutePath.login, {state: {data: location?.pathname + location?.search}});
             return;
            
           }
           else{
            const message = await response.text();
            const parsedMessage = JSON.parse(message);
           
              showInfoToast(parsedMessage?.message);
              return;
            
           }
         }
        const jsonData = await response.json();
        if(jsonData === null || jsonData === undefined){
          throw new Error("Data is null");
        }
        setOccupiedPropertyData(jsonData)
      }catch(error){
        console.log("Error fetching occupied property data", error)
        showErrorToast("Error fetching occupied property data");
      }
      finally{
        setLoading(false);
      }
    }
  fetchData();
  }, [])

  
  return (
    <><OccupiedProperty setActiveOption={setActiveOption} occupiedPropertyData={occupiedPropertyData} loading={loading} setLoading={setLoading} screenWidth={screenWidth} openModal={openModal} setOpenModal={setOpenModal} /></>
  )
}

export default OccupiedPropertyLogic