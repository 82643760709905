import React from 'react'
import ListingCard from './ListingCard'
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import RoutePath from '../../routes/RoutePath';

const ListingCardLogic = ({ele, collegeId}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const screenWidth = useSelector(state=>state.screenWidth.screenWidth)
  const handleNavigate = (ele)=>{
    console.log("ele",ele);
    const encodedEleString = btoa(encodeURIComponent(JSON.stringify(ele)));
    const name = ele?.permanentAddress?.addressLine1;
    const url = `${RoutePath.collegeListingById?.replace(":listingId", ele?._id)?.replace(":id", collegeId)}?data=${encodedEleString}`;
    console.log("url" , url);
    navigate(url, { state: { data: ele } });
  }
  return (
    <><ListingCard ele={ele} location={location} handleNavigate={handleNavigate} collegeId={collegeId} screenWidth={screenWidth}
    /></>
  )
}

export default ListingCardLogic