import React from 'react'

const ImagesLayout = ({imagesUrl, id})=>{
    let flexClass = '';

  if (id === 'localAssistance') {
    flexClass = 'flex-row space-x-3 ';
  } else if (id === 'flat') {
    flexClass = 'flex-col space-y-3';
  } else if (id === 'rental') {
    flexClass = 'flex-row-reverse space-x-3';
  } else if (id === 'flatmate') {
    flexClass = 'flex-col-reverse space-y-3';
  }

    return(
        <div className={` md:w-1/2 flex ${flexClass} `}>
           <img src={imagesUrl[0]} alt="" className={`rounded-xl ${id === 'flatmate' || id=== 'flat' ? 'w-full' : 'w-1/2'} ${id === 'flatmate' ? 'mt-3' : id === 'rental' ? 'ml-3' : ''} `}/>
           <div className={`${id === 'flatmate' || id=== 'flat' ? 'flex-row md:space-x-3 space-x-2' : 'flex-col space-y-3'} flex items-center `}>
              <img src={imagesUrl[1]} alt="" className={`rounded-xl  ${id === 'flatmate' || id=== 'flat' ? 'w-[49%]' : 'h-[49%]'}`}/>
              <img src={imagesUrl[2]} alt="" className={`rounded-xl  ${id === 'flatmate' || id=== 'flat' ? 'w-[49%]' : 'h-[49%]'}`}/>
           </div>
        </div>
    )
}

const TextLayout = ({title, content, uspPoints})=>{
    return(
        <div className='md:w-[45%] flex flex-col items-start'>
            <h4 className='font-poppins font-semibold text-secondary tracking-[-0.2px] leading-[44px] text-[36px]'>{title}</h4>
            <p className='text-textColor mt-3 font-poppins text-lg font-normal text-justify '>{content}</p> 
            <div className="flex  w-full  space-x-3 mt-6">
            <span className=' bg-[#FFF4EB] border-2 border-[#FFF4EB] p-[10px] font-semibold rounded-full text-center text-[#F07C22] font-poppins text-sm'>{uspPoints[0]}</span>
            <span className=' bg-[#FFF4EB] border-2 border-[#FFF4EB] p-[10px] font-semibold rounded-full text-center text-[#F07C22] font-poppins text-sm'>{uspPoints[1]}</span>
            <span className=' bg-[#FFF4EB] border-2 border-[#FFF4EB] p-[10px] font-semibold rounded-full text-center text-[#F07C22] font-poppins text-sm'>{uspPoints[2]}</span>

            </div>
            {/* <button className='w-[130px] hover:bg-[#E4E4E7] mt-6 h-[44px] border-2 border-[#E4E4E7] bg-white py-[10px] px-[16px] rounded-[4px] text-center text-[#3F3F46] font-poppins font-medium text-sm'>Learn more</button> */}
        </div>
    )
}

const Features = ({featureData, screenWidth}) => {
  return (
    <div className='md:py-[50px] py-[56px] px-[20px] background md:px-[80px] flex flex-col items-center justify-center w-full'>
        <h2 className='text-secondary font-poppins font-semibold text-[32px] leading-10 text-center'>Bachelor Housing...Resolved !</h2>
        <p className='text-textColor font-normal md:mb-20  font-poppins text-base text-center my-3 '>Bringing balance between your Expense and Living Standards</p>
        <div className='flex mt-[20px]  md:space-y-24 space-y-10 flex-col items-center w-full'>
        {screenWidth > 768 ? featureData &&
          featureData.map((ele, ind) => {
            return ind % 2 === 0 ? (
              <div className='flex justify-between w-full items-center' key={ind}>
                <ImagesLayout imagesUrl={ele.image_urls} id={ele.id} />
                <TextLayout title={ele?.title} content={ele?.content} uspPoints={ele?.uspPoints} />
              </div>
            ) : (
              <div className='flex w-full justify-between items-center' key={ind}>
                <TextLayout title={ele.title} content={ele.content} uspPoints={ele?.uspPoints} />
                <ImagesLayout imagesUrl={ele.image_urls} id={ele.id} />
              </div>
            );
          })
          :
          featureData && featureData.map((ele, ind)=>(
            <div id={ele.id} key={ind} className='flex space-y-6 bg-white border border-[#E4E4E7] rounded-lg px-6  py-8 flex-col items-center'>
                <h4 className='font-poppins text-2xl font-semibold text-secondary text-center'>{ele?.title}</h4>
                <ImagesLayout imagesUrl={ele.image_urls} id={ele.id}/>
                 <p className='text-textColor font-poppins text-base font-normal text-justify'>{ele?.content}</p>
                 <div className="flex flex-wrap items-center justify-evenly  w-full  mt-3">
            <span className={ `${ele.id==='flat' || ele.id==='rental' ? 'hidden' : '' }  bg-[#FFF4EB] border-2 border-[#FFF4EB] p-[8px] min-w-fit font-semibold rounded-full text-center text-[#F07C22] font-poppins text-[10px] m-1`}>{ele?.uspPoints[0]}</span>
            <span className=' bg-[#FFF4EB] border-2 border-[#FFF4EB] p-[8px] min-w-fit font-semibold rounded-full text-center text-[#F07C22] font-poppins text-[10px] m-1'>{ele?.uspPoints[1]}</span>
            <span className=' bg-[#FFF4EB] border-2 border-[#FFF4EB] p-[8px] min-w-fit font-semibold rounded-full text-center text-[#F07C22] font-poppins text-[10px] m-1'>{ele?.uspPoints[2]}</span>

            </div>
            {/* <button className='w-[130px] hover:bg-[#E4E4E7] mt-6 h-[44px] border-2 border-[#E4E4E7] bg-white py-[10px] px-[16px] rounded-[4px] text-center text-[#3F3F46] font-poppins font-medium text-sm'>Learn more</button> */}

            </div>
          ))
        }

        </div>

    </div>
  )
}

export default Features