import React, { useEffect, useState } from 'react'
import ScheduleVisit from './ScheduleVisit'
import { useSelector } from 'react-redux'
import validationRules from '../../utils/validationRules'
import { validateField } from '../../utils/formValidationFunctions'
import { checkForErrors } from '../../utils/checkForErrors';
import { combineDateTime } from '../../utils/formatDate'
import {showErrorToast, showInfoToast, showSuccessToast} from '../../utils/toastNotif'
import { useLocation, useParams } from 'react-router-dom';
import {fetchAddVisit} from "../../redux/slices/addVisitSlice";
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import RoutePath from '../../routes/RoutePath'
import LoginLogic from '../../pages/login/LoginLogic'

const ScheduleVisitLogic = ({data, openVisitForm, setOpenVisitForm}) => {
    const screenWidth = useSelector(state=>state.screenWidth.screenWidth)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [collegeId, setCollegeId] = useState(null);
    const phoneNumber = localStorage.getItem('phoneNumber');
    const userData = (localStorage.getItem("userData")  && localStorage.getItem("userData") !== "undefined") ? JSON.parse(localStorage.getItem("userData")) : "";
    const [formData, setFormData] = useState({name: (userData?.name  && userData?.name !== "undefined") ? userData?.name : "", phoneNumber: (phoneNumber && phoneNumber !== "undefined" && parseInt(phoneNumber) !== -1) ? parseInt(phoneNumber) : "", date: "", time: ""});
    const requiredFields = ["date", "time"];
    const [requirederrors, setrequirederrors] = useState({});
    const [errors, setErrors] = useState({});
    const [submitClicked, setSubmitClicked] = useState(false);
    const [showLoginModal , setShowLoginModal] = useState(false);

    const tokenFromRedux = useSelector(state=>state.auth.tokenId);
    const tokenFromStorage = localStorage.getItem('token');
    const listingId = data?._id;
    const token = tokenFromRedux || tokenFromStorage;
    const { visitingDetails, status, error } = useSelector(state => state.addVisit.colleges[listingId] || {});

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (value.trim() === '') {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
          }));
        } else if (validationRules[name]) {
          const isValid = validateField(name, value, formData);
          setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: isValid ? '' : validationRules[name].message,
          }));
        }
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      } 


    const handleCollegeIdChange = (event) => {
        const file = event.target.files[0];
        setCollegeId(file);
    };

    const removeCollegeId = () => {
        setCollegeId(null);
    };
    const handleBookVisit = ()=>{
    
      const isErrors = checkForErrors(errors, formData, requiredFields, setrequirederrors);
      if (isErrors) {
        return;
      }else {
        const time = combineDateTime(formData?.date, formData?.time);
        if(!time){
          showErrorToast("Kindly enter valid date and time");
          return;
        }
        if(!collegeId){
          showErrorToast("please upload college id");
          return;
        }
        if(!token || token === "undefined" || token === ""){
          showInfoToast("kindly login again to continue");
          setShowLoginModal(true);
          // navigate(RoutePath.login, {state: {data: location?.pathname + location?.search}});
          return;
        }else{
          setShowLoginModal(false);
          setSubmitClicked(true);
        const apiBody = {
           
                visitTime: time,
                listingId: data?._id,
                listingDetails: data

          
        }

        dispatch(fetchAddVisit({apiBody, token, listingId, collegeId}))}
      }
    }
    
    
    useEffect(()=>{
     if(submitClicked){
      if(error === "Unauthorized"){

        showErrorToast("Kindly login again to continue")
        navigate(RoutePath.login, {state: {data: location?.pathname + location?.search}});
      }
       else if(status === 'failed'){
        showErrorToast(error);
        // setFormData({name: "", phoneNumber: "", date: "", time: ""});
       }else{
        return;
       }
     }
    }, [ submitClicked, error, status])

    useEffect(()=>{
      setFormData({name: (userData?.name  && userData?.name !== "undefined") ? userData?.name : "", phoneNumber: (phoneNumber && phoneNumber !== "undefined" && parseInt(phoneNumber) !== -1) ? parseInt(phoneNumber) : "", date: "", time: ""});
    },[showLoginModal]);



  return (
    <><ScheduleVisit setShowLoginModal={setShowLoginModal} isLoggedIn={token} collegeId={collegeId} formData={formData} handleCollegeIdChange={handleCollegeIdChange} removeCollegeId={removeCollegeId} status={status} visitingDetails={visitingDetails} screenWidth={screenWidth} errors={errors} requirederrors={requirederrors} handleChange={handleChange} handleBookVisit={handleBookVisit} openVisitForm={openVisitForm} setOpenVisitForm={setOpenVisitForm}/>
      {showLoginModal && <LoginLogic setShowLoginModal={setShowLoginModal} isFromLoginBtn={false} />}
    </>
  )
}

export default ScheduleVisitLogic