import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const HorizontalStepper = ({ status }) => {
  const screenWidth = useSelector(state=>state.screenWidth.screenWidth)
  const location = useLocation();



  if (screenWidth < 768) {
    return (
      <div className="flex flex-col  items-start mx-10">
        <div className="flex flex-col gap-4 justify-start items-start text-xs relative">
          {/* First stepper */}
          <div className="flex flex-col  justify-center items-center text-xs relative">
           <div className='flex items-center space-x-3'>
         <div className='flex flex-col items-center'>
         <div
              className={`w-8 h-8 rounded-full border-2 ${
                status === "orderPlaced" ? 'border-primary stepper-circle' : 'border-[#e4e4e7]'
              } ${(status === "orderShipped" || status === "orderDelivered") && 'background-primary border-none'} relative`}
            >
              {(status === "orderShipped" || status === "orderDelivered") ? (
                <svg
                  className="w-6 h-6 text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                </svg>
              ) : (
                <div
                  className={`w-2 h-2 rounded-full ${status === "orderPlaced" ? 'background-primary' : ''} absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`}
                />
              )}
            </div>
            <div className={` w-1 h-12 ${status === "orderPlaced" ? 'background-primary' : 'bg-gray-300'}`}></div>
         </div>
            <div className="text-secondary text-sm font-normal font-poppins">Order Placed</div>
           </div>
            
             
           
           
          </div>

          {/* Second stepper */}
          <div className="flex flex-col  justify-center items-center text-xs relative">
           <div className='flex items-center space-x-3'>
         <div className='flex flex-col items-center'>
         <div
              className={`w-8 h-8 rounded-full border-2 ${
                status === "orderShipped" ? 'border-primary stepper-circle' : 'border-[#e4e4e7]'
              } ${( status === "orderDelivered") && 'background-primary border-none'} relative`}
            >
              {( status === "orderDelivered") ? (
                <svg
                  className="w-6 h-6 text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                </svg>
              ) : (
                <div
                  className={`w-2 h-2 rounded-full ${status === "orderShipped" ? 'background-primary' : ''} absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`}
                />
              )}
            </div>
            <div className={` w-1 h-12 ${status === "orderShipped" ? 'background-primary' : 'bg-gray-300'}`}></div>
         </div>
            <div className="text-secondary text-sm font-normal font-poppins">Order Shipped</div>
           </div>
            
             
           
           
          </div>

          {/* Third stepper */}
          <div className="flex flex-col  justify-center items-center text-xs relative">
           <div className='flex items-center space-x-3'>
         <div className='flex flex-col items-center'>
         <div
              className={`w-8 h-8 rounded-full border-2 ${
                status === "orderShipped" ? 'border-primary stepper-circle' : 'border-[#e4e4e7]'
              } ${( status === "orderDelivered") && 'background-primary border-none'} relative`}
            >
              {( status === "orderDelivered") ? (
                <svg
                  className="w-6 h-6 text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                </svg>
              ) : (
                <div
                  className={`w-2 h-2 rounded-full ${status === "orderDelivered" ? 'background-primary' : ''} absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`}
                />
              )}
            </div>
          
         </div>
            <div className="text-secondary text-sm font-normal font-poppins">Order Delivered</div>
           </div>
            
             
           
           
          </div>

        </div>
      </div>
    );
  }

  // Horizontal Stepper
  return (
    <div className="w-5/6 flex relative justify-center mx-10">
      {/* First stepper */}
    <div>
        <div className="flex flex-col gap-2 justify-center items-start text-xs relative">
        <div className='flex items-center'>
        <div
          className={`w-8 h-8 rounded-full border-2 ${
            status === "orderPlaced" ? 'border-primary stepper-circle' : 'border-[#e4e4e7]'
          } ${(status === "orderShipped" || status === "orderDelivered") && 'background-primary border-none'} relative`}
        >
          {(status === "orderShipped" || status === "orderDelivered") ? (
            <svg
              className="w-6 h-6 text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
            </svg>
          ) : (
            <div
              className={`w-2 h-2 rounded-full ${status === "orderPlaced" ? 'background-primary' : ''} absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`}
            />
          )}
        </div>
        <div className={` w-[250px] h-1 ${status === "orderPlaced" ? 'background-primary' : 'bg-gray-300'}`}></div>
        </div>
        <div className="text-secondary text-sm font-normal font-poppins">Order Placed</div>
      </div>
    </div>

      {/* Second stepper */}
     <div>
     <div className="flex flex-col gap-2 justify-center items-start text-xs relative">
       <div className='flex items-center'>
       <div
          className={`w-8 h-8 rounded-full border-2 ${
            status === "orderShipped" ? 'border-primary stepper-circle' : 'border-[#e4e4e7]'
          } ${status === "orderDelivered" && 'background-primary border-none'} relative`}
        >
          {(status === "orderDelivered") ? (
            <svg
              className="w-6 h-6 text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
            </svg>
          ) : (
            <div
              className={`w-2 h-2 rounded-full ${status === "orderShipped" ? 'background-primary' : ''} absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`}
            />
          )}
        </div>
        <div className={` w-[250px] h-1 ${status === "orderShipped" ? 'background-primary' : 'bg-gray-300'}`}></div>
       </div>
        <div className="text-secondary text-sm font-normal font-poppins">Dispatched</div>
      </div>
     </div>

      {/* Third stepper */}
      <div className="flex flex-col gap-2 justify-center items-center text-xs relative">
        <div
          className={`w-8 h-8 rounded-full border-2 ${
            status === "orderDelivered" ? 'border-primary stepper-circle' : 'border-[#e4e4e7]'
          } ${status === "orderDelivered" && 'background-primary border-none'} relative`}
        >
          {status === "orderDelivered" ? (
            <svg
              className="w-6 h-6 text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
            </svg>
          ) : (
            <div
              className={`w-2 h-2 rounded-full ${status === "orderDelivered" ? 'background-primary' : ''} absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`}
            />
          )}
        </div>
        <div className="text-secondary text-sm font-normal font-poppins">Delivery</div>
      </div>
    </div>
  );
};

export default HorizontalStepper;
