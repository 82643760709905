import React, { useState, useEffect } from 'react'
import NoticePeriodModal from './NoticePeriodModal'
import { useNavigate } from 'react-router-dom';

const NoticePeriodModalLogic = ({openModal, setOpenModal, type, occupiedPropertyData}) => {
    const [confirmModal, setConfirmModal] = useState(false);
    const navigate = useNavigate();
   
  return (
    <><NoticePeriodModal openModal={openModal} occupiedPropertyData={occupiedPropertyData} setOpenModal={setOpenModal} type={type} confirmModal={confirmModal} setConfirmModal={setConfirmModal}/></>
  )
}

export default NoticePeriodModalLogic