import React, {useState} from 'react'
import blankImage from "../../assets/images/blankUserImage.jpg"
import upload from "../../assets/images/upload.svg"
import {RxCross2} from "react-icons/rx"
import imageUpload from "../../assets/images/uploadImageIcon.svg"
import SpinnerLogic from "../../components/spinner/SpinnerLogic"
import useFindCollege from '../../utils/findCollege';
import StatesService from '../../utils/statesService'

import {handleImageUpload, handleDragEnter, handleDragOver, handleDragLeave, handleDrop, handleRemoveImage} from "../../utils/dragAndDrop"

const PersonalDetails = ({ userData, fields, editableFields, handleCancelClick,
  handleEditClick,
  handleInputChange, 
  handleUpdateClick,
  collegeList,
  imageFiles, 
  setImageFiles,
  statesData,
  citiesData,
  handleProfileImage,
  profileImage,
  imageLoading,
  loading,
  setImageList
  
}) => {

  const userId = localStorage.getItem('user_id')
  const {findCollegeName} = useFindCollege();
  

  const renderEditableField = (field, ) => {

    return (
      <div key={field.name} className='border-b w-full flex  flex-col items-start py-6 px-8 border-[#E4E4E7]'>
        <div className='flex items-start w-full justify-between'>
          <div >
            <p className='text-sm text-secondary font-poppins font-medium'>{field?.label}</p>
            <p className='text-sm text-[#52525B] font-poppins font-normal mt-2'>{field?.subLabel}</p>
          </div>
          <button
            className='text-[#3F3F46] font-poppins cursor-pointer  text-sm font-medium'
            onClick={() => handleCancelClick(field.name)}
          >
            Cancel
          </button>
        </div>
        <div className='mt-5 w-full'>
          <p className='font-poppins text-[#3F3F46]  font-medium text-sm'>{field.label}</p>
          {
            field?.name !== "collegeName" && field?.name !== 'collegeId' && field?.name !== 'permanentAddress' &&
            <input
              type='text'
              className='border border-[#D4D4D8] blog-button-shadow w-full md:min-w-[381px] outline-none text-secondary font-poppins font-normal text-base mt-2 py-[10px] px-[14px] rounded'
              value={editableFields[field.name] || ''}
              onChange={(e) => handleInputChange(e, field.name)}
            />
          }
          {
            field?.name === 'collegeName' && <select className='w-full cursor-pointer border border-[#D4D4D8] blog-button-shadow outline-none text-secondary font-poppins font-normal text-base mt-2 py-[10px] px-[14px] rounded' onChange={(e) => handleInputChange(e, field?.name)} value={editableFields[field.name] || ''}>
              {
                collegeList && collegeList.map((ele, ind) => (
                  <option key={ind} value={ele?._id}>{ele?.collegeName}</option>
                ))
              }
            </select>
          }
          {
            field?.name === "collegeId" && <> <div className='mt-[20px]  md:mb-0 w-full'>
            {
              imageFiles?.length === 0 ?  (
               <label
               onDragOver={(e)=>handleDragOver(e)}
               onDragEnter={(e)=>handleDragEnter(e)}
               onDragLeave={(e)=>handleDragLeave(e)}
               onDrop={(e)=>handleDrop(e,setImageFiles)}
                className=' cursor-pointer border-dotted border border-[#E4E4E7] rounded-lg  w-full py-4 px-6 flex flex-col items-center justify-center'>
               <input
                 type='file'
                 accept='image/*'
                 onChange={(e)=>handleImageUpload(e, setImageFiles, false, setImageList)}
                 className='hidden'
               />
               <img src={imageUpload} alt="+" className='w-[32px]'/>
               <div className='font-poppins mt-7 flex items-center space-x-2 w-full text-sm font-semibold justify-center'>
                 <p className='text-primary cursor-pointer '>Upload photos</p>
                 <p className='text-secondary '>or drag and drop</p>
               </div>
               <p className='font-poppins mt-2 text-[#706B68] font-normal text-xs text-center'>PNG, JPG, GIF upto 5MB</p>
             </label>
             ) : 
             (
               <div className='grid grid-cols-1 sm:grid-cols-2  gap-6'>
               
                   <div key={imageFiles[0].id} className='col-span-1 relative'>
                     <img src={URL.createObjectURL(imageFiles[0].file)} alt='uploaded' className='w-full h-[240px] object-cover rounded-lg' />
                     <button
                       className='absolute -top-3 -right-3 p-2 background-secondary text-white rounded-full'
                       onClick={() => handleRemoveImage(imageFiles[0].id, setImageFiles, setImageList)}
                     >
                      <RxCross2 />
                     </button>
                   </div>
              
               </div>
             )
            }
           </div></>
          }
          
          {field?.name === 'permanentAddress' && (
          <div className='mt-3'>
            <p className='text-[#3F3F46] font-medium font-poppins text-sm'>Country</p>
            <select
              className='md:w-[381px] w-full cursor-pointer border border-[#D4D4D8] blog-button-shadow outline-none text-secondary font-poppins font-normal text-base mt-2 py-[10px] px-[14px] rounded'
              onChange={(e) => handleInputChange(e, 'country')}
              value={editableFields['country'] || 'India'}
            >
              <option value="India">India</option>
            </select>
            <p className='text-[#3F3F46] mt-5 font-medium font-poppins text-sm'>Address line 1</p>
            <input
              type='text'
              className='border border-[#D4D4D8] blog-button-shadow w-full md:min-w-[381px] outline-none text-secondary font-poppins font-normal text-base mt-2 py-[10px] px-[14px] rounded'
              value={ editableFields?.permanentAddress['addressLine1'] || ''}
              onChange={(e) => handleInputChange(e, 'permanentAddress.addressLine1')}
            />
             <p className='text-[#3F3F46] mt-5 font-medium font-poppins text-sm'>Address line 2</p>
            <input
              type='text'
              className='border border-[#D4D4D8] blog-button-shadow w-full md:min-w-[381px] outline-none text-secondary font-poppins font-normal text-base mt-2 py-[10px] px-[14px] rounded'
              value={  editableFields?.permanentAddress?.['addressLine2'] || '' }
              onChange={(e) => handleInputChange(e, 'permanentAddress.addressLine2')}
            />
            <div className='flex flex-col w-full md:space-x-4 mt-5 md:flex-row items-center'>
            <div className='w-full md:w-1/3'>
            <p className='text-[#3F3F46] mt-5 font-medium font-poppins text-sm'>State</p>
            <select
              className='w-full  cursor-pointer border border-[#D4D4D8] blog-button-shadow outline-none text-secondary font-poppins font-normal text-base mt-2 py-[10px] px-[14px] rounded'
              onChange={(e) => handleInputChange(e, 'permanentAddress.state')}
              value={editableFields?.permanentAddress?.['state'] || ''}
            >
             {
              statesData && statesData.map((ele, ind)=>(
                <option key={ind} value={ele?.state}>{ele?.state}</option>
              ))
             }
            </select>
            </div>
           <div className='w-full md:w-1/3'>
           <p className='text-[#3F3F46] mt-5  font-medium font-poppins text-sm'>City</p>
           <select
              className=' cursor-pointer w-full border border-[#D4D4D8] blog-button-shadow outline-none text-secondary font-poppins font-normal text-base mt-2 py-[10px] px-[14px] rounded'
              onChange={(e) => handleInputChange(e, 'permanentAddress.city')}
              value={editableFields?.permanentAddress?.['city'] || ''}
            >
              {
                citiesData && citiesData.map((city) => (
                  <option key={city.id} value={city.city}>
                    {city.city}
                  </option>  ))
              }
            </select>
           </div>
           <div className='md:w-1/3 w-full'>
           <p className='text-[#3F3F46] mt-5 font-medium font-poppins text-sm'>Pin code</p>
           <input
              type='number'
              className='border border-[#D4D4D8] blog-button-shadow w-full  outline-none text-secondary font-poppins font-normal text-base mt-2 py-[10px] px-[14px] rounded'
              value={editableFields?.permanentAddress?.['pinCode'] || ''}
              onChange={(e) => handleInputChange(e, 'permanentAddress.pinCode')}
            />
           </div>
            </div>
          </div>
        )}
        </div>
      </div>
    );
  };

  const renderField = (field) => {
    let fieldValue;

    if (field.name === 'email' || field.name === 'phoneNumber') {
        fieldValue = localStorage.getItem(field.name); // Check localStorage directly for email and phoneNumber fields
    } else {
        fieldValue = userData[field.name]; // For other fields, check userData
    }

    return (
        <div key={field.name} className={`border-b w-full flex justify-between items-start  py-6 px-5 md:px-8 border-[#E4E4E7] ${Object.keys(editableFields).includes(field.name) ? '' : 'block'}`}>
            <div className='flex flex-col w-full space-y-1 items-start'>
                <p className='font-poppins text-secondary font-medium text-sm'>{field.label}</p>
                {editableFields[field.name] !== undefined ? (
                    <input
                        type='text'
                        className='border border-[#D4D4D8] blog-button-shadow min-w-[381px] outline-none text-secondary font-poppins font-normal text-base mt-2 py-[10px] px-[14px] rounded'
                        value={editableFields[field.name] || ''}
                        onChange={(e) => handleInputChange(e, field.name)}
                    />
                ) : (
                    field.name === 'permanentAddress' ? (
                       userData?.permanentAddress && userData?.permanentAddress !== "undefined" ? 
                       <p className='text-[#52525B] font-poppins text-sm font-medium'>{`${userData?.permanentAddress?.addressLine1}, ${userData?.permanentAddress?.city}, ${userData?.permanentAddress?.state}, ${userData.permanentAddress?.pinCode}`}</p>
                       :
                       <p className='text-[#52525B] font-poppins text-sm font-medium'>Add {field.label}</p> // Show "Add" if userData doesn't contain data for the field
                    ) : (
                        field.name === 'collegeName' && fieldValue && fieldValue !== "undefined" && fieldValue !== "" ? // Check if fieldValue is present in localStorage
                            <p className='text-[#52525B] font-poppins text-sm font-medium'>{findCollegeName(fieldValue)}</p> // Call findCollegeName function with fieldValue
                            :
                            fieldValue && fieldValue !== "undefined" && fieldValue !== "-1"? // If fieldValue is present in localStorage
                                <p className='text-[#52525B] font-poppins text-sm font-medium'>{fieldValue}</p> // Display fieldValue
                                :
                                <p className='text-[#52525B] font-poppins text-sm font-medium'>Add {field.label}</p> // Show "Add" if fieldValue is not present in localStorage
                    )
                )}
            </div>
            {(editableFields[field.name] === undefined && editableFields[field.name] !== "-1")  && (
                <p
                    className='text-[#3F3F46] font-poppins cursor-pointer text-sm font-medium'
                    onClick={() => handleEditClick(field.name)}
                >
                    {fieldValue ? 'Edit' : 'Add'} {/* Display "Edit" if fieldValue is present, otherwise "Add" */}
                </p>
            )}
        </div>
    );
};


  

  return (
   <>
   {
    loading ? <><div className="absolute min-h-screen inset-0 bg-black opacity-50"></div><div className='absolute  z-30 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'><SpinnerLogic /></div></> :  <div className='flex mt-[30px] w-full min-h-screen flex-col items-start'>
    <h1 className='text-secondary font-poppins text-lg font-medium'>Personal details</h1>
    <p className='font-poppins text-textColor font-normal text-sm mt-2'>Provide personal details and how we can reach you</p>
    <div className='flex flex-col mt-[30px] w-full  md:space-x-6 items-center md:flex-row md:items-start '>
      <div className='md:w-[405px] md:min-h-[552px] min-h-[auto] w-full flex flex-col items-center border border-[#E4E4E7] rounded-xl py-6 px-5 md:px-[84px] md:py-[52px]'>
        <div className='flex flex-col items-center'>
         {
          imageLoading ? <div className='animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-gray-900'></div> :
          <>
           <img src={userData?.profilePhotoUrl || profileImage || blankImage} alt="" className='rounded-full md:w-[202px] md:h-[202px] w-[160px] h-[160px]' />
        {/* <p className='mt-5 font-poppins text-[#52525B] break-words text-base font-medium text-center'>USER ID : {userId}</p> */}
       <div>
       <input
              type="file"
              accept="image/*"
              id="profileImageInput"
              onChange={handleProfileImage}
              style={{ display: 'none' }} 
          />
        <button onClick={() => document.getElementById('profileImageInput').click()} className='mt-5  rounded-[4px] py-[10px] px-4 bg-[#FFF4EB]  blog-button-shadow flex space-x-2 items-center'><img src={upload} alt="" /><p className='text-primary font-poppins font-medium texts-sm'>Upload</p></button>
       </div>
          </>
         }
        </div>
      </div>
      <div className='flex flex-col items-start  md:w-[80%] mt-[20px] md:mt-0 w-full mx-auto '>
        <div className='border w-full rounded-lg border-[#E4E4E7]'>
          {fields.map((field) => (editableFields[field.name] !== undefined ? renderEditableField(field) : renderField(field)))}
        </div>
        {Object.keys(editableFields).length > 0 && (

          <button className='text-white background-primary blog-button-shadow mt-3 py-[10px] px-4 rounded font-poppins cursor-pointer text-sm font-medium' onClick={handleUpdateClick}>
            Update
          </button>

        )}
      </div>
    </div>

  </div>
   }
   </>
  )
}

export default PersonalDetails