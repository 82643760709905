import React, {useState} from 'react'
import LocalAssistCard from './LocalAssistCard'
import { useNavigate, useLocation } from 'react-router-dom'

const LocalAssistCardLogic = ({ele, id, dispatch, tokenId}) => {
    const navigate = useNavigate();  
    const [loading, setLoading] = useState(false); 
  return (
    <><LocalAssistCard ele={ele} id={id} dispatch={dispatch} tokenId={tokenId} loading={loading} setLoading={setLoading}/></>
  )
}

export default LocalAssistCardLogic