import React from 'react'
import ImageCarouselLogic from '../imageCarousel/ImageCarouselLogic'
import RoutePath from "../../routes/RoutePath"
import { CiCalendar } from "react-icons/ci";
import locationIcon from "../../assets/images/locationIcon.png"
import { RxExternalLink } from "react-icons/rx";
import {formatDate} from "../../utils/formatDate"
import {IoIosStar} from "react-icons/io";
import { IoShareSocialOutline } from "react-icons/io5";
import useFindLocality from "../../utils/findLocality";
import truncateText from '../../utils/truncateText'
import {findDistance} from "../../utils/nearByCollegeDetails"
import useFindCollege from '../../utils/findCollege';
import { useNavigate } from 'react-router-dom';
import { IoFemale } from "react-icons/io5";
import { IoMale } from "react-icons/io5";
import { IoMaleFemaleSharp } from "react-icons/io5";
import { Badge } from 'antd';
import { FaStar } from "react-icons/fa6";


const ListingCard = ({ele, location, collegeId, screenWidth, handleNavigate}) => {
  const navigate = useNavigate()
  const {findLocalityName} = useFindLocality();
  const {findCollegeName} = useFindCollege();
  const {findCollegeCode} = useFindCollege();
  const collegeName = screenWidth < 768 ? findCollegeCode(collegeId) : findCollegeName(collegeId)

  function camelCaseToNormal(camelCaseStr) {
    // Split the camelCase string at each uppercase letter and join with a space
    const words = camelCaseStr.replace(/([A-Z])/g, ' $1').toLowerCase();

    // Capitalize the first letter of each word
    const normalStr = words.replace(/\b\w/g, char => char.toUpperCase());

    return normalStr;
}
  return (
    <Badge.Ribbon text={<p className='flex items-center p-1 gap-2 text-md' ><FaStar />{ele?.averageRating ? ele?.averageRating : '4'}</p>} color='orange' >

      <div className='mt-[30px] w-full flex flex-col md:flex-row md:space-x-8 items-start'>
       <div onClick={()=>handleNavigate(ele)} className='md:w-4/12 h-[256px] relative w-full  aspect-[16/9] cursor-pointer rounded-xl object-cover'>
        {
          ele?.occupied && <p className='py-1 px-[10px] block md:hidden rounded-[100px] bg-[#27272A] font-poppins text-xs text-white text-center font-medium absolute top-3 left-3'>Occupied</p>
        }
        <ImageCarouselLogic images={ele?.imgArr} id={ele?._id} apibody={{_id: ele?._id, type: "listing", imgArr: ele?.imgArr, localityId: ele?.localityId, apartmentName: ele?.apartmentName ? ele?.apartmentName : ele?.permanentAddress?.addressLine1 , genderPreference: ele?.genderPreference, rent: ele?.rent }}/>
       </div>
       <div className=' md:w-8/12 w-full mt-5 md:mt-0'>
       <div className='flex md:items-center items-start gap-2 justify-between'>
        <div>
        <div className='flex items-center space-x-2'> <p onClick={()=>handleNavigate(ele)} className='cursor-pointer hover:text-gray-600 font-poppins font-semibold text-[#0F172A] text-base md:text-lg'>{camelCaseToNormal(ele?.furnishing)} {ele?.propertySize[0]} in {ele?.permanentAddress?.addressLine2 ? ele.permanentAddress.addressLine2 : findLocalityName(ele?.localityId)}
</p>{
          ele?.occupied && <p className='bg-[#FFF4EB] hidden md:block rounded-[100px] text-primary font-poppins font-medium text-sm text-center py-1 px-3'>Occupied</p>
         }</div>
          <div className='font-poppins font-normal text-sm text-textColor mt-2 flex items-center space-x-2'>{ele?.apartmentName && <p>{ele?.apartmentName}</p>}
          {
            (!location?.pathname?.includes(RoutePath.account) && ele?.genderPreference) && 
              (
                <>
                  {ele?.genderPreference === "Boys" && <p className=' text-black bg-[#C7E3FF] py-1 px-2 rounded-full flex items-center gap-1'><IoMale />Boys</p>}
                  {ele?.genderPreference === "Girls" && <p className=' text-black bg-[#FBC2D3] py-1 px-2 rounded-full flex items-center gap-1'><IoFemale />Girls</p>}
                  {ele?.genderPreference === "Both" && <p className=' text-black bg-[#FFF4EB] py-1 px-2 rounded-full flex items-center gap-1'><IoMaleFemaleSharp />Both</p>}
                </>
              )
            
          }
          {
            location?.pathname?.includes(RoutePath.account) &&  <p className='font-poppins font-normal text-sm text-textColor'>Room # : {ele?.roomId} | {ele?.occupancyType} sharing</p>
          }
         
          </div>
        </div>
           <div className='block md:hidden w-1/2'>
            <p className='text-textColor font-poppins font-normal text-xs'>Starts at just</p>
            <p className='font-poppins md:text-lg text-sm text-secondary font-semibold'>₹{ele?.rent} <span className='text-textColor font-poppins font-normal text-sm'>/month</span></p>
          </div>
          <div className='md:flex hidden items-center space-x-3'>
           { location?.pathname?.includes(RoutePath.account) &&  <IoShareSocialOutline className='text-[#52525B] cursor-pointer text-2xl' /> }
          </div>
        </div>
        {
          ele?.features?.length > 0 && <div className='flex md:mt-7 mt-4 flex-col items-start'>
            <p className='font-poppins text-xs font-normal'>Amenities</p>
            <div className='flex mt-2 flex-wrap items-center md:gap-3 gap-1'>
              {
                ele?.features.map((amenity, ind) => (
                  <div key={ind} className='flex items-center border border-[#E2E8F0] space-x-3 rounded-[24px] md:px-3 px-2 py-2'>
                    {/* <img src={amenity?.image} alt="" className='w-[15px] h-[15px]' /> */}
                    <p className='font-poppins text-xs text-secondary font-normal'>{amenity}</p>
                  </div>
                ))
              }
            </div>
          </div>
        }
          {
            !location?.pathname?.includes(RoutePath.account) &&
             <div className='flex items-center md:my-6 my-3 justify-between w-full'>
              <div className='flex md:inline-flex md:w-auto items-center space-x-2 py-2 px-3 rounded-[24px] bg-[#FFF4EB] text-[#0F172A] font-poppins font-normal text-xs'><img src={locationIcon} alt="" /><p>
            {findDistance(ele?.nearbyColleges, collegeId) 
                ? `${findDistance(ele?.nearbyColleges, collegeId)} m away from ${collegeName}`
                : `near ${collegeName}`}
        </p></div>
           {
            ele?.mapUrl && <> <div className='flex items-center space-x-3 text-primary text-sm font-medium font-poppins'><RxExternalLink className='cursor-pointer'/><p className='cursor-pointer'>View direction</p></div></>
           }
            </div>
          }
        
       {
        location?.pathname?.includes(RoutePath.account) &&  <div className='mt-5 border lg:w-[60%] md:w-[auto] rounded-lg border-[#E4E4E7] p-3'>
        <div className='text-[#3F3F46] font-poppins text-sm w-full font-medium flex items-center justify-between'><p>Lock-in tenure end date</p><p>{formatDate(ele?.tenureEndDate)}</p></div>
        <p className='font-poppins mt-1 text-sm text-textColor font-normal'>(Minimum notice period : 30 days)</p>
      </div>
       }
        <div className='flex flex-wrap gap-3 items-center mt-6 justify-between w-full'>
          <div className='hidden md:block'>
            <p className='text-textColor font-poppins font-normal text-xs'>Start from single bed at</p>
            <p className='font-poppins text-lg text-secondary font-semibold'>₹{ele?.rent} <span className='text-textColor font-poppins font-normal text-sm'>/month</span></p>
          </div>
          <div className='flex items-center w-full md:w-auto space-x-3'>
           {
            location?.pathname?.includes(RoutePath.account) && <> <button className='font-poppins w-1/2 md:w-auto text-white text-sm font-medium blog-button-shadow background-primary rounded-[4px] py-[10px] px-4'>Start notice period</button>
            <button className='font-poppins w-1/2 md:w-auto text-sm font-medium text-primary rounded-[4px] py-[10px] px-4 border hover-primary border-primary blog-button-shadow'>Give feedback</button></>
           }
           {
            !location?.pathname?.includes(RoutePath.account) && <button onClick={()=>handleNavigate(ele)}  className='font-poppins w-full md:w-auto text-white text-sm font-medium blog-button-shadow background-primary flex items-center justify-center space-x-2 rounded-[4px] py-[10px] px-4'><p>View Details</p></button>
           }
          </div>
        </div>
       </div>
    </div>
    </Badge.Ribbon>
  )
}

export default ListingCard