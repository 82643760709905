import React, {useEffect, useRef, useState} from 'react'
import Filters from './Filters'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchCategories } from '../../redux/slices/getCategoriesSlice';
import { BiPackage } from "react-icons/bi";
import { IoBedOutline } from "react-icons/io5";
import { TbWashMachine } from "react-icons/tb";
import { RxCross2 } from 'react-icons/rx';


const FiltersLogic = ({ setCurrentCategory, currentCategory, genderPreference, propertySize, setPropertySize, furnishing, setFurnishing, minRent, maxRent, setMaxRent, setMinRent, setGenderPreference, setRent,setCharge, sortOptions, setApibody, apibody, serviceType,categoryId, setCategoryId, sortValue, setSortValue, localAssistCategories}) => {
 
  const [openPriceRange, setOpenPriceRange] = useState(false);
  const dispatch = useDispatch();
  const [openMobileFilter, setOpenMobileFilter] = useState(false);
  const { status, categoriesData, error } = useSelector(state => state.getCategories);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState(apibody?.categoryIds || []);
  const dropdownRef = useRef(null);
  const categoryOptions = categoriesData?.filter((ele) => ele?.name === serviceType);
  const screenWidth = useSelector(state=>state.screenWidth.screenWidth);

  useEffect(() => {
    // Function to close dropdown when clicking outside
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenPriceRange(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Unbind the event listener on cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  useEffect(()=>{
    if(!categoriesData){
      dispatch(fetchCategories())
    }
  }, [serviceType])

  const handleGenderChange = (gender)=>{
    setApibody(prevState => ({...prevState, filters: { ...prevState.filters, genderPreference: gender}}))
  }

  const handleFurnishingChange = (furnishing)=> {
    setApibody(prevState => ({...prevState, filters: { ...prevState.filters, furnishing: furnishing}}))
  }

  const handlePropertyChange = (property)=> {
    setApibody(prevState => ({...prevState, filters: { ...prevState.filters, propertySize: property}}))
  }

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (value) => {
    if (selectedCategories.includes(value)) {
      setSelectedCategories(selectedCategories.filter((cat) => cat !== value));
    } else {
      setSelectedCategories([...selectedCategories, value]);
    }
  };

  const handleApply = () => {
    handleCategoryChange(selectedCategories);
    setIsOpen(false);
  };
  const handleClear = ()=>{
   
    if(serviceType === "listing"){
      setGenderPreference("");
      setSortValue(`distance-${-1}`)
      setMinRent(0)
      setMaxRent(50000)
      setApibody(prevState => ({...prevState, filters: { ...prevState.filters, genderPreference: "", startRent: 0, endRent: ""}, sortKey: "distance", sortValue: 1}))
    }else if(serviceType === "furnishing"){
      setCategoryId("")
      setMinRent(0)
      setMaxRent(40000)
      setSortValue(`rating-${-1}`)
      setApibody(prevState => ({...prevState, filters: { ...prevState.filters, categoryId: "", startRent: 0, endRent: ""}, sortKey: "rating", sortValue: -1}))
    }else {
      setMinRent(0)
      setMaxRent(10000)
      setSortValue(`_id-${-1}`)
      setCategoryId([])
       setApibody(prevState => ({...prevState, categoryId: "", startCharge: 0, endCharge: "", sortKey: "_id", sortValue: -1}))
    }
  }

  const handleCategoryChange = (category)=>{
    
    if(serviceType === "furnishing"){
      setCurrentCategory(category);
      console.log("category" , category);
      setApibody(prevState => ({...prevState, filters: { ...prevState.filters, categoryId: category}}))
    }else{
      setApibody(prevState => ({...prevState,  categoryIds: category}))
    }
    
  }

  useEffect(() => {
    console.log("currentCategory", currentCategory);
  }, [currentCategory]);

  const toggleCategory = (id) => {
    if (categoryId.includes(id)) {
      setCategoryId(categoryId.filter((cat) => cat !== id));
    } else {
      setCategoryId([...categoryId, id]);
    }
  };

  const handlePriceRange = () => {
    if (serviceType === "listing" || serviceType === "furnishing") {
     setApibody(prevState => ({...prevState, filters: {...prevState.filters, startRent: minRent, endRent: maxRent }}));
    } else {
      setApibody(prevState => ({...prevState,  startCharge: minRent, endCharge: maxRent }));
    }
    setOpenPriceRange(false); 
    };

    const handleSliderChange = (value) => {
      setMinRent(parseInt(value[0]));
      setMaxRent(parseInt(value[1]));
    };

    const handleClearRange = () => {
      setMinRent(0);
       
      if(serviceType === "listing" || serviceType === "furnishing"){
        setMaxRent(50000);
        setApibody(prevState => ({...prevState, filters: {...prevState.filters, startRent: 0, endRent: "" }}));
      }else{
        setMaxRent(10000)
        setApibody(prevState => ({...prevState,  startCharge: 0, endCharge: "" }));
      }
      setOpenPriceRange(false); 
    };

    const onSortChange = (value)=>{
      const [sortKey, sortValue] = value?.split('-');
      const parsedSortValue = parseInt(sortValue);
      const validSortValue = isNaN(parsedSortValue) ? -1 : parsedSortValue;
      setApibody(prevState => ({ ...prevState, sortKey, sortValue: validSortValue }));
    }

    const handleApplyFilter = ()=>{
        const [sortKey, sortvalue] = sortValue?.split('-');
        const parsedSortValue = parseInt(sortvalue);
        const validSortValue = isNaN(parsedSortValue) ? -1 : parsedSortValue;
      if(serviceType === "listing"){
         sortValue ?  setApibody(prevState => ({...prevState, filters: { ...prevState.filters, genderPreference: genderPreference ? genderPreference : "", propertySize: propertySize ? propertySize : "", furnishing: furnishing ? furnishing : "", startRent: minRent, endRent: maxRent}, sortKey: sortKey, sortValue: validSortValue}))
          :  setApibody(prevState => ({...prevState, filters: { ...prevState.filters, genderPreference: genderPreference ? genderPreference : "", propertySize: propertySize ? propertySize : "", furnishing: furnishing ? furnishing : "", startRent: minRent, endRent: maxRent}}))

      }else if(serviceType === "furnishing"){
        sortValue ?  setApibody(prevState => ({...prevState, filters: { ...prevState.filters, categoryId: categoryId ? categoryId : "", startRent: minRent, endRent: maxRent}, sortKey: sortKey, sortValue: validSortValue}))
        :  setApibody(prevState => ({...prevState, filters: { ...prevState.filters, categoryId: categoryId ? categoryId : "", startRent: minRent, endRent: maxRent }}))
      }else{
        sortValue ?  setApibody(prevState => ({...prevState,  sortKey: sortKey, sortValue: validSortValue, categoryIds: categoryId ? categoryId : "", startCharge: minRent, endCharge: maxRent}))
        :  setApibody(prevState => ({...prevState, categoryIds: categoryId ? categoryId : "", startCharge: minRent, endCharge: maxRent}))     }
    }


  return (
    <>{
      serviceType === 'furnishing' && screenWidth <= 768 ? 
      <div className=' w-full flex justify-center md:justify-start space-x-4 text-md' >
        {categoryOptions[0]?.value?.map((ele, ind) => (
          <div onClick={()=>{
            handleCategoryChange(ele?._id)
            } } value={ele?._id} key={ind} className={`flex flex-col w-1/3 items-center justify-evenly rounded-lg hover:shadow-lg duration-150 hover:shadow-gray-300 border  p-3 cursor-pointer  ${ele?._id === currentCategory ? 'bg-orange-100 border-orange-100' : 'border-gray-300'}`} >
          <div className={`flex justify-end w-full ${ele?._id === currentCategory ? 'visible' : 'invisible'}`} ><RxCross2 onClick={(e)=>{ e.stopPropagation();handleCategoryChange('')}} className='text-xl' /></div>
          {ele?.name === 'Package' && <BiPackage className='text-orange-400 text-4xl' />}
          {ele?.name === 'Furniture' && <IoBedOutline className='text-orange-400 text-4xl' />}
          {ele?.name === 'Home Appliance' && <TbWashMachine className='text-orange-400 text-4xl' />}
          <p className='text-md text-center' >{ele?.name}</p>
        </div>
          
        ))}
      </div> : <Filters dropdownRef={dropdownRef} propertySize={propertySize} setPropertySize={setPropertySize} furnishing={furnishing} setFurnishing={setFurnishing} handleFurnishingChange={handleFurnishingChange} handlePropertyChange={handlePropertyChange} toggleCategory={toggleCategory} toggleDropdown={toggleDropdown} isOpen={isOpen} selectedCategories={selectedCategories} handleCheckboxChange={handleCheckboxChange} handleApply={handleApply} localAssistCategories={localAssistCategories} handleSliderChange={handleSliderChange} apibody={apibody} setApibody={setApibody} setCategoryId={setCategoryId} genderPreference={genderPreference} setGenderPreference={setGenderPreference}  handleClear={handleClear} handleApplyFilter={handleApplyFilter} openMobileFilter={openMobileFilter} setOpenMobileFilter={setOpenMobileFilter} categoryId={categoryId} handleCategoryChange={handleCategoryChange} categoriesData={categoriesData}  sortkey={apibody?.sortKey} value={apibody?.sortValue} sortOptions={sortOptions} onSortChange={onSortChange} setMaxRent={setMaxRent} setMinRent={setMinRent} handleGenderChange={handleGenderChange} openPriceRange={openPriceRange} handleClearRange={handleClearRange} setOpenPriceRange={setOpenPriceRange} handlePriceRange={handlePriceRange} maxRent={maxRent} minRent={minRent} serviceType={serviceType} sortValue={sortValue} setSortValue={setSortValue}/>

    }
    </>
  )
}

export default FiltersLogic