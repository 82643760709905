import React, { createContext, useEffect, useState } from 'react';

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);

  const updateCartItems = (newCartItems) => {
    setCartItems(newCartItems);
  };

  const token = localStorage.getItem('token')

  useEffect(()=>{
    const fetchData = async()=>{
        try{
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/users/getCartItems`, {
                method: "GET",
                headers: {
                  'Authorization': `Bearer ${token}`,
                },
            });
            if(response === null || response === undefined){
                throw new Error("Response is null")
            }
            if(!response.ok){
                throw new Error("Response not ok");
            }
            const data = await response.json();
            if(data === null || data === undefined){
                throw new Error("Data is null")
            }
            updateCartItems(data);
        }catch(error){
            console.log("Error fetching cart data", error)
        }
    }
    if(token){
      fetchData()
    }
},[token])

  return (
    <CartContext.Provider value={{ cartItems, updateCartItems }}>
      {children}
    </CartContext.Provider>
  );
};

export default CartContext;
