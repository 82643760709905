import React, {useState, useEffect} from 'react'
import Payments from "./Payments"
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { showErrorToast, showInfoToast } from '../../utils/toastNotif';
import RoutePath from '../../routes/RoutePath';
import { PAYMENT_ITEMS_PER_PAGE } from '../../utils/constants';

const PaymentsLogic = () => {
  const screenWidth = useSelector(state=>state.screenWidth.screenWidth)
  const [monthlyData, setMonthlyData] = useState({})
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const [openMobileFilter, setOpenMobileFilter] = useState(false);
  const [expandedOrderId, setExpandedOrderId] = useState(null);
  const serviceOptions = [
    {name: "houseRent", label: "House Rent"},
    {name: "servicePurchase", label: "Service Purchase"},
    {name: "serviceRent", label: "Service rent"},
  ];
  const [currentPage, setCurrentPage] = useState(1);
  const [failed, setFailed] = useState(false);
  const [apibody, setApibody] = useState({status: "", paymentFor: "", startDate: "", endDate: "", sortKey: "_id", sortValue: -1, currPage: currentPage, itemsPerPage: PAYMENT_ITEMS_PER_PAGE})
  const tableHeadings = ["Order ID", "Time", "Service", "Amount", "Status", " "];
  const [selectedService, setSelectedService] = useState("")
  const [loading, setLoading] = useState(false);
  const [appliedFilter, setAppliedFilter] = useState(false)
  const [paymentData, setPaymentData] = useState([]);
  const location = useLocation();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection',
    },
  ]);
  const handleDateSelect = (ranges) => {
    setDateRange([ranges.selection]);
    setShowDatePicker(false);
  };

  useEffect(()=>{
   if(dateRange[0]?.startDate && dateRange[0]?.endDate){
     const date = new Date(dateRange[0]?.startDate);
    const date1 = new Date(dateRange[0]?.endDate);
    const startDate =  date?.toISOString();
    const endDate =  date1?.toISOString();
   setApibody({...apibody, startDate: startDate, endDate: endDate});
   }
  
  }, [dateRange])

  useEffect(()=>{
    const fetchData = async()=>{
      try{
        setLoading(true);
        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/payments/getPaymentsOfUser`, {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`
          },

          body : JSON.stringify(apibody)
         });

         if(response === null){
          throw new Error("Response is null");
         }

         if(!response.ok){
          if(response.status === 401){
            showInfoToast("kindly login again to continue");
            navigate(RoutePath.login, {state: {data: location?.pathname + location?.search}});
            return;

          } else{
            const message = await response.text();
            const parsedMessage = JSON.parse(message);
            if(parsedMessage?.message){
              showErrorToast(parsedMessage?.message);
              setFailed(true)
              return;
            }else{
              throw new Error("something went wrong");
            }
             
          }
        }

        const data = await response.json();
        if(data === null || data === undefined){
          throw new Error("data is null");
        }

        setPaymentData(data);
        setFailed(false);
        
      }catch(error){
        console.log("Error fetching payments data", error)
        setFailed(true)
        showErrorToast("Error fetching payment data");
      }finally{
        setLoading(false);
      }
    }
    fetchData();
  }, [apibody])

  const toggleExpandedOrder = async(orderId) => {
    if (expandedOrderId === orderId) {
      setExpandedOrderId(null);
    } else {
      setExpandedOrderId(orderId);
     }
    
  };

  useEffect(() => {
    const organizeData = () => {
      const organizedData = {};
      paymentData?.payments?.forEach((payment) => {
        const paymentDate = new Date(payment.createdOn);
        const monthYearKey = `${paymentDate.toLocaleString('default', {
          month: 'long',
        })}'${paymentDate.getFullYear().toString().slice(2)}`;

        if (!organizedData[monthYearKey]) {
          organizedData[monthYearKey] = [];
        }
        organizedData[monthYearKey].push(payment);
      });
      setMonthlyData(organizedData);
    };

    organizeData();
  }, [paymentData]);

  const handleClear = ()=>{
    setSelectedService("");
    setDateRange([{ startDate: null, endDate: null, key: 'selection',},])
    setOpenMobileFilter(false)
  }

  
  useEffect(() => {
   if(!openMobileFilter || appliedFilter){
    if(dateRange[0]?.startDate && dateRange[0]?.endDate){
      const date = new Date(dateRange[0]?.startDate);
    const date1 = new Date(dateRange[0]?.endDate);
    const startDate =  date?.toISOString();
    const endDate =  date1?.toISOString();
   setApibody({...apibody, startDate: startDate, endDate: endDate, paymentFor: selectedService});
    }else{
      const date = new Date(dateRange[0]?.startDate);
      const date1 = new Date(dateRange[0]?.endDate);
      const startDate =  date?.toISOString();
      const endDate =  date1?.toISOString();
     setApibody({...apibody, paymentFor: selectedService});
    }
   }
  }, [appliedFilter]);  


  
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  return (
    <><Payments
    serviceOptions={serviceOptions}
    tableHeadings={tableHeadings}
    selectedService={selectedService}
    setSelectedService={setSelectedService}
    handlePageChange={handlePageChange}
    currentPage={currentPage}
    showDatePicker={showDatePicker}
    setShowDatePicker={setShowDatePicker}
    dateRange={dateRange}
    handleDateSelect={handleDateSelect}
    paymentData={paymentData}
    apibody={apibody}
    screenWidth={screenWidth}
    monthlyData={monthlyData}
    toggleExpandedOrder={toggleExpandedOrder}
    openMobileFilter={openMobileFilter}
    setOpenMobileFilter={setOpenMobileFilter}
    handleClear={handleClear}
    setAppliedFilter={setAppliedFilter}
    loading={loading}
    failed={failed}
    setApibody={setApibody}
    expandedOrderId={expandedOrderId}
    /></>
  )
}

export default PaymentsLogic