import React, { useState, useEffect } from 'react'
import FavouritePage from './FavouritePage'
import RoutePath from '../../routes/RoutePath';
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux';
import { showErrorToast, showInfoToast } from '../../utils/toastNotif'
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {fetchFavouriteData} from "../../redux/slices/getFavouritesSlice"

const FavouritePageLogic = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const screenWidth = useSelector(state=>state.screenWidth.screenWidth)
    const tokenRedux = useSelector(state=>state?.auth?.tokenId);
    const tokenLocalstorage = localStorage.getItem('token');
    const location = useLocation();
    useEffect(()=>{
     if(tokenLocalstorage && tokenLocalstorage!== "undefined" && tokenLocalstorage !== ""){
      const fetchData = () => {
        try {
          dispatch(fetchFavouriteData({ token:  tokenLocalstorage }));
        } catch (error) {
          showErrorToast("Error fetching college favourite data. Please try again later.");
          navigate(RoutePath.homepage)
        }
      };
  
      fetchData();
     }else{
      showInfoToast("kindly login again to continue");
      navigate(RoutePath.login, {state: {data: location?.pathname + location?.search}});
      return;
     }
    },[tokenLocalstorage])
    const selectOptions = [
        {name: "Local Assist", value: "localAssistance"},
        {name: "Flat", value: "listing"},
        {name: "Furniture", value: "furnishingCategory"}
    ]

    const headings = {
        flat: {
          heading: "Flat",
          subheading: "In our monkhood-inspired living space, simplicity reigns in our flat, where minimalism meets tranquility."
        },
        localassist: {
          heading: "Local Assist",
          subheading: "Update you photo and personal details here."
        },
        furniture: {
          heading: "Furniture",
          subheading: "Gharbar Furniture Service: Elevate your space with mindful design and artisanal craftsmanship."
        }
      };

    const [selectedOption, setSelectedOption] = useState('listing');
    const { status, favouriteData, error } = useSelector(state => state.getFavourites);
    useEffect(()=>{
     if(error === "Unauthorized"){
      showErrorToast("Kindly login again to continue")
      navigate(RoutePath.login, {state: {data: location?.pathname + location?.search}});
     }
    }, [error])

   
  return (
    <><FavouritePage
    selectOptions={selectOptions}
    selectedOption={selectedOption}
    setSelectedOption={setSelectedOption}
    headings={headings}
    favouriteData={favouriteData}
    status={status}
    error={error}
    screenWidth={screenWidth}
    navigate={navigate}
    /></>
  )
}

export default FavouritePageLogic