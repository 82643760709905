import React, { useState } from 'react'

const PaymentSelect = ({selectedMethod , setSelectedMethod}) => {
    

    const handleMethodChange = (e) => {
        setSelectedMethod(e.target.value);
        
    };
  
    return (
      <div className="max-w-md mx-auto">
        <div className="space-y-2">
          <label className="flex items-center cursor-pointer font-semibold text-md">
            <input
              type="radio"
              value="DC"
              checked={selectedMethod === 'DC'}
              onChange={handleMethodChange}
              className="mr-5 appearance-none w-[10px] h-[10px] rounded-full border-3 ring-2 ring-offset-2 ring-orange-500 checked:bg-orange-500"
            />
            Debit Card (0.5% upto Rs2000 and 1% above Rs2000) 
          </label>
          <label className="flex items-center cursor-pointer font-semibold text-md">
            <input
              type="radio"
              value="CC"
              checked={selectedMethod === 'CC'}
              onChange={handleMethodChange}
              className="mr-5 appearance-none w-[10px] h-[10px] rounded-full border-3 ring-2 ring-offset-2 ring-orange-500 checked:bg-orange-500"
            />
            Credit Card (2%)
          </label>
          <label className="flex items-center cursor-pointer font-semibold text-md">
            <input
              type="radio"
              value="UPI"
              checked={selectedMethod === 'UPI'}
              onChange={handleMethodChange}
              className="mr-5 appearance-none w-[10px] h-[10px] rounded-full border-3 ring-2 ring-offset-2 ring-orange-500 checked:bg-orange-500"
            />
            UPI (0%)
          </label>
          <label className="flex items-center cursor-pointer font-semibold text-md">
            <input
              type="radio"
              value="NB"
              checked={selectedMethod === 'NB'}
              onChange={handleMethodChange}
              className="mr-5 appearance-none w-[10px] h-[10px] rounded-full border-3 ring-2 ring-offset-2 ring-orange-500 checked:bg-orange-500"
            />
            Netbanking (1.75%)
          </label>
        </div>
      </div>
    );
}

export default PaymentSelect