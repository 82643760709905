import React, {useState, useEffect} from 'react'
import SubNavbarCollege from './SubNavbarCollege'
import { useNavigate } from 'react-router-dom';
import RoutePath from '../../routes/RoutePath';
import { useLocation } from 'react-router-dom';

const SubNavbarCollegeLogic = ({collegeId}) => {
    const location = useLocation();
    const currentPath = location.pathname;
    const navigate = useNavigate();
    const videoUrls = [" ", " ", " "]
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
          setCurrentSlide((prevSlide) => (prevSlide + 1) % videoUrls.length);
        }, 5000);
    
        return () => clearInterval(interval);
      }, []);



    const navbarData = [
        { id:"all", heading: "Search all", path: RoutePath.searchByCollege?.replace(":id", collegeId)},
        { id:"Flat", heading: "Flat & PG", path: RoutePath.searchByCollegeHome?.replace(":id", collegeId)},
        { id:"Furnishing", heading: "Rental Furnishing", path: RoutePath.searchByCollegeFurnishing?.replace(":id", collegeId)},
        { id:"Local", heading: "Local Assist", path: RoutePath.searchByCollegeLocalAssist?.replace(":id", collegeId)},
        { id:"Nearby", heading: "College & Nearby", path: RoutePath.searchByCollegeFlatmate?.replace(":id", collegeId)},
    ]
  return (
    <><SubNavbarCollege
    navbarData={navbarData}
    currentPath={currentPath}
    navigate={navigate}
    collegeId={collegeId}
    videoUrls={videoUrls}
    currentSlide={currentSlide}
    /></>
  )
}

export default SubNavbarCollegeLogic