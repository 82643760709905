import React from 'react'
import Modal from "react-awesome-modal";
import { RxCross2 } from 'react-icons/rx'
import BarLoaderLogic from '../barLoader/BarLoaderLogic';

const FeedBackModal = ({openModal, setOpenModal, loading, handleFeedback, formData, setFormData, progress})=>{
    const ratingOptions = [1, 2, 3, 4, 5]
    return(
        <Modal
        visible={openModal}
        width="400"
        height="550"
        effect="fadeInUp"
        >
           {
            loading ? <div className='p-6'>
                <BarLoaderLogic progress={progress}/>
            </div> : 
            <div className='flex relative flex-col'>
            <div className='px-6 pt-6 pb-[8px]'>
                <div className='flex flex-col items-center justify-center'>
                    <div className='flex items-start'>
                    <RxCross2 onClick={()=>setOpenModal(false)} className='text-[#52525b] absolute cursor-pointer top-3 right-3 text-xl'/>
                    </div>
                    <p className='font-poppins mt-3 text-secondary text-center font-semibold text-lg'>Give Feedback</p>
                </div>
            <div>
                <div className='w-full flex flex-col my-3'>
                <p className='text-[#3F3F46] font-poppins font-medium text-sm'>Rating</p>
                <select value={formData?.rating} onChange={(e)=>setFormData({...formData, rating: e.target.value})} className='blog-button-shadow mt-2 outline-none border border-[#D4D4D8] py-[10px] px-[14px] rounded' >
                    <option value="">choose rating</option>
                    {
                        ratingOptions && ratingOptions?.map((ele, ind)=>(
                            <option value={ele} key={ind}>{ele}</option>
                        ))
                    }
                </select>
                </div>
                <div className='w-full flex flex-col my-3'>
                <p className='text-[#3F3F46] font-poppins font-medium text-sm'>Comment</p>
                <textarea onChange={(e)=>setFormData({...formData, comment: e.target.value})} value={formData?.comment}  cols="30" rows="5" placeholder='Enter comment' className=' resize-none blog-button-shadow mt-2 outline-none border border-[#D4D4D8] py-[10px] px-[14px] rounded'></textarea>
                </div>
               
            </div>
            </div>
            <div className='p-6 w-full'>
            <div onClick={handleFeedback} className='background-primary cursor-pointer justify-center text-white w-full md:w-auto flex items-center space-x-3 px-5 py-3 rounded blog-button-shadow font-poppins font-medium text-base'><p className=''>Submit Feedback</p> </div>
            </div>
        </div>
           }
    
        </Modal>
      )
}

const FeedBack = ({openModal, setOpenModal, loading, formData, setFormData, handleFeedback, progress}) => {
  return (
   <>
    <button onClick={()=>setOpenModal(true)} className='font-poppins w-1/2 md:w-auto text-sm font-medium text-primary rounded-[4px] py-[10px] px-4 border hover-primary border-primary blog-button-shadow'>Give feedback</button>
    {
        openModal && <FeedBackModal progress={progress} formData={formData} handleFeedback={handleFeedback} setFormData={setFormData} loading={loading} setOpenModal={setOpenModal} openModal={openModal}/>
    }
   </>
  )
}

export default FeedBack