import React, {useState, useEffect} from 'react'
import FurnishingByCollege from './FurnishingByCollege'
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import RoutePath from '../../routes/RoutePath';
import { showErrorToast } from '../../utils/toastNotif';
import { fetchFurnishings } from '../../redux/slices/getFurnishingsSlice';
import useFindCollege from '../../utils/findCollege';
import { useSelector } from 'react-redux';
import {FURNISHINGS_ITEMS_PER_PAGE} from '../../utils/constants'

const FurnishingByCollegeLogic = () => {
  const { id } = useParams();
  const {findCollegeDetailsById } = useFindCollege();
  const collegeData = findCollegeDetailsById(id);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [rent, setRent] = useState({startRent: 0, endRent: ""})
  const [categoryId, setCategoryId] = useState("");
  const [sortValue, setSortValue] = useState(`rating-${-1}`);
  const [minRent, setMinRent] = useState(0);
  const [maxRent, setMaxRent] = useState(40000);
  const [furnishingAvailable, setFurnishingAvailable] = useState(true);
  const [apibody, setApibody] = useState({filters: {status: "available", }, currPage: 1, itemsPerPage: FURNISHINGS_ITEMS_PER_PAGE, sortKey: "rating", sortValue: -1})
  const sortOptions = [{label: "Top Selling", sortKey: "rating", sortValue: -1}, {label: "Lowest Selling", sortKey: "rating", sortValue: 1}, {label: "Rent: Low to High", sortKey: "rent", sortValue: 1}, {label: "Rent: High to Low", sortKey: "rent", sortValue: -1}, {label: "Newest First", sortKey: "time", sortValue: -1}]
  const dispatch = useDispatch();
  const details = findCollegeDetailsById(id);
  const [currentCategory, setCurrentCategory] = useState('');


  const handlePageChange = (page) => {
    setCurrentPage(page);
    setApibody(prevState => ({...prevState, currPage: page}));
  };




  useEffect(()=>{
  
   if(details?.furnishingAvailable){
    setFurnishingAvailable(true);
    const fetchData = () => {
      try {
        dispatch(fetchFurnishings({apibody }));
      } catch (error) {
        showErrorToast("Error fetching furnishings. Please try again later.");
        navigate(RoutePath.homepage)
      }
    };

    fetchData();
  } else {
    setFurnishingAvailable(false);
  }

  }, [details, id, apibody, currentPage])

  const { status, furnishingData, error } = useSelector(state => state.getFurnishings);

 


  return (
    <><FurnishingByCollege
    currentCategory={currentCategory}
    setCurrentCategory={setCurrentCategory}
    collegeData={collegeData}
    currentPage={currentPage}
    handlePageChange={handlePageChange}
    collegeId={id}
    setApibody={setApibody}
    apibody={apibody}
    status={status}
    error={error}
    sortOptions={sortOptions}
    furnishingData={furnishingData}
    furnishingAvailable={furnishingAvailable}
    navigate={navigate}
    setRent={setRent}
    setCategoryId={setCategoryId}
    categoryId={categoryId}
    sortValue={sortValue}
    setSortValue={setSortValue}
    minRent={minRent}
    maxRent={maxRent}
    setMaxRent={setMaxRent}
    setMinRent={setMinRent}
    /></>
  )
}

export default FurnishingByCollegeLogic