const RoutePath = {
    homepage: '/',
    login: '/login',
    signup: '/signup',
    services: '/services',
    favourite: '/favourite',
    listing: '/list-properties',
    listingForm: '/listing-form',
    searchByCollege: '/:id',
    searchByCollegeHome: '/:id/Flat & PG',
    searchByCollegeFurnishing: '/:id/Rental Furnishing',
    searchByCollegeLocalAssist: '/:id/Local Assist',
    searchByCollegeFlatmate: '/:id/College & Nearby',
    exploreProperties: '/explore-properties',
    earnFromUs: '/earn-from-us',
    referrals: '/referrals',
    account: '/account',
    furnishingById: '/:productId',
    cart: '/shopping-cart',
    orders: '/order',
    furnishingByIdForCollege: '/:id/furnishing/:productId',
    collegeListingById: '/:id/Flat & PG/:listingId',
    listingById: '/listing/:listingId',
    paymentSuccessful: '/payment-successful',
    paymentFail: '/payment-failed',
    payRent: '/pay-rent'

    
}

export default RoutePath