import React from 'react'
import SubNavbarCollegeLogic from '../../components/subNavbarCollege/SubNavbarCollegeLogic'
import RoutePath from '../../routes/RoutePath'
import ListingCardLogic from '../../components/listingCard/ListingCardLogic'
import PaginationLogic from "../../components/pagination/PaginationLogic"
import { LISTINGS_ITEMS_PER_PAGE } from '../../utils/constants'
import SpinnerLogic from "../../components/spinner/SpinnerLogic"
import useFindCollege from '../../utils/findCollege';
import FiltersLogic from '../../components/filters/FiltersLogic'
import WhatsappContactLogic from '../../components/whatsappContact/WhatsappContactLogic'
import RequestListingForm from '../../components/RequestListingForm/RequestListingForm'



const HomeByCollege = ({furnishing, setFurnishing, propertySize, setPropertySize, maxRent, minRent, setMaxRent, setMinRent, genderPreference, setGenderPreference, collegeId, currentPage, handlePageChange, listingsData, navigate, status, sortValue, setSortValue, typeOfProperty, handlePropertyChange,  setRent, sortOptions, setApibody, apibody}) => {
  const {findCollegeName} = useFindCollege();
  return (
    <div>
       <WhatsappContactLogic/>
        <SubNavbarCollegeLogic
        collegeId={collegeId}
        />
       {
        status === 'failed' ? 
         <div className='flex flex-col w-full min-h-screen  items-center justify-center'>
         <div className='text-center'>
         <h1 className="text-secondary font-poppins font-semibold text-5xl">Internal server error</h1>
          <p className="text-2xl font-poppins text-textColor mt-6 font-normal">We are facing some internal server error while fetching data, sorry for the inconvinience caused</p>
          <button onClick={()=>navigate(RoutePath.homepage)} className='blog-button-shadow background-primary mt-10 text-white text-lg rounded-[4px] px-4 py-2 font-medium font-poppins'>Go Back</button>
         </div>
          </div> 
         :
        <>
        {
          status === 'loading'  ? <><div className="absolute min-h-screen inset-0 bg-black opacity-50"></div><div className='absolute  z-30 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'><SpinnerLogic /></div></> :
        
             
          <>
                    <div className='w-full flex justify-center md:justify-end fixed bottom-5 z-[200] md:px-10 px-5 md:py-2 py-2' ><RequestListingForm /></div>

          <div className="py-6 px-5  md:px-20 w-full flex justify-between md:justify-normal items-start ">
            {/* desktop */}
            <div className="border  mr-4 hidden border-[#D4D4D8]  gap-y-3 px-4 py-[14px] rounded-lg md:flex items-center ">
            <div className="flex mr-3 w-[65%]  items-center space-x-3">
              <div onClick={()=>handlePropertyChange("flat")} className={`w-[16.67px] h-[16.67px] cursor-pointer border-[1.25px]  rounded-full flex items-center justify-center ${typeOfProperty === "flat" ? 'border-primary' : 'border-[#D4D4D8]'}`}>
                {
                  typeOfProperty === "flat" && <div className="bg-[#F84D26] w-[11.67px] h-[11.67px] rounded-full flex items-center justify-center"></div>
                }
              </div>
                <p onClick={()=>handlePropertyChange("flat")} className={`cursor-pointer font-poppins font-normal text-lg ${typeOfProperty === "flat" ? 'text-primary' : 'text-[#3F3F46]'}`}>Flat</p>
                </div>
              <div className="flex items-center space-x-3">
              <div  onClick={()=>handlePropertyChange("pg")} className={`w-[16.67px] cursor-pointer h-[16.67px]  border-[1.25px]  rounded-full flex items-center justify-center ${typeOfProperty === "pg" ? 'border-primary' : 'border-[#D4D4D8]'}`}>
                {
                  typeOfProperty === "pg" && <div className="bg-[#F84D26] w-[11.67px] h-[11.67px] rounded-full flex items-center justify-center"></div>
                }
              </div>
                <p onClick={()=>handlePropertyChange("pg")} className={`cursor-pointer font-poppins font-normal text-lg ${typeOfProperty === "pg" ? 'text-primary' : 'text-[#3F3F46]'}`}>PG</p>
                </div>
              
            </div>

            {/* mobile */}
            <div className='w-[25%] md:hidden mr-3 flex h-[40px] rounded-[32px] border border-[#E4E4E7] bg-[#FAFAFA] justify-between items-center p-[2px]'>
              <p onClick={()=>handlePropertyChange("flat")} className={`${typeOfProperty === "flat" ? 'text-white background-primary rounded-[100px] h-full py-2' : 'text-[#3F3F46]'} font-poppins w-1/2 text-sm font-medium text-center`}>Flat</p>
              <p onClick={()=>handlePropertyChange("pg")} className={`${typeOfProperty === "pg" ? 'text-white background-primary rounded-[100px] h-full py-2' : 'text-[#3F3F46]'} font-poppins w-1/2 text-sm font-medium text-center`}>PG</p>
            </div>
            
            <FiltersLogic furnishing={furnishing} setFurnishing={setFurnishing} propertySize={propertySize}  setPropertySize={setPropertySize} setMaxRent={setMaxRent} setMinRent={setMinRent} minRent={minRent} maxRent={maxRent} sortValue={sortValue} setSortValue={setSortValue} genderPreference={genderPreference} setGenderPreference={setGenderPreference} setRent={setRent} sortOptions={sortOptions} setApibody={setApibody} apibody={apibody} serviceType={"listing"}/>
           
          </div>

          {
               listingsData?.listings?.length === 0 ? 
               <div className='flex flex-col w-full min-h-screen  items-center justify-center'>
              <div className='text-center'>
              <h1 className="text-secondary font-poppins font-semibold text-5xl">No listings available</h1>
               <p className="text-2xl font-poppins text-textColor mt-6 font-normal">currently no listings are available, kindly check back later for updates</p>
               <button onClick={()=>navigate(RoutePath.homepage)} className='blog-button-shadow background-primary mt-10 text-white text-lg rounded-[4px] px-4 py-2 font-medium font-poppins'>Go Back</button>
              </div>
               </div> 
               :
               <div className='w-full md:px-20 md:pt-5 md:pb-24 px-5 pt-6'>
               <p className="font-poppins text-secondary font-medium text-xs md:text-base">{listingsData?.listings?.length} {typeOfProperty === "pg" ? 'PG Home' : 'Flats'}<span className="text-textColor md:text-base text-sm font-normal"> near {findCollegeName(collegeId)}</span></p>
               {
                 listingsData?.listings?.map((ele, ind)=>(
                   <div key={ind} className='flex flex-col w-full'>
                   <ListingCardLogic ele={ele} collegeId={collegeId}/>
                   <hr className='text-[#D4D4D8] mt-[30px] w-full'/>
                   </div>
                 ))
               }
               <PaginationLogic totalPages={Math.ceil(listingsData?.totalListingCount / LISTINGS_ITEMS_PER_PAGE)} currentPage={currentPage} handlePageChange={handlePageChange} />
             </div>
          }
        
          </>
        }
        </>
       }
    </div>
  )
}

export default HomeByCollege