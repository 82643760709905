
import React, { useEffect } from 'react';
import { useDispatch} from 'react-redux';
import { setTokenId, setUserState } from '../redux/slices/authSlice';


const AuthProvider = ({children}) => {
  const dispatch = useDispatch();

  const verify = async () => {
    const token = localStorage.getItem('token');
    const user_id = localStorage.getItem('user_id');
  

    if (user_id === null || user_id === "undefined" || user_id === "" || token === null || token === "undefined" || token === "") {
      return;
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/users/${user_id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if(res === null){
        return;
      }

      if(!res.ok){
        return;
      }

      const data = await res.json();
      if (data === null || data === undefined) {
        return;
      }
     

      dispatch(setTokenId(token));
      localStorage.setItem("userData", JSON.stringify(data));
      dispatch(setUserState(data));
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    verify();
  }, [dispatch]);

  return <>{children}</>;
};

export default AuthProvider;
