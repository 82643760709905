import React, { useEffect, useState } from 'react'
import CollegeListingById from './CollegeListingById'
import { useLocation } from 'react-router-dom'
import { showInfoToast } from '../../utils/toastNotif'
import { useNavigate, useParams } from 'react-router-dom'
import RoutePath from '../../routes/RoutePath'

const CollegeListingByIdLogic = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [openVisitForm, setOpenVisitForm] = useState(false);
    const [isFavorited, setIsFavorited] = useState(false);
    const [loading, setLoading] = useState(false);
    const userDataString =  localStorage.getItem('userData');
    const [key, setKey] = useState(Date.now());
    const [data, setData] = useState([]);

  
      


    useEffect(()=>{
      if (location.state && location.state.data) {
        setData(location.state.data)
      }
      const searchParams = new URLSearchParams(location.search);
      const eleString = searchParams.get('data');
      if (eleString) {
        const decodedEleString = decodeURIComponent(atob(eleString));
        const ele = JSON.parse(decodedEleString);
        setData(ele);
      }
    }, [location])
   useEffect(()=>{
    if(!data){
      showInfoToast("There is some problem displaying in data, kindly try again");
       navigate(RoutePath.homepage)
    }
   }, [data]);
   useEffect(() => {
    const handleStorageChange = () => {
        setKey(Date.now());  
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
     
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      const favouriteIds = userData.favourites.map(favourite => favourite._id);
      setIsFavorited(favouriteIds.includes(data?._id));
    }
  }, [data?._id, userDataString, key]);
  return (
    <><CollegeListingById id={data?._id}
    isFavorited={isFavorited}
    setIsFavorited={setIsFavorited}
    apibody={{_id: data?._id, type: "listing", imgArr: data?.imgArr, localityId: data?.localityId, apartmentName: data?.apartmentName ? data?.apartmentName : data?.permanentAddress?.addressLine1 , genderPreference: data?.genderPreference, rent: data?.rent }}
    loading={loading}
    setLoading={setLoading} data={data} openVisitForm={openVisitForm} setOpenVisitForm={setOpenVisitForm}/></>
  )
}

export default CollegeListingByIdLogic