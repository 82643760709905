import { createSlice } from "@reduxjs/toolkit";

const statesDataSlice = createSlice({
    name: "states",
    initialState : [],

    reducers : {
        setStates : (state, action)=> {
            return action.payload
        }
    }
})

export const { setStates } = statesDataSlice.actions;
export default statesDataSlice.reducer;