import React from 'react'
import FlatmateByCollege from './FlatmateByCollege'
import { useParams } from 'react-router-dom';
import useFindCollege from '../../utils/findCollege';

const FlatmateByCollegeLogic = () => {
  const { id } = useParams();
  const {findCollegeDetailsById } = useFindCollege();
  const collegeData = findCollegeDetailsById(id);

  return (
    <><FlatmateByCollege
    collegeData={collegeData}
    collegeId={id}
    /></>
  )
}

export default FlatmateByCollegeLogic