import React, {useEffect, useRef} from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import RoutePath from "../../routes/RoutePath";
const ServiceDropDown = ({isServiceDropdownOpen, togglingServiceMobileHeaderRef, togglingServiceDesktopHeaderRef, serviceOptions, selectService, setisServiceDropdownOpen, togglingDivRef, togglingDivMobileRef})=>{
    const dropdownServiceRef = useRef(null);
    const screenWidth = useSelector(state=>state.screenWidth.screenWidth)
    const location = useLocation();
    useEffect(() => {
        const handleClickOutside = (event) => {
           if(screenWidth < 768){
            if(location?.pathname === RoutePath.homepage){
                if (dropdownServiceRef?.current && !dropdownServiceRef?.current.contains(event.target) && togglingServiceMobileHeaderRef?.current && !togglingServiceMobileHeaderRef?.current.contains(event.target) ) {
                    setisServiceDropdownOpen(false)
                 }
            }else{
                if (dropdownServiceRef?.current && !dropdownServiceRef?.current.contains(event.target) && togglingDivMobileRef?.current && !togglingDivMobileRef?.current.contains(event.target) ) {
                    setisServiceDropdownOpen(false)
                 }
            }
           }else{
            if(location?.pathname === RoutePath.homepage){
                if (dropdownServiceRef?.current && !dropdownServiceRef?.current.contains(event.target) && togglingServiceDesktopHeaderRef?.current && !togglingServiceDesktopHeaderRef?.current.contains(event.target) ) {
                    setisServiceDropdownOpen(false)
                 }
            }else{
                if (dropdownServiceRef?.current && !dropdownServiceRef?.current.contains(event.target) && togglingDivRef?.current && !togglingDivRef?.current.contains(event.target) ) {
                    setisServiceDropdownOpen(false)
                 }
            }
           }
          
        };

        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [screenWidth]);
    return(
        <div  ref={dropdownServiceRef} className={`${isServiceDropdownOpen ? 'flex flex-col items-start' : 'hidden'} absolute bg-white blog-button-shadow rounded-lg ${location?.pathname === RoutePath.homepage ? 'md:top-[66px] top-[50px]' : 'top-[54px]'} border border-[#E4E4E7] z-30 header-box-shadow w-full py-4 px-2  -left-1`}>
                        {
                            serviceOptions && serviceOptions.map((ele, ind)=>(
                                <div onClick={()=>selectService(ele)} key={ind} className='py-[9px] w-full px-[10px]'>
                                    <p  className='font-poppins hover:text-orange-400 cursor-pointer font-medium text-sm text-secondary'>{ele}</p>
                                </div>
                            ))
                        }
                    </div>
    )
}

export default ServiceDropDown;