import React, {useState} from 'react';
import { HiOutlineChevronRight } from "react-icons/hi";
import { HiOutlineChevronLeft } from "react-icons/hi";
import heart from "../../assets/images/heart.svg"
import { handleFav } from '../../utils/handleFav';
import redHeart from "../../assets/images/redHeartIcon.svg";
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSwipeable } from 'react-swipeable';

const ImageCarousel = ({nofavourite, currentImageIndex, goToImage, images, indicator, isFavorited,loading,setLoading, apibody }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
 

  const handleArrowClick = (direction) => {
    if (direction === 'left') {
      goToImage(currentImageIndex - 1);
    } else if (direction === 'right') {
      goToImage(currentImageIndex + 1);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {

      handleArrowClick('right');
    },
    onSwipedRight: () => {
      handleArrowClick('left');
    },
  });

  return (
    <div {...(images?.length > 1 ? handlers : {})} className="relative h-full w-full"
  >
    
      {
        (!indicator && !nofavourite) && (loading ? <div className='absolute z-30 top-2 right-2'><div className='animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-gray-900'></div></div> : <div onClick={(e)=>{
          e.stopPropagation();
          handleFav(apibody, navigate, dispatch, setLoading, location);}} className='absolute z-30 top-2 right-2'><img src={isFavorited ? redHeart : heart} alt="" /></div>)
      }
      <div className="w-full h-full">
        {images?.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Blog Image ${index}`}
            className={index === currentImageIndex ? 'visible w-full rounded-lg h-full bg-contain' : 'hidden'}
          />
        ))}
        {indicator && (
          <>
            <div className="absolute z-10 top-1/2 left-2 transform -translate-y-1/2 flex items-center space-x-2">
              <button
                className={`${currentImageIndex === 0 ? 'bg-[#E4E4E7] opacity-50' : ' opacity-65'} rounded-full h-[48px] w-[48px] blog-button-shadow flex justify-center items-center`}
                onClick={() => handleArrowClick('left')}
                disabled={currentImageIndex === 0}
              >
                <HiOutlineChevronLeft />
              </button>
            </div>
            <div className="absolute z-10 top-1/2 right-2 transform -translate-y-1/2 flex items-center space-x-2">
              <button
                className={`${currentImageIndex === images?.length -1 ? 'bg-[#E4E4E7] opacity-50' : 'opacity-80 bg-white'} rounded-full h-[48px] w-[48px] blog-button-shadow flex justify-center items-center`}
                onClick={() => handleArrowClick('right')}
                disabled={currentImageIndex === images?.length - 1}
              >
               <HiOutlineChevronRight />
              </button>
            </div>
          </>
        )}
      </div>
      {images?.length > 1 && <div className="absolute z-10 bottom-5 left-1/2 transform -translate-x-1/2 flex items-center space-x-2">
        {images?.map((_, index) => (
          <span
            key={index}
            className={
              index === currentImageIndex ? 'rounded-full bg-white h-[7px] w-[7px]' : 'bg-gray-300 h-[7px] w-[7px] rounded-full'
            }
            onClick={() => goToImage(index)}
          ></span>
        ))}
      </div>}
    </div>
  );
};

export default ImageCarousel;
