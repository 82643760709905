import { toast } from 'react-hot-toast';

export const showErrorToast = (message, options = {}) => {
    toast.error(message, {
      ...getDefaultOptions(),
      ...options
    });
  };
  
  export const showSuccessToast = (message, options = {}) => {
    toast.success(message, {
      ...getDefaultOptions(),
      ...options
    });
  };
  
  export const showInfoToast = (message, options = {}) => {
    toast(message, {
      icon: 'ℹ️',
      ...getDefaultOptions(),
      ...options
    });
  };
  
  const getDefaultOptions = () => ({
    position: "top-center",
    reverseOrder: true,
    duration:4000
  });