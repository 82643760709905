import React, {useState, useEffect, useContext} from 'react'
import { json, useLocation, useNavigate } from 'react-router-dom';
import Cart from './Cart'
import { useSelector } from 'react-redux';
import { showErrorToast, showInfoToast } from '../../utils/toastNotif'
import RoutePath from '../../routes/RoutePath';
import CartContext from '../../context/CartContext';

const CartLogic = () => {
    const userEmail = localStorage.getItem('email');
    const [cartItems, setCartItems] = useState([]);
    const token = localStorage.getItem('token')
    const [addNew, setAddNew] = useState(false)
    const [paymentLoading, setPaymentLoading] = useState(false);
    const [email , setEmail] = useState(userEmail || null);
    const shippingAddresses = (localStorage.getItem("shipping-address")  && localStorage.getItem("shipping-address") !== "undefined") ? JSON.parse(localStorage.getItem("shipping-address")) : []
    const [selectedAddress, setSelectedAddress] = useState(shippingAddresses ? shippingAddresses[0] : {});
    const [loading, setLoading] = useState(false);
    const [openEmailModal,setOpenEmailModal] = useState(false);
    const totalCharges = 799;
    const [totalAmount, setTotalAmount] = useState(0);
    const location = useLocation();
    const [cartLoading, setCartLoading] = useState(false);
    const [key, setKey] = useState(Date.now());
    const [promoCodeApplied, setPromoCodeApplied] = useState(false);
    const navigate = useNavigate();
    const [openAddressForm, setOpenAddressForm] = useState(false);
    const [selectedMethod, setSelectedMethod] = useState('UPI');
    const [charge, setCharge] = useState(0);
    const [netAmount,setNetAmount] = useState(0);
    const { updateCartItems } = useContext(CartContext);
    const [gst,setGst] = useState(0);
    const [deposit,setDeposit] = useState(0);

    useEffect(()=>{
        const fetchData = async()=>{
            try{
                setLoading(true);
                const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/users/getCartItems`, {
                    method: "GET",
                    headers: {
                      'Authorization': `Bearer ${token}`,
                    },
                });
                if(response === null || response === undefined){
                    throw new Error("Response is null")
                }
              
                if(!response.ok){
                    if(response?.status === 401){
                        showErrorToast("kindly login again to continue");
                        navigate(RoutePath.login, {state: {data: location?.pathname + location?.search}});
                    }else{
                    throw new Error("Response not ok");}
                }
                const data = await response.json();
                if(data === null || data === undefined){
                    throw new Error("Data is null")
                }
                
                setCartItems(data);
                updateCartItems(data);
            }catch(error){
                console.log("Error fetching cart data", error)
            }finally{
                setLoading(false)
            }
        }
        fetchData()
    },[key])

    useEffect(()=>{
        let totalAmount = 0;
        // let totalCharges = 0;
        cartItems.forEach(item => {
            totalAmount += item?.item?.rent * item?.item?.quantity;
            setTotalAmount(totalAmount)
            // if (item?.item?.charges?.delivery) {
            //     totalCharges += item?.item?.charges?.delivery?.value;
            // }
            // setTotalCharges(totalCharges);
        });
        setDeposit(totalAmount*2);
        setGst(totalAmount*0.18);
        if(parseInt(totalAmount) < 2000){
          setNetAmount(totalAmount+totalCharges + deposit + gst +((totalAmount+totalCharges + deposit + gst)*charge))

        }
        else{
          setNetAmount(totalAmount + deposit + gst + ((totalAmount + deposit + gst)*charge))
        }
    }, [cartItems,charge,totalAmount,totalCharges,gst,deposit])

    useEffect(()=>{
      console.log(selectedMethod);
      switch (selectedMethod) {
        case 'DC':
          if((totalAmount + totalCharges + deposit + gst) < 2000){
            
            setCharge(0.005);
          }
          else{
            setCharge(0.01);
          }

          break;
        case 'CC':
          setCharge(0.02);
          break;
        case 'UPI':
          setCharge(0);
          break;
        case 'NB':
          setCharge(0.0175);
          break;
        default:
          setCharge(0);
          break;
      }
    },[selectedMethod])

    const handleSelectAddress = (address) => {
        setSelectedAddress(address);
      };

      const handlePayment = async()=>{
        if(!selectedAddress){
            showErrorToast("kindly select a shipping address");
            return;
        }
        
        if(!email || email === "undefined"){
          showErrorToast("kindly add your email address");
          navigate(RoutePath.account)
           return;
        }

        try{
            const modifiedData = cartItems.map(item => item.item);
            const body = {
                netAmount: parseFloat(netAmount),
                totalCharges: parseInt(totalCharges),
                totalAmount: parseInt(totalAmount),
                totalDiscount: 0,
                paymentFor: 'servicePurchase',
                services :
                   modifiedData,
                userEmail: email,
                addressDetails: {
                    addressLine1: selectedAddress?.addressLine1,
                    state: selectedAddress?.state,
                    city: selectedAddress?.city,
                    pinCode: selectedAddress?.pinCode,
                    country: "India"
                },
                surl : `https://www.gharbar.co.in${RoutePath.paymentSuccessful}`,
                furl: `https://www.gharbar.co.in${RoutePath.paymentFail}`,
                paymentMode: selectedMethod
              }
             
            setPaymentLoading(true);
          const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/payments/initiatePayment`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
              },
            
              body: JSON.stringify(body)
          })
          if(response === null){
            throw new Error("Response is null");
          }

          if(!response.ok){
            if(response.status === 401){
                showErrorToast("kindly login again to continue");
                navigate(RoutePath.login, {state: {data: location?.pathname + location?.search}});
                return;
            }else{
                console.log(response.status)
                throw new Error("something went wrong");
            }
          }

          const data = await response.json();
          if(data === null || data === undefined){
            throw new Error("data is null");
          }
          if(!data?.paymentLink){
            throw new Error("payment link not provided");
          }
          const url = data?.paymentLink;
          console.log(data);
          window.open(url, "_blank");
        }catch(error){
            console.log(error);
            showErrorToast("Error while trying to proceed your payment")
        }finally{
            setPaymentLoading(false);
        }

      }

  return (
    <><Cart gst={gst} deposit={deposit} email={email} setEmail={setEmail} netAmount={netAmount} selectedMethod={selectedMethod} setSelectedMethod={setSelectedMethod} openEmailModal={openEmailModal} setOpenEmailModal={setOpenEmailModal} openAddressForm={openAddressForm} handlePayment={handlePayment} paymentLoading={paymentLoading} totalAmount={totalAmount} totalCharges={totalCharges} setAddNew={setAddNew} addNew={addNew} selectedAddress={selectedAddress} handleSelectAddress={handleSelectAddress} setOpenAddressForm={setOpenAddressForm} cartItems={cartItems} setKey={setKey} cartLoading={cartLoading} setCartLoading={setCartLoading} navigate={navigate} shippingAddresses={shippingAddresses} promoCodeApplied={promoCodeApplied}  setPromoCodeApplied={setPromoCodeApplied} loading={loading}/></>
  )
}

export default CartLogic