import React from 'react'
import { useLocation } from 'react-router-dom'
import BreadCrumbs from './BreadCrumbs'
import { useSelector } from 'react-redux';

const BreadCrumbsLogic = () => {
  const location = useLocation();
  const pathname = location?.pathname

  return (
    <><BreadCrumbs pathname={pathname}/></>
  )
}

export default BreadCrumbsLogic