import React from 'react'
import ContactUsLogic from '../../components/contactUs/ContactUsLogic'
import FaqLogic from '../../components/faq/FaqLogic'
import DemoVideoLogic from '../../components/demoVideo/DemoVideoLogic'
import TestimonialLogic from '../../components/testimonial/TestimonialLogic'
import BlogsLogic from '../../components/blogs/BlogsLogic'
import FeaturesLogic from '../../components/features/FeaturesLogic'
import HeaderLogic from '../../components/header/HeaderLogic'
import WhatsappContactLogic from '../../components/whatsappContact/WhatsappContactLogic'

const Homepage = ({videoUrl}) => {
  return (
    <div className='min-h-screen'>
      <WhatsappContactLogic/>
       <HeaderLogic/>
        <FeaturesLogic/>
        <div className='w-[90%] mx-auto'>
        <DemoVideoLogic videoUrl={videoUrl}/>
        </div>
        <TestimonialLogic/>
        <BlogsLogic />
        <FaqLogic/>
        <ContactUsLogic/>
    </div>
  )
}

export default Homepage