import React, {useState, useEffect} from 'react'
import FurnishingCard from './FurnishingCard'
import { useNavigate, useLocation } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import RoutePath from '../../routes/RoutePath'

const FurnishingCardLogic = ({ele, setCartLoading, cartLoading, setKey}) => {
    const {id} = useParams();
    const navigate = useNavigate();   
    const location = useLocation();
    const handleNavigate = (ele)=>{
      const name = ele?.name;
      const encodedEleString = btoa(encodeURIComponent(JSON.stringify(ele)));
      let url = ""
      if(id){
         url = `${RoutePath.furnishingByIdForCollege?.replace(":id", id)?.replace(":productId", name)}?data=${encodedEleString}`
      }else {
         url = `${RoutePath.furnishingById?.replace(":productId", name)}?data=${encodedEleString}`;
      }
      navigate(url, { state: { data: ele } });
    }
  return (
    <><FurnishingCard handleNavigate={handleNavigate} ele={ele} navigate={navigate} location={location} setKey={setKey} cartLoading={cartLoading} setCartLoading={setCartLoading}/></>
  )
}

export default FurnishingCardLogic