import React, {useEffect, useState} from 'react'
import SearchByCollege from './SearchByCollege'
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useFindCollege from '../../utils/findCollege';

const SearchByCollegeLogic = () => {
  const { id } = useParams();
  const screenWidth = useSelector(state=>state.screenWidth.screenWidth)
  const itemWidth = 296; 
  const itemMargin = 32;
  const containerWidth = screenWidth;
  const containerPadding=80;
  const {findCollegeName } = useFindCollege();
  const collegeName = findCollegeName(id);
  const [scrollPosition, setScrollPosition] = useState({cafe: 0, entertainment: 0, society: 0});
  const [nextClicked, setNextClicked] = useState(false);
  const [cafeData, setCafeData] = useState([]);
  const [societyData, setSocietyData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [entertainmentData, setEntertainmentData] = useState([]);
  const [openModal, setopenModal] = useState(false);
  const [modalContent, setModalContent] = useState(null)
  
  useEffect(()=>{
    const fetchData = async()=>{
      try{
        const response = await fetch(process.env.PUBLIC_URL + '/data/cafeLoungesData.json');
        if(!response.ok){
          throw new Error("Network error");
        }
        const jsonData = await response.json();
        const allCafeData = jsonData?.cafes_lounges;
        if(allCafeData){
          const filteredCafeData = allCafeData.filter((cafe) =>
            cafe.colleges.some((college) => college.college_id === parseInt(id, 10))
          );
          setCafeData(filteredCafeData)
        }
      }catch(error){
        console.log("Error fetching cafe data", error);
      }
    }
    fetchData();
  }, [])
  useEffect(()=>{
    const fetchData = async()=>{
      try{
        const response = await fetch(process.env.PUBLIC_URL + '/data/collegeSocietyData.json');
        if(!response.ok){
          throw new Error("Network error");
        }
        const jsonData = await response.json();
        const allSocietyData = jsonData?.societies;
        if(allSocietyData){
          const filteredData = allSocietyData.filter((ele) =>
           (ele.college_id === parseInt(id, 10))
          );
          setSocietyData(filteredData)
        }
      }catch(error){
        console.log("Error fetching society data", error);
      }
    }
    fetchData();
  }, [])
  useEffect(()=>{
    const fetchData = async()=>{
      try{
        const response = await fetch(process.env.PUBLIC_URL + '/data/entertainmentData.json');
        if(!response.ok){
          throw new Error("Network error");
        }
        const jsonData = await response.json();
        const allEntertainmentData = jsonData?.entertainment;
        if(allEntertainmentData){
          const filteredData = allEntertainmentData.filter((ele) =>
            ele.colleges.some((college) => college.college_id === parseInt(id, 10))
          );
          setEntertainmentData(filteredData)
        }
      }catch(error){
        console.log("Error fetching entertainment data", error);
      }
    }
    fetchData();
  }, [])
  useEffect(()=>{
    const fetchData = async()=>{
      try{
        const response = await fetch(process.env.PUBLIC_URL + '/data/nearbyLocationData.json');
        if(!response.ok){
          throw new Error("Network error");
        }
        const jsonData = await response.json();
        const allData = jsonData?.nearbylocations;
        if(allData){
          const filteredData = allData.filter((ele) =>
          (ele.college_id === parseInt(id, 10))
         );
          setLocationData(filteredData)
        }
      }catch(error){
        console.log("Error fetching locations data", error);
      }
    }
    fetchData();
  }, [])
  const handlePrevClick = (field) => {
    const visibleItems = calculateVisibleItems();
    const maxVisibleWidth = (itemWidth + itemMargin) * visibleItems;
    const scrollDecrement = maxVisibleWidth;
    const newPosition = Math.max(scrollPosition[field] - scrollDecrement, 0);
    setScrollPosition({...scrollPosition, [field] : newPosition});
    setNextClicked(false);
  };

  const calculateVisibleItems = ()=>{
    const totalItemWidth = itemWidth + itemMargin;
  const visibleItems = Math.floor((containerWidth - containerPadding) / totalItemWidth);
  return visibleItems;
  }

  const handleNextClick = (data, field) => {
    const visibleItems = calculateVisibleItems();
    const totalWidth = ((itemWidth + itemMargin) * data.length);
    const maxVisibleWidth = (itemWidth + itemMargin) * visibleItems;
    const maxScroll = Math.max(totalWidth - maxVisibleWidth, 0);
    const scrollIncrement = maxVisibleWidth;
    const newPosition = Math.min(scrollPosition[field] + scrollIncrement, maxScroll);
    setScrollPosition({...scrollPosition, [field] : newPosition});
  };

  const calculateMaxScroll = (data) => {
    if(screenWidth < 768){
      return 0
    }
    const visibleItems = calculateVisibleItems();
    const totalWidth = ((itemWidth + itemMargin) * data?.length);
    const maxVisibleWidth = (itemWidth + itemMargin) * visibleItems;
    const maxScroll = Math.max(totalWidth - maxVisibleWidth, 0);
    return maxScroll;
  };
  return (
    <><SearchByCollege
    collegeName={collegeName}
    cafeData={cafeData}
    collegeId={id}
    entertainmentData={entertainmentData}
    societyData={societyData}
    locationData={locationData}
    openModal={openModal}
    setopenModal={setopenModal}
    modalContent={modalContent}
    setModalContent={setModalContent}
    handleNextClick={handleNextClick}
    handlePrevClick={handlePrevClick}
    scrollPosition={scrollPosition}
    calculateMaxScroll={calculateMaxScroll}
    nextClicked={nextClicked}
    screenWidth={screenWidth}
    /></>
  )
}

export default SearchByCollegeLogic