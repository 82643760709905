
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import StatesService from './statesService';
import { setStates } from '../redux/slices/statesDataSlice';

const useInitializeStateData = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const initializeStateData = async () => {
      try {
        const statesService = new StatesService();
        const allStates = statesService.getAllStates();
        dispatch(setStates(allStates));
      } catch (error) {
        console.error('Error fetching state data', error);
      }
    };

    initializeStateData();
  }, [dispatch]);

  return {};
};

export default useInitializeStateData;
