import React from 'react'
import SubNavbarCollegeLogic from '../../components/subNavbarCollege/SubNavbarCollegeLogic'
import RoutePath from '../../routes/RoutePath'
import FurnishingCardLogic from '../../components/furnishingCard/FurnishingCardLogic'
import SpinnerLogic from '../../components/spinner/SpinnerLogic'
import PaginationLogic from '../../components/pagination/PaginationLogic'
import useFindCollege from '../../utils/findCollege';
import { FURNISHINGS_ITEMS_PER_PAGE } from '../../utils/constants'
import WhatsappContactLogic from '../../components/whatsappContact/WhatsappContactLogic'
import FiltersLogic from '../../components/filters/FiltersLogic'


const FurnishingByCollege = ({setCurrentCategory,currentCategory,setMaxRent, setMinRent, minRent, maxRent, collegeId, furnishingData, navigate, currentPage, handlePageChange, status, sortValue, setSortValue, setApibody, apibody, furnishingAvailable, setRent, sortOptions, setCategoryId, categoryId }) => {
  const {findCollegeName} = useFindCollege();
  return (
    <div>
         <WhatsappContactLogic/>
      <SubNavbarCollegeLogic
        collegeId={collegeId}
      />
     
        {
         status === "failed" ?  <div className='flex flex-col w-full min-h-screen  items-center justify-center'>
         <div className='text-center'>
         <h1 className="text-secondary font-poppins font-semibold text-5xl">Internal server error</h1>
          <p className="text-2xl font-poppins text-textColor mt-6 font-normal">We are facing some internal server error while fetching data, sorry for the inconvinience caused</p>
          <button onClick={()=>navigate(RoutePath.homepage)} className='blog-button-shadow background-primary mt-10 text-white text-lg rounded-[4px] px-4 py-2 font-medium font-poppins'>Go Back</button>
         </div>
          </div>  
         : <>
         {
          status === "loading" ? <><div className="absolute min-h-screen inset-0 bg-black opacity-50"></div><div className='absolute  z-30 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'><SpinnerLogic /></div></> :
           <>
           <div className='py-6 px-5 md:px-20 w-full flex items-center space-x-5'>
            <FiltersLogic currentCategory={currentCategory}
    setCurrentCategory={setCurrentCategory} minRent={minRent} maxRent={maxRent} setMaxRent={setMaxRent} setMinRent={setMinRent} sortValue={sortValue} setSortValue={setSortValue} categoryId={categoryId} setApibody={setApibody} apibody={apibody} sortOptions={sortOptions} setRent={setRent} serviceType={"furnishing"} setCategoryId={setCategoryId}/>
            </div>
            
           {
          !furnishingAvailable || (furnishingData?.furnishings?.length === 0)   ?
           <div className='min-h-screen w-full flex flex-col items-center justify-center'>
             <div className="text-center">
               <h1 className="text-secondary font-poppins font-semibold text-5xl">No furnishings available</h1>
               <p className="text-2xl font-poppins text-textColor mt-6 font-normal">There are currently no furnishings available, kindly check back later for updates</p>
               <button onClick={() => navigate(RoutePath.homepage)} className='blog-button-shadow background-primary mt-10 text-white text-lg rounded-[4px] px-4 py-2 font-medium font-poppins'>Go Back</button>
             </div>
           </div> :
           <>
           
               <p className="font-poppins text-secondary font-medium text-xs md:text-base px-6 md:px-20">{furnishingData?.totalFurnishingsCount} Furnishings <span className="text-textColor md:text-base text-sm font-normal"> near {findCollegeName(collegeId)}</span></p>
            <div className='md:px-20 md:pt-5 md:pb-24 pb-10 px-5 md:py-6 w-full  flex flex-wrap'>
               {
                 furnishingData?.furnishings?.map((ele, ind)=>(
                   <div key={ind} className='md:w-full w-1/2 md:p-0 px-1 '>
                  <FurnishingCardLogic key={ind} ele={ele}/>
                   {/* <hr className='text-[#D4D4D8] mt-[30px] w-full'/> */}
                   </div>
                 ))
               }
               {/* <PaginationLogic totalPages={Math.ceil(furnishingData?.totalFurnishingsCount / FURNISHINGS_ITEMS_PER_PAGE)} currentPage={currentPage} handlePageChange={handlePageChange} /> */}
             </div>
           </>
         }
          </>
}
        
         </>
        }
        
      </div>

  )
}

export default FurnishingByCollege