import React from 'react'
import plus from "../../assets/images/plusIcon.svg";
import minus from "../../assets/images/minusIcon.svg";

const Faq = ({ faqData, faqType, setFaqType, toggleAnswer, openIndexes, isType, isFaqTypeChanging }) => {
  const filteredFaq = faqData.filter((ele) => ele?.heading === faqType);
  return (
    <div className='w-[90%] mx-auto py-6 md:border-b md:border-b-[#DDDDDD] md:px-4 md:py-[80px]'>
      <h2 className='hidden md:block font-poppins font-semibold text-secondary text-[32px]  leading-10'>Frequently Asked Questions</h2>
      <h2 className='md:hidden text-[#F07C22] font-poppins font-semibold text-secondary text-[32px]  leading-10'>FAQs</h2>
      <p className='font-poppins mt-5 text-textColor md:w-[50%] font-medium text-base'>Got questions? We’ve got answers for all the housing insights you need.</p>
      <p className='font-poppins text-textColor md:w-[50%] font-medium text-base'>Let us take you a step closer to finding your perfect house with all the housing insights you need</p>
      {
        isType === '' && <div className='overflow-x-scroll md:hidden mt-5 no-scrollbar flex items-center space-x-4 border-b border-b-[#D4D4D8]'>
          {
            faqData && faqData.map((ele, ind) => (
              <p key={ind} onClick={() => setFaqType(ele?.heading)} className={`${faqType === ele?.heading ? 'text-primary border-b border-primary' : 'text-textColor '} flex-none pb-2 cursor-pointer font-poppins font-medium  text-center text-sm`}>{ele?.heading}</p>
            ))
          }
        </div>
      }
      <div className='mt-[40px] md:mt-[60px] md:flex md:items-start w-full justify-between'>
        <div className={`${isType === 'listing' ? 'flex w-full md:w-[20%] mb-8' : 'hidden md:flex w-[20%]'}  flex-col items-start`}>
          {
            faqData && faqData.map((ele, ind) => (
              <p key={ind} onClick={() => setFaqType(ele?.heading)} className={`${faqType === ele?.heading ? 'text-secondary bg-[#F4F4F5] rounded-[10px] ' : 'text-[#3F3F46] '} my-1 hover:bg-[#F4F4F5] py-[10px] px-[14px] hover:rounded-[10px] cursor-pointer font-poppins font-medium w-full text-left text-sm`}>{ele?.heading}</p>
            ))
          }

        </div>
        <div className='md:w-[70%]'>
          {filteredFaq[0]?.data?.length > 0 ? (
            filteredFaq[0]?.data.map((item, index) => (
              <div key={index} className={`${index === 0 ? 'pb-6' : 'py-6'}  border-b border-b-[#DDDDDD] ${isFaqTypeChanging ? '' : 'transition-all duration-500'}`}>
                <div onClick={() => toggleAnswer(index)} className='flex cursor-pointer w-full items-center justify-between'>
                  <p className='font-poppins font-[500] w-[88%] md:w-full text-base text-secondary'>{item.question}</p>
                  <div className=' w-7' >
                    {openIndexes.includes(index) ? <img src={minus} alt='Minus Icon' /> : <img src={plus} alt='Plus Icon' />}
                  </div>
                </div>

                <div className={`overflow-hidden ${isFaqTypeChanging ? '' : 'transition-all duration-500'} ${openIndexes.includes(index) ? 'h-auto py-4' : 'h-0 py-0'}`}>
                  <p className='font-poppins font-normal text-base text-textColor'>{item.answer}</p>
                </div>

              </div>
            ))
          ) : (
            <p className='font-poppins mt-5 text-textColor font-medium text-base'>
              No FAQ's available
            </p>
          )}
        </div>
      </div>

    </div>
  )
}

export default Faq