import React from 'react'

const BarLoader = ({progress}) => {
    return (
        <div className="loading-bar-container">
          <div className="loading-bar" style={{ width: `${progress}%` }}></div>
        </div>
      );
}

export default BarLoader
