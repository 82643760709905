import React, {useState, useEffect} from 'react'
import Navbar from './Navbar'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import RoutePath from '../../routes/RoutePath'
import { setTokenId, setUserState } from '../../redux/slices/authSlice'
import { showSuccessToast, showInfoToast, showErrorToast } from '../../utils/toastNotif'


const NavbarLogic = () => {
  const screenWidth = useSelector(state=>state.screenWidth.screenWidth);
  const dispatch = useDispatch();
  const collegeList = useSelector(state=>state.dashboard.colleges)
  const [isCollegeDropdownOpen, setisCollegeDropdownOpen] = useState(false);
  const [selectedCollege, setselectedCollege] = useState(null);
  const [filteredColleges, setFilteredColleges] = useState([])
  const [searchQuery, setSearchQuery] = useState('');
  const status = useSelector(state => state.dashboard.status);
    const error = useSelector(state => state.dashboard.error);
  
  const [isServiceDropdownOpen, setisServiceDropdownOpen] = useState(false);
  const [selectedService, setselectedService] = useState(null);
  const tokenId = useSelector(state=>state.auth.tokenId)
  const tokenFromLocalstorage = localStorage.getItem("token");
  const navigate = useNavigate();
  const [showLabel, setshowLabel] = useState(true)
  const [openMobileModal, setOpenMobileModal] = useState(false);
  const [menuOff, setMenuOff] = useState(true);

  const toggleCollegeDropdown = () => {
    if(!isCollegeDropdownOpen){
      if (status === 'loading'){
        showInfoToast("Loading college list");
        return;
      }
      if (status === 'failed') {
        console.log(error);
        showErrorToast("There was some problem fetching college list, please try again later")
        return;
      }
      setisServiceDropdownOpen(false)
    }
    isCollegeDropdownOpen  && setSearchQuery('')
   setisCollegeDropdownOpen(!isCollegeDropdownOpen);
  
 
  
};

const selectCollege = (college) => {
  setselectedCollege(college);
  setisCollegeDropdownOpen(false);
};

const topSearchColleges = [{id: '6', name: "Delhi University"}, {id: '7', name: "Delhi College of Arts and Commerce"}, {id: '8', name: "Delhi Technological University (DTU)"}]
      useEffect(()=>{
        const tempCollege = collegeList.filter((college) =>
        college.collegeName.toLowerCase().includes(searchQuery.toLowerCase()) || college.collegeCode.toLowerCase().includes(searchQuery.toLowerCase())
      );
          setFilteredColleges(tempCollege);
      }, [collegeList, searchQuery])

   const handleLogout = ()=>{
    setMenuOff(true)
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    localStorage.removeItem("userData");
    localStorage.removeItem("phoneNumber");
    localStorage.removeItem("email");
    localStorage.removeItem("shipping-address");
    localStorage.removeItem("listingData");
    dispatch(setTokenId(null));
    dispatch(setUserState(null));
    showSuccessToast("User Logged out successfully")
    navigate(RoutePath.homepage)
  }

  const handleSearch = ()=>{
    if(selectedCollege?._id){
     switch(selectedService) {
      case 'Home' :
        navigate(`${RoutePath.searchByCollegeHome?.replace(":id", selectedCollege?._id)}`);
        break;
      case 'Furnishing' :
        navigate(`${RoutePath.searchByCollegeFurnishing?.replace(":id", selectedCollege?._id)}`);
        break;
      case 'Local assist' :
        navigate(`${RoutePath.searchByCollegeLocalAssist?.replace(":id", selectedCollege?._id)}`);
        break;
      case 'Flat mate' :
        navigate(`${RoutePath.searchByCollegeFlatmate?.replace(":id", selectedCollege?._id)}`);
        break;
      default :
        navigate(`${RoutePath.searchByCollege?.replace(":id", selectedCollege?._id)}`);
        break;  
     }
    }else{
      showInfoToast("kindly select a college")
    }
  }
  const selectService = (service) => {
    setselectedService(service);
    setisServiceDropdownOpen(false);
  };
  return (
    <><Navbar
    screenWidth={screenWidth}
    navigate={navigate}
    showLabel={showLabel}
    setshowLabel={setshowLabel}
    openMobileModal={openMobileModal}
    setOpenMobileModal={setOpenMobileModal}
    menuOff={menuOff}
    setMenuOff={setMenuOff}
    isServiceDropdownOpen={isServiceDropdownOpen}
    setisServiceDropdownOpen={setisServiceDropdownOpen}
    tokenId={tokenId}
    tokenFromLocalstorage={tokenFromLocalstorage}
    handleLogout={handleLogout}
    selectService={selectService}
    setselectedService={setselectedService}
    selectedService={selectedService}
    handleSearch={handleSearch}
    isCollegeDropdownOpen={isCollegeDropdownOpen}
    toggleCollegeDropdown={toggleCollegeDropdown}
    selectCollege={selectCollege}
    selectedCollege={selectedCollege}
    collegeList={collegeList}
    searchQuery={searchQuery}
    setSearchQuery={setSearchQuery}
    filteredColleges={filteredColleges}
    setselectedCollege={setselectedCollege}
    topSearchColleges={topSearchColleges}
    setisCollegeDropdownOpen={setisCollegeDropdownOpen}
    />
    </>
  )
}

export default NavbarLogic