import React, {useContext, useEffect, useRef, useState} from 'react'
import { RxCross2 } from 'react-icons/rx'
import mainLogo from "../../assets/images/gharbarLogo.svg"
import searchIcon from "../../assets/images/navbarSearchIcon.svg"
import useFindCollege from '../../utils/findCollege';
import uparrow from "../../assets/images/upArrow.svg"
import downarrow from "../../assets/images/downArrow.svg"
import favourite from "../../assets/images/favouriteIcon.svg"
import cart from "../../assets/images/cartIcon.svg"
import hamburger from "../../assets/images/hamburgerIcon.svg"
import userIcon from "../../assets/images/userIcon.svg"
import RoutePath from '../../routes/RoutePath'
import CollegeDropdownLogic from '../collegeDropdown/CollegeDropdownLogic';
import truncateText from '../../utils/truncateText';
import payRent from "../../assets/images/payRent.svg"
import ServiceDropDownLogic from '../serviceDropDown/ServiceDropDownLogic';
import { useLocation, useNavigate } from 'react-router-dom'
import desktopSearchIcon from "../../assets/images/desktopNavbarSearchIcon.svg"
import leftarrow from "../../assets/images/leftarrow.svg"
import CartContext from '../../context/CartContext';
import { Badge } from 'antd';

const MobileSearch = ({selectedCollege, searchQuery,isCollegeDropdownOpen, filteredColleges, setSearchQuery, setisCollegeDropdownOpen, topSearchColleges, collegeList, selectCollege, toggleCollegeDropdown,  isServiceDropdownOpen, selectService, selectedService,  setisServiceDropdownOpen, handleSearch, openMobileModal, setOpenMobileModal, id}) => {
  const serviceOptions = ["Home", "Furnishing", "Local assist", "Flat mate"];
  const { findCollegeName } = useFindCollege();
  const togglingDivMobileRef = useRef(null)
  const togglingCollegeMobileNavbarRef = useRef(null);
  const navigate = useNavigate();
  return (
    <div className='bg-white w-full fixed z-50 top-0 left-0 min-h-screen'>
        <div className='py-4 px-5'>
            <img onClick={()=>setOpenMobileModal(false)} src={leftarrow} alt="back" className='cursor-pointer'/>
        </div>
        <div className='w-full flex flex-col py-6 px-5'>
                <div ref={togglingCollegeMobileNavbarRef}  onClick={(e) => {
    e.stopPropagation();  
    toggleCollegeDropdown()}} className=' w-full rounded-xl relative bg-white items-center py-[10px] px-[14px] border border-[#D4D4D8] blog-button-shadow flex md:hidden'>
            
            
            <CollegeDropdownLogic togglingCollegeMobileNavbarRef={togglingCollegeMobileNavbarRef}  setisCollegeDropdownOpen={setisCollegeDropdownOpen} isCollegeDropdownOpen={isCollegeDropdownOpen} selectCollege={selectCollege} collegeList={collegeList} searchQuery={searchQuery} setSearchQuery={setSearchQuery} filteredColleges={filteredColleges} topSearchColleges={topSearchColleges}/>    
             {    
                   isCollegeDropdownOpen ?        
                   <input type="text" onClick={(e)=>e.stopPropagation()} className='outline-none font-poppins text-sm font-normal w-full text-textColor' placeholder='Search college' value={searchQuery}
                   onChange={(e) =>{
                     e.stopPropagation();
                     setSearchQuery(e.target.value)
                   }}/>
                 :    <p className='text-secondary   text-base font-medium font-poppins'>{selectedCollege ? truncateText(selectedCollege?.collegeName, 2) : truncateText(findCollegeName(id), 2)}</p>
             }
              
              
           
            </div>
            <div   className='flex   mt-8 flex-col items-start space-y-2'>
                <p className='font-poppins font-medium text-sm text-[#3F3F46]'>Choose service</p>
                <div ref={togglingDivMobileRef} onClick={()=>setisServiceDropdownOpen(!isServiceDropdownOpen)} className=' w-full relative rounded-xl bg-white justify-between items-center py-[10px] px-[14px] border border-[#D4D4D8] blog-button-shadow flex md:hidden'>
            
             <p className='font-poppins font-normal text-base text-textColor'>{selectedService ? selectedService : 'Choose Service' }</p>
             <img src={isServiceDropdownOpen ? uparrow : downarrow} alt="search" className=''/>
             <ServiceDropDownLogic setisServiceDropdownOpen={setisServiceDropdownOpen} togglingDivMobileRef={togglingDivMobileRef} isServiceDropdownOpen={isServiceDropdownOpen} serviceOptions={serviceOptions} selectService={selectService}/>
          </div>
            </div>
            
            <div onClick={()=>handleSearch(id)} className='w-full background-primary text-white font-poppins cursor-pointer mt-8 rounded-[4px] py-[12px] px-5 font-medium text-base text-center'>Search</div>
        </div>
    </div>
  )
}

const Navbar = ({screenWidth,isServiceDropdownOpen, setselectedCollege, setisCollegeDropdownOpen, toggleCollegeDropdown, isCollegeDropdownOpen, selectCollege, collegeList, selectedCollege, searchQuery, setSearchQuery, filteredColleges, topSearchColleges, handleSearch, selectService, selectedService, setselectedService, setisServiceDropdownOpen, openMobileModal, setOpenMobileModal, navigate, showLabel, setshowLabel, menuOff, setMenuOff, tokenId, handleLogout, tokenFromLocalstorage}) => {
  const dropdownRef = useRef(null);
  const toggleRef = useRef(null)
  const location = useLocation();
  const [id, setId] = useState("");
  const toggleMobileRef = useRef(null);
  const togglingDivRef = useRef(null);
  const togglingCollegeDesktopNavbarRef = useRef(null)
  const pathname = location?.pathname;
  const { findCollegeName } = useFindCollege();
  const serviceOptions = ["Home", "Furnishing", "Local assist", "Flat mate"];
  const { cartItems } = useContext(CartContext);
  const [cartItemCount, setCartItemCount] = useState(0);

  useEffect(() => {
    const totalCount = cartItems.reduce((total, item) => total + item.item.quantity, 0);
    setCartItemCount(totalCount);
  }, [cartItems]);

  useEffect(() => {
    
    const pattern = /^\/([0-9][a-zA-Z0-9]*)\/?.*$/;
    const match = pathname.match(pattern);
    if (match && match[1]) {
      let idValue = "";
      setId(match[1]);
      switch (true) {
        case /furnishing/i.test(pathname):
            idValue = "Furnishing";
            break;
        case /home/i.test(pathname):
            idValue = "Home";
            break;
        case /local-assist/i.test(pathname):
            idValue = "Local assist";
            break;
        case /flats-mate/i.test(pathname):
            idValue = "Flat mate";
            break;
        default:
            idValue = "";
    }
    setselectedService(idValue);
    }else{
      setId("")
      setselectedCollege("")
    }
  }, [pathname]);
  useEffect(() => {
    const handleClickOutside = (event) => {
     if(screenWidth < 768){
    
      if (dropdownRef.current && toggleMobileRef.current && !toggleMobileRef.current.contains(event.target) && !dropdownRef.current.contains(event.target)) {
        setMenuOff(true);
      }
     }else{
      if (dropdownRef.current && toggleRef.current && !toggleRef.current.contains(event.target) && !dropdownRef.current.contains(event.target)) {
        setMenuOff(true);
      }
     }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [screenWidth]);


  function useScrollDirection() {
    const [scrollDirection, setScrollDirection] = useState(null);
  
    useEffect(() => {
      let lastScrollY = window.scrollY;
  
      const updateScrollDirection = () => {
        const scrollY = window.scrollY;
        const direction = scrollY > lastScrollY ? "down" : "up";
        if (direction !== scrollDirection && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
          setScrollDirection(direction);
        }
        lastScrollY = scrollY > 0 ? scrollY : 0;
      };
      window.addEventListener("scroll", updateScrollDirection); // add event listener
      return () => {
        window.removeEventListener("scroll", updateScrollDirection); // clean up
      }
    }, [scrollDirection]);
  
    return scrollDirection;
  };

  const scrollDirection = useScrollDirection();

  const scrollToContact = () => {
    var contactSection = document.getElementById("contactUs");
    contactSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  return (
    <div className={`sticky w-full z-50 ${ scrollDirection === "down" ? "-top-full" : "top-0"} transition-all duration-500`}>
    {/* <div className={`${showLabel ? 'flex' : 'hidden'} w-full relative navbar-label md:py-[30px] py-[16px] items-center justify-center`}>
      <RxCross2 onClick={()=>setshowLabel(false)} className='absolute text-xl top-1/2 cursor-pointer transform -translate-y-1/2 text-white right-4'/>
      <p className='font-poppins w-[80%] font-normal text-sm text-center text-white'>Limited Time Offer! Book Now and Make Unforgettable Memories. 🌟</p>
    </div> */}
    <div className='w-full relative flex items-center  lg:px-[80px] px-[20px] justify-between bg-[#FFFFFF] py-[5px] md:py-[16px] border border-[#E4E4E7]'>
      <div ref={dropdownRef} className={`absolute bg-white z-50 flex-col items-start border border-[#E4E4E7] rounded-lg w-[240px] header-box-shadow top-[80px] right-8 md:right-[100px] ${!menuOff ? 'flex' : 'hidden'}`}>
        <div className='flex flex-col w-full border-b border-[#D4D4D8] px-[4px]'>
          {
            tokenId || (tokenFromLocalstorage && tokenFromLocalstorage !== "undefined" && tokenFromLocalstorage !== "") ? <>
            <p onClick={()=>{
              setMenuOff(true)
              navigate(RoutePath.account)
            }} className='py-[9px] cursor-pointer px-[10px] text-secondary font-poppins font-medium text-sm'>My Account</p>
            <p onClick={()=>{
              setMenuOff(true)
              navigate(RoutePath.cart)
            }} className='py-[9px] cursor-pointer px-[10px] text-secondary font-poppins font-medium text-sm'>My cart</p>
            <p onClick={()=>{
              setMenuOff(true)
              navigate(RoutePath.favourite)
            }} className='py-[9px] cursor-pointer px-[10px] text-secondary font-poppins font-medium text-sm'>Favourite</p>
            <p onClick={handleLogout} className='py-[9px] cursor-pointer px-[10px] text-secondary font-poppins font-medium text-sm'>Log out</p>
            <p onClick={()=>{
              setMenuOff(true)
              navigate(RoutePath.orders)
            }} className='py-[9px] cursor-pointer px-[10px] text-secondary font-poppins font-medium text-sm'>My Orders</p>
            </> : <><p onClick={()=>{
              setMenuOff(true)
              navigate(RoutePath.login)}
            } className='py-[9px] cursor-pointer px-[10px] text-secondary font-poppins font-medium text-sm'>Log in</p></>
          }
        </div>
        {/* <div className='flex flex-col w-full border-b border-[#D4D4D8] px-[4px]'>
          <p onClick={()=>{
            setMenuOff(false)
            navigate(RoutePath.earnFromUs)}} className='py-[9px] px-[10px] cursor-pointer text-secondary font-poppins font-medium text-sm'>Earn from us</p>
        </div> */}
        <div className='flex flex-col w-full border-b border-[#D4D4D8] px-[4px]'>
          <p onClick={()=>{
            setMenuOff(true)
            scrollToContact() }} className='py-[9px] px-[10px] cursor-pointer text-secondary font-poppins font-medium text-sm'>Contact us</p>
        </div>
        <div className='flex flex-col border-b w-full border-[#D4D4D8] px-[4px]'>
        <p onClick={()=>{
            setMenuOff(true)
            navigate(RoutePath.listing)}} className='py-[9px] px-[10px] cursor-pointer text-secondary font-poppins font-medium text-sm'>List your Properties</p>
          <p  className='py-[9px] px-[10px] cursor-pointer text-secondary font-poppins font-medium text-sm'><a href="https://forms.gle/WJj3H9jkFbAczKRQA">Careers</a></p>
          {/* <p onClick={()=>{
            setMenuOff(true)
            navigate(RoutePath.referrals)}} className='py-[9px] px-[10px] cursor-pointer text-secondary font-poppins font-medium text-sm'>Referrals</p> */}
          {/* <p onClick={()=>{
            setMenuOff(true)
            navigate(RoutePath.listing)}} className='py-[9px] px-[10px] cursor-pointer text-secondary font-poppins font-medium text-sm'>List properties</p>
          <p onClick={()=>{
            setMenuOff(true)
            navigate(RoutePath.payRent)}} className='py-[9px] px-[10px] cursor-pointer text-secondary font-poppins font-medium text-sm'>Pay rent</p> */}
          {/* {
            tokenId && <><p onClick={()=>{
              setMenuOff(true)
              navigate(RoutePath.account)
            }} className='py-[9px] px-[10px] cursor-pointer text-secondary font-poppins font-medium text-sm'>Rent Agreements</p>
            <p onClick={()=>{
              setMenuOff(true)
              navigate(RoutePath.account)
            }} className='py-[9px] px-[10px] cursor-pointer text-secondary font-poppins font-medium text-sm'>Police verification</p>
        
            </>
          } */}
        </div>
    </div>
       <img onClick={()=>{
        setMenuOff(true)
        navigate(RoutePath.homepage)}} src={mainLogo} alt="Gharbar" className='cursor-pointer '/>
       <div className={`md:flex hidden items-center space-x-5`}>
       
        {
         id  ? <>
         <div className='w-[521px] blog-button-shadow border border-[#D4D4D8] rounded-[44px] flex items-center'>
          <div className='w-[461px]  flex items-center justify-between'>
            <div ref={togglingCollegeDesktopNavbarRef}  onClick={(e) => {
    e.stopPropagation();  
    toggleCollegeDropdown()}} className='py-[14px] w-[243px]  relative px-6 cursor-pointer'>
            <CollegeDropdownLogic togglingCollegeDesktopNavbarRef={togglingCollegeDesktopNavbarRef}  setisCollegeDropdownOpen={setisCollegeDropdownOpen} isCollegeDropdownOpen={isCollegeDropdownOpen} selectCollege={selectCollege} collegeList={collegeList} searchQuery={searchQuery} setSearchQuery={setSearchQuery} filteredColleges={filteredColleges} topSearchColleges={topSearchColleges}/>    
             {    
                   isCollegeDropdownOpen ?        
                   <input type="text" onClick={(e)=>e.stopPropagation()} className='outline-none font-poppins text-sm font-normal w-full text-textColor' placeholder='Search college' value={searchQuery}
                   onChange={(e) =>{
                     e.stopPropagation();
                     setSearchQuery(e.target.value)
                   }}/>
                 :    <p className='text-secondary   text-base font-medium font-poppins'>{selectedCollege ? truncateText(selectedCollege?.collegeName, 2) : truncateText(findCollegeName(id), 2)}</p>
             }
              
              
            </div>
            <div ref={togglingDivRef} onClick={()=>setisServiceDropdownOpen(!isServiceDropdownOpen)} className=' flex w-[218px] relative cursor-pointer py-[14px] border-l border-l-[#d4d4d8] pl-4 pr-6 items-center justify-between'>
                <p className='text-textColor   font-normal font-poppins text-base'>{selectedService ? selectedService : 'Choose Service'}</p>
                <img src={isServiceDropdownOpen ? uparrow : downarrow} alt="" />
                <ServiceDropDownLogic togglingDivRef={togglingDivRef} setisServiceDropdownOpen={setisServiceDropdownOpen} isServiceDropdownOpen={isServiceDropdownOpen} serviceOptions={serviceOptions} selectService={selectService}/>
              </div>
          </div>
         
           <div className='w-[52px] flex justify-end'>
           <img src={desktopSearchIcon} onClick={()=>handleSearch(id)} className='w-[40px] cursor-pointer h-[40px] rounded-[36px]  blog-button-shadow background-primary py-[10px]'/>
           </div>
         
         </div>
         <div className='flex items-center space-x-2  hover:bg-[#FEE3CD] bg-[#FFF4EB] py-[10px] px-[16px] text-primary font-poppins cursor-pointer rounded-[4px] font-medium text-sm text-center' onClick={()=>{
          setMenuOff(true)
          navigate(RoutePath.payRent)
        }}>
          <img src={payRent} alt="" />
          <p>Pay Rent</p>
        </div>
         </> : <>
         <div className='flex items-center space-x-2  hover:bg-[#FEE3CD] bg-[#FFF4EB] py-[10px] px-[16px] text-primary font-poppins cursor-pointer rounded-[4px] font-medium text-sm text-center' onClick={()=>{
          setMenuOff(true)
          navigate(RoutePath.payRent)
        }}>
          <img src={payRent} alt="" />
          <p>Pay Rent</p>
        </div>
          
        {/* <p onClick={()=>{
          setMenuOff(true)
          navigate(RoutePath.exploreProperties)}} className='text-[#3F3F46] cursor-pointer hover:scale-105 font-poppins font-medium text-sm text-center'>Explore Properties</p> */}
        {/* <p onClick={()=>{
          setMenuOff(true)
          navigate(RoutePath.earnFromUs)}} className='text-[#3F3F46] cursor-pointer hover:scale-105 font-poppins font-medium text-sm text-center'>Earn from us</p> */}
        {/* <p onClick={()=>{
          setMenuOff(false);
          navigate(RoutePath.services);}} className='text-[#3F3F46] cursor-pointer hover:scale-105 font-poppins font-medium text-sm text-center'>Services</p>    */}
        <p onClick={()=>{
          setMenuOff(true)
          navigate(RoutePath.listing)}
      } className='bg-[#FFF4EB] py-[10px] px-[16px] text-primary font-poppins hover:bg-[#FEE3CD] cursor-pointer rounded-[4px] font-medium text-sm text-center'>List your Properties</p>
          </>
        }
         
       {
         (tokenId || (tokenFromLocalstorage && tokenFromLocalstorage !== "undefined" && tokenFromLocalstorage !== "")) &&
        <><img onClick={()=>{
          setMenuOff(true);
          navigate(RoutePath.favourite)
        }} src={favourite} alt="" className='cursor-pointer hover:scale-105'/> </>
       }
       {
        (tokenId || (tokenFromLocalstorage && tokenFromLocalstorage !== "undefined" && tokenFromLocalstorage !== "")) && <div className='cursor-pointer flex space-x-3' onClick={()=>  navigate(RoutePath.cart)}>
         <Badge color='#EF7C22' count={cartItemCount} ><img onClick={()=>{
          setMenuOff(true);
        }} src={cart} alt="" className='cursor-pointer hover:scale-105'/></Badge>
        <p className='text-[#3F3F46] font-poppins font-medium text-sm text-center'>Cart</p> 
        </div>
       }
       <div ref={toggleRef} onClick={()=>
          setMenuOff(!menuOff)
        } className='flex cursor-pointer items-center space-x-2 border border-[#E4E4E7] rounded-[40px] py-[8px] px-[12px]'>
          <img src={hamburger} alt="" />
          <img src={userIcon} alt="" />
        </div> 
       </div>
      <div className='flex md:hidden items-center space-x-3'>
      {
        id &&   <div onClick={()=>setOpenMobileModal(true)} className="bg-[#FFF4EB] p-3 cursor-pointer rounded-full"><img src={searchIcon}/></div>
      }
      <div className='flex items-center space-x-2  hover:bg-[#FEE3CD] bg-[#FFF4EB] py-[10px] px-[10px] text-primary font-poppins cursor-pointer rounded-[4px] font-medium text-sm text-center' onClick={()=>{
          setMenuOff(true)
          navigate(RoutePath.payRent)
        }}>
          <img src={payRent} alt="" />
          {!id && <p>Pay Rent</p>}
        </div>
        {
        id &&   <Badge color='#EF7C22' count={cartItemCount}>
          <div className='cursor-pointer flex space-x-3' onClick={()=>  navigate(RoutePath.cart)}>
        <img src={cart} alt="" className='cursor-pointer hover:scale-105'/>
       </div>
        </Badge>
      }
      <div ref={toggleMobileRef} onClick={(e)=>setMenuOff(!menuOff)} className={`flex cursor-pointer md:hidden items-center space-x-2 border border-[#E4E4E7] rounded-[40px] py-[8px] px-[12px]`}>
          <img src={hamburger} alt="" />
          <img src={userIcon} alt="" />
        </div> 
      </div>
    </div>
    {
      openMobileModal && <MobileSearch toggleCollegeDropdown={toggleCollegeDropdown} setisCollegeDropdownOpen={setisCollegeDropdownOpen} isCollegeDropdownOpen={isCollegeDropdownOpen} selectCollege={selectCollege} collegeList={collegeList} searchQuery={searchQuery} setSearchQuery={setSearchQuery} filteredColleges={filteredColleges} topSearchColleges={topSearchColleges} selectedCollege={selectedCollege}
       selectService={selectService}
      setselectedService={setselectedService} handleSearch={handleSearch}
      selectedService={selectedService} id={id} isServiceDropdownOpen={isServiceDropdownOpen} setisServiceDropdownOpen={setisServiceDropdownOpen} openMobileModal={openMobileModal} setOpenMobileModal={setOpenMobileModal}/>
    }
    </div>
  )
}

export default Navbar