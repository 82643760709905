import React, {useState, useEffect} from 'react'
import HomeByCollege from "./HomeByCollege"
import useFindCollege from '../../utils/findCollege';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { showErrorToast, showInfoToast } from '../../utils/toastNotif';
import RoutePath from '../../routes/RoutePath';
import { fetchCollegeListings } from '../../redux/slices/collegeListingsSlice';
import { LISTINGS_ITEMS_PER_PAGE} from '../../utils/constants';

const HomeByCollegeLogic = () => {
 
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [typeOfProperty, setTypeOfProperty] = useState("flat")
  const [rent, setRent] = useState({startRent: 0, endRent: ""})
  const [genderPreference, setGenderPreference] = useState("");
  const [propertySize, setPropertySize] = useState("");
  const [furnishing, setFurnishing] = useState("")
  const [minRent, setMinRent] = useState(0);
  const location = useLocation();
  const [maxRent, setMaxRent] = useState(50000);
  const [apibody, setApibody] = useState({filters: {typeOfProperty: typeOfProperty, genderPreference: ""}, currPage: 1, itemsPerPage: LISTINGS_ITEMS_PER_PAGE, sortKey: "rent", sortValue: 1})
  const [sortValue, setSortValue] = useState(`distance-${1}`);
  const { id } = useParams();
  const dispatch = useDispatch();
  const sortOptions = [ {label: "rating", sortKey: "rating", sortValue: -1},{label: "Price: Low to High", sortKey: "rent", sortValue: 1}, {label: "Price: High to Low", sortKey: "rent", sortValue: -1}]

  const { status, listingsData, error } = useSelector(state => state.collegeListings);

  useEffect(()=>{
   if(error === "Unauthorized"){
    showErrorToast("Kindly login again to continue");
    navigate(RoutePath.login, {state: {data: location?.pathname + location?.search}});
   }
  }, [error])
 
  const handlePageChange = (page) => {
    setCurrentPage(page);
    setApibody(prevState => ({...prevState, currPage: page}));
  };

  const handlePropertyChange = (property) => {
    if(typeOfProperty === property){
      return;
    }
    setTypeOfProperty(property);
    setGenderPreference("")
    setApibody(prevState => ({...prevState, filters: { ...prevState.filters, genderPreference: "", startRent: 0, endRent: "", propertySize: "", furnishing: "", typeOfProperty: property}}))
  }




  useEffect(()=>{
    if(!id){
      showErrorToast("Something went wrong, please try again")
      navigate(RoutePath.homepage);
      return;
    }
   
  
    // if(!tokenRedux && (!tokenLocalstorage || tokenLocalstorage === "undefined" || tokenLocalstorage === "")){
    //   showInfoToast("Kindly login to continue");
    //   navigate(RoutePath.login);
    //   return;
    // }

    
   if(id){
    const fetchData = () => {
      try {
        dispatch(fetchCollegeListings({ id, apibody }));
      } catch (error) {
        showErrorToast("Error fetching college listings. Please try again later.");
        navigate(RoutePath.homepage)
      }
    };

    fetchData();
   }else{
      showInfoToast("something went wrong")
      return;
   }

  }, [ id, apibody, currentPage])



 
  return (
    <><HomeByCollege
    collegeId={id}
    currentPage={currentPage}
    handlePageChange={handlePageChange}
    listingsData={listingsData}
    navigate={navigate}
    status={status}
    error={error}
    typeOfProperty={typeOfProperty}
    handlePropertyChange={handlePropertyChange}
    setRent={setRent}
    sortOptions={sortOptions}
    setApibody={setApibody}
    apibody={apibody}
    sortValue={sortValue}
    setSortValue={setSortValue}
    genderPreference={genderPreference}
    setGenderPreference={setGenderPreference}
    setMaxRent={setMaxRent}
    setMinRent={setMinRent}
    minRent={minRent}
    maxRent={maxRent}
    furnishing={furnishing}
    setFurnishing={setFurnishing}
    propertySize={propertySize}
    setPropertySize={setPropertySize}
    /></>
  )
}

export default HomeByCollegeLogic