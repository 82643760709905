import React from 'react'

const   Terms = () => {
  return (
    <div className="container mx-auto p-1" >
        <h2 className="md:text-xl text-lg font-semibold mb-2">Onboarding</h2>
        <div className="space-y-2 text-sm">
            <p>1. Booking will be considered only if 50% of one month's rent will be paid to Gharbar as a token
amount. The token amount is subjected to be further managed into security deposits after deducting
our service charges (i.e- 50% of one month's rent for Bachelors only ).</p>
            <p>2. The token amount will be refunded if the booking is cancelled by the tenants on or before 8 PM
the same day. After this period, if the booking is cancelled by the tenant then the token amount is
subjected to forfeit</p>
            <p>3. If Gharbar declines the tenant’s booking then the full token amount will be refunded.</p>
            <p>4. Only Gharbar has the right to confirm or decline any tenant’s booking. In case Gharbar declines
any tenant’s booking, a notification via SMS or mail will be sent to the tenant on the same day.</p>
            <p>5. In case any tenant uploads invalid documents, Gharbar can reject such invalid documents and
extend the move-in date for resubmission of the valid documents by the tenant. Till such time,
Gharbar will not cancel the booking and only move-in date will be extended.</p>
            <p>6. The room will be automatically released and will be available for booking to others if the tenant
fails to pay the service charges, security deposit and advance monthly rental in full before move-in
date or KYC documents to Gharbar</p>
        </div>

        <h2 className="md:text-xl text-lg font-semibold md:mt-8 mt-4 mb-2">Security Deposit</h2>
        <div className="space-y-2 text-sm">
            <p>1. Tenants need to pay the Security Deposit at least 2 days prior to the License start date/Agreement
start date and also submit KYC documents 2 days prior to the move-in date for verification purposes.
The move-in date will be delayed in the event any tenant fails to submit the required documents
within the above stipulated time.</p>
            <p>2. The security deposit will not be adjusted against the rental of the Tenant, it is fully refundable with
any interest. </p>
            <p>3. Gharbar shall not be held liable for the security amount deposited to the house owners. It might
be deducted if:
            <ul className="list-disc list-inside space-y-px">
                <li>Any damage to the property is done.</li>
                <li>Late Payment charges are not paid.</li>
                <li>Electricity and Water bills are not cleared before vacating the property.</li>
            </ul>
            </p>
        </div>

        <h2 className="md:text-xl text-lg font-semibold md:mt-8 mt-4 mb-2">Leave & License Agreement</h2>
        <div className="space-y-2 text-sm">
            <p>1. If the tenant renews the rental agreement 30 days prior to license end date then the agreement
will be renewed 8 %, and if the tenant renews the rental agreement 30 days post to license end date
then the agreement will be renewed 10% or this can be decided mutually between the tenant and
the landlords.</p>
            <p>2. The tenant has to bear a cost of INR 599 as Lease renewal charges</p>
        </div>

        <h2 className="md:text-xl text-lg font-semibold md:mt-8 mt-4 mb-2">Move-in Process</h2>
        <div className="space-y-2 text-sm">
            <p>1. It is mandatory to schedule a move-in date at the time of booking.</p>
            <p>2. If the tenant decides to move-in before the license start date/agreement start date then the
license start date/agreement start date and move-in date can be preponed subject to the availability
of the room. The rent shall be calculated according to the revised License start date/Agreement start
date clause.</p>
            <p>3. Token and Security deposit needs to be paid within 7 days starting from the booking date</p>
            <p>4. KYC Verification is mandatory before move-in and the same needs to be done by uploading ID
Proof and address proof on Gharbar Tenants Dashboard. The tenants will not get permission to
move-in unless profile details are completed and documents are not uploaded and verified</p>
            <p>5. The license agreement needs to be signed by the tenant before move-in and shall be delivered to
the tenant within 7 working days.</p>
            <p>6. The room will be automatically released and will be available for booking to others if the tenant
fails to pay the move-in charges, security deposit and advance monthly rental in full before move-in
date or KYC documents to Gharbar.</p>
        </div>

        <h2 className="md:text-xl text-lg font-semibold md:mt-8 mt-4 mb-2">Notice Period, Lock-in Period & Minimum Stay</h2>
        <div className="space-y-2 text-sm">
            <p>1. 1 month’s rent will be taken as an early termination charge that applies if the tenant moves-out
before completing his lock-in period of stay at the Gharbar Property. 6 months from the License start
date/Agreement start date shall be considered as Lock-in period.</p>
            <p>2. The tenant has to provide 30-days' notice while vacating, without exception at any point in time,
any shortfall in notice period will be charged on a pro-rata basis where the fees would be equal to
the no. of days of shortfall of the 30-day period.</p>
        </div>

        <h2 className="md:text-xl text-lg font-semibold md:mt-8 mt-4 mb-2">Please Note</h2>
        <div className="space-y-2 text-sm">
            <p>1. Gharbar will not be responsible for any delay in police verification or execution of leave and license
agreement due to delay from the Tenant, Owner or the police.</p>
            <p>2. If during the tenure of the stay, if the tenant shifts to another house managed by Gharbar, move in
charges are to be paid as per the new property.</p>
            <p>3. The Society Move-in Charges (Required to be paid to the Society Welfare on behalf of Tenant),
wherever applicable, needs to be paid over and above the existing charges.</p>
        </div>

        <h2 className="md:text-xl text-lg font-semibold md:mt-8 mt-4 mb-2">License Start Date/Agreement Start Date</h2>
        <div className="space-y-2 text-sm">
            <p>The rent will start from the license start date/agreement start date, provided at the time of booking.</p>
            <p>Payment Terms
            <ul className="list-disc list-inside space-y-px">
                <li>Tenant needs to pay the rent in advance, on or before the 5th day of due date</li>
                <li>In case, the tenant is moving into a Gharbar property on any day of the said month, the rent for
that month will be calculated on a Pro Rata and needs to be paid within 7 days of booking.</li>
            </ul>
            </p>
        </div>

        <h2 className="md:text-xl text-lg font-semibold md:mt-8 mt-4 mb-2">Late Payment Charges</h2>
        <div className="space-y-2 text-sm">
            <p>1. If the tenant fails to pay the rent on or before the 5th day of due date, then Gharbar will charge
INR. 100 per day as of late payment charges. It should not exceeds more than INR 1500, if so then
vacancy notice will be issued and one month’s of security deposit along with late payment charges
will be deducted.</p>
            <p>2. Non-payment of rent post 15th of the month would result in a breach of tenancy terms and the
tenant would be required to vacate the scheduled premises within 48 hours.</p>
            <p>3. If the rent is not received by the due date as per the policies, Gharbar is at liberty to report the
delay or non-payment to credit agencies which can impact the tenants' credit score.</p>
        </div>
        
        <h2 className="md:text-xl text-lg font-semibold md:mt-8 mt-4 mb-2">Your Rent Covers</h2>
        <div className="space-y-2 text-sm">
            <p>1. Monthly rent</p>
            <p>2. Apartment/society maintenance charges wherever applicable (unless mentioned otherwise)</p>
            <p>3. Managed services in selected properties subject to the subscription taken</p>
        </div>
        
        <h2 className="md:text-xl text-lg font-semibold md:mt-8 mt-4 mb-2">Your Rent Does Not Cover</h2>
        <div className="space-y-2 text-sm">
            <p>1. Monthly Water charges</p>
            <p>2. Monthly Electricity Charges</p>
            <p>3. Monthly Cooking Gas charges</p>
            <p>4. Monthly DTH and Internet charges, unless mentioned in the contract.</p>
            <p>5. Any recurring charges for the housekeeping, cleaning, garbage collection, etc unless mentioned
otherwise</p>
            <p>6. Any additional furnishing that’s not part of the Gharbar package</p>
            <p>7. Club House or Other Amenities, if charged by the Society, are not included in the Gharbar package
and it is at the sole discretion of the Society to allow/not allow tenants to use common society
facilities.</p>
            <p>8. Anything not mentioned in the “Your Rent Covers” Section</p>
            <p>9. "FOOD IS NOT PROVIDED AND NOT INCLUDED IN THE MONTHLY RENT" unless mentioned
otherwise</p>
        </div>

        <h2 className="md:text-xl text-lg font-semibold md:mt-8 mt-4 mb-2">Cancellation and Refund Policy</h2>
        <div className="space-y-2 text-sm">
            <p>Refund on cancellation before the license start date/agreement start date</p>
            <p>1. If Tenant cancels before the license start date/agreement start date, the token amount will be
forfeited entirely.</p>
            <p>2. If the tenant doesn't pay the remainder of the security deposit and advance rent in full within 7
days of booking, the booking will be auto-cancelled. In this situation, as stated before the token
amount will be forfeited in full.</p>
            <p>3. If the tenant has made all the payments on time before moving in, but due to unavoidable
circumstances, he is unable to move-in, in that situation the token amount will be forfeited and the
rest of the amount will be refunded in full.</p>
        </div>

        <h2 className="md:text-xl text-lg font-semibold md:mt-8 mt-4 mb-2">Home Furnishing</h2>
        <div className="space-y-2 text-sm">
            <p>1. In the case of theft/loss and damage of any furnishings or appliances or furniture in the house, all
the tenants staying at the scheduled premises at that point of time shall be held responsible</p>
            <p>2. A reasonable sum shall be deducted from the security deposit of tenants towards compensation of
the loss/ theft and damage or an amount as mentioned in the cancellation & refund policy. In case
the recovery amount exceeds the security deposit, the tenant shall be liable to pay the amount</p>
            <p>3. We shall not be responsible for any theft or accidental damage to your valuables in case of an
untoward incident.</p>
        </div>
        
        <h2 className="md:text-xl text-lg font-semibold md:mt-8 mt-4 mb-2">General Policies</h2>
        <div className="space-y-2 text-sm">
            <p>1. Keys for the main door of the house and particular rooms are provided to all tenants.</p>
            <p>2. On losing the keys, the tenant can make a duplicate key by himself/herself or request Gharbar to
do the same. In case, the tenant requests Gharbar, a charge of INR 350 will be levied in addition to
the actual cost incurred for making a duplicate key.</p>
            <p>Gharbar is not responsible for the maintenance of any appliances that are fitted in the house except
the items rented through gharbar, that the tenant has rented, although on-demand Gharbar may
provide maintenance service and the same will be charged to the tenant.</p>
        </div>
        
        <h2 className="md:text-xl text-lg font-semibold md:mt-8 mt-4 mb-2">Agreement Renewal Charges</h2>
        <div className="space-y-2 text-sm">
            <p>The tenant will be required to pay a non-refundable charge to Gharbar towards the Agreement
Renewal. The cost will be INR. 599.</p>
        </div>

        <h2 className="md:text-xl text-lg font-semibold md:mt-8 mt-4 mb-2">Deboarding</h2>
        <div className="space-y-2 text-sm">
            <p>1. The tenant has to serve 30 days of the notice period without exception, any shortfall in notice
period will be charged pro-rata based on the shortfall in the number of days from the 30 day notice
period.</p>
            <p>2. Any cancellation after the move-in date shall be considered a move-out and all policies related to
move-out shall be applicable.</p>
            <p>3. The security deposit shall be refunded, after the deduction of any charges as per Gharbar’s
policies, within 30 bank working days from the day of move-out.</p>
            <p>4. If the tenant decides to move out before the lock-in period as per license agreement of stay with
Gharbar, one month's rent as early termination charges would be deducted from the security
deposit. A notice period of 30 days would be needed in any case.</p>
            <p>5. If a tenant moves out after completing the lock-in period without giving 30 days of notice, then
rent for 1 month will be deducted.</p>
            <p>6. If the tenant moves out from any Gharbar Property without giving any notice and he/she also
hasn’t completed the minimum lock-in period, then 1 month’s rent will be charged from the security
deposit</p>
            <p>7. Any unpaid rent, utility payments will be deducted from the security deposit.</p>
            <p>8. A charge of INR 500 will be deducted towards cleaning of the room. This is a fixed charge which
every tenant will bear upon his/her move out from the property.</p>
            <p>9. The tenant has to pay for damages, on actuals, in case there’s some damage to the property or its
belongings at the time of move out audit.</p>
        </div>

        <h2 className="md:text-xl text-lg font-semibold md:mt-8 mt-4 mb-2">Safety and Security</h2>
        <div className="space-y-2 text-sm">
            <p>1. Gharbar is located across Delhi in a reputed society and safe locations. In order to maintain a
peaceful environment of the society, nuisance or misbehaviour of any kind including but not limited
to playing loud music beyond the permitted time limit, drinking/smoking in society corridors/
premises and consumption of illegal and banned substances are not allowed in any Gharbar</p>
            <p>2. Internal issues/ fights among the tenants are better resolved among housemates. The involvement
of Gharbar is not beneficial and hence undesirable.</p>
            <p>3.During the stay, if the tenant violates any rules as specified herein or as included in the leave and
license agreement, Gharbar owns the right to circulate appropriate notice including but not limited
to Eviction notice to the tenant or impose a penalty amounting INR 10000 or more at its sole
discretion</p>
            <p>4. There will be tenant visits, maintenance drives as and when necessary. During such occasions,
tenants are requested to keep all belongings safe to avoid any confusion regarding the same.
Gharbar will not be responsible for any theft that takes place.</p>
        </div>






    </div>
  )
}

export default   Terms