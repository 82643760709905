import { showErrorToast, showInfoToast, showSuccessToast } from './toastNotif';
import RoutePath from "../routes/RoutePath";
export const handleCheckAvailability = async (id, serviceType, dispatch, tokenId, navigate, setLoading)=>{
    setLoading(true);
   if(!id){
    showErrorToast("Invalid college id");
    return;
   }
   
   if(!serviceType){
    showErrorToast("Something went wrong, please try again later");
    console.log("service type not provided");
    return;
   }

   const token = localStorage.getItem("token");
   if(!tokenId && (!token || token === "undefined" || token === "")){
    showInfoToast("kindly login to continue");
    navigate(RoutePath.login);
    return;
   }

   try{
   
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/colleges/checkServiceAvailability`,{
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${tokenId || token}`
        },

        body: JSON.stringify({
            collegeId: id,
            serviceType: serviceType
        })
    });

    if(response === null){
        console.log("Response is null");
        showErrorToast("Something went wrong, please try again later")
        return;
    }

    if(response?.status === 401) {
        console.log("Unauthorized");
        showInfoToast("Kindly login to continue");
        navigate(RoutePath.login);
        return;
    }

    if(!response.ok){
        console.log(`Error fetching check availability api: ${response.status} - ${response.statusText}`);
        showErrorToast("Error checking availability, please try again later");
        return;
    }
    const data = await response.json();
    if (data === null || data === undefined) {
        console.log("Data is null or undefined")
        showErrorToast("Something went wrong, please try again later")
        return;
    }
    if(data?.isAvailable){
        showSuccessToast(`Available`);
      }else{
         showErrorToast("Not available")
      }
    return data;
   }catch(error){
    console.error(error);
    showErrorToast("Something went wrong, please try again later");
}finally{
    setLoading(false);
}

}