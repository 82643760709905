import React, { useState } from 'react'
import ServicePage from './ServicePage'
import bed from "../../assets/images/bedIcon.png"
import househelp from "../../assets/images/househelpIcon.png"
import appliance from "../../assets/images/applianceIcon.png"
import { useSelector } from 'react-redux'

const ServicePageLogic = () => {
    const [serviceType, setserviceType] = useState('')
    const [formData, setFormData] = useState({});
    const [selectedTenures, setSelectedTenures] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const screenWidth = useSelector(state=>state.screenWidth.screenWidth);
    const serviceTypeOptions = [
        {img: bed, name: 'Furniture'},
        {img: appliance, name: 'Appliance'},
        {img: bed, name: 'Kitchen Set'},
        {img: househelp, name: 'House Help'},
    ]

    const handleChange = (e, fieldName) => {
      setFormData({ ...formData, [fieldName]: e.target.value });
    };
  
    const handleRemoveTenure = (tenureToRemove) => {
      setSelectedTenures((prevSelectedTenures) =>
        prevSelectedTenures.filter((tenure) => tenure !== tenureToRemove)
      );
    };

    const handleTenureChange = (selectedOptions) => {
      setSelectedTenures((prevSelectedTenures) => {
        const uniqueSelectedTenures = Array.from(new Set([...prevSelectedTenures, ...selectedOptions]));
    
        handleChange(
          { target: { value: uniqueSelectedTenures.map((option) => option.value) } },
          'tenures'
        );
        return uniqueSelectedTenures;
      });
    };
    const toggleDropdown = () => {
      setIsDropdownOpen(!isDropdownOpen);
    };
  
   
      const handleSubmit = () => {
       
        setFormData({serviceType: serviceType})
      };  
  return (
    <><ServicePage
    serviceType={serviceType}
    setserviceType={setserviceType}
    serviceTypeOptions={serviceTypeOptions}
    screenWidth={screenWidth}
    handleChange={handleChange}
    handleSubmit={handleSubmit}
    formData={formData}
    isDropdownOpen={isDropdownOpen}
    toggleDropdown={toggleDropdown}
    selectedTenures={selectedTenures}
    handleTenureChange={handleTenureChange}
    handleRemoveTenure={handleRemoveTenure}
    setFormData={setFormData}
    /></>
  )
}

export default ServicePageLogic