import React, { useState, useEffect, useRef } from 'react';
import "./OtpInput.css";

const KEY = {
    backspace: 8,
    left: 37,
    right: 39,
};

const InputOTP = ({
    value = '',
    otpLength = 6,
    type = 'tel',
    numberOnly = false,
    autoFocus = false,
    pattern = null,
    error = false,
    errorMessage = '',
    disabled = false,
    wrapperClass,
    inputWrapperClass,
    inputClass,
    errorMessageClass,
    onComplete,
    ...rest
}) => {
    const [inputValues, setInputValues] = useState(Array(otpLength).fill(''));
    const inputRefs = useRef([]);

    useEffect(() => {
        if (autoFocus && inputRefs.current[0]) {
            inputRefs.current[0].focus();
        }
    }, [autoFocus]);

    useEffect(() => {
        const otp = inputValues.join('');
        if (otp && otp.length == otpLength) {
            onComplete(otp);
        }
    }, [inputValues]);

    const inputFilter = pattern || (numberOnly ? /[^0-9]/gi : /[^0-9a-zA-Z]/gi);

    const handleChange = (event, index) => {
        const newValues = [...inputValues];
        newValues[index] = event.target.value.replace(inputFilter, '').slice(0, 1);
        setInputValues(newValues); // This will trigger useEffect
        // Focus next input after current input
        if (index < otpLength - 1 && newValues[index].length === 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (event, index) => {
        if (event.keyCode === KEY.backspace && inputValues[index] === '' && index > 0) {
            inputRefs.current[index - 1].focus();
        }
        if (event.keyCode === KEY.left && index > 0) {
            inputRefs.current[index - 1].focus();
        }
        if (event.keyCode === KEY.right && index < otpLength - 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handlePaste = (event, index) => {
        event.preventDefault();
        const pastedData = event.clipboardData.getData('text/plain').replace(inputFilter, '').slice(0, otpLength - index);
        const newValues = [...inputValues];
        pastedData.split('').forEach((char, i) => {
            if (index + i < otpLength) {
                newValues[index + i] = char;
                if (inputRefs.current[index + i]) {
                    inputRefs.current[index + i].focus();
                }
            }
        });
        setInputValues(newValues); // This will trigger useEffect
    };

    return (
        <div>
            <div className={wrapperClass}>
                {Array.from({ length: otpLength }, (_, index) => (
                    <div
                        key={index}
                        className={`${inputWrapperClass} ${error ? 'error' : ''}`}
                    >
                        <input
                            type={type}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            className={inputClass}
                            ref={(el) => (inputRefs.current[index] = el)}
                            value={inputValues[index]}
                            onChange={(e) => handleChange(e, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            onPaste={(e) => handlePaste(e, index)}
                            disabled={disabled}
                            {...rest}
                        />
                    </div>
                ))}
            </div>
            {error && errorMessage && (
                <p className={errorMessageClass}>{errorMessage}</p>
            )}
        </div>
    );
};

export default InputOTP;