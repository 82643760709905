import React from 'react'
import { useLocation } from 'react-router-dom'
import videoIcon from "../../assets/images/videoIcon.svg"
import RoutePath from '../../routes/RoutePath'

const DemoVideo = ({videoUrl}) => {
  const location = useLocation();
  return (
    <div className={`${location?.pathname === RoutePath.homepage ? "flex flex-col items-start pt-6 rounded-lg" : "flex flex-row-reverse items-center md:rounded-lg pt-5 md:pt-6"} w-[100%] my-[56px] md:flex md:items-center md:flex-row-reverse mx-auto background-secondary   px-5 pb-8 `}>
     <div className={` ${location?.pathname === RoutePath.homepage ? "w-full md:w-[40%]" : "md:w-[40%] md:block hidden"} `}>
     <iframe width="100%" height="152" className='rounded-lg' src={videoUrl} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
     </div>
      <div className='md:w-[60%]'>
      <h4 className='text-[#FFFFFF] font-poppins font-medium mt-3 text-[22px] md:text-[36px] md:leading-[44px] md:w-[75%] leading-8 py-3 px-2'>Affordable is Not Just A Name, It's A Promise!</h4>
      <p className='font-poppins font-normal text-sm text-[#FAFAFA] px-2 w-[80%]'>Offers a convenient and flexible solution for furnishing</p>
      <div className='flex w-[200px] mt-5 items-center space-x-2 py-[10px] px-4 border rounded-[4px] border-[#FFFFFF]'>
        <img src={videoIcon} alt="" />
        <p className='font-poppins font-medium text-sm text-[#FFFFFF]'>Watch Demo Video</p>
      </div>
      </div>
    </div>

   
  )
}

export default DemoVideo