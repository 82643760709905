import React, {useState, useEffect} from 'react'
import Features from './Features'
import { useSelector } from 'react-redux';

const FeaturesLogic = () => {
    const [featureData, setfeatureData] = useState([]);
    const screenWidth = useSelector(state=>state.screenWidth.screenWidth)

    useEffect(()=>{
        const fetchData = async()=>{
            try {
                const response = await fetch(process.env.PUBLIC_URL + '/data/featureData.json')
                if(!response.ok){
                    throw new Error('Network response was not ok');
                }
                const jsonData = await response.json();
                setfeatureData(jsonData.features)

            }catch(error){
                console.log('Error fetching features data:', error)
            }
        }

     fetchData()
    }, [])
  return (
    <><Features
    featureData={featureData}
    screenWidth={screenWidth}
    /></>
  )
}

export default FeaturesLogic