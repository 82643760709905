import React, {useState} from 'react'
import Stepper from './Stepper'

const StepperLogic = ({totalSteps, stepLabels, activeStep, handleStepChange}) => {
    
  return (
    <><Stepper
    totalSteps={totalSteps}
    stepLabels={stepLabels}
    activeStep={activeStep}
    handleStepChange={handleStepChange}

    /></>
  )
}

export default StepperLogic