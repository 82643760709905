import screenWidthReducer from '../slices/screenWidthSlice';
import authReducer from '../slices/authSlice';
import statesDataReducer from "../slices/statesDataSlice"
import dashboardReducer from "../slices/dashboardSlice"
import localAssistReducer from '../slices/localAssistSlice';
import collegeListingsReducer from "../slices/collegeListingsSlice"
import getFurnishingsReducer from "../slices/getFurnishingsSlice"
import getCategoriesReducer from '../slices/getCategoriesSlice';
import getFavouritesReducer from "../slices/getFavouritesSlice"
import getLocalitiesReducer from "../slices/getLocalitiesSlice"
import contactUsReducer from "../slices/contactUsSlice"
import addVisitReducer from "../slices/addVisitSlice"
import { combineReducers } from '@reduxjs/toolkit';


const rootReducer = combineReducers({
    screenWidth: screenWidthReducer,
    auth: authReducer,
    states: statesDataReducer,
    dashboard: dashboardReducer,
    localAssist: localAssistReducer,
    collegeListings: collegeListingsReducer,
    getLocalities: getLocalitiesReducer,
    getFavourites: getFavouritesReducer,
    contactUs: contactUsReducer,
    addVisit: addVisitReducer,
    getFurnishings: getFurnishingsReducer,
    getCategories: getCategoriesReducer,

  });

export default rootReducer