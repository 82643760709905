import { calculateEndDate, convertToformat } from './formatDate';
import RoutePath from "../routes/RoutePath"
import { showErrorToast, showInfoToast, showSuccessToast } from './toastNotif';
export const handleStartNoticePeriod = async (occupiedPropertyId, listingId, reason, setConfirmModal, setLoading, navigate, location) => {
     if(!reason){
        showErrorToast("kindly enter reason");
        return;
     }else{
        try{
            setLoading(true);
            const token = localStorage.getItem("token");
            if(!token || token === "undefined" || token === ""){
                showInfoToast("Kindly login to continue");
                navigate(RoutePath.login, {state: {data: location?.pathname + location?.search}});
                return;
            }
            const startDate = convertToformat(new Date());
            if(!startDate){
                throw new Error("invalid start date");
            }
            const endDate = calculateEndDate(25);
            if(!endDate){
                throw new Error("invalid end date");
            }
            const apibody = {
                occupiedPropertyId,
                listingId,
                startDate: startDate,
                endDate: endDate,
                reason
            }
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/occupiedProperties/startNoticePeriodOfOccupiedProperty`,{
                method: 'POST',
                headers: {
                  "Content-Type": "application/json",
                  "Authorization": `Bearer ${token}`
                }, body: JSON.stringify(apibody)
            });
        
            if(response === null){
                console.log("Response is null");
                showErrorToast("Something went wrong, please try again later")
                return;
            }
        
            if(response?.status === 401) {
                console.log("Unauthorized");
                showInfoToast("Kindly login to continue");
                navigate(RoutePath.login, {state: {data: location?.pathname + location?.search}});
                return;
            }
        
            if(!response.ok){
                console.log(`Error fetching  notice period api: ${response.status} - ${response.statusText}`);
                showErrorToast("Error checking notice period, please try again later");
                return;
            }
            const data = await response.json();
            if (data === null || data === undefined) {
                console.log("Data is null or undefined")
                showErrorToast("Something went wrong, please try again later")
                return;
            }
             
         
            showSuccessToast("notice period started")
        
        }catch(error){
            console.log(error);
            showErrorToast("Error starting notice period, please try again later");
        }finally{
            setConfirmModal(false);
            setLoading(false)
        }
     }
}