import React, { useEffect, useRef, useState } from 'react'
import background from "../../assets/images/contactUs.png"
import mobilebackground from "../../assets/images/contactUsMobile.png"
import SpinnerLogic from "../../components/spinner/SpinnerLogic"
import useFindCollege from '../../utils/findCollege';
import CollegeDropdownLogic from '../collegeDropdown/CollegeDropdownLogic'
import { Modal } from 'antd';
import PrivacyPolicy from '../privacyPolicy/PrivacyPolicy';

const ContactUs = ({ screenWidth, isChecked, handleCheckboxChange, formData, handleDataChange, handleSubmit, status, errors, requirederrors, isCollegeDropdownOpen,
  toggleCollegeDropdown,
  selectCollege,
  selectedCollege,
  collegeList,
  searchQuery,
  setSearchQuery,
  filteredColleges, }) => {
  const dropdownRef = useRef(null);  
  const { findCollegeName } = useFindCollege();
  const options = ["Student", "House Owner", "Channel Partner", "Other"];
  const handleOptionClick = (option) => {
    selectCollege(option);
    setIsOpen(false);
  };
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    // Function to close dropdown when clicking outside
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Unbind the event listener on cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div id='contactUs' className='md:w-[90%] pb-20 w-full mx-auto flex md:py-[80px] md:flex-row-reverse md:flex flex-col items-start mt-[30px] justify-center'>
      <div className='md:w-[51%] block w-full '>
        <img src={screenWidth < 768 ? mobilebackground : background} alt="form bg" className='w-full h-full bg-cover' />
      </div>
      <div className='md:w-[56%] w-[90%] mx-auto mt-[30px] md:mt-0 flex flex-col'>
        {
          status === "loading" ? <div className="flex justify-center align-middle"><SpinnerLogic /></div> : <>  <h2 className='font-poppins font-semibold md:text-[32px] text-[29px] md:leading-10 leading-8 text-[#0F172A] tracking-[-0.2px]'>
            Contact us
          </h2>
            <p className='font-poppins mt-4 mb-7 font-[400] text-base text-[#475569]'>
              Our friendly team would love to hear from you.
            </p>
            <div className='md:flex md:flex-row md:flex-wrap gap-2 flex flex-col items-center md:items-start md:w-[80%] w-full justify-between'>
              <div className='w-full'>
                <p className='font-poppins font-semibold text-sm text-[#3F3F46]'>Full name</p>
                <input
                  type='text'
                  name="name"
                  value={formData?.name}
                  onChange={(e) => handleDataChange(e)}
                  placeholder='Full Name'
                  className='border-[1px] w-full md:w-full outline-none  mt-2 login-box-shadow h-[44px] text-textColor font-normal text-base font-poppins rounded-[4px] border-[#D4D4D8] py-[10px] px-[14px]'
                />
                <p className='mt-1 text-red-800 font-poppins text-xs font-normal'>{errors?.name || requirederrors?.name}</p>
              </div>
              {/* <div className='w-full mt-5 md:mt-0 md:w-[48%]'>
                <p className='font-poppins font-semibold text-sm text-[#3F3F46]'>Last name</p>
                <input
                  type='text'
                  placeholder='Last Name'
                  name="lastName"
                  value={formData?.lastName}
                  onChange={(e) => handleDataChange(e)}
                  className='border-[1px] outline-none w-full md:w-full mt-2 login-box-shadow h-[44px] text-textColor font-normal text-base font-poppins rounded-[4px] border-[#D4D4D8] py-[10px] px-[14px]'
                />
              </div> */}
            </div>
            <p className='font-poppins font-semibold mt-5 text-sm text-[#3F3F46]'>Email</p>
            <input
              type='text'
              name='email'
              value={formData?.email}
              onChange={(e) => handleDataChange(e)}
              placeholder='You@college.com'
              className='border-[1px] outline-none w-full md:w-[80%] mt-2 login-box-shadow h-[44px] text-textColor font-normal text-base font-poppins rounded-[4px] border-[#D4D4D8] py-[10px] px-[14px]'
            />
            <p className='mt-1 text-red-800 font-poppins text-xs font-normal'>{errors?.email}</p>
            <p className='font-poppins font-semibold text-sm mt-5 text-[#3F3F46]'>Phone number</p>
            <input
              type='text'
              name="phoneNumber"
              value={formData?.phoneNumber}
              onChange={(e) => handleDataChange(e)}
              placeholder='+91 000-000-0000'
              className='border-[1px] outline-none w-full md:w-[80%] mt-2 login-box-shadow h-[44px] text-textColor font-normal text-base font-poppins rounded-[4px] border-[#D4D4D8] py-[10px] px-[14px]'
            />
            <p className='mt-1 text-red-800 font-poppins text-xs font-normal'>{errors?.phoneNumber || requirederrors?.phoneNumber}</p>
            <p className='font-poppins font-semibold text-sm mt-5 text-[#3F3F46]'>You are a </p>
            {/* <div ref={dropdownRef} className='border-[1px] relative cursor-pointer outline-none w-full md:w-[80%] mt-2 login-box-shadow h-[44px] text-textColor font-normal text-base font-poppins rounded-[4px] border-[#D4D4D8] py-[10px] px-[14px]'>

              {
                isCollegeDropdownOpen ? <div onClick={(e) => e.stopPropagation()}>

                  <input type="text" className='outline-none font-poppins text-base font-normal text-textColor' placeholder='Search college' value={searchQuery}
                    onChange={(e) => {
                      e.stopPropagation();
                      setSearchQuery(e.target.value)
                    }} />
                </div> : <p onClick={toggleCollegeDropdown} className={`font-poppins font-normal text-base ${selectedCollege ? 'text-secondary' : 'text-textColor'}`}>{formData?.collegeId ? findCollegeName(formData?.collegeId) : 'Enter Your College'}</p>
              }
              <CollegeDropdownLogic contactUs={true} isCollegeDropdownOpen={isCollegeDropdownOpen} selectCollege={selectCollege} collegeList={collegeList} searchQuery={searchQuery} setSearchQuery={setSearchQuery} filteredColleges={filteredColleges} />
            </div> */}
            <div ref={dropdownRef} className='relative w-full md:w-[80%] mt-2 login-box-shadow'>
      <div
        
        className='border-[1px] cursor-pointer outline-none h-[44px] text-textColor font-normal text-base font-poppins rounded-[4px] border-[#D4D4D8] py-[10px] px-[14px]'
        onClick={toggleDropdown}
      >
        {formData?.userType ? formData?.userType : "Click to select an option"}
      </div>
      {isOpen && (
        <div className='absolute z-10 top-full left-0 w-full bg-white border border-gray-300 shadow-lg rounded-b-lg'>
          {options.map((option, index) => (
            <div
              key={index}
              className='py-2 px-4 cursor-pointer hover:bg-gray-100'
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
            <p className='mt-1 text-red-800 font-poppins text-xs font-normal'>{requirederrors?.userType}</p>
            <p className='font-poppins mt-5 font-semibold text-sm text-[#3F3F46]'>Subject</p>
            <input
              type='text'
              name="subject"
              value={formData?.subject}
              placeholder='Enter your query subject'
              onChange={(e) => handleDataChange(e)}
              className='border-[1px] outline-none w-full md:w-[80%] mt-2 login-box-shadow h-[44px] text-textColor text-base font-normal font-poppins rounded-[4px] border-[#D4D4D8] py-[10px] px-[14px]'
            />
            <p className='mt-1 text-red-800 font-poppins text-xs font-normal'>{requirederrors?.subject}</p>
            <p className='font-poppins mt-5 font-semibold text-sm text-[#3F3F46]'>Message</p>
            <textarea name="query" value={formData?.query} onChange={(e) => handleDataChange(e)} placeholder='Leave a message...' className='border-[1px] outline-none w-full md:w-[80%] mt-2 login-box-shadow h-[120px] text-textColor font-normal text-base font-poppins rounded-[4px] border-[#D4D4D8] py-[10px] px-[14px]'></textarea>
            <p className='mt-1 text-red-800 font-poppins text-xs font-normal'>{requirederrors?.query}</p>
            <div className='mt-5 space-x-2 flex items-center'>
              <label className="flex items-center">
                <input
                  id='termsAndConditionsAccepted'
                  type="checkbox"
                  name="termsAndConditionsAccepted"
                  value={formData?.termsAndConditionsAccepted}
                  className="hidden"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                <span
                  className={`relative inline-block w-5 h-5 border border-[#D4D4D8] rounded-md cursor-pointer ${isChecked ? 'background-primary' : ''
                    }`}
                >
                  {isChecked && (
                    <svg
                      className="w-4 h-4 text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  )}
                </span>
              </label>
              <p onClick={showModal} className='cursor-pointer underline underline-offset-4 font-poppins text-textColor font-normal text-sm'>You agree to our friendly privacy policy.</p>

            </div>
            <button disabled={!isChecked} onClick={handleSubmit} className={`${isChecked ? "" : 'disabled:opacity-50'} block w-full md:w-[80%] mt-6 h-[48px] background-primary login-box-shadow rounded-[8px] font-poppins text-white text-base font-medium text-center py-[12px] px-[20px]`}>
              Send message
            </button>
          </>
        }
      </div>
      <Modal title={<div className="font-bold text-xl md:text-2xl px-1">Privacy Policy</div>} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null} width={800} bodyStyle={{ height: '500px', overflowY: 'auto' }} >
        <PrivacyPolicy/>
      </Modal>

    </div>
  )
}

export default ContactUs