import React, {useEffect, useState} from 'react'
import ScreenWidthProvider from './utils/ScreenWidthProvider'
import AuthProvider from "./utils/authProvider"
import Routers from "./routes/Routers";
import { useNavigate } from 'react-router-dom';
import { BrowserRouter, Link } from 'react-router-dom';
import { fetchDashboardData } from './redux/slices/dashboardSlice';
import { fetchLocalityData } from './redux/slices/getLocalitiesSlice'
import { useDispatch } from 'react-redux';
import useInitializeStateData from './utils/useInitializeStateData';
import Modal from "react-awesome-modal";
import { fetchCategories } from './redux/slices/getCategoriesSlice';
import RoutePath from './routes/RoutePath';
import { Toaster } from 'react-hot-toast';


const App = () => {

   useInitializeStateData();
   const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchDashboardData());
    dispatch(fetchLocalityData());
    dispatch(fetchCategories());


  }, [dispatch]);


  return (
    <BrowserRouter>
     <Toaster />
    <ScreenWidthProvider>
     <AuthProvider>
       <Routers/>
       </AuthProvider> 
    </ScreenWidthProvider>
   
    </BrowserRouter>
  )
}

export default App