import React, { useEffect, useState } from 'react'
import Login from './Login'
import { showErrorToast, showSuccessToast } from '../../utils/toastNotif';
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { verify } from '../../utils/verifyUser';
import { setTokenId, setUserState } from '../../redux/slices/authSlice';
import RoutePath from '../../routes/RoutePath';
import validationRules from '../../utils/validationRules';
import { handleISValidUser, handleLoginWithOtp } from '../../utils/otpHandler';
import { validateField } from '../../utils/formValidationFunctions';
import { checkForErrors } from '../../utils/checkForErrors';
import { Button, Modal } from 'antd';
import FirebaseOtpModalLogic from '../../components/firebaseOtpModal/FirebaseOtpModalLogic';

const LoginLogic = ({isFromLoginBtn=true , setShowLoginModal}) => {
  const dispatch = useDispatch();
  const screenWidth = useSelector(state => state.screenWidth.screenWidth);
  const [loading, setLoading] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [timerotp, settimerotp] = useState(30);
  const navigate = useNavigate();

  const [phoneNumber, setPhoneNumber] = useState('');
  const requiredFields = ["name", "phoneNumber", "email"]
  // const [verificationType, setVerificationType] = useState("");
  // const [dummyState, setDummyState] = useState(false);
  const [otp, setOtp] = useState("");
  const [errormsg, setErrormsg] = useState("")
  const [otperrormsg, setOtpErrormsg] = useState("")
  const location = useLocation();
  const route = location?.state?.data;
  const [formData, setformData] = useState({ userName: "", phoneNumber: phoneNumber || "", email: "", gender: "" });
  const [signup, setSignup] = useState(false);
  const [errors, setErrors] = useState({});
  const [requirederrors, setrequirederrors] = useState({})


  // TRYING TO CREATE THE LOGIC MODAL
  const [isModalOpen, setIsModalOpen] = useState(!isFromLoginBtn);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  // END OF LOGIN MODAL


  //resend otp timer
  useEffect(() => {

    if (confirmationResult?.status === 'USER_NOT_EXISTS') {
      showSuccessToast("This phone number is not registered. Please sign up");
      setSignup(true);
    }

    const interval = setInterval(() => {
      if (timerotp > 0 && confirmationResult) {
        settimerotp(prevTimer => prevTimer - 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [confirmationResult]);

  // CHECKS IF USER EXISTS AND SETS RESULT IN CONFIRMATION RESULT
  const checkExistingUser = async () => {
    if (phoneNumber === "") {
      setErrormsg("please enter phone number");
      return;
    }
    
    // if (!validationRules?.phoneNumber?.regex?.test(phoneNumber)) {
    //   setErrormsg("kindly enter valid phone number")
    //   return;
    // }
    setErrormsg("");
    handleLoginWithOtp(phoneNumber, setLoading, setOpenModal, setConfirmationResult, "login-btn", settimerotp)
  };

  // RUNS AFTER checkExistingUser TO VERIFY OTP AND LOGIN USER 
  const verifyAndLogin = async (OTP) => {
    setOtpErrormsg("");
    if (OTP === "") {
      setOtpErrormsg("Kindly enter OTP");
      return;
    }
    try {
      setVerifying(true);
      const requestBody = {
        otp: OTP, // Replace with actual OTP
        mobile: phoneNumber, // Replace with actual mobile number
        userType: 'user' // Replace with actual user type
      };

      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });

      if (res !== null) { // Check if res is not null
        if (res.ok) {
          const data = await res.json();
          if (data) {
            dispatch(setTokenId(data?.token));
            localStorage.setItem("token", data?.token);
            localStorage.setItem("user_id", data?.foundUser?._id);
            data?.payload?.email ? localStorage.setItem("email", data?.payload?.email) : localStorage.setItem("email", "")
            localStorage.setItem('type', data?.payload?.type);
            data?.payload?.phone && localStorage.setItem('phoneNumber', data?.payload?.phone)
            localStorage.setItem("userData", JSON.stringify(data?.foundUser));
            window.dispatchEvent(new Event('storage'))
            dispatch(setUserState(data));
            setOpenModal(false);
            setVerifying(false);
            handleCancel();
            console.log("location" , location);
            console.log("route",route);
            setTimeout(() => {
              if (location?.pathname !== RoutePath.login) {

                navigate(location?.pathname + location?.search);;
              } else if (route) {
                navigate(route);
              } else {
                navigate(RoutePath.homepage);
              }

            }, 2);
          } else {
            showErrorToast("Something went wrong, please try again later");
            setVerifying(false);
          }
        } else {
          const message = await res.text();
          const parsedMessage = JSON.parse(message);
          setOpenModal(false);
          console.log(res);
          showErrorToast(parsedMessage || "Something went wrong");
          setVerifying(false);

        }
      } else {
        showErrorToast("There was a problem with the request. Please try again later.");
        setVerifying(false);
      }
    } catch (error) {
      console.error('Error:', error);
      // Handle error here
      setVerifying(false);
    }
  };

  // HANDLES FORM DATA CHANGES IN SIGNUP FORM
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (value.trim() === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: '',
      }));
    } else if (validationRules[name]) {
      const isValid = validateField(name, value, formData);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: isValid ? '' : validationRules[name].message,

      }));
    }
    setformData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // RUNS AFTER isValidUser TO VERIFY OTP AND SIGNUP USER
  const handleSignUp = async (OTP) => {

    setOtpErrormsg("");
    if (OTP === "") {
      setOtpErrormsg("Kindly enter OTP");
      return;
    }

    const user = {
      email: formData.email,
      gender: formData.gender,
      phoneNumber: phoneNumber,
      name: formData.userName
    }

    const requestBody = {
      otp: OTP,
      user: user,
      signUpMethod: 'phone'
    };

    try {
      setVerifying(true);
      console.log(requestBody);
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/signUp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });

      if (res !== null) {
        if (res.ok) {
          const data = await res.json();
          if (data) {
            dispatch(setTokenId(data?.token));
            localStorage.setItem("token", data?.token);
            localStorage.setItem("user_id", data?.user?.id);
            data?.user?.phone && localStorage.setItem("phoneNumber", data?.user?.phone)
            data?.user?.email && localStorage.setItem( "email" , data?.user?.email )
            await verify(navigate, dispatch);
            setOpenModal(false);
            handleCancel();
            setVerifying(false);
            setTimeout(() => {
              if (location?.pathname !== RoutePath.login) {

                navigate(location?.pathname + location?.search);;
              } else if (route) {
                navigate(route);
              } else {
                navigate(RoutePath.homepage);
              }
            }, 2);
          } else {
            showErrorToast("Something went wrong, please try again later");
          }
        }
        else {
          // console.error(res);
          const message = await res.text();
          const parsedMessage = JSON.parse(message);
          if (parsedMessage?.message === "Phone Number already Exists" || parsedMessage?.message === "Email Already linked with another account") {
            setOpenModal(false);
            showErrorToast("User already exists, redirecting to login page.");
            navigate(RoutePath.login, { state: { data: route } })
          }
          else {
            showErrorToast("Something went wrong, please try again later");
          }
        }

      } else {
        showErrorToast("There was a problem with the request. Please try again later.");
      }
      setVerifying(false);
    } catch (error) {
      console.error('Signup failed:', error.message);
      // Handle signup error (e.g., display error message to user)
      setVerifying(false);
    }
  };

  // CHECKS isValidUser BEFORE SIGNUP
  const checkIsValidUser = async () => {
    if (phoneNumber === "") {
      setErrormsg("please enter phone number");
      return;
    }

    handleISValidUser(formData, phoneNumber, setLoading, setOpenModal, setConfirmationResult, "login-btn", settimerotp)
  };

  const checkLoginOrSignup = (otp) => {
    if (signup) {
      handleSignUp(otp);
    }
    else {
      verifyAndLogin(otp);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !signup) {
      checkExistingUser();
    }
  };

  const handleRefresh = () =>{
    setShowLoginModal(false);
  }

  return (
    <>{isFromLoginBtn ? <Login
      handleKeyPress={handleKeyPress}
      errors={errors}
      checkIsValidUser={checkIsValidUser}
      requirederrors={requirederrors}
      handleSignUp={handleSignUp}
      formData={formData}
      handleChange={handleChange}
      checkLoginOrSignup={checkLoginOrSignup}
      signup={signup}
      checkExistingUser={checkExistingUser}
      screenWidth={screenWidth}
      navigate={navigate}
      phoneNumber={phoneNumber}
      setPhoneNumber={setPhoneNumber}
      // verificationType={verificationType}
      // handleSendOTP={handleSendOTP}
      loading={loading}
      openModal={openModal}
      setOpenModal={setOpenModal}
      // handleVerifyOTP={handleVerifyOTP}
      setOtp={setOtp}
      otp={otp}
      errormsg={errormsg}
      otperrormsg={otperrormsg}
      setOtpErrormsg={setOtpErrormsg}
      verifying={verifying}
      setVerifying={setVerifying}
      timerotp={timerotp}
      confirmationResult={confirmationResult}
      setLoading={setLoading}
      setConfirmationResult={setConfirmationResult}
      settimerotp={settimerotp}
      handleSendFirebaseOtp={handleLoginWithOtp}
    /> : <>
      <Modal className='z-40 md:flex md:justify-center md:align-middle' open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null} closable={true} afterClose={handleRefresh}>
      <div className={screenWidth < 768 ? 'w-[90%] mx-auto mt-[30px]' : 'w-[90%] md:w-auto'}>
            <h5 className='font-poppins font-semibold md:text-[32px] text-[24px] md:leading-10 leading-8 text-secondary tracking-[-0.2px]'>
              Welcome back
            </h5>
            <p className='font-poppins mt-4 mb-7 font-[400] text-base text-textColor'>
              Please enter your details
            </p>
            <p className='font-poppins font-medium text-sm text-[#3F3F46]'>Phone number*</p>
            <input
              required
              type="tel"
              maxLength={10}
              disabled={loading}
              placeholder='+91 000-000-0000'
              value={phoneNumber}
              name='phoneNumber'
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
              onKeyPress={handleKeyPress}
              className='border-[1px] outline-none w-full md:w-[360px] mt-2 login-box-shadow h-[44px] rounded-[4px] border-[#D4D4D8] py-[10px] px-[14px]'
            />
            <p className='mt-1 text-red-800 font-poppins text-xs font-normal'>{errormsg && errormsg}</p>
            {signup && (
              <>
                <p className='font-poppins font-medium text-sm text-[#3F3F46]'>Username*</p>
                <input
                  required
                  type="text"
                  maxLength={10}
                  disabled={loading}
                  placeholder='Full Name'
                  value={formData?.['userName']}
                  name='userName'
                  onChange={(e) => handleChange(e)}
                  className='border-[1px] outline-none w-full md:w-[360px] mt-2 login-box-shadow h-[44px] rounded-[4px] border-[#D4D4D8] py-[10px] px-[14px]'
                />
                <p className='mt-1 text-red-800 font-poppins text-xs font-normal'>{errors?.['userName'] || requirederrors?.['userName']}</p>
                <p className='font-poppins font-medium text-sm text-[#3F3F46]'>Email*</p>
                <input
                  required
                  type="text"
                  maxLength={30}
                  disabled={loading}
                  placeholder='example@gmail.com'
                  value={formData?.['email']}
                  name='email'
                  onChange={(e) => handleChange(e)}
                  className='border-[1px] outline-none w-full md:w-[360px] mt-2 login-box-shadow h-[44px] rounded-[4px] border-[#D4D4D8] py-[10px] px-[14px]'
                />
                <p className='mt-1 text-red-800 font-poppins text-xs font-normal'>{errors?.['email'] || requirederrors?.['email']}</p>
                <p className='font-poppins font-medium text-sm text-[#3F3F46]'>Gender</p>
                <input type="radio" id="male" name="gender" value="male" checked={formData?.['gender'] === 'male'} onChange={(e) => handleChange(e)} className="mr-2" />
                <label htmlFor="male" className='font-poppins font-medium text-sm text-[#3F3F46]'>Male</label>
                <input type="radio" id="female" name="gender" value="female" checked={formData?.['gender'] === 'female'} onChange={(e) => handleChange(e)} className="mr-2" />
                <label htmlFor="female" className='font-poppins font-medium text-sm text-[#3F3F46]'>Female</label>

                <button disabled={loading || openModal}
                  id="login-btn" onClick={checkIsValidUser} className='block w-full md:w-[360px] mt-6 h-[48px] background-primary login-box-shadow rounded-[4px] font-poppins text-white text-base font-medium text-center py-[12px] px-[20px]'>
                  SignUp
                </button>
              </>
            )}

            {!signup && (
              <button disabled={loading || openModal}
                id="login-btn" onClick={checkExistingUser} className='block w-full md:w-[360px] mt-6 h-[48px] background-primary login-box-shadow rounded-[4px] font-poppins text-white text-base font-medium text-center py-[12px] px-[20px]'>
                Login with OTP
              </button>
            )}
            {/* <div className='flex items-center justify-center mt-7'>
          <p className='font-poppins font-normal text-sm text-textColor mr-1'>Don't have an account?</p>
          <button disabled={loading || openModal} onClick={() => navigate(RoutePath.signup)} className='text-primary font-medium text-sm font-poppins'>Sign up</button>
        </div> */}
          </div>
          <FirebaseOtpModalLogic checkLoginOrSignup={checkLoginOrSignup} openModal={openModal} setOpenModal={setOpenModal} phoneNumber={phoneNumber} setOtp={setOtp} otp={otp} verifying={verifying} otperrormsg={otperrormsg} setOtpErrormsg={setOtpErrormsg} timerotp={timerotp}
          confirmationResult={confirmationResult}
          setLoading={setLoading}
         
          setConfirmationResult={setConfirmationResult}
          
          handleLoginWithOtp={handleLoginWithOtp}
          settimerotp={settimerotp} />
      </Modal>
      
    </> 
    }
    </>
  )
}

export default LoginLogic