import React from 'react'
import download from "../../assets/images/downloadIcon.svg"
import payment from "../../assets/images/paymentIcon.svg"
import { formatDate } from "../../utils/formatDate"
import NoticePeriodModalLogic from '../noticePeriodModal/NoticePeriodModalLogic';
import { showErrorToast } from '../../utils/toastNotif';

const OccupiedServices = ({setActiveOption, filteredData, serviceType, setServiceType, openModal, setOpenModal}) => {
  console.log("filteredData", filteredData);

  const downloadAgreement = (url) => {
    if(url === ''){
      showErrorToast('Rent Agreement does not Exist for this service');
    }else{
      const link = document.createElement("a");
    link.href = url;
    link.download = "agreement.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    }
};

  return (
    <div>
      {
         filteredData?.length === 0 ? <div className='w-full min-h-screen flex flex-col items-center justify-center'>
         <h1 className='text-secondary font-poppins text-2xl font-medium'>No data available</h1>
         <p className='text-textColor text-base mt-3 font-poppins font-normal'>There are no occupied services</p>
       </div>
       :
       <div className='flex mt-[30px] flex-col'>
          <select value={serviceType} onChange={(e)=>setServiceType(e.target.value)} className='border  rounded-lg cursor-pointer mt-1 border-[#D4D4D8] blog-button-shadow text-secondary outline-none font-poppins text-base font-normal w-full md:w-[296px] py-[10px] px-[14px]'>
          <option value="furniture">Furniture</option>
          <option value="Home Appliance">Home Appliances</option>
        </select>
        <div className='w-full mt-[20px]'>
        <div className='flex items-start justify-between'>
      <div className='flex flex-col items-start'>
        <p className='font-poppins text-lg font-medium text-secondary'>Furniture</p>
      </div>
      <div className='flex flex-wrap items-center mx-2 md:gap-2 md:space-x-4'>
        <div onClick={()=>setActiveOption('Payment')} className='flex items-center space-x-2 text-base font-medium font-poppins cursor-pointer text-primary'><img src={payment} alt="" /><p>Payments</p></div>
      </div>
    </div>
    <p className='font-poppins text-textColor font-normal text-sm'>Monkhood Living Furniture Service: Elevate your space with mindful design and artisanal craftsmanship.</p>
    <div className='mt-[30px] '>
    {
      filteredData.map((ele, ind)=>(
       <div className='w-full flex mb-6 flex-col md:flex-row md:space-x-8 items-start'>
         <div className='md:w-4/12 w-full'>
        <img src={ele?.images[0]} alt="" className='rounded-xl object-cover w-full h-full md:min-h-[316px]  ' />
      </div>
      <div className=' md:w-8/12 w-full mt-5 md:mt-0'>
      <div className='flex  items-start  justify-between'>
          <div>
          <p className='font-poppins font-semibold text-[#0F172A] text-base md:text-lg'>{ele?.serviceName}</p>
          <p className='font-poppins text-xs md:text-sm font-normal text-textColor mt-1'>{ele?.category?.value}</p>
          </div>
          <div onClick={()=>downloadAgreement(ele?.rentAgreement?.pdfLink ? ele?.rentAgreement?.pdfLink : '')} className='md:flex items-center space-x-2 text-base font-medium font-poppins hidden cursor-pointer text-primary'><img src={download} alt="" /><p>Download agreement</p></div>
          <div className='md:hidden block'>
            <p className='text-textColor text-right font-poppins font-normal text-xs'>Rent</p>
            <p className='font-poppins text-sm text-secondary flex font-semibold'>₹{ele?.rent} <span className='text-textColor font-poppins font-normal text-sm'>/month</span></p>
          </div>
            {/* <div className='md:flex hidden items-center space-x-2'>
              <IoIosStar className='text-[#475569]' />
              <p className='text-[#222222] font-normal text-[15px] leading-[20px]'>{ele?.rating}</p>
            </div> */}
        </div>
        {/* <div className='px-3 py-2 font-poppins inline-flex bg-[#FFF4EB]  rounded-[24px] font-normal text-xs text-[#0F172A]  items-center space-x-2 mt-5'>
          <img src={bed} alt="" />
          <p>{ele?.serviceId?.productDetails?.size}</p>
        </div>
        <div className='px-3 py-2 font-poppins inline-flex bg-[#FFF4EB]  rounded-[24px] font-normal text-xs text-[#0F172A]  items-center space-x-2 mt-5'>
          <img src={bed} alt="" />
          <p>{ele?.serviceId?.productDetails?.material}</p>
        </div> */}
        {Object.values(ele.serviceId.productDetails).map((val, ind) => 
  val && (
    <div
      key={ind}
      className="px-3 py-2 mr-2 font-poppins inline-flex bg-[#FFF4EB] rounded-[24px] font-normal text-xs text-[#0F172A] items-center space-x-2 mt-5"
    >
      <p>{val}</p>
    </div>
  )
)}

        <div className='flex flex-col md:items-center md:flex-row p-3 md:p-0 rounded-[8px] md:rounded-none border border-[#E4E4E7] space-y-4 md:space-y-0 md:border-none items-start md:space-x-8 mt-7'>
         <div className='flex flex-col'>
          <h1 className='text-secondary font-medium text-sm font-poppins'>Service start from</h1>
          <p className='mt-2 font-poppins font-normal text-textColor text-sm'>{ele?.deliveredOn ? formatDate(ele?.deliveredOn) : 'Not yet Delivered'}</p>
         </div>
         <div className='flex md:px-8 py-3 w-full md:w-auto md:border-y-0 border-y md:border-x border-[#E4E4E7] flex-col'>
          <h1 className='text-secondary font-medium text-sm font-poppins'>Service tenure</h1>
          <p className='mt-2 font-poppins font-normal text-textColor text-sm'>{(ele?.rentAgreement?.startDate && ele?.rentAgreement?.startDate) ? formatDate(ele?.rentAgreement?.startDate) - formatDate(ele?.RentAgreement?.endDate) : 'Not Available'}</p>
         </div>
         <div className='flex flex-col'>
          <h1 className='text-secondary font-medium text-sm font-poppins'>Service book date</h1>
          <p className='mt-2 font-poppins font-normal text-textColor text-sm'>{ele?.createdOn ? formatDate(ele?.createdOn) : 'Not Available'}</p>
         </div>
        </div>
        <div className='flex flex-wrap gap-3 items-center mt-6 justify-between w-full'>
          <div className='hidden md:block'>
            <p className='text-textColor font-poppins font-normal text-xs'>Rent</p>
            <p className='font-poppins text-lg text-secondary font-semibold'>₹{ele?.rent} <span className='text-textColor font-poppins font-normal text-sm'>/month</span></p>
          </div>
          <div className='flex items-center w-full md:w-auto space-x-3'>
            <button onClick={()=>setOpenModal(true)} className='font-poppins w-1/2 md:w-auto text-white text-sm font-medium blog-button-shadow background-primary rounded-[4px] py-[10px] px-4'>Start notice period</button>
            <button className='font-poppins w-1/2 md:w-auto text-sm font-medium text-primary rounded-[4px] py-[10px] px-4 border hover-primary border-primary blog-button-shadow'>Pay rent</button>
          </div>
        </div>
      </div>
       </div>
      ))
    }
    </div>
        </div>
       </div>
      }
       <NoticePeriodModalLogic openModal={openModal} setOpenModal={setOpenModal} type={"furniture"} />
    </div>
  )
}

export default OccupiedServices