import React, { useRef, useEffect } from "react"
import RoutePath from "../../routes/RoutePath"
import { useLocation } from "react-router-dom"
import { useSelector } from "react-redux"

const CollegeDropdown = ({isCollegeDropdownOpen, contactUs, togglingCollegeDesktopHeaderRef, togglingCollegeMobileHeaderRef, togglingCollegeMobileNavbarRef, togglingCollegeDesktopNavbarRef, setisCollegeDropdownOpen, collegeList, selectCollege, searchQuery, setSearchQuery, filteredColleges, topSearchColleges, screenWidth})=>{
    const location = useLocation();
    const collegeDropdownRef = useRef(null)
   
    useEffect(() => {
        const handleClickOutside = (event) => {
           if(screenWidth < 768){
           if(location?.pathname === RoutePath.homepage){
            if (collegeDropdownRef?.current && !collegeDropdownRef?.current.contains(event.target) && togglingCollegeMobileHeaderRef?.current && !togglingCollegeMobileHeaderRef?.current.contains(event.target) ) {
                setisCollegeDropdownOpen(false)
              }
           }else{
            if (collegeDropdownRef?.current && !collegeDropdownRef?.current.contains(event.target) && togglingCollegeMobileNavbarRef?.current && !togglingCollegeMobileNavbarRef?.current.contains(event.target) ) {
                setisCollegeDropdownOpen(false)
              }
           }
           }else{
             if(location?.pathname === RoutePath.homepage){
                if (collegeDropdownRef?.current && !collegeDropdownRef?.current.contains(event.target) && togglingCollegeDesktopHeaderRef?.current && !togglingCollegeDesktopHeaderRef?.current.contains(event.target) ) {
                    setisCollegeDropdownOpen(false)
                 }

             }else{
                if (collegeDropdownRef?.current && !collegeDropdownRef?.current.contains(event.target) && togglingCollegeDesktopNavbarRef?.current && !togglingCollegeDesktopNavbarRef?.current.contains(event.target) ) {
                    setisCollegeDropdownOpen(false)
                 }
             }
           }
          
        };

        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [screenWidth, location]);
    return(
        <div ref={collegeDropdownRef}  onClick={(e)=>e.stopPropagation()} className={`${isCollegeDropdownOpen ? 'flex flex-col items-start' : 'hidden'} h-[389px] overflow-y-scroll border border-[#D4D4D8] scrollbar absolute z-30  bg-white rounded-lg ${(location?.pathname === RoutePath.homepage && !contactUs) ? ' md:top-[68px] sm:w-[384px] top-[50px]' : (contactUs ? 'top-[48px]' : 'top-[60px] sm:w-[384px]')}   header-box-shadow  left-1/2 transform  -translate-x-1/2  w-full  overflow-x-hidden`}>
                      
                        <div className='border-b border-[#D4D4D8] mt-3 w-full px-2'>
                            <p className='font-poppins  px-[10px] font-medium text-[#52525B] text-sm'>Top Search</p>
                            {
                            topSearchColleges && topSearchColleges.map((ele, ind)=>(
                                <div key={ind} className=''>
                                    <p onClick={()=>selectCollege(ele)} className='font-poppins hover:text-orange-400 py-[9px] px-[10px] cursor-pointer font-medium text-base text-secondary'>{ele.collegeName}</p>
                                </div>
                            ))
                        }
                        </div>
                        <div className='px-2 mt-3 w-full'>
                            <p className='font-poppins px-[10px] font-medium text-[#52525B] text-sm'>All colleges</p>
                            {
                            (searchQuery ? filteredColleges : collegeList)?.map((ele, ind)=>(
                                <div key={ind} className=''>
                                    <p onClick={()=>selectCollege(ele)} className='font-poppins hover:text-orange-400 py-[9px] px-[10px] cursor-pointer font-medium text-base text-secondary'>{ele?.collegeName}</p>
                                </div>
                            ))
                        }
                        </div>
                    </div>
    )
}

export default CollegeDropdown