// import { verify } from "./verifyUser"
import { showErrorToast } from './toastNotif';
// import RoutePath from "../routes/RoutePath";

export const handleAddToCart = async ( token,setShowLoginModal,navigate, dispatch, cartButtonClicked, setCartButtonClicked, userState, itemId, setQuantity, quantity, setCartLoading, location, setProgress)=>{
    setCartLoading(true);
    setProgress(30);

    if (!cartButtonClicked) {
        if (!token) {
            setShowLoginModal(true);
            showErrorToast("Kindly Login to continue");
            
        } 
            
        else {
            setQuantity(1);
            setCartButtonClicked(true);
            
        }
    } else {
        setQuantity(quantity + 1);
        
    }

    setCartLoading(false);
    setProgress(100);
}