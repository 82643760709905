import React, { useState } from 'react'
import facebookLogo from "../../assets/images/facebookLogo.svg";
import instaLogo from "../../assets/images/instaLogo.svg";
import twitterLogo from "../../assets/images/twitterLogo.svg";
import linkedinLogo from "../../assets/images/linkedinLogo.svg";
import logo from "../../assets/images/logo.svg";
import RoutePath from '../../routes/RoutePath';
import { Modal } from 'antd';
import PrivacyPolicy from '../privacyPolicy/PrivacyPolicy';
import Terms from '../termsAndConditions/Terms';

const Footer = ({screenWidth, navigate}) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPrivacyPolicy, setIsPrivacyPolicy] = useState(false);

  const openPdf = (pdfName) => {

    switch (pdfName) {
      case 'Terms & Conditions':
        setIsPrivacyPolicy(false);
        setIsModalOpen(true);
        break;
      case 'Privacy Policy':
        setIsPrivacyPolicy(true);
        setIsModalOpen(true);
        break;
      default:
        break;
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
   {
    screenWidth < 768 ? <> <div className={`w-full flex flex-col px-5 footer py-10 items-center justify-center`}>         
    <div className='w-[390px] px-5 flex flex-col items-center justify-center space-y-5'>
    <img onClick={()=>navigate(RoutePath.homepage)} src={logo} alt="logo" className={`cursor-pointer w-[100px] h-[40px]`}/>
      <p className='text-[#F8F7F7] font-normal text-sm text-center font-poppins'>Bachelor Housing, Beyond the Bar</p>
    </div>
    <div className='flex mt-5 items-center space-x-3'>
        <p onClick={() => openPdf('Privacy Policy')} className='font-poppins font-medium text-sm text-center text-white cursor-pointer  hover:scale-105 opacity-[0.81]'>Privacy Policy</p>
        <div className='w-[1px] h-[20px] bg-[#71717A]'></div>
        <p onClick={() => openPdf('Terms & Conditions')} className='font-poppins font-medium text-sm text-center text-white cursor-pointer  hover:scale-105 opacity-[0.81]'>Terms & Conditions</p>
        <div className='w-[1px] h-[20px] bg-[#71717A]'></div>
        <p className='font-poppins font-medium text-sm text-center text-white cursor-pointer  hover:scale-105 opacity-[0.81]'><a href="https://forms.gle/WJj3H9jkFbAczKRQA">Careers</a></p>
        </div>   
</div>
<div className='w-full background-secondary md:flex flex flex-col justify-center p-8  items-center text-center '>
<div className="w-full flex items-center mb-5 justify-center gap-[25px]">
          <a rel="noopener noreferrer" target='_blank' href='https://www.facebook.com/thegharbar'><img src={facebookLogo} alt="facebook" className="cursor-pointer hover:scale-105"/></a>
          <a rel="noopener noreferrer" target='_blank' href='https://www.instagram.com/thegharbar'><img src={instaLogo} alt="insta" className="cursor-pointer  hover:scale-105"/></a>
          <a rel="noopener noreferrer" target='_blank' href='https://www.linkedin.com/company/gharbar/'><img src={linkedinLogo} alt="linkedin" className="cursor-pointer  hover:scale-105"/></a>
          <a rel="noopener noreferrer" target='_blank' href='https://www.x.com/gharbarofficial'><img src={twitterLogo} alt="twitter" className="cursor-pointer  hover:scale-105"/></a>
      </div>
<p className='footer-heading font-poppins text-base font-medium'>Copyright ©️2023 Monkhood Technologies Private Limited</p>

</div></> :
<>
<div className={`w-full md:flex md:flex-row flex flex-col items-start footer pt-10 pb-12 px-[80px]`}>
              <div className="md:w-[33%] w-[80%] flex flex-col items-start">
                 <img onClick={()=>navigate(RoutePath.homepage)} src={logo} alt="logo" className={`cursor-pointer w-[80px] h-[40px]`}/>
                 <p className={`mt-[32px] mb-[20px] w-full font-poppins footer-label not-italic font-normal leading-[normal] text-base`}>Bachelor Housing, Beyond the Bar</p>
                 <p className='footer-label md:block hidden mt-[18px] mb-3 font-poppins font-normal leading-[normal] text-sm'>Social Media</p>
                  <div className="w-full md:flex hidden items-center gap-[25px]">
                      <a rel="noopener noreferrer" target='_blank' href='https://www.facebook.com/thegharbar'><img src={facebookLogo} alt="facebook" className="cursor-pointer"/></a>
                      <a rel="noopener noreferrer" target='_blank' href='https://www.instagram.com/thegharbar'><img src={instaLogo} alt="insta" className="cursor-pointer"/></a>
                      <a rel="noopener noreferrer" target='_blank' href='https://www.linkedin.com/company/gharbar/'><img src={linkedinLogo} alt="linkedin" className="cursor-pointer"/></a>
                      <a rel="noopener noreferrer" target='_blank' href='https://x.com/gharbarofficial'><img src={twitterLogo} alt="twitter" className="cursor-pointer"/></a>
                  </div>
                 
              </div>
              <div className="md:flex md:flex-row flex flex-col items-start w-[60%] md:justify-around justify-start md:ml-5 md:flex-wrap">
                  <div className="flex flex-col gap-1">
                      <p className="font-poppins footer-heading md:text-base text-sm uppercase mt-[20px] md:mt-0 not-italic font-medium leading-[normal] mb-1">Company</p>
                     <p className={`font-poppins md:mt-1 mt-2 footer-subheading not-italic  cursor-pointer hover:scale-105 font-medium text-sm leading-[normal] ${screenWidth > 900 ? 'text-[15px]' : 'text-sm'}`}>About Us</p>
                     <p className={`font-poppins md:mt-1 mt-2 footer-subheading not-italic cursor-pointer hover:scale-105 font-medium text-sm leading-[normal] ${screenWidth > 900 ? 'text-[15px]' : 'text-sm'}`}><a href="https://forms.gle/WJj3H9jkFbAczKRQA">Careers</a></p>
                      <p className={`font-poppins md:mt-1 mt-2 footer-subheading not-italic cursor-pointer hover:scale-105 font-medium text-sm leading-[normal] ${screenWidth > 900 ? 'text-[15px]' : 'text-sm'}`}>Blogs</p>
                      <p onClick={() => openPdf('Terms & Conditions')} className={`font-poppins md:mt-1 mt-2 footer-subheading not-italic cursor-pointer hover:scale-105 font-medium text-sm leading-[normal] ${screenWidth > 900 ? 'text-[15px]' : 'text-sm'}`}>Terms & Conditions</p>
                     <p onClick={() => openPdf('Privacy Policy')} className={`font-poppins md:mt-1 mt-2 footer-subheading not-italic cursor-pointer hover:scale-105 font-medium text-sm leading-[normal] ${screenWidth > 900 ? 'text-[15px]' : 'text-sm'}`}>Privacy Policy</p>
                     <p className={`font-poppins md:mt-1 mt-2 footer-subheading not-italic cursor-pointer hover:scale-105 font-medium text-sm leading-[normal] ${screenWidth > 900 ? 'text-[15px]' : 'text-sm'}`}>Shipping Policy</p>
                      <p className={`font-poppins md:mt-1 mt-2 footer-subheading not-italic cursor-pointer hover:scale-105 font-medium text-sm leading-[normal] ${screenWidth > 900 ? 'text-[15px]' : 'text-sm'}`}>Refund Policy</p>
                  </div>
                  <div className="flex flex-col gap-1">
                      <p className="font-poppins footer-heading md:text-base text-sm mt-[20px] md:mt-0 uppercase not-italic font-medium leading-[normal] mb-1">Quick Links</p>
                      <p  className={`cursor-pointer hover:scale-105 md:mt-1 mt-2  font-poppins footer-subheading not-italic font-medium leading-[normal] ${screenWidth > 900 ? 'text-[15px]' : 'text-sm'}`}>My Account</p>
                     <p className={`font-poppins md:mt-1 mt-2 footer-subheading cursor-pointer hover:scale-105 not-italic font-medium leading-[normal] ${screenWidth > 900 ? 'text-[15px]' : 'text-sm'}`}>Pay Rent</p>
                    
                  </div>
                  <div className={`flex flex-col gap-1 ${screenWidth < 765 ? 'mt-5 mb-5' : ''}`}>
                      <p className="font-poppins footer-heading md:text-base text-sm uppercase not-italic mt-[20px] md:mt-0 font-medium leading-[normal] mb-1">Home Services</p>
                     <p className={`font-poppins footer-subheading not-italic cursor-pointer hover:scale-105 font-medium md:mt-1 mt-2 leading-[normal] ${screenWidth > 900 ? 'text-[15px]' : 'text-sm'}`}>House Help</p>
                     <p className={`font-poppins footer-subheading not-italic cursor-pointer hover:scale-105 font-medium md:mt-1 mt-2 leading-[normal] ${screenWidth > 900 ? 'text-[15px]' : 'text-sm'}`}>Rental Agreement</p>
                     <p className={`font-poppins footer-subheading not-italic cursor-pointer hover:scale-105 font-medium md:mt-1 mt-2 leading-[normal] ${screenWidth > 900 ? 'text-[15px]' : 'text-sm'}`}>Laundry Service</p>
                      <p className={`font-poppins footer-subheading not-italic cursor-pointer hover:scale-105 font-medium md:mt-1 mt-2 leading-[normal] ${screenWidth > 900 ? 'text-[15px]' : 'text-sm'}`}>Appliances Rental</p>
                     <p className={`font-poppins footer-subheading not-italic cursor-pointer hover:scale-105 font-medium md:mt-1 mt-2 leading-[normal] ${screenWidth > 900 ? 'text-[15px]' : 'text-sm'}`}>Furniture Rental</p>
                     <p className={`font-poppins footer-subheading not-italic cursor-pointer hover:scale-105 font-medium md:mt-1 mt-2 leading-[normal] ${screenWidth > 900 ? 'text-[15px]' : 'text-sm'}`}>Kitchen Setup</p>
                  </div>
              </div>
          </div>
          <div className='w-full background-secondary md:flex flex flex-col justify-center p-8 md:p-0 items-center text-center md:h-[70px]'>
         
            <p className='footer-heading font-poppins text-base font-medium'>Copyright ©️2023 Monkhood Technologies Private Limited</p>

          </div>
</>
       }
       <Modal title={<div className="font-bold text-xl md:text-2xl px-1">{isPrivacyPolicy ? "Privacy Policy" : "Terms & Conditions"}</div>} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null} width={800} bodyStyle={{ height: '500px', overflowY: 'auto' }} >
        {isPrivacyPolicy ? <PrivacyPolicy/> : <Terms/>}
        
      </Modal>
    </>
    
  )
}

export default Footer