import React from 'react'
import RoutePath from '../../routes/RoutePath';
import { useLocation } from 'react-router-dom';

const Stepper = ({totalSteps,
    stepLabels,
    activeStep,
    handleStepChange
    
}) => {
  const location = useLocation();
    return (
        <div className={`flex flex-col items-start`}>
        {[...Array(totalSteps)].map((_, index) => {
          const stepNumber = index + 1;
          const isActive = stepNumber === activeStep;
          const isCompleted = stepNumber < activeStep;
          const isLastStep = index === totalSteps - 1;
          const handleClick = () => {
              if(isCompleted){
                handleStepChange(stepNumber)
              }
          };
      
          return (
            <div onClick={handleClick} key={index} className={`${isCompleted ? 'cursor-pointer' : 'cursor-not-allowed'} relative   mb-8 flex items-center`}>
              <div
                className={`w-8 h-8 rounded-full border-2 ${
                  isActive ? 'border-primary stepper-circle' : 'border-[#e4e4e7]'
                } ${isCompleted && 'background-primary border-none'} relative`}
              >
                {isCompleted ? (
                  <svg
                    className="w-6 h-6 text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                ) : (
                  <div
                    className={`w-2 h-2 rounded-full ${
                      isActive ? 'background-primary' : ''
                    } absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`}
                  />
                )}
              </div>
      
              {!isLastStep && (
                <div
                  className={`w-[2px] h-8 ${
                     isCompleted ? 'background-primary' : 'bg-[#E4E4E7]'
                  } absolute top-1/2 transform translate-y-1/2 left-4`}
                ></div>
              )}
      
              <div className="ml-4 font-poppins font-medium text-base text-secondary">
                {stepLabels[index]}
              </div>
            </div>
          );
        })}
      </div>
      
      );
      
      
      
      
      
      
      
      
}

export default Stepper