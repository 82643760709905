import React, { useState } from 'react'
import Modal from "react-awesome-modal"
import { useLocation, useNavigate } from 'react-router-dom';
import noticePeriod from "../../assets/images/noticePeriod.png"
import SpinnerLogic from "../../components/spinner/SpinnerLogic"
import { RxCross2 } from "react-icons/rx";
import { handleStartNoticePeriod } from '../../utils/handleStartNoticePeriod';

const ConfirmModal = ({ setConfirmModal, occupiedPropertyData})=>{
    const [reason, setReason] = useState("");
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
 return(
    <div className='w-full min-h-screen z-50 fixed top-0 left-0 bg-white'>
       {
        loading ? <SpinnerLogic/> : 
        <>
         <div className='w-full flex items-center justify-between py-4 px-5'>
            <p className='text-secondary text-xl font-poppins font-medium'>Add your reason</p>
            <RxCross2 onClick={()=>setConfirmModal(false)} className='text-2xl cursor-pointer text-[#52525B]'/>
        </div>
       <div className='fixed bottom-5 px-5 w-full'>
       <div className='flex items-center justify-between w-full'>
       <input value={reason} onChange={(e)=>setReason(e.target.value)} type="text" placeholder='Write message' className=' font-poppins text-base font-normal text-textColor border border-[#D4D4D8] blog-button-shadow w-[80%] rounded-[52px] py-[10px] px-[21px]' />
       <div onClick={()=>handleStartNoticePeriod(occupiedPropertyData?._id, occupiedPropertyData?.listingId, reason, setConfirmModal, setLoading, navigate, location)} className='background-primary  text-white rounded-xl py-2 px-4'>Send</div>
       </div>
       </div>
        </>
       }
    </div>
 )
}

const NoticePeriodModal = ({openModal, setOpenModal, type, confirmModal, setConfirmModal, occupiedPropertyData}) => {
  return (
  <>
    <Modal
    visible={openModal}
     width="350"
    height="320"
    effect="fadeInUp"
    onClickAway={()=>setOpenModal(false)}
    >
        <div className='w-full h-full rounded-lg'>
         <div className='px-6 pt-6 pb-2'>
            <div className='flex flex-col'>
                <div className='flex items-center justify-between'>
                    <img src={noticePeriod} alt="" className='border-8 rounded-[48px] bg-[#FEE2E2] border-[#FEF2F2]' />
                    <RxCross2 className='text-2xl cursor-pointer text-[#52525B]' onClick={()=>setOpenModal(false)}/>
                </div>
                <h1 className='mt-2 font-poppins font-semibold text-lg text-secondary'>Are you sure to start Notice Period?</h1>
                <p className='text-textColor font-poppins font-normal text-sm mt-2'>Initiate notice period for the rental {type}. Confirm your intention to vacate and proceed with the necessary steps.</p>
                
            </div>
         </div>
         <div className='w-full p-6 mt-2 mb-4 flex items-center space-x-3 justify-between'>
            <button onClick={()=>setOpenModal(false)} className='w-1/2 rounded-[4px] py-[10px] px-4 border border-[#E4E4E7] text-[#3F3F46] font-medium font-poppins text-sm'>No</button>
            <button onClick={()=>{
                setOpenModal(false);
                setConfirmModal(true);
            }} className='w-1/2 rounded-[4px] py-[10px] px-4 font-medium font-poppins text-sm text-white bg-[#DC2626] blog-button-shadow'>Confirm</button>
         </div>
        </div>
    </Modal>
    {
        confirmModal && <ConfirmModal  occupiedPropertyData={occupiedPropertyData} setConfirmModal={setConfirmModal}/>
    }
  </>
  )
}

export default NoticePeriodModal