import validationRules from "./validationRules";

export const validateField = (fieldName, value, data) => {
    if (validationRules[fieldName]) {
      if (validationRules[fieldName].validate) {
        return validationRules[fieldName].validate(value, data);
      } else if (validationRules[fieldName].regex) {
        return validationRules[fieldName].regex.test(value);
      }
    }
    return true;
  };
   