import React, { useState } from 'react'
import Account from './Account'
import { useLocation } from 'react-router-dom';

const AccountLogic = () => {
    const location = useLocation();
    const activePage = location?.state || "Personal details";
    const options = ["Personal details", "Occupied Property", "Occupied Services", "Payment", "Schedule visit"]
    const [activeOption, setActiveOption] = useState(activePage)
  return (
    <><Account
    options={options}
    activeOption={activeOption}
    setActiveOption={setActiveOption}
    /></>
  )
}

export default AccountLogic