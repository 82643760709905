import React, {useState, useEffect} from 'react'
import ImageCarousel from './ImageCarousel';
import { useDispatch, useSelector } from 'react-redux';

const ImageCarouselLogic = ({images, indicator, id, apibody, nofavourite}) => {
  const [isFavorited, setIsFavorited] = useState(false);
  const [loading, setLoading] = useState(false);
  const userDataString =  localStorage.getItem('userData');
  const [key, setKey] = useState(Date.now());
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const handleStorageChange = () => {
        setKey(Date.now());  
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

    useEffect(() => {
     
      if (userDataString) {
        const userData = JSON.parse(userDataString);
        const favouriteIds = userData.favourites.map(favourite => favourite._id);
        setIsFavorited(favouriteIds.includes(id));
      }
    }, [id, userDataString, key]);

    const goToImage = (index) => {
      setCurrentImageIndex(index);
    };

  return (
    <><ImageCarousel currentImageIndex={currentImageIndex}
     goToImage={goToImage}
     images={images}
     indicator={indicator}
     id={id}
     isFavorited={isFavorited}
     setIsFavorited={setIsFavorited}
     apibody={apibody}
     loading={loading}
     setLoading={setLoading}
     nofavourite={nofavourite}
    /></>
  )
}

export default ImageCarouselLogic