import React from 'react'
import SubNavbarCollegeLogic from '../../components/subNavbarCollege/SubNavbarCollegeLogic'
import ComingSoonLogic from '../../components/comingSoon/ComingSoonLogic'
import WhatsappContactLogic from '../../components/whatsappContact/WhatsappContactLogic'

const FlatmateByCollege = ({collegeData, collegeId}) => {
  return (
    <div>
       <WhatsappContactLogic/>
        <SubNavbarCollegeLogic
        collegeId={collegeId}
        />
       <div className='w-full md:px-20 md:pt-16 md:pb-24 px-5 py-6'>
       <ComingSoonLogic message={"Apologies, but it seems that local assistance is not available at the moment. We're constantly updating our database, so please check back later or explore our other services"}/>
       </div>
    </div>
  )
}

export default FlatmateByCollege