import React from 'react'
import Homepage from './Homepage'


const HomepageLogic = () => {
    // const videoUrl = 'https://www.youtube.com/embed/UVL6hzTvzvE?si=eL2ez86tc3UNtrvs'
    const videoUrl = '';
    
    
  return (
    <><Homepage
    videoUrl={videoUrl}
    /></>
  )
}

export default HomepageLogic