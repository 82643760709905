import React, { useState, useEffect, useRef } from 'react'
import ContactUs from './ContactUs'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import validationRules from '../../utils/validationRules'
import { validateField } from '../../utils/formValidationFunctions'
import { checkForErrors } from '../../utils/checkForErrors';
import { fetchContactUs } from "../../redux/slices/contactUsSlice"
import { showErrorToast, showSuccessToast, showInfoToast } from '../../utils/toastNotif'

const ContactUsLogic = () => {
  
  const screenWidth = useSelector(state => state.screenWidth.screenWidth);
  const collegeList = useSelector(state => state.dashboard.colleges)
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [requirederrors, setrequirederrors] = useState({});
  const [isCollegeDropdownOpen, setisCollegeDropdownOpen] = useState(false);
  const [selectedCollege, setselectedCollege] = useState(null);
  const [filteredColleges, setFilteredColleges] = useState([])
  const [searchQuery, setSearchQuery] = useState('');
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({ name: "", phoneNumber: "", email: "", subject: "", query: "", source: "website", termsAndConditionsAccepted: false, userType: "" })
  const requiredFields = ["name", "phoneNumber", "subject", "query", "source", "userType"]
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setFormData(prevFormData => ({
      ...prevFormData,
      termsAndConditionsAccepted: !prevFormData.termsAndConditionsAccepted
    }));
  };

  const toggleCollegeDropdown = () => {

    if (!isCollegeDropdownOpen) {
      if (status === 'loading') {
        showInfoToast("Loading college list");
        return;
      }
      if (status === 'failed') {
        console.log(error);
        showErrorToast("There was some problem fetching college list, please try again later")
        return;
      }
    }
    isCollegeDropdownOpen && setSearchQuery('')
    setisCollegeDropdownOpen(!isCollegeDropdownOpen);

  };

  const selectCollege = (userType) => {
    setFormData({ ...formData, userType: userType });
    setisCollegeDropdownOpen(false);
  };

  useEffect(() => {
    const tempCollege = collegeList.filter((college) =>
      college.collegeName.toLowerCase().includes(searchQuery.toLowerCase()) || college.collegeCode.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredColleges(tempCollege);
  }, [collegeList, searchQuery])

  const handleDataChange = (event) => {
    const { name, value } = event.target;
    if (value.trim() === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: '',
      }));
    } else if (validationRules[name]) {
      const isValid = validateField(name, value, formData);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: isValid ? '' : validationRules[name].message,
      }));
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  }

  const handleSubmit = () => {
    setSubmitClicked(true);
    const isErrors = checkForErrors(errors, formData, requiredFields, setrequirederrors);
    if (isErrors) {
      return;
    } else {
      const apiBody = {
        query: {
          userDetails: {
            name: formData?.name,
            phone: parseInt(formData?.phoneNumber),
            email: formData?.email
          },
          subject: formData?.subject,
          query: formData?.query,
          source: formData?.source,
          userType: formData?.userType,
          termsAndConditionsAccepted: formData?.termsAndConditionsAccepted
        }
      };
      if (apiBody) {
        dispatch(fetchContactUs({ apiBody }));
      }
    }


  }
  const { status, queryDetails, error } = useSelector(state => state.contactUs);
  useEffect(() => {
    if (submitClicked) {
      if (status === 'failed') {
        showErrorToast("Something went wrong, please try again")
        setIsChecked(false)
        setFormData({ name: "", lastName: "", phoneNumber: "", email: "", subject: "", query: "", source: "website", termsAndConditionsAccepted: false, collegeId: "" })
      } else if (status === 'succeeded') {
        showSuccessToast("Query submitted successfully, we will soon contact you")
        setFormData({ name: "", lastName: "", phoneNumber: "", email: "", subject: "", query: "", source: "website", termsAndConditionsAccepted: false, collegeId: "" })
        setIsChecked(false)
      }
    }
  }, [status, error])


  

  return (
    <><ContactUs
      
      screenWidth={screenWidth}
      isChecked={isChecked}
      handleCheckboxChange={handleCheckboxChange}
      formData={formData}
      handleDataChange={handleDataChange}
      status={status}
      errors={errors}
      requirederrors={requirederrors}
      handleSubmit={handleSubmit}
      isCollegeDropdownOpen={isCollegeDropdownOpen}
      toggleCollegeDropdown={toggleCollegeDropdown}
      selectCollege={selectCollege}
      selectedCollege={selectedCollege}
      collegeList={collegeList}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      filteredColleges={filteredColleges}
    /></>
  )
}

export default ContactUsLogic