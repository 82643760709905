import { showErrorToast, showInfoToast, showSuccessToast } from './toastNotif';
import RoutePath from '../routes/RoutePath';
import {verify} from './verifyUser'
export const handleFav = async (apibody, navigate, dispatch, setLoading, location)=>{
   
  try{
    setLoading(true)
    const newApibody = { service : apibody}
    const token = localStorage.getItem("token");
    if(!token || token === "undefined" || token === ""){
        showInfoToast("kindly login to continue");
        navigate(RoutePath.login, {state: {data: location?.pathname + location?.search}});
        return;
       }
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/users/updateFavourites`,{
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        }, body: JSON.stringify(newApibody)
    });

    if(response === null){
        console.log("Response is null");
        showErrorToast("Something went wrong, please try again later")
        return;
    }

    if(response?.status === 401) {
        console.log("Unauthorized");
        showInfoToast("Kindly login to continue");
        navigate(RoutePath.login, {state: {data: location?.pathname + location?.search}});
        return;
    }

    if(!response.ok){
        console.log(`Error fetching  favourite api: ${response.status} - ${response.statusText}`);
        showErrorToast("Error checking favourite, please try again later");
        return;
    }
    const data = await response.json();
    if (data === null || data === undefined) {
        console.log("Data is null or undefined")
        showErrorToast("Something went wrong, please try again later")
        return;
    }
     
    await verify(navigate, dispatch);
    showSuccessToast("Favourite updated")

  }catch(error){
    if (error.message === "Unauthorized") { 
      showInfoToast("Kindly login to continue"); 
      navigate(RoutePath.login, {state: {data: location?.pathname + location?.search}});
    } else {
      console.log(error);
      showErrorToast("Error updating favourites")
    }
  }finally{
    setLoading(false)
  }
}