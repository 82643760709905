import React from 'react';
import { FiChevronLeft } from "react-icons/fi";
import { FiChevronRight } from "react-icons/fi";

const Pagination = ({ totalPages, currentPage, handlePageChange }) => {
  const renderPageNumbers = () => {
    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        i === totalPages ||
        (i >= currentPage - 1 && i <= currentPage + 1)
      ) {
        pageNumbers.push(i);
      } else if (i === currentPage - 2 || i === currentPage + 2) {
        pageNumbers.push('...');
      }
    }

    return pageNumbers.map((pageNumber, index) => (
      <button
        key={index}
        className={`mx-1 w-[40px] h-[40px] font-poppins text-sm font-medium py-[10px] px-[17px]   rounded-md ${
          pageNumber === currentPage ? 'bg-[#FAFAFA] text-[#27272A]' : 'text-[#52525B]'
        }`}
        onClick={() =>
          typeof pageNumber === 'number' ? handlePageChange(pageNumber) : null
        }
        disabled={typeof pageNumber === 'string'}
      >
        {pageNumber}
      </button>
    ));
  };

  return (
    <div className="mt-4 flex justify-center items-center w-full">
      <button
        className={`${currentPage === 1 ? 'hidden' : 'flex'} rounded-[4px] py-[10px] px-4 border hover:bg-[#E4E4E7] border-[#E4E4E7] items-center space-x-2 blog-button-shadow font-poppins text-sm font-medium text-[#3F3F46]`}
        onClick={() => handlePageChange(currentPage - 1)}>
       <FiChevronLeft />
       <p>Previous</p>
      </button>
      <div className='flex items-center justify-center mx-auto'>{renderPageNumbers()}</div>
      <button
        className={`${currentPage === totalPages ? 'hidden' : 'flex'} hover:bg-[#E4E4E7] rounded-[4px] py-[10px] px-4 border border-[#E4E4E7] items-center space-x-2 blog-button-shadow font-poppins text-sm font-medium text-[#3F3F46]`}
        onClick={() => handlePageChange(currentPage + 1)}>
       <p>Next</p>
        <FiChevronRight />
      </button>
    </div>
  );
};

export default Pagination;
