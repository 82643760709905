import React from 'react'
import Footer from './Footer'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'


const FooterLogic = () => {
    const screenWidth = useSelector(state=>state.screenWidth.screenWidth)
    const navigate = useNavigate();
  return (
    <><Footer 
    screenWidth={screenWidth}
    navigate={navigate}
    /></>
  )
}

export default FooterLogic