import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    tokenId: '',
    userState: [],
  },
  reducers: {
    setTokenId: (state, action) => {
      state.tokenId = action.payload;
    },
    setUserState: (state, action) => {
      state.userState = action.payload;
    },
  },
});

export const { setTokenId, setUserState } = authSlice.actions;
export default authSlice.reducer;
