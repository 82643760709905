import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { DASHBOARD_BLOGS_LIMIT, DASHBOARD_TESTIMONIALS_LIMIT } from "../../utils/constants";

export const fetchDashboardData = createAsyncThunk(
    'dashboard/fetchDashboardData',
    async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/dashboard/getDashboardData`, {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            blogsLimit: DASHBOARD_BLOGS_LIMIT,
            testimonialLimit: DASHBOARD_TESTIMONIALS_LIMIT
          })
        });
       
        if (response === null) {
          console.log("Response is null");
          throw new Error("Response is null");
      }
        if (!response.ok) {
            console.log(`Error fetching dashboard data: ${response.status} - ${response.statusText}`);
            throw new Error(`Error fetching dashboard data: ${response.status} - ${response.statusText}`);
          }
          const data = await response.json();
          if (data === null || data === undefined) {
            console.log("Data is null or undefined")
            throw new Error("Data is null or undefined");
        }
              const { colleges, testimonials, blogs } = data;
              return { colleges, testimonials, blogs };
      
      } catch (error) {
        console.log("Error fetching dashboard data", error);
        throw error; 
      }
    }
  );
  

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: {
        colleges: [],
        testimonials: [],
        blogs: [],
        status: 'idle',
        error: null
      },

      reducers: {},
      extraReducers: (builder) => {
        builder
          .addCase(fetchDashboardData.pending, (state) => {
            state.status = 'loading';
          })
          .addCase(fetchDashboardData.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.colleges = action.payload.colleges;
            state.testimonials = action.payload.testimonials;
            state.blogs = action.payload.blogs;
          })
          .addCase(fetchDashboardData.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
          });
      }
})

export default dashboardSlice.reducer;