import React from 'react'
import WhatsappContactLogic from '../../components/whatsappContact/WhatsappContactLogic';
import BreadCrumbsLogic from '../../components/breadCrumbs/BreadCrumbsLogic';
import RoutePath from '../../routes/RoutePath';
import bed from "../../assets/images/bedIcon.png"
import truncateText from "../../utils/truncateText"
import redHeart from "../../assets/images/redHeartIcon.svg"
import SpinnerLogic from "../../components/spinner/SpinnerLogic"
import ImageCarouselLogic from "../../components/imageCarousel/ImageCarouselLogic"
import useFindLocality from "../../utils/findLocality";
const Flatmate = ({data})=>{
    return(
        <>
         {
             data?.length === 0  ? <div className="flex flex-col items-center"><div className=''>
             <h1 className="text-secondary font-poppins font-semibold text-2xl">No Favourites available</h1>
              <p className="text-lg font-poppins text-textColor mt-6 font-normal">currently no flatmate favourites are available, kindly check another favourite categories</p>    
             </div></div> : data.map((ele, ind)=>(
                <div key={ind} className='flex flex-col col-span-1'>
                   <div className='rounded-xl'><img src={ele?.img} alt={ele?.name} className='rounded-xl bg-cover w-full h-full'/></div>
                   <p className='text-[#0F172A] mt-5 text-base md:text-lg font-poppins font-semibold'>{ele?.name}</p>
                   <p className='text-textColor mt-1 text-xs font-normal font-poppins md:text-base'>{ele?.gender}</p>
                </div>
            ))
         }
        </>
    )

}

const Flat = ({data})=>{
  const {findLocalityName} = useFindLocality();
    return(
        <>
         {
            data?.length === 0  ? <div className="flex flex-col items-center"><div className=''>
            <h1 className="text-secondary font-poppins font-semibold text-2xl">No Favourites available</h1>
             <p className="text-lg font-poppins text-textColor mt-6 font-normal">currently no flat favourites are available, kindly check another favourite categories</p>    
            </div></div> : data.map((ele, ind)=>(
                <div key={ind} className='flex flex-col col-span-1'>
                  <div className='w-full h-full relative  rounded-xl object-cover'>
                    
                   <ImageCarouselLogic images={ele?.imgArr} id={ele?._id} apibody={{_id: ele?._id, type: "listing", imgArr: ele?.imgArr, localityId: ele?.localityId, apartmentName: ele?.apartmentName ? ele?.apartmentName : ele?.permanentAddress?.addressLine1 , genderPreference: ele?.genderPreference, rent: ele?.rent }}/>
                 </div>
                   <p className='text-[#0F172A] mt-5 text-base md:text-lg font-poppins font-semibold'>{findLocalityName(ele?.localityId)}</p>
                   <div className='text-textColor flex items-center space-x-2 mt-1 text-xs font-normal font-poppins md:text-base'>  <p >{ ele?.apartmentName }</p> <span>|</span><p>{ele?.genderPreference}</p></div>
                   <p className='mt-3 font-poppins text-sm md:text-lg font-semibold text-secondary'>₹{ele?.rent} <span className='text-textColor font-normal'>/month</span></p>
                </div>
            ))
         }
        </>
    )

}

const Furniture = ({data, screenWidth})=>{
    return(
        <>
         {
           data?.length === 0  ? <div className="flex flex-col w-full items-center"><div className=''>
           <h1 className="text-secondary font-poppins font-semibold text-2xl">No Favourites available</h1>
            <p className="text-lg font-poppins text-textColor mt-6 font-normal">currently no furniture favourites are available, kindly check another favourite categories</p>    
           </div></div> : data.map((ele, ind)=>(
                <div key={ind} className='flex flex-col col-span-1'>
                    <div className='w-full h-full relative  rounded-xl object-cover'>
                   <ImageCarouselLogic images={ele?.images} id={ele?._id} apibody={ele}/>
                 </div>
                   <div className='flex items-center space-x-2 mt-5'> <p className='text-[#0F172A]  text-base md:text-lg font-poppins font-semibold'>{ele?.name}</p> 
                   {/* {ele?.occupied && <span className='font-medium font-poppins text-xs text-center text-[#DA6910] bg-[#FFF4EB] py-[4px] px-[10px] rounded-[100px]'>Occupied</span>} */}
                   </div>
                   <p className='text-textColor mt-1 text-xs font-normal font-poppins md:text-base'>{ele?.category?.value} </p>
                   <p className='mt-3 font-poppins text-xs font-normal text-textColor'>Size </p>
                   <div className='bg-[#FFF4EB] md:w-[85%] flex items-center space-x-2 rounded-[24px] mt-1 py-2 px-3 font-poppins text-xs font-normal text-[#0F172A]'><img src={bed} alt={ele?.category} /> {screenWidth < 500 ? (
                <p>{truncateText(ele?.productDetails?.size, 3)}</p>
              ) : (
                <p>{truncateText(ele?.productDetails?.size, 7)}</p>
              )}</div>
                </div>
            ))
         }
        </>
    )

}

const LocalAssist = ({data})=>{
    return(
        <>
         {
            data?.length === 0  ? <div className="flex flex-col items-center"><div className=''>
            <h1 className="text-secondary font-poppins font-semibold text-2xl">No Favourites available</h1>
             <p className="text-lg font-poppins text-textColor mt-6 font-normal">currently no local assist favourites are available, kindly check another favourite categories</p>    
            </div></div> : data.map((ele, ind)=>(
                <div key={ind} className='flex flex-col col-span-1'>
                   <div className='w-full h-full relative  rounded-xl object-cover'>
                   <ImageCarouselLogic images={ele?.images} id={ele?._id} apibody={ele}/>
                 </div>
                   <p className='text-[#0F172A] mt-5 text-base md:text-lg font-poppins font-semibold'>{ele?.name}</p>
                   <p className='text-textColor mt-1 text-xs font-normal font-poppins md:text-base'>{ele?.category?.value}</p>
                   <p className='mt-3 font-poppins text-xs font-normal text-textColor'>Service</p>
                   <p className='mt-1 font-poppins text-xs font-normal text-[#0F172A]'>{ele?.details}</p>
                </div>
            ))
         }
        </>
    )
}

const FavouritePage = ({selectOptions, selectedOption, setSelectedOption, headings, favouriteData, screenWidth, status, error, navigate}) => {
  const filteredData = favouriteData?.filter(item => {
    return item?.category?.name === selectedOption || (!item?.category?.name && item?.type === selectedOption);
});

  return (
  <div className='md:py-12 md:px-20 py-6 px-5'>
  <BreadCrumbsLogic pathname={RoutePath.favourite}/>
  {
    status === "failed" ? 
    <div className='flex flex-col w-full min-h-screen  items-center justify-center'>
         <div className='text-center'>
         <h1 className="text-secondary font-poppins font-semibold text-5xl">Internal server error</h1>
          <p className="text-2xl font-poppins text-textColor mt-6 font-normal">We are facing some internal server error while fetching data, sorry for the inconvinience caused</p>
          <button onClick={()=>navigate(RoutePath.homepage)} className='blog-button-shadow background-primary mt-10 text-white text-lg rounded-[4px] px-4 py-2 font-medium font-poppins'>Go Back</button>
         </div>
          </div> 
     :   <>

        {
          status === 'loading'  && <><div className="absolute min-h-screen inset-0 bg-black opacity-50"></div><div className='absolute  z-30 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'><SpinnerLogic /></div></>
        }
     {
      favouriteData?.length === 0 ? <> <div className='flex flex-col w-full min-h-screen  items-center justify-center'>
      <div className='text-center'>
      <h1 className="text-secondary font-poppins font-semibold text-5xl">No Favourites available</h1>
       <p className="text-2xl font-poppins text-textColor mt-6 font-normal">currently no favourites are available, kindly check back later for updates</p>
       <button onClick={()=>navigate(RoutePath.homepage)} className='blog-button-shadow background-primary mt-10 text-white text-lg rounded-[4px] px-4 py-2 font-medium font-poppins'>Go Back</button>
      </div>
       </div> </> : <> 
      <WhatsappContactLogic/>
 <div className=''>
  <div className='flex md:flex-row flex-col md:items-center items-start w-full md:justify-between'>
  <h2 className='font-poppins text-secondary font-semibold md:text-[32px] mt-3 tracking-[-0.2px] text-xl md:leading-10'>My Favourite</h2>
  <select value={selectedOption} onChange={(e)=>setSelectedOption(e.target.value)} className='md:w-[296px] w-full cursor-pointer md:text-lg text-base mt-6 md:mt-0 outline-none font-poppins font-medium text-secondary border border-[#D4D4D8] px-4 py-[12px] md:py-[14px] md:rounded-xl rounded-lg'>
      {
        selectOptions && selectOptions.map((ele, ind)=>(
          <option value={ele?.value}>{ele?.name}</option>
        ))
      }
  </select>
  </div>
  <p className='font-poppins font-medium text-lg text-secondary  mt-6'>{headings[selectedOption]?.heading}</p>
  <p className='font-poppins text-textColor text-sm font-normal'>{headings[selectedOption]?.subheading}</p>
  <div className='grid mt-8 gap-5 grid-cols-1  min-[300px]:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'>
   {/* { selectedOption === 'flatmate' && <Flatmate data={filteredData}/> } */}
   {  selectedOption === 'localAssistance' && <LocalAssist data={filteredData}/> }
   {  selectedOption === 'listing' && <Flat data={filteredData}/> }
   {   selectedOption === 'furnishingCategory' && <Furniture data={filteredData} screenWidth={screenWidth}/>
   }
 </div>
  </div>   </>
     }
   </>
  }
  </div>
  )
}

export default FavouritePage