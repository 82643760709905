import React, { useState } from 'react'
import RoutePath from '../../routes/RoutePath'
import SpinnerLogic from '../../components/spinner/SpinnerLogic'
import FurnishingCardLogic from "../../components/furnishingCard/FurnishingCardLogic"
import ShippingAddressFormLogic from '../../components/ShippingAddressForm/ShippingAddressFormLogic'
import { HiOutlinePlus } from "react-icons/hi";
import { showErrorToast } from '../../utils/toastNotif'
import BreadCrumbsLogic from '../../components/breadCrumbs/BreadCrumbsLogic'
import { Badge, Modal } from 'antd'
import PaymentSelect from '../../components/paymentSelection/PaymentSelect'
import { TfiReceipt } from "react-icons/tfi";

const Cart = ({ deposit,gst,setEmail,email,netAmount,setSelectedMethod,selectedMethod,totalAmount, totalCharges, cartItems, paymentLoading, selectedAddress, navigate, shippingAddresses, promoCodeApplied, setPromoCodeApplied, loading, cartLoading, setCartLoading, setKey, openAddressForm, setOpenAddressForm, handleSelectAddress, addNew, setAddNew, handlePayment}) => {
    const [tempAddresses, setTempAddress] = useState(shippingAddresses ? shippingAddresses : []);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOk = () => {
        setIsModalOpen(false);
      };
      const handleCancel = () => {
        setIsModalOpen(false);
        setSelectedMethod('UPI')
      };
      const showmodal = () =>{
        setIsModalOpen(true);
      }
    return (
        <div className='md:px-20 md:py-12 py-6 px-5'>
            <BreadCrumbsLogic pathname={RoutePath.cart}/>
            {
                (loading || paymentLoading) ? <><div className="z-[9999] absolute min-h-full inset-0 bg-black opacity-50"></div><div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'><SpinnerLogic /></div></> : cartItems?.length === 0 ? <div className='min-h-screen w-full items-center justify-center'>
                    <div className='text-center'>
                        <h1 className="text-secondary font-poppins font-semibold text-5xl">Cart empty!</h1>
                        <p className="text-2xl font-poppins text-textColor mt-6 font-normal">You have not added any items in cart</p>
                        <button onClick={() => navigate(RoutePath.homepage)} className='blog-button-shadow background-primary mt-10 text-white text-lg rounded-[4px] px-4 py-2 font-medium font-poppins'>Go Back</button>
                    </div>
                </div> : <div>
                    <h1 className='text-secondary font-poppins font-semibold leading-10 text-[32px] tracking-[-0.2px]'>Shopping Cart</h1>
                    <div className='mt-[40px] flex flex-col md:flex-row  md:items-start '>

                        <div className='w-full md:flex-grow md:mr-5  flex flex-col'>
                            <div className='rounded-lg mb-7 w-full border border-[#D4D4D8] md:p-5 py-5 px-4'>
                                <div className='w-full flex items-center justify-between my-[20px]'>
                                    <p className='font-poppins font-medium md:text-xl text-lg text-secondary'>
                                        {openAddressForm ? 'Add Shipping Address' : (shippingAddresses.length > 0 ? 'Shipping Addresses' : "")}
                                    </p>
                                    <button onClick={() => {
                                        if(!openAddressForm && !selectedAddress){
                                            showErrorToast("select an address");
                                            return;
                                        }else{
                                            setOpenAddressForm(!openAddressForm)
                                    setAddNew(false);
                                        }
                
                                    }} className='text-sm font-medium font-poppins text-[#3F3F46]'>
                                        {openAddressForm ? 'Cancel' : (shippingAddresses.length > 0 ? 'Edit' : "")}
                                    </button>
                                </div>
                               {
                                !openAddressForm && <>
                                 {tempAddresses?.length > 0 && <hr className='w-full text-[#E4E4E7] my-5' />}
                                {tempAddresses?.length > 0 &&  tempAddresses?.map((address, index) => (
                                    <div key={index} className='flex w-full flex-col items-start'>
                                       <div className='flex items-start justify-between w-full'>
                                       <div>
                                       <p className='text-secondary font-poppins text-sm font-medium'>{address.name}</p>
                                        <p className='text-secondary font-poppins text-sm font-medium mt-2'>{address.phoneNumber}</p>
                                        <p className='text-[#52525B] font-poppins text-sm font-normal mt-2'>{address.addressLine1}, {address.city}, {address.state}, {address.pinCode}</p>
                                       </div>
                                        {/* <button onClick={() => handleSelectAddress(address)} className='text-sm font-medium cursor-pointer font-poppins text-primary mt-3'>{selectedAddress === tempAddresses[index] ? 'Selected' : 'Select'}</button> */}
                                        <button 
    onClick={() => handleSelectAddress(address)} 
    className='text-sm font-medium cursor-pointer font-poppins text-primary mt-3 flex items-center'
>
    {selectedAddress === tempAddresses[index] ? (
        <svg width="16" height="16" viewBox="0 0 24 24" fill='none' className="mr-2">
            <circle cx="12" cy="12" r="6" fill="currentColor"/>
            <circle cx="12" cy="12" r="11" stroke="currentColor" strokeWidth='2' />
        </svg>
    ) : (
        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" className="mr-2">
            <circle cx="12" cy="12" r="11" stroke="currentColor" strokeWidth="2"/>
        </svg>
    )}
</button>
                                       </div>
                                       <hr className='w-full text-[#E4E4E7] my-5' />
                                    </div>
                                ))}
                                </>                              }
                                {openAddressForm && <ShippingAddressFormLogic handleSelectAddress={handleSelectAddress} address = {selectedAddress} setTempAddress={setTempAddress} setOpenAddressForm={setOpenAddressForm} addNew={addNew}/>}
                                {!openAddressForm && <>
                                    <hr className='w-full text-[#E4E4E7] my-5' />
                                    <div onClick={() => {
                                        setAddNew(true)
                                        setOpenAddressForm(true)}} className='flex items-center space-x-3 cursor-pointer text-primary font-medium text-sm'><HiOutlinePlus className='text-primary text-xl' /><p>Add Shipping Address</p></div>
                                </>}
                            </div>
                            <div className='rounded-lg mb-7 border border-[#D4D4D8] md:p-5 py-4 md:px-4 px-3'>
                                <h1 className=' text-xl font-poppins font-medium text-secondary pl-5 md:pl-0'>Cart</h1>

                                <hr className='w-full text-[#E4E4E7] md:my-5 my-2' />
                                {
                                    cartItems && cartItems?.map((ele, ind) => (
                                        <div className=' flex flex-col items-center justify-center'>
                                            <FurnishingCardLogic ele={ele?.item} cartLoading={cartLoading} setCartLoading={setCartLoading} setKey={setKey} />
                                            <hr className='w-full text-[#E4E4E7] my-5' />
                                        </div>
                                    ))
                                }
                            </div>

                        </div>
                        <div className='md:w-[55%] w-full'>
                            <div className={`md:w-[100%] md:mt-0 rounded-lg py-5 px-4 bg-[#FAFAFA] md:bg-white  md:p-5 border border-[#D4D4D8] header-box-shadow w-full`}>
                                <h1 className='md:text-xl text-lg font-poppins text-orange-500 font-medium flex items-center gap-2'><TfiReceipt />Order Summary</h1>
                                {/* <hr className='w-full block md:hidden text-[#D4D4D8] my-1' /> */}
                                
                                <Badge.Ribbon text='One Time Payable' placement='start' color='orange' >
                                <div className="py-5 px-3 mt-5 border border-gray-300 rounded-lg">
                                <div className='flex items-center mt-5 font-poppins text-right font-normal text-sm text-[#52525B] w-full justify-between'><p>Refundable Security Deposit</p><p>₹{deposit}</p></div>
                                <div className='flex items-center mt-2 font-poppins text-right font-normal text-sm text-[#52525B] w-full justify-between'><p>Delivery Charge</p><p>₹{totalCharges}</p></div>
                                <hr className='w-full text-[#D4D4D8] my-1' />
                                <div className='flex items-center mt-2 font-poppins text-right font-normal text-sm text-[#52525B] w-full justify-between'><p>Total</p><p>₹{totalCharges+deposit}</p></div>
                                </div>
                                </Badge.Ribbon>
                                <Badge.Ribbon text='Monthly Payable' placement='start' color='orange'>
                                    <div className="py-5 px-3 mt-5 border border-gray-300 rounded-lg">
                                    <div className='flex items-center mt-5 font-poppins text-right font-normal text-sm text-[#52525B] w-full justify-between'><p>Total Rent</p><p>₹{totalAmount}/mo</p></div>
                                {/* <div className='flex items-center mt-2 font-poppins text-right font-normal text-sm text-[#52525B] w-full justify-between'><p>GST</p><p>₹{parseFloat(gst).toFixed(2)}/mo</p></div> */}
                                {/* <hr className='w-full text-[#D4D4D8] my-1' /> */}
                                {/* <div className='flex items-center mt-2 font-poppins text-right font-normal text-sm text-[#52525B] w-full justify-between'><p>Total ( incl. of GST )</p><p>₹{parseFloat(gst+ totalAmount).toFixed(2)}/mo</p></div> */}
                                    </div>
                                </Badge.Ribbon>
                                
                                
                                {
                                    parseInt(totalAmount) > 2000 && <div>
                                        <hr className='w-full text-[#D4D4D8] my-4' />
                                        <div className='w-full flex text-sm font-poppins text-right text-secondary font-medium items-center justify-between'><p>Sub-total</p><p>₹{totalAmount + totalCharges + deposit + gst}</p></div>
                                        <div className='w-full flex mt-2 text-sm font-poppins text-[#52525B] text-right font-normal items-center justify-between'><p>Free Delivery applied</p><p>-₹{totalCharges}</p></div>
                                    </div>
                                }
                                <div>
                                    <hr className='w-full text-[#D4D4D8] my-4' />
                                    <div className='w-full flex text-base font-poppins text-right text-secondary font-medium items-center justify-between'><p>Total Payable ( incl. of GST )</p><p>₹{parseInt(totalAmount) > 2000 ? parseFloat(totalAmount + deposit + gst).toFixed(2) : parseFloat(totalAmount + deposit + gst + totalCharges).toFixed(2)}</p></div>
                                </div>
                                <Modal title={<div className="font-bold text-xl md:text-2xl px-1">Payment Options</div>} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null} maskClosable={false} >
                                    <PaymentSelect selectedMethod={selectedMethod} setSelectedMethod={setSelectedMethod}/>
                                    <div className='flex items-center mt-5 font-poppins text-right font-normal text-sm text-[#52525B] w-full justify-between'><p>Total Amount</p><p>₹{parseInt(totalAmount) > 2000 ? parseFloat(totalAmount + deposit + gst).toFixed(2) : parseFloat(totalAmount + deposit + gst + totalCharges).toFixed(2)}</p></div>
                                    <div className='flex items-center mt-5 font-poppins text-right font-normal text-sm text-[#52525B] w-full justify-between'><p>Transaction Fee</p><p>+₹{parseInt(totalAmount) > 2000 ? (netAmount-(totalAmount + deposit + gst)).toFixed(2) : (netAmount-(totalAmount + deposit + gst + totalCharges)).toFixed(2)}</p></div>
                                    <hr className='w-full text-[#D4D4D8] my-4' />
                                    <div className='flex items-center mt-5 font-poppins text-right font-normal text-sm text-[#52525B] w-full justify-between'><p>Final Amount</p><p className='text-md font-semibold' >₹{parseFloat(netAmount).toFixed(2)}</p></div>
                                    <button onClick={handlePayment} disabled={cartLoading} className='background-primary px-5 py-3 mt-10 w-full rounded blog-button-shadow font-poppins text-white font-medium text-base'>Pay ₹{parseFloat(netAmount).toFixed(2)}</button>

                                </Modal>
                                <div className='w-full'>
                                <p className='font-poppins mt-2 font-medium text-md text-[#3F3F46]'>Email<span className='text-red-600 text-lg' >*</span></p>
                                <input type="text" placeholder='abc@gmail.com' name="email" value={email} onChange={(e)=>setEmail(e.target.value)} className='w-full outline-none text-textColor font-poppins text-base font-normal mt-2 blog-button-shadow border border-[#D4D4D8] rounded py-[10px] px-[14px] '/>
                                </div>
                                <button onClick={showmodal} disabled={cartLoading || !email || totalAmount < 1499} className={`background-primary px-5 py-3 mt-10 w-full rounded blog-button-shadow font-poppins text-white font-medium text-base ${email && (totalAmount > 1499)  ? '' : 'disabled:opacity-50'}`}>{parseInt(totalAmount) > 1499 ? 'Proceed to Pay' : `Add items worth ₹${1499-totalAmount} to Continue`}</button>
                            </div>
                            {/* <div className='rounded-lg border border-[#D4D4D8] py-5 mt-7 px-4 md:p-5'>
                                <h1 className='text-secondary font-poppins font-medium text-lg md:text-xl'>Do you have a promo code?</h1>
                                <hr className='w-full my-5 text-[#D4D4D8]' />
                                <div className='w-full md:justify-between items-center flex flex-col md:flex-row'>
                                    <input placeholder='Enter your Promo Code' type="text" className='py-[10px] px-[14px] rounded blog-button-shadow border border-[#D4D4D8] font-poppins font-normal md:flex-grow w-full md:mr-3 text-base text-textColor outline-none' />
                                    <button onClick={() => setPromoCodeApplied(true)} className='background-primary mt-3 md:mt-0 text-white font-medium text-sm font-poppins rounded px-4 py-[10px] w-full md:w-[112px] blog-button-shadow'>Apply</button>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default Cart