import React from 'react'
import ContactUsLogic from "../../components/contactUs/ContactUsLogic"
import FaqLogic from "../../components/faq/FaqLogic"
import listing from "../../assets/images/listingHomepage.svg"
import headavatar from "../../assets/images/headAvatar.svg"
import star from "../../assets/images/starIcon.svg"
import RoutePath from "../../routes/RoutePath"
import { useNavigate } from 'react-router'
import truncateText from '../../utils/truncateText'
import useFindCollege from '../../utils/findCollege'
import heroImg from '../../assets/images/HeroWoButton.svg'
import owners from "../../assets/images/2500+ Happy House Owner Icon.svg"
import income from "../../assets/images/more Income Icon.svg"
import tenants from "../../assets/images/5000+ Happy Tenants.svg"
import uspFull from "../../assets/images/USPs full.svg"
import agentSupport from "../../assets/images/Agent Support Photo.svg"
import moreIncome from "../../assets/images/More Income Photo.svg"
import guidedVisit from "../../assets/images/Guided Visit Photo.svg"
import paperwork from "../../assets/images/Paperworks Photo.svg"
import collection from "../../assets/images/Rent Collection Photo.svg"
import manage from "../../assets/images/Manage Properties Photo.svg"
import { useSelector } from 'react-redux'




const MainHeader = ({handleClick})=>{
  const navigate = useNavigate();
  return(
    <div className='flex md:flex-row flex-col-reverse w-[90%] mx-auto py-5 md:py-20 items-center justify-between'>
      <div className='flex md:w-[55%] flex-col items-start'>
        <p className='text-[#52525B] font-normal text-base md:text-lg font-poppins'>List your Properties</p>
        <h2 className='text-secondary mt-2 tracking-[-0.2px] text-[36px] leading-[44px] md:text-[48px] md:leading-[56px] font-poppins font-semibold'>Tell us about your place</h2>
        <p className='font-poppins mt-3 font-normal text-lg text-[#52525B]'>In this step, we'll ask you which type of property you have and if anyone will book the entire place or just a room.</p>
        <button onClick={handleClick} className='background-primary font-poppins font-medium text-base mt-10 blog-button-shadow rounded-[4px] py-[12px] px-5 text-white'>Get started</button>
      </div>
      <img src={listing} alt="" className='md:w-[45%]'/>
    </div>
  )
}

const ListingTestimonials = ({testimonialData, currentSlide, handleIndicatorClick})=>{
  const {findCollegeName} = useFindCollege();
    return(
        <div className='md:px-20 md:py-24 py-8 flex flex-col items-center'>
           <h3 className='font-poppins font-semibold text-[32px] leading-10 px-5 md:px-0 md:text-[40px] md:leading-[48px] text-center tracking-[-0.2px] text-secondary'>Don't just take our word for it</h3>
           <p className='text-textColor mt-2 text-base text-center px-5 md:px-0 md:text-lg font-poppins font-normal'>Hear from some of our amazing customers who are building faster.</p>
           <div className='mt-8 relative flex  items-center md:w-[90%] mx-auto w-full min-h-[462px] bg-[#FAFAFA] py-8 px-5 md:rounded-xl md:p-16 overflow-hidden '>
  {testimonialData.map((ele, index) => (
    <div key={index} className={`flex w-full items-center flex-none mx-auto flex-col transition-transform duration-1000 ease-in-out ${currentSlide === index ? 'opacity-100' : 'opacity-0'} `} style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
    <h1 className='font-poppins md:font-medium font-normal text-[28px] leading-[36px] tracking-[-0.2px] text-secondary text-center md:text-[36px] md:leading-[44px]'>"{truncateText(ele?.description , 20)}"</h1>
    <img className='rounded-full mt-5' src={headavatar} alt="pic" />
    <p className='mt-2 text-secondary text-lg font-poppins text-center font-semibold md:font-medium'>{ele?.user?.name}</p>
    <p className='font-normal text-base font-poppins mt-1 text-textColor'>{findCollegeName(ele?.user?.college)}</p>
    <div className='flex items-center mt-2 space-x-1'>
    {Array.from({ length: ele?.rating}).map((_, index) => (
        <>
        <img src={star} alt={index} />
        </>
      ))}
    </div>
    
    </div>
  ))}
  <div className='absolute z-10 bottom-[20px] left-1/2 transform -translate-x-1/2 flex space-x-2'>
            {Array.from({ length: testimonialData?.length}).map((_, index) => (
          <div
            key={index}
            className={`w-[10px] h-[10px] rounded-full ${
              index === currentSlide ? 'background-primary' : 'bg-[#F4F4F5]'
            } cursor-pointer`}
           
          ></div>
        ))}
            </div>
            
</div>

</div>
      
    )
} 


const ListingHomepage = ({testimonialData, currentSlide, handleIndicatorClick, handleClick}) => {

  return (
    <div className='min-h-screen max-w-screen overflow-x-hidden'>

          <div  className='max-w-screen flex justify-center items-start relative ' >
          <img className='w-full ' src={heroImg} alt="bg" />
          <button onClick={handleClick} className='absolute md:right-20 md:bottom-14 bottom-3 right-5 border-2 rounded-full md:p-5 p-1 md:w-[180px] w-[80px] hover:bg-white hover:text-orange-400 duration-300 font-poppins md:font-semibold text-white text-xs md:text-xl' >Get Started</button>
        </div>
        <div className='w-full flex md:justify-evenly  py-10 md:px-40 px-20' >
          <div>
            <img src={owners} alt="" />  
          </div>
          <div>
            <img src={income} alt="" />
          </div>
          <div>
            <img src={tenants} alt="" />
          </div>
        </div>
        <div className='w-full flex border justify-center md:py-10 px-10' ><img src={uspFull} alt="" /></div>
        <div className='w-full flex flex-col items-center md:px-40 py-10' >
          <h1 className='text-center md:text-5xl text-3xl font-bold font-poppins md:mb-10 mb-5 ' >We manage your Gharbar</h1>
          <div className='flex md:flex-row flex-col w-full p-10' >
            <div>
              <img src={agentSupport} alt="" />
            </div>
            <div className='flex flex-col md:w-2/3 w-full md:pl-20 py-5' >
              <p className='md:text-4xl text-3xl font-poppins font-semibold'>Agent Support</p>
              <p className='md:text-xl text-md mt-5'>Our dedicated channel partners, located in your locality, are readily available to assist house owners in every possible way, ensuring a seamless experience.</p>
            </div>
          </div>
          <div className='flex md:flex-row-reverse flex-col w-full p-10' >
            <div>
              <img src={guidedVisit} alt="" />
            </div>
            <div className='flex flex-col md:w-2/3 w-full py-5 md:pr-20' >
              <p className='md:text-4xl text-3xl font-poppins font-semibold'>Guided Visits</p>
              <p className='md:text-xl text-md mt-5'>Say goodbye to the stress of arranging house visits. We provide guided tours to potential tenants, allowing house owners to sit back and relax.</p>
            </div>
          </div>
          <div className='flex flex-col md:flex-row w-full p-10' >
            <div>
              <img src={paperwork} alt="" />
            </div>
            <div className='flex flex-col md:w-2/3 w-full md:pl-20 py-5' >
              <p className='md:text-4xl text-3xl font-poppins font-semibold' >Paperworks</p>
              <p className='md:text-xl text-md mt-5'>From rent agreements to police verifications, we take care of all necessary paperwork, facilitating smooth tenant onboarding for house owners.</p>
            </div>
          </div>
          <div className='flex md:flex-row-reverse flex-col w-full p-10' >
            <div>
              <img src={collection} alt="" />
            </div>
            <div className='flex flex-col md:w-2/3 w-full py-5 md:pr-20' >
              <p className='md:text-4xl text-3xl font-poppins font-semibold'>Rent Collection</p>
              <p className='md:text-xl text-md mt-5' >No more chasing tenants for rent payments or sharing private banking information. We handle rent collection on behalf of house owners, ensuring convenience and security.</p>
            </div>
          </div>
          <div className='flex w-full flex-col md:flex-row p-10' >
            <div>
              <img src={manage} alt="" />
            </div>
            <div className='flex flex-col md:w-2/3 w-full md:pl-20 py-5' >
              <p className='md:text-4xl text-3xl font-poppins font-semibold'>Manage Properties</p>
              <p className='md:text-xl text-md mt-5'>Easily manage your rental properties through our user-friendly platform, giving you full control at your fingertips.</p>
            </div>
          </div>
          <div className='flex md:flex-row-reverse flex-col w-full p-10' >
            <div>
              <img src={moreIncome} alt="" />
            </div>
            <div className='flex flex-col md:w-2/3 w-full py-5 md:pr-20' >
              <p className='md:text-4xl text-3xl font-poppins font-semibold'>More Income</p>
              <p className='md:text-xl text-md mt-5'>Benefit from higher tenant inflow and furnished properties, reducing vacancy rates by 20-25%. With Gharbar, house owners can earn up to 20-25% more income compared to traditional methods.</p>
            </div>
          </div>
        </div>
        
       {
        testimonialData?.length > 0 &&  <ListingTestimonials testimonialData={testimonialData} currentSlide={currentSlide}
        handleIndicatorClick={handleIndicatorClick}/>
       }
        <FaqLogic type={'listing'}/>
        <ContactUsLogic/>
    </div>
  )
}

export default ListingHomepage